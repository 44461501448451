import { isBefore } from "date-fns";
import { useEffect, useState } from "react";

import { useMounted } from "../../../libs/component_utils";
import CommonService from "../../../services/common";

const commonService = new CommonService();

const useDealForm = ({ intl, onError }) => {
  const isMounted = useMounted();
  const [currencies, setCurrencies] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [dealChannels, setDealChannels] = useState([]);
  const [supplySources, setSupplySources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [currenciesResp, supplySourcesResp, publishersResp, dealChannelsResp] = await Promise.all([
          commonService.getCurrencies(),
          commonService.getSupplySources(),
          commonService.getPublishers(),
          commonService.getDealChannels(),
        ]);

        setCurrencies(
          currenciesResp.data.map(({ id, title, currency_code, symbol }) => ({
            key: id,
            text: `${title} (${currency_code})`,
            value: currency_code,
            symbol: symbol,
          })),
        );
        setPublishers(
          publishersResp.data.map(({ id, title }) => ({
            key: id,
            text: title,
            value: id,
          })),
        );
        setDealChannels(
          dealChannelsResp.data.map(({ id, title }) => ({
            key: id,
            text: title,
            value: id,
          })),
        );
        setSupplySources(
          supplySourcesResp.data.map(({ id, title }) => ({
            key: id,
            text: title,
            value: id,
          })),
        );
      } catch (_) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();

    // clear cache
    return () => {
      setCurrencies([]);
      setPublishers([]);
      setDealChannels([]);
      setSupplySources([]);
    };
  }, []);

  useEffect(() => {
    if (hasError && onError) {
      const message = "Failed to fetch data";
      onError(
        intl
          ? intl.formatMessage({
              id: "FAILED_FETCH_DATA",
              defaultMessage: message,
            })
          : message,
      );
    }
  }, [hasError, onError, intl]);

  /**
   * Do a custom validation
   * @param e
   * @param {object} fields
   */
  const formRules = {
    dealName: (value) =>
      !value || value.length < 3 || value.length > 256
        ? intl.formatMessage({
            id: "ERROR_MIN_DEAL_NAME_REQUIRED",
            defaultMessage: "Deal Name must be between 3 and 256 characters",
          })
        : false,
    dealDescription: (value) =>
      !value && value.length > 4095
        ? intl.formatMessage({
            id: "ERROR_MAX_CHARS_DEAL_DESC",
            defaultMessage: "Description must be up to 4095 characters",
          })
        : false,
    dealId: (value) =>
      !value || value.length < 3 || value.length > 256
        ? intl.formatMessage({
            id: "ERROR_MIN_DEAL_ID_REQUIRED",
            defaultMessage: "Deal ID must be between 3 and 256 characters",
          })
        : false,
    startDate: (value, fields) => {
      // if value has started, it should'n be validated
      if (fields.id && isBefore(value, new Date())) return false;

      return !value || new Date(value) <= new Date()
        ? intl.formatMessage({
            id: "ERROR_START_DATE_FUTURE",
            defaultMessage: "Start Date must be in the future",
          })
        : false;
    },
    endDate: (value, fields) =>
      !fields.neverEnds && (!value || new Date(value) <= new Date(fields.startDate))
        ? intl.formatMessage({
            id: "ERROR_END_DATE_AFTER_START",
            defaultMessage: "End Date must be after Start Date",
          })
        : false,
    dealPrice: (value) =>
      !value || value <= 0
        ? intl.formatMessage({
            id: "ERROR_DEAL_PRICE_POSITIVE",
            defaultMessage: "Deal Price must be greater than 0",
          })
        : false,
    exchange: (value) =>
      value === ""
        ? intl.formatMessage({
            id: "ERROR_EXCHANGE_REQUIRED",
            defaultMessage: "Select an exchange",
          })
        : false,
    channel: (value) =>
      value.length === 0
        ? intl.formatMessage({
            id: "ERROR_CHANNEL_REQUIRED",
            defaultMessage: "At least one Channel must be selected",
          })
        : false,
  };

  return {
    currencies,
    dealChannels,
    publishers,
    supplySources,
    isLoading,
    isMounted: isMounted.current,
    formRules,
  };
};

export default useDealForm;
