import React, {useCallback, useLayoutEffect, useState} from "react";
import PropTypes from "prop-types";
import PixelsService from "../../../services/pixels";
import {useIntl} from "react-intl";
import {Divider, Header, Message, Segment} from "semantic-ui-react";
import PixelRoiForm from "../form/roi";

const initialData = {
  "status": true,
  "title": "",
  "advertiser_id": null,
  "attribution_level": "brand",
  "brand_names": [],
  "sku_ids": ""
};
const PixelRoiCreatePage = ({history}) => {
  const back_url = "/pixels";
  const service = new PixelsService();
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  let _isMounted = React.useRef(true);
  const intl = useIntl();

  // mark component as loaded
  useLayoutEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  /**
   * navigate user back
   */
  const navigateToGrid = useCallback(() => {
    history.push(back_url);
  }, []);

  /**
   * form submit handler
   * @param {object} values
   * @param {File} file
   */
  const handleSubmit = async values => {
    setLoading(true);

    try {
      const pixel = {
        ...values,
        "status": Boolean(values.status),
        "sku_ids": values.sku_ids && values.attribution_level === "sku" ? values.sku_ids.split(",").map(sku => sku.trim()) : []
      }

      delete pixel["attribution_level"];
      await service.create_roi(pixel);
      history.push(back_url, { "action": "created" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_CREATE_ROI_PIXEL",
          defaultMessage: "Create ROI Pixel",
        })}
      </Header>
      <Divider />
      <Message
        style={{"marginTop": "10px"}}
        error
        hidden={!serverError}
        size="tiny"
        content={serverError}
      />
      <PixelRoiForm
        formSending={loading}
        initialData={initialData}
        submitText={intl.formatMessage({
          id: "BTN_CREATE_ROI_PIXEL",
          defaultMessage: "Create ROI Pixel",
        })}
        onSubmit={handleSubmit}
        onCancel={navigateToGrid}
      />
    </Segment>
  );
};
PixelRoiCreatePage.propTypes = {
  "history": PropTypes.object.isRequired
};

export default PixelRoiCreatePage;
