import { addDays, addMonths, setHours, setMinutes } from 'date-fns';
import { utcToZonedTime } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Segment } from "semantic-ui-react";

import { Config } from "../../../config/api";
import { getCampaignType, getClosestTo15Date } from "../../../libs/common_utils";
import CampaignsService from "../../../services/campaign";
import CampaignForm from "../form";

/**
 * Campaign create page component
 * @param history
 * @return {*}
 * @constructor
 */
const CampaignCreatePage = ({ history }) => {
	const intl = useIntl();
	const campaignsService = new CampaignsService();
	const campaign_type = Config.strategy_supply_type;
	const {
		whitelabelSupplyPgFlag,
		whitelabelSupplyPmpFlag,
		whitelabelSupplyAudienceFlag,
		whitelabelSupplyOpenFlag,
    whitelabelCtvCampaign,
    whitelabelOnSiteCampaign,
    whitelabelCampaignBidOnCrossDevice
	} = useFlags();

	var parent_url = "/campaigns";

	const handleCancel = useCallback(() => {
		history.push(parent_url);
	}, []);

	/**
	 * Handle create form submit
	 * @param {object} values
	 * @returns {Promise<void>}
	 */
	const handleSubmit = async (values) => {
		const response = await campaignsService.create(values);
		if (response.hasOwnProperty("data") && response.data) {
			if (response.data.hasOwnProperty("t1_id") && response.data.t1_id) {
				const campaign_id = response.data.t1_id;
				parent_url = campaign_id.toString().concat("/strategies");
			}
		}
		history.push(parent_url, { "action": "created", "type": "campaign" });
	};

	const isPGAvailableOnly =
		whitelabelSupplyPgFlag &&
		!whitelabelSupplyPmpFlag &&
		!whitelabelSupplyAudienceFlag &&
		!whitelabelSupplyOpenFlag;

  const nextDate = addDays(new Date().setSeconds(0), 1);
	const budgetFlightStart = setHours(setMinutes(utcToZonedTime(nextDate,  Config.defaultTimezone), 0), 0);

	const initialData = {
		"status": true,
		"campaign_name": "",
		"advertiser_id": null,
		"campaign_type": isPGAvailableOnly ? "PG" : getCampaignType(campaign_type),
		"time_zone": Config.defaultTimezone,
		"currency": Config.defaultCurrency,
		"budget_flights": [
			{
				"start_date": budgetFlightStart,
				"end_date": addMonths(budgetFlightStart, 1),
				"total_impression_budget": null,
				"total_budget": 0,
			}
		],
		"goal_type": "cpc",
		"goal_value": "",
		"spend_cap_type": "no-limit",
		"spend_cap_automatic": 0,
		"spend_cap_amount": "",
		"frequency_optimization": 1,
		"frequency_type": "asap",
		"frequency_amount": "",
		"frequency_interval": "day",
		"restrict_targeting_to_same_device_id": 0,
		"merit_pixel_id": "",
		"pc_window_days": 30,
		"pv_window_days": 7,
		"margin_pct": 0,
		"site_list_ids": []
	};

  if(whitelabelCtvCampaign){
    initialData.ctv = 0;
  }

  if(whitelabelOnSiteCampaign){
    initialData.onsite = 0;
  }

  if(whitelabelCampaignBidOnCrossDevice){
    initialData["restrict_targeting_to_same_device_id"] = 0;
  }

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_CREATE_CAMPAIGN",
					defaultMessage: "Create Campaign",
				})}
			</Header>
			<Divider />
			<CampaignForm
				initialData={initialData}
				submitText={intl.formatMessage({
					id: "BTN_CREATE_CAMPAIGN",
					defaultMessage: "Create Campaign",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default CampaignCreatePage;
