import React, {useCallback, useState} from "react";
import { zonedTimeToUtc } from "date-fns-tz";

import {
	Divider,
	Header,
	Segment
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import StrategyManageContext from "../common/context";
import "react-datepicker/dist/react-datepicker.css";
import CampaignsService from "../../../services/campaign";
import {campaignHasPGType, currencySymbol, isDigit, parseAPIDateTime} from "../../../libs/common_utils";
import StrategiesService from "../../../services/strategy";
import {StrategyNavigationEditComponent} from "../common";
import CommonService from "../../../services/common";
import AgenciesService from "../../../services/agencies";
import AudiencesService from "../../../services/audiences";
import CreativesService from "../../../services/creatives";
import T1Service from "../../../services/t1";
import {NavigationBlockerModal} from "../common/navigation_blocker";
import PixelsService from "../../../services/pixels";

/**
 * Strategy create page component
 * @param history
 * @param match
 * @param step
 * @return {*}
 * @constructor
 */
let _commonData = {
	"currencies": [],
	"timezones": [],
	"strategy": {},
	"campaign": {}
};
const StrategyEditPage = ({history, match, step}) => {
	const campaign_id = match.params.campaign_id,
		strategy_url = `/campaign/${campaign_id}/strategies`;

	const intl = useIntl();
	const block = React.useRef(),
		url_to_navigate = React.useRef(strategy_url);

	const [activeStep, setActiveStep] = useState(parseInt(step));
	const strategy_id = match.params.strategy_id;
	const services = React.useRef(new Map([["strategies", new StrategiesService()],
		["strategies_common", new StrategiesService()],
		["agencies", new AgenciesService()],
		["creatives", new CreativesService()],
		["commonService", new CommonService()],
		["t1", new T1Service()],
		["audiences", new AudiencesService()],
		["pixels", new PixelsService()],
		["campaigns", new CampaignsService()]]));

	const [showBlocker, setShowBlocker] = useState(false);
	const [dataLoading, setDataLoading] = useState(true);
	let _isMounted = React.useRef(true);

	React.useEffect(() => {
		// load campaign
		(async () => {
			try {
				let promises = [];
				if(!_commonData.strategy?.id || _commonData.strategy.id !== strategy_id) {
					promises.push(services.current.get("strategies").get(strategy_id));
				} else {
					promises.push({"data": _commonData.strategy});
				}

				if(!_commonData.currencies.length) {
					promises.push(services.current.get("commonService").getCurrencies());
				} else {
					promises.push({"data": _commonData.currencies});
				}

				if(!_commonData.timezones.length && activeStep === 0) {
					promises.push(services.current.get("commonService").getTimeZones());
				} else {
					promises.push({"data": _commonData.timezones});
				}

				if(!_commonData.campaign?.id || _commonData.campaign.id !== campaign_id) {
					promises.push(services.current.get("campaigns").get(campaign_id));
				} else {
					promises.push({"data": _commonData.campaign});
				}

				const [strategy, currencies, timezones, response] = await Promise.all(promises);

				const campaignData = response.data;
				if(!campaignLoaded(campaignData)) {
					// throw new Error("campaign is invalid and should be redirected");
					console.warn("campaign is invalid - should be redirected");
					return stepNavigation.backToGrid();
				}

				if(!validStrategyLoaded(strategy.data, campaignData)) {
					console.warn("invalid strategy/campaign combination - should be redirected");
					return stepNavigation.backToGrid();
				}

				campaignData.currency_symbol = currencySymbol(campaignData.currency_code, currencies.data);
				campaignData.is_pg = campaignHasPGType(campaignData);
				campaignData.budget_flights.forEach(flight => {
					flight.start_date = parseAPIDateTime(flight["start_date"]);
					flight.end_date = parseAPIDateTime(flight["end_date"]);
					flight.start_date_tz = zonedTimeToUtc(flight.start_date, campaignData.time_zone);
					flight.end_date_tz = zonedTimeToUtc(flight.end_date, campaignData.time_zone);
				});

				_commonData = {
					..._commonData,
					"currencies": currencies.data,
					"timezones": timezones.data,
					"strategy": strategy.data || {},
					"campaign": campaignData
				}

			} catch (e) {
				console.error(e);
				return stepNavigation.backToGrid();
			} finally {
				if(_isMounted.current) {
					setDataLoading(false);
				}
			}
		})();

		// let's show modal warning when user leaves the page
		block.current = history.block(tx => {
			if(!~tx.pathname.search(/^\/campaign\/\d+\/strategies\/edit.+/)) {
				url_to_navigate.current = tx.pathname;
				setShowBlocker(true);
				return false;
			}
		});

		return () => {
			if(block.current) {
				block.current();
			}

			_isMounted.current = false;
		}
	}, [campaign_id]);

	/**
	 * do a hard redirect
	 */
	const unblock = () => {
		block.current();
		history.push(url_to_navigate.current);
	};

	/**
	 * navigate user back
	 * @param {boolean} force
	 */
	const getBack = (force=false) => {
		if(force) {
			block.current();
		}
		history.push(strategy_url);
	};

	const campaignLoaded = (item) => item.hasOwnProperty("id") && isDigit(item.id) && item.id > 0;
	const validStrategyLoaded = (strategy, campaign) => (strategy?.id)? strategy.campaign_id === campaign.id : true;

	/**
	 * Store flag that campaign created and navigate user back to the grid
	 */
	const strategySuccessfullyUpdated = (strategyName) => {
    block.current();
		history.push(strategy_url, {"action": "updated", "name": strategyName});
	};

	/**
	 * update strategy data
	 * @param {object} strategy
	 */
	const updateStrategyData = (strategy) => {
		_commonData = {
			..._commonData,
			"strategy": strategy
		}
	};

	/**
	 * step navigation class
	 */
	const stepNavigation = {
		"passMarketingObjective": id => {
			// setActiveStep(1);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/supplies`);
		},
		"backToMarketingObjective": id => {
			// setActiveStep(0);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}`);
		},
		"backToSupplies": id => {
			// setActiveStep(1);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/supplies`);
		},
		"passSupplies": id => {
			// setActiveStep(2);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/creatives`);
		},
		"backToGrid": () => {
			history.push(`/campaign/${campaign_id}/strategies`);
		},
		"backToCreatives": id => {
			// setActiveStep(2);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/creatives`);
		},
		"passCreatives": id => {
			// setActiveStep(3);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/audiences`);
		},
		"backToAudiences": id => {
			// setActiveStep(3);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/audiences`);
		},
		"passAudiences": id => {
			// setActiveStep(4);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/locations`);
		},
		"backToLocations": id => {
			// setActiveStep(4);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/locations`);
		},
		"passLocations": id => {
			// setActiveStep(5);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/dayparts`);
		},
		"backToDayparts": id => {
			// setActiveStep(5);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/dayparts`);
		},
		"passDayparts": id => {
			// setActiveStep(6);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/technology`);
		},
		"backToTechnology": id => {
			// setActiveStep(6);
			history.push(`/campaign/${campaign_id}/strategies/edit/${id}/technology`);
		}
	};

	// do not load whole component until
	if(!campaignLoaded(_commonData.campaign) || dataLoading) {
		return (<Segment disabled tertiary textAlign="center" className="loading">
			&nbsp;
		</Segment>);
	}

	return (
		<StrategyManageContext.Provider value={{
			getBack, services, "strategy_id": strategy_id,
			stepNavigation, updateStrategyData, strategySuccessfullyUpdated,
			"campaign": _commonData.campaign,
			"currencies": _commonData.currencies,
			"timezones": _commonData.timezones,
			"strategy": _commonData.strategy
		}}>
			<Segment basic>
				<Header as="h2">
					{intl.formatMessage({
						id: "HEADING_EDIT STRATEGY",
						defaultMessage: "Edit Strategy",
					})}
				</Header>
				<Divider />
				{showBlocker && <NavigationBlockerModal unblock={() => unblock()} close={() => setShowBlocker(false)} />}
				<StrategyNavigationEditComponent is_pg={campaignHasPGType(_commonData.campaign)} current_step={activeStep} />
			</Segment>
		</StrategyManageContext.Provider>
	)
};

export default StrategyEditPage;
