import CreativeCreatePage from "../components/creative/create";
import CreativeEditPage from "../components/creative/edit";
import { CreativesPage } from "../components/creative/index";

export const Routes = (flags = {}) => {
  const { whitelabelChannelDisplay, whitelabelChannelVideo, whitelabelDoubleCreativeApproval } = flags;
  const isVideo = !whitelabelChannelDisplay && whitelabelChannelVideo;

  const creativeChannelProps = {
    defaultType: isVideo ? "video" : "display",
    title: isVideo ? "Video" : "Display",
  };

  const routes = [
    {
      component: CreativesPage,
      creative_type: creativeChannelProps.defaultType, // TODO: fix these prop names
      path: "/creatives",
      title: ["Creatives"],
    },
    {
      component: CreativeCreatePage,
      creative_type: creativeChannelProps.defaultType,
      path: "/creative/create",
      title: ["Creatives", creativeChannelProps.title, "Create"],
    },
    {
      component: CreativeEditPage,
      creative_type: creativeChannelProps.defaultType,
      path: "/creative/edit/:creative_id",
      title: ["Creatives", creativeChannelProps.title, "Edit"],
    },
  ];

  if (whitelabelChannelVideo) {
    routes.push(
      {
        component: CreativeCreatePage,
        creative_type: "video",
        path: "/creative/video/create",
        title: ["Creatives", "Video", "Create"],
      },
      {
        component: CreativeEditPage,
        creative_type: "video",
        path: "/creative/video/edit/:creative_id",
        title: ["Creatives", "Video", "Edit"],
      },
      {
        component: CreativesPage,
        creative_type: "video",
        path: "/creatives/video",
        title: ["Creatives"],
      },
    );
  }

  if (whitelabelChannelDisplay) {
    routes.push(
      {
        component: CreativeCreatePage,
        creative_type: "display",
        path: "/creative/display/create",
        title: ["Creatives", "Display", "Create"],
      },
      {
        component: CreativeEditPage,
        creative_type: "display",
        path: "/creative/display/edit/:creative_id",
        title: ["Creatives", "Display", "Edit"],
      },
      {
        component: CreativesPage,
        creative_type: "display",
        path: "/creatives/display",
        title: ["Creatives"],
      },
    );
  }

  if (whitelabelDoubleCreativeApproval) {
    routes.push({
      path: "/creatives/approve",
      creative_type: "creative_approval",
      component: CreativesPage,
      title: ["Creatives"],
    });
  }

  return routes;
};
