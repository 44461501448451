/* eslint-disable no-unreachable */
export const campaignsActions = {
	"SORTING": "SORTING",
	"FILTERING": "FILTERING",
	"INIT": "INIT",
	"SET_STRATEGIES": "SET_STRATEGIES",
	"CHECK_ALL": "CHECK_ALL",
	"CHECK": "CHECK"
};

const LIMIT = 10;
let defaultChecked = false;
const campaignsCheckedMap = new Map();

const getTotalPages = (amount, limit) => {
	return Math.ceil(amount / limit);
};

const getFilteredCampaigns = (data, query) => {
	return data.filter(item => ~item.name.toLowerCase().indexOf(query.toLowerCase() || ""));
};

let reducerData = {
	"query": ""
};

const sortCampaigns = (sort_by, order) => {
	//
};

const filterCampaigns = (query, state) => {
	reducerData.query = query;
	let campaigns = getFilteredCampaigns(state.initial_data, reducerData.query);
	return {
		...state,
		"page": 1,
		"total_pages": getTotalPages(campaigns.length, LIMIT),
		"campaigns": campaigns.splice(0, LIMIT)
	}
};

/**
 * Init campaigns
 * @param {array} campaigns
 * @param {object} meta
 * @param {number} meta.total_count
 * @param {number} meta.total
 * @param state
 * @return {object} new state
 */
const initCampaigns = (campaigns, meta, state) => {
	// rebuild campaign request in according to UI necessity
	campaigns = campaigns.map(campaign => {
		campaign.strategies = [];
		campaign.checked = (campaignsCheckedMap.has(campaign.id))? campaignsCheckedMap.get(campaign.id) : defaultChecked;
		return campaign;
	});

	state.pager.setTotal(meta);

	return {
		...state,
		campaigns
	}
};

/**
 * check or uncheck all campaigns
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const checkCampaigns = (flag, state) => {
	defaultChecked = flag;
	const campaigns = [...state.campaigns].map(campaign => {
		campaignsCheckedMap.set(campaign.id, defaultChecked);
		campaign.checked = defaultChecked;
		return campaign;
	});

	return {
		...state,
		campaigns
	}
};

/**
 * check or uncheck specific campaign
 * @param {number} id
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const checkCampaign = (id, flag, state) => {
	const campaigns = state.campaigns.map(campaign => {
		if(campaign.id === id) {
			campaignsCheckedMap.set(campaign.id, flag);
			campaign.checked = flag;
		}

		return campaign;
	});

	return {
		...state,
		campaigns
	}
};

const setStrategies = (data, state) => {
	const cloned = [...state.campaigns];
	const index = cloned.findIndex(item => item.id === data.id);
	if(index === -1) {
		return state;
	}

	cloned[index].strategies = data.strategies;
	return {
		...state,
		"campaigns": cloned
	};
};

export const campaignsGridReducer = (state, action) => {
	switch(action.type) {
		case(campaignsActions.INIT):
			return initCampaigns(action.data, action.pager, state);
		break;

		case(campaignsActions.SET_STRATEGIES):
			return setStrategies(action.data, state);
		break;

		case(campaignsActions.SORTING):
			//
		break;

		case(campaignsActions.CHECK_ALL):
			return checkCampaigns(action.flag, state);
		break;

		case(campaignsActions.CHECK):
			return checkCampaign(action.id, action.flag, state);
		break;

		case(campaignsActions.FILTERING):
			return filterCampaigns(action.query, state);
		break;

		default:
			throw new Error("Method is not implement");
	}
};
