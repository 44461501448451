import {Types} from "./enum/supply";


export default class StrategySuppliesSelected {
	constructor(strategy_id, supply_type, supply_ids)
	{
		this.strategy_id = strategy_id || 0;
		this.supply_type = Types.assign(supply_type) || Types.PMP;
		this.supply_ids = supply_ids || [];
	}

	/**
	 * transform data that comes from API/json
	 * @param {number} strategy_id
	 * @param {object} json
	 */
	static fromJson(strategy_id, json) {
		return new StrategySuppliesSelected(strategy_id, json.supply_type, json.supply_ids);
	}

	/**
	 * Do a validation
	 * @return {boolean}
	 */
	isValid() {
		if(~[Types.PMP, Types.PG, Types.OPEN_SUPPLY].indexOf(this.supply_type)) {
			return this.supply_ids.length > 0;
		}
		return true;
	};

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		return {
			...this,
			"strategy_id": parseInt(this.strategy_id),
			"supply_ids": this.supply_ids
		};
	}
}
