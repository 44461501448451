import {isDigit} from "../libs/common_utils";
import Frequency from "./object_value/frequency";
import Pacing from "./object_value/pacing";
import Goal from "./object_value/goal";
import DateRange from "./object_value/date_range";

export default class DraftModel {
	static BUDGET = {
		"MIN": 0.01,
		"MAX": 9_999_999.99
	};

	constructor(id, t1_id, campaign_id, status, strategy_name,
		use_campaign_start, use_campaign_end,
		date_range,
		budget, goal,
		min_bid, max_bid, bid_aggressiveness,
		bid_price_is_media_only,
		pacing, frequency, media_type)
	{
		this.id = id || 0;
		this.t1_id = t1_id || 0;
		this.campaign_id = campaign_id || 0;
		this.status = Boolean(status);
		this.strategy_name = strategy_name || "";
		this.use_campaign_start = use_campaign_start || 0;
		this.use_campaign_end = use_campaign_end || 0;
		this.date_range = date_range || new DateRange();
		this.budget = parseFloat(budget) || "";
		this.bid_price_is_media_only = Boolean(bid_price_is_media_only);
		this.goal = goal || new Goal();
		this.min_bid = Number.isNaN(parseFloat(min_bid)) ? null : parseFloat(min_bid);
		this.max_bid = Number.isNaN(parseFloat(max_bid)) ? null : parseFloat(max_bid);
		this.bid_aggressiveness = Number.isNaN(parseFloat(bid_aggressiveness)) ? null : parseFloat(bid_aggressiveness);
		this.pacing = pacing || new Pacing();
		this.frequency = frequency || new Frequency();
		this.media_type = media_type || "video";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new DraftModel(json.id, json.t1_id, json.campaign_id, json.status, json.strategy_name,
			json.use_campaign_start, json.use_campaign_end,
			DateRange.fromJson(json),
			json.budget, Goal.fromJson(json),
			json.min_bid, json.max_bid, json.bid_aggressiveness,
			Boolean(json.bid_price_is_media_only),
			Pacing.fromJson(json), Frequency.fromJson(json), json.media_type);
	}

	/**
	 * verify that budget field is valid
	 * @param {string} budget
	 * @return {boolean}
	 */
	static budgetIsValid(budget) {
		const value = parseFloat(budget);
		return isDigit(value) && value >= DraftModel.BUDGET.MIN;
	}

	/**
	 * check that object is in edit mode
	 * @return {boolean}
	 */
	isEditMode() {
		return Boolean(this.id || this.t1_id);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this,
			...this.frequency.toJson(),
			...this.pacing.toJson(),
			...this.goal.toJson(),
			...this.date_range.toJson()
		};

		delete json.frequency; delete json.pacing;
		delete json.goal; delete json.date_range;

		if(!json.id) {
			delete json.id;
		}

		if(!json.t1_id) {
			delete json.t1_id;
		}

		if (!json.min_bid) delete json.min_bid;
		if (!json.max_bid) delete json.max_bid;

		if(this.isEditMode()) {
			json.budget = (!json.budget)? null : json.budget;
		} else {
			if(!json.budget) {
				delete json.budget;
			}
		}

		return json;
	}
}
