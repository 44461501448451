import React, { useState, useCallback, useEffect } from "react";
import "../styles.css";
import Pacing from "../../../models/object_value/pacing";
import Frequency from "../../../models/object_value/frequency";
import { strategiesActions as sA } from "../index/reducers";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalDescription,
  Radio,
  Form,
  Checkbox,
  Input,
  Select,
  Message,
  Grid
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import StrategiesService from "../../../services/strategy";
import { useForm } from "../../../libs/component_utils";
import { frequency_intervals, frequency_options, daily_pacing_options, daily_pacing_intervals } from "../fixtures";
import StrategiesBulkEditModel from "../../../models/strategies_bulk_edit";
import { currencyCodeToSymbol, currencyDecimalPlaces } from "../../../libs/common_utils";
import { Config } from "../../../config/api";

const fields = ['status', 'bid', 'dailyPacing', 'frequencyCap'];

export const StrategiesBulkEditModal = ({isBulkEditModalOpen, setIsBulkEditModalOpen, selectedStrategies, items, dispatch, setBulkEditResponseMessage}) => {
  const [checkedFields, setCheckedFields] = useState([]);
	const [formSending, setFormSending] = useState(false);
	const [serverError, setServerError] = useState("");
	const service = new StrategiesService();
  const intl = useIntl();
  const { formatMessage } = intl;
  const currency = Config.defaultCurrency
  const initialData = {
    "ids": selectedStrategies,
    "status": null,
    "pacingType": null,
    "pacingInterval": null,
    "pacingAmount": null,
    "frequencyOptimization": null,
    "frequencyType": null,
    "frequencyAmount": null,
    "frequencyInterval": null,
    "minBid": null,
    "maxBid": null,
  }

  const handleResponse = ({ data = [], errors = [] }) => {
    const successMessage = data.map(({ id, name }) => ({ id, name }));
    const errorMessage = errors.map(({ message }) => ({ message }));

    setBulkEditResponseMessage({ successMessage, errorMessage });
  };

  const updateStateForStrategies = (response, model) => {
    const { ids, ...fieldsToUpdate } = model;
    const successfulIDs = response.data.map(strategy => strategy.id);

    const updatedItems = items.map(item => {
      if (successfulIDs.includes(item.id)) {
        return {
          ...item,
          ...fieldsToUpdate
        };
      }
      return item;
    });

    dispatch({ type: sA.UPDATE, data: updatedItems });
  };

  const editBulkStrategiesForm = async (params) => {
    try {
      setFormSending(true);
      clearMessages();
      const model = new StrategiesBulkEditModel(params);
      const response = await service.bulkEditStrategies(model);
      handleResponse(response);
      updateStateForStrategies(response, model);
      setFormSending(false);
      handleOnClose();
    } catch (e) {
      setServerError(e.error.message);
      setFormSending(false);
    };
  };

  const updateCheckboxActions = {
    status: {
      checked: () => updateValues({ status: 1 }),
      unchecked: () => updateValues({ status: null })
    },
    bid: {
      checked: () => updateValues({ minBid: null, maxBid: null }),
      unchecked: () => {
        updateValues({ minBid: null, maxBid: null });
        removeError('maxBid');
      }
    },
    dailyPacing: {
      checked: () => updateValues({ pacingType: 'asap', pacingInterval: 'day' }),
      unchecked: () => {
        updateValues({ pacingType: null, pacingInterval: null, pacingAmount: null });
        removeError('pacingAmount');
      }
    },
    frequencyCap: {
      checked: () => updateValues({
        frequencyOptimization: 1
      }),
      unchecked: () => {
        updateValues({
          frequencyOptimization: null,
          frequencyType: null,
          frequencyAmount: null,
          frequencyInterval: null
        });
        removeError('frequencyAmount');
      }
    }
  };

  const handleCheckboxChange = useCallback((_e, { name, checked }) => {
    if (checked) {
      setCheckedFields((prevFields) => [...prevFields, name]);
      updateCheckboxActions[name].checked();
    } else {
      setCheckedFields((prevFields) => prevFields.filter((field) => field !== name));
      updateCheckboxActions[name].unchecked();
    }
  }, [updateCheckboxActions]);

	const postValidation = (_e, flds) => {
    const errors = {};
    const { frequencyAmount, minBid, maxBid, pacingAmount } = flds;

		if(pacingAmount && !Pacing.isValid(pacingAmount)) {
			errors["pacingAmount"] = formatMessage({
				id: "ERROR_PACING_OUTSIDE_MIN_MAX",
				defaultMessage: "Pacing amount must be a positive number between {min} and {max}",
			}, {
				min: Pacing.MIN,
				max: Pacing.MAX,
			});
		}

		if(frequencyAmount && !Frequency.isValid(frequencyAmount)) {
			errors["frequencyAmount"] = formatMessage({
				id: "ERROR_FREQUENCY_AMOUNT_OUTSIDE_MIN_MAX",
				defaultMessage: "Frequency amount must be a positive number between {min} and {max}",
			}, {
				min: Frequency.MIN,
				max: Frequency.MAX,
			});
		}

		if (minBid && maxBid) {
			if (parseFloat(minBid) > parseFloat(maxBid)) {
				errors["maxBid"] = formatMessage({
					id: "ERROR_MIN_BID_GT_MAX_BID",
					defaultMessage: "Max Bid must be greater than or equal Min Bid",
				});
			}
		}

		return errors;
	};

  const clearMessages = () => {
    setServerError("");
  }

  const {
		values,
		errors,
    onSwitch,
		updateValues,
		onChange,
		onSubmit,
    removeError
	} = useForm(editBulkStrategiesForm, initialData, postValidation);

  const disableField = useCallback((field) => (
    !checkedFields.includes(field)
  ), [checkedFields]);

  const handleOnClose = useCallback(() => {
    fields.forEach((field) => {
      updateCheckboxActions[field].unchecked();
    });
    setCheckedFields([]);
    clearMessages();
    setIsBulkEditModalOpen(false);
  }, [fields, updateCheckboxActions]);

  const handleDefaultFrequencyCapBasedOnOptimization = () => {
    if (values.frequencyOptimization === 0) {
      updateValues({frequencyType: 'asap', frequencyAmount: null, frequencyInterval: 'hour'})
    }
    else {
      updateValues({frequencyType: null, frequencyAmount: null, frequencyInterval: null})
    }
  }

  useEffect(() => {
    updateValues({ids: selectedStrategies})
  },[selectedStrategies])

  useEffect(() => {
    handleDefaultFrequencyCapBasedOnOptimization()
  },[values.frequencyOptimization])

  return (
    <Modal
      onClose={handleOnClose}
      onOpen={() => setIsBulkEditModalOpen(true)}
      open={isBulkEditModalOpen}
      size="large"
      closeIcon
    >
      <ModalHeader>
        {formatMessage({
          id: "HEADING_BULK_EDITING_STRATEGIES",
          defaultMessage: `Edit Details for ${selectedStrategies.length} Strategies`,
          }, {
            count: selectedStrategies.length
        })}
      </ModalHeader>
      <ModalContent>
        <ModalDescription>
          <Message
            error
            hidden={!serverError}
            size="tiny"
            content={serverError}
          />
          <Form method="PUT" onSubmit={onSubmit} loading={formSending}  error={Boolean(Object.keys(errors).length)}>
            <Form.Field inline className="strategy-bulk-edit-form-field-row">
              <Grid>
                <Grid.Row style={{paddingBottom: '0px'}}>
                  <Checkbox
                    name='status'
                    checked={!disableField('status')}
                    onChange={handleCheckboxChange}
                    label= {formatMessage({
                              id: "LABEL_STATUS",
                              defaultMessage: "Status",
                            })}
                  />
                </Grid.Row>
                <Grid.Row style={{paddingLeft: '24px', paddingBottom: '0px'}}>
                  <Radio
                    disabled={disableField('status')}
                    name="status"
                    label={formatMessage({
                      id: "STATUS_ACTIVE",
                      defaultMessage: "Active",
                    })}
                    value={1}
                    checked={values.status === 1}
                    onChange={onSwitch}
                  />
                  <Radio
                    disabled={disableField('status')}
                    style={{ "marginLeft": "15px" }}
                    name="status"
                    label={formatMessage({
                      id: "STATUS_INACTIVE",
                      defaultMessage: "Inactive",
                    })}
                    value={0}
                    checked={values.status === 0}
                    onChange={onSwitch}
                  />
                </Grid.Row>
              </Grid>
            </Form.Field>

            <Form.Field inline className="strategy-bulk-edit-form-field-row"  error={Object.hasOwn(errors, "maxBid") || Object.hasOwn(errors, "minBid")}>
              <Grid>
                <Grid.Row style={{paddingBottom: '0px'}}>
                  <Checkbox
                    name='bid'
                    checked={!disableField('bid')}
                    onChange={handleCheckboxChange}
                    label= {formatMessage({
                              id: "LABEL_BID",
                              defaultMessage: "Bid",
                            })}
                  />
                </Grid.Row>
                <Grid.Row style={{paddingLeft: '24px', paddingBottom: '0px', alignItems: 'center'}}>
                  <span style={{ opacity: disableField('bid') ? 0.5 : 1 }}>
                    {formatMessage({
                      id: "LABEL_MIN_BID_CPM",
                      defaultMessage: "Min Bid CPM",
                    })}
                  </span>
                  <Input
                    required
                    name="minBid"
                    type="number"
                    min={0}
                    step={currencyDecimalPlaces(currency)}
                    places={2}
                    disabled={disableField('bid')}
                    value={values.minBid || ''}
                    label={currencyCodeToSymbol(currency)}
                    onChange={onChange}
                  />
                  <span style={{ opacity: disableField('bid') ? 0.5 : 1 }}>
                    {formatMessage({
                      id: "LABEL_Max_BID_CPM",
                      defaultMessage: "Max Bid CPM",
                    })}
                  </span>
                  <Input
                    required
                    name="maxBid"
                    type="number"
                    min={0}
                    step={currencyDecimalPlaces(currency)}
                    places={2}
                    disabled={disableField('bid')}
                    value={values.maxBid || ''}
                    label={currencyCodeToSymbol(currency)}
                    onChange={onChange}
                  />
                </Grid.Row>
                <Grid.Row className="edit-field-custom-error">
                    {errors["minBid"] || errors["maxBid"] || errors["bid_aggressiveness"]}
                  </Grid.Row>
              </Grid>

            </Form.Field>

            <Form.Field inline className="strategy-bulk-edit-form-field-row" error={Object.hasOwn(errors, "pacingAmount")}>
              <Grid>
                <Grid.Row style={{paddingBottom: '0px'}}>
                  <Checkbox
                    name='dailyPacing'
                    checked={!disableField('dailyPacing')}
                    onChange={handleCheckboxChange}
                    label= {formatMessage({
                              id: "LABEL_DAILY_PACING",
                              defaultMessage: "Daily Pacing",
                            })}
                  />
                </Grid.Row>
                <Grid.Row style={{paddingLeft: '24px', paddingBottom: '0px'}}>
                  <Select
                    options={daily_pacing_options(intl)}
                    name="pacingType"
                    placeholder={formatMessage({
                      id: "HINT_EXPANSION_TRIGGER",
                      defaultMessage: "Select one",
                    })}
                    value={values.pacingType || ''}
                    onChange={onChange}
                    disabled={disableField('dailyPacing')}
                  />
                  <Input
                    required
                    name="pacingAmount"
                    type="number"
                    min={0}
                    step={0.01}
                    places={2}
                    value={values.pacingAmount || ''}
                    disabled={disableField('dailyPacing')}
                    label="$"
                    onChange={onChange}
                  />
                  <Select
                    options={daily_pacing_intervals(intl)}
                    name="pacingInterval"
                    placeholder={formatMessage({
                      id: "HINT_EXPANSION_TRIGGER",
                      defaultMessage: "Select one",
                    })}
                    value={values.pacingInterval || ''}
                    onChange={onChange}
                    disabled={disableField('dailyPacing')}
                  />
                </Grid.Row>
                <Grid.Row className="edit-field-custom-error">
                  {errors["pacingAmount"]}
                </Grid.Row>
              </Grid>
            </Form.Field>

            <Form.Field inline className="strategy-bulk-edit-form-field-row" error={Object.hasOwn(errors, "frequencyAmount")}>
              <Grid>
                <Grid.Row style={{paddingBottom: '0px'}}>
                  <Checkbox
                    name='frequencyCap'
                    checked={!disableField('frequencyCap')}
                    onChange={handleCheckboxChange}
                    label= {formatMessage({
                              id: "LABEL_FREQUENCY_CAP",
                              defaultMessage: "Frequency Cap",
                            })}
                  />
                </Grid.Row>
                <Grid.Row style={{paddingLeft: '24px', paddingBottom: '0px'}}>
                  <Radio
                    disabled={disableField('frequencyCap')}
                    name="frequencyOptimization"
                    label={formatMessage({
                      id: "FREQUENCY_CAP_OPTIMIZED",
                      defaultMessage: "Optimized",
                    })}
                    value={1}
                    checked={values.frequencyOptimization === 1}
                    onChange={onSwitch}
                  />
                  <Radio
                    disabled={disableField('frequencyCap')}
                    style={{ "marginLeft": "15px", "marginRight": "15px" }}
                    name="frequencyOptimization"
                    label={formatMessage({
                      id: "FREQUENCY_CAP_CUSTOM",
                      defaultMessage: "Custom",
                    })}
                    value={0}
                    checked={values.frequencyOptimization === 0}
                    onChange={onSwitch}
                  />
                  {values.frequencyOptimization === 0 &&
                    <>
                      <Select
                        options={frequency_options(intl)}
                        name="frequencyType"
                        placeholder={formatMessage({
                          id: "HINT_EXPANSION_TRIGGER",
                          defaultMessage: "Select one",
                        })}
                        value={values.frequencyType || ''}
                        onChange={onChange}
                        disabled={disableField('frequencyCap')}
                      />
                      <Input
                        required
                        name="frequencyAmount"
                        type="number"
                        min={0}
                        step={1}
                        places={2}
                        value={values.frequencyAmount || ''}
                        disabled={disableField('frequencyCap')}
                        label="#"
                        onChange={onChange}
                      />
                      <Select
                        options={frequency_intervals(intl)}
                        name="frequencyInterval"
                        placeholder={formatMessage({
                          id: "HINT_EXPANSION_TRIGGER",
                          defaultMessage: "Select one",
                        })}
                        value={values.frequencyInterval || ''}
                        onChange={onChange}
                        disabled={disableField('frequencyCap')}
                      />
                    </>
                  }
                </Grid.Row>
                <Grid.Row className="edit-field-custom-error">
                  {errors["frequencyAmount"]}
                </Grid.Row>
              </Grid>
            </Form.Field>

            <Form.Field align="right">
              <Button size="tiny" type="button" onClick={handleOnClose}>
                {formatMessage({
                  id: "BTN_CANCEL",
                  defaultMessage: "Cancel",
                })}
              </Button>
              <Button size="tiny" color="green" type="submit" disabled={checkedFields.length <= 0}>
                {formatMessage({
                  id: "BTN_UPDATE_STRATEGIES",
                  defaultMessage: "Update Strategies",
                })}
              </Button>
            </Form.Field>
          </Form>
        </ModalDescription>
      </ModalContent>
    </Modal>
  );
};
