import React from "react";
import {Icon, Label, Tab} from "semantic-ui-react";
import {MarketingObjectiveStep} from "./steps/marketing_objective";
import {SupplyStep} from "./steps/supply";
import PropTypes from "prop-types";
import {CreativesStep} from "./steps/creatives";
import {AudiencesStep} from "./steps/audiences";
import {LocationsStep} from "./steps/locations";
import StrategyManageContext from "./context";
import {DaypartsStep} from "./steps/dayparts";
import {TechnologyStep} from "./steps/technology";
import { useIntl } from "react-intl";

export const StrategyNavigationEditComponent = ({is_pg, current_step}) => {
	const context = React.useContext(StrategyManageContext);

	/**
	 * calculate status
	 * @param {number} current
	 * @param {number} active
	 * @return {string}
	 */
	const getStatus = (current, active) => {
		if(current === active) {
			return "active"
		}

		return "waiting";
	};

	const intl = useIntl();

	React.useLayoutEffect(() => {
		// insert delimiters
		const parent = document.querySelector(".strategy_tab > .menu");
		const hr = document.createElement("hr");
		hr.className = "navigation-delimiter";

		const children = [...parent.children];
		children.shift();
		children.forEach(node => {
			parent.insertBefore(hr.cloneNode(), node);
		});
	}, []);

	let panes = [
		{
			"menuItem": {
				"key": 0,
				"content": (
					<span
						active="true"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToMarketingObjective(
								context.strategy_id
							)
						}
					>
						<StrategyNavBarItem
							status={getStatus(0, current_step)}
							title={intl.formatMessage({
								id: "LABEL_MARKETING_OBJECTIVE",
								defaultMessage: "Marketing Objective",
							})}
							step="1"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<MarketingObjectiveStep is_pg={is_pg} />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 1,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToSupplies(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(1, current_step)}
							title={intl.formatMessage({
								id: "LABEL_SUPPLY",
								defaultMessage: "Supply",
							})}
							step="2"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<SupplyStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 2,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToCreatives(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(2, current_step)}
							title={intl.formatMessage({
								id: "LABEL_CREATIVES",
								defaultMessage: "Creatives",
							})}
							step="3"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<CreativesStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 3,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToAudiences(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(3, current_step)}
							title={intl.formatMessage({
								id: "LABEL_AUDIENCE",
								defaultMessage: "Audience",
							})}
							step="4"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<AudiencesStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 4,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToLocations(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(4, current_step)}
							title={intl.formatMessage({
								id: "LABEL_LOCATION",
								defaultMessage: "Location",
							})}
							step="5"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<LocationsStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 5,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToDayparts(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(5, current_step)}
							title={intl.formatMessage({
								id: "LABEL_DAYPART",
								defaultMessage: "Daypart",
							})}
							step="6"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<DaypartsStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 6,
				"content": (
					<span
						active="false"
						className="waiting"
						onClick={() =>
							context.stepNavigation.backToTechnology(context.strategy_id)
						}
					>
						<StrategyNavBarItem
							status={getStatus(6, current_step)}
							title={intl.formatMessage({
								id: "LABEL_TECHNOLOGY",
								defaultMessage: "Technology",
							})}
							step="7"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<TechnologyStep />
				</Tab.Pane>
			),
		},
	];

	// leave the only 3 tabs in case campaign has PG type
	if(is_pg) {
		panes = panes.slice(0, 3);
	}

	return <Tab panes={panes}
				menu={{"secondary": true}}
				activeIndex={current_step}
				className="strategy_tab"
	/>;
};
StrategyNavigationEditComponent.propTypes = {
	"current_step": PropTypes.number.isRequired,
	"is_pg": PropTypes.bool.isRequired
};

export const StrategyNavigationComponent = ({is_pg, current_step}) => {
	/**
	 * calculate status
	 * @param {number} current
	 * @param {number} active
	 * @return {string}
	 */
	const getStatus = (current, active) => {
		if(current === active) {
			return "active"
		} else if(active < current) {
			return "waiting";
		}

		return "pass";
	};

	const intl = useIntl();

	React.useLayoutEffect(() => {
		// insert delimiters
		const parent = document.querySelector(".strategy_tab > .menu");
		const hr = document.createElement("hr");
		hr.className = "navigation-delimiter";

		const children = [...parent.children];
		children.shift();
		children.forEach(node => {
			parent.insertBefore(hr.cloneNode(), node);
		});
	}, []);

	let panes = [
		{
			"menuItem": {
				"key": 0,
				"content": (
					<span active="true" className="active">
						<StrategyNavBarItem
							status={getStatus(0, current_step)}
							title={intl.formatMessage({
								id: "LABEL_MARKETING_OBJECTIVE",
								defaultMessage: "Marketing Objective",
							})}
							step="1"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<MarketingObjectiveStep is_pg={is_pg} />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 1,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(1, current_step)}
							title={intl.formatMessage({
								id: "LABEL_SUPPLY",
								defaultMessage: "Supply",
							})}
							step="2"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<SupplyStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 2,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(2, current_step)}
							title={intl.formatMessage({
								id: "LABEL_CREATIVES",
								defaultMessage: "Creatives",
							})}
							step="3"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<CreativesStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 3,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(3, current_step)}
							title={intl.formatMessage({
								id: "LABEL_AUDIENCE",
								defaultMessage: "Audience",
							})}
							step="4"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<AudiencesStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 4,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(4, current_step)}
							title={intl.formatMessage({
								id: "LABEL_LOCATION",
								defaultMessage: "Location",
							})}
							step="5"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<LocationsStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 5,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(5, current_step)}
							title={intl.formatMessage({
								id: "LABEL_DAYPART",
								defaultMessage: "Daypart",
							})}
							step="6"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<DaypartsStep />
				</Tab.Pane>
			),
		},
		{
			"menuItem": {
				"key": 6,
				"content": (
					<span active="false" className="waiting">
						<StrategyNavBarItem
							status={getStatus(6, current_step)}
							title={intl.formatMessage({
								id: "LABEL_TECHNOLOGY",
								defaultMessage: "Technology",
							})}
							step="7"
						/>
					</span>
				),
			},
			"render": () => (
				<Tab.Pane>
					<TechnologyStep />
				</Tab.Pane>
			),
		},
	];

	// leave the only 3 tabs in case campaign has PG type
	if(is_pg) {
		panes = panes.slice(0, 3);
	}

	return <Tab panes={panes}
				menu={{"secondary": true}}
				activeIndex={current_step}
				className="strategy_tab"
	/>;
};
StrategyNavigationComponent.propTypes = {
	"current_step": PropTypes.number.isRequired,
	"is_pg": PropTypes.bool.isRequired
};

const StrategyNavBarItem = ({status, title, step}) => {
	let jsx = <></>;

	switch(status) {
		default:
		case("active"):
			jsx = <><Label circular className="primary">{step}</Label>{title}</>;
			break;

		case("waiting"):
			jsx = <><Label circular className="disabled">{step}</Label>{title}</>;
			break;

		case("pass"):
			jsx = <><Label circular className="primary"><Icon name="check" /></Label>{title}</>;
			break;
	}
	return jsx;
};
StrategyNavBarItem.propTypes = {
	"status": PropTypes.oneOf(["pass", "waiting", "active"]).isRequired,
	"title": PropTypes.string.isRequired,
	"step": PropTypes.string.isRequired
};
