import axios from "axios";
import {Config} from "../config/api";
import Registry from "./register_storage";

class HttpConnect {
	constructor() {
		this.registry = new Registry(window.localStorage);
		if(!HttpConnect.instance) {
			HttpConnect.instance = this;

			const cancelToken = axios.CancelToken;
			this.source = cancelToken.source();

			this.instance = axios.create({
				"baseURL": this.getHost(Config.api_host),
				"headers":
					{
						"Content-Type": "application/json"
					},
				"timeout": 180000,
				"withCredentials": true,
				"cancelToken": this.source.token
			});

			/*
			this.instance.interceptors.response.use(response => {
				return response;
			}, error => {
				return Promise.reject(error);
			});
			*/
		}

		return HttpConnect.instance;
	}

	/**
	 * generate correct api host, in case its not an URL attach that prefix to original domain
	 * @param {string} api_host
	 * @return {string}
	 */
	getHost(api_host) {
		let host = api_host;
		try {
			new URL(host);
		} catch {
			host = `${window.location.origin}${host}`;
		}

		return host;
	}

	/**
	 * Cancel all active requests
	 */
	cancelRequest() {
		this.source.cancel("cancelled");

		// assign new token
		const cancelToken = axios.CancelToken;
		this.source = cancelToken.source();

		this.instance.defaults.cancelToken = this.source.token;
	}

	/**
	 * Send a request to the server
	 * @param {String} uri
	 * @param {Object} config
	 * @private
	 */
	__fetch(uri, config = {}) {
		const token = this.registry.getItem("token");
		if(token) {
			if(!config.hasOwnProperty("headers")) {
				config["headers"] = {};
			}
			config["headers"]["Authorization"] = `Bearer ${token}`;
		}

		return new Promise((resolve, reject) => this.instance.request({...config, "url": uri}).then(response => {
				resolve(response.data);
			}, error => {
				if(axios.isCancel(error)) {
					reject({});
					return;
				}

				// for unauthorized requests send message through bus
				if(error.response && ~[401].indexOf(error.response.status) && !~["/login"].indexOf(uri)) {
					//PubSub.publish(AuthController.PUBSUB_TOPIC,
					//	new BusMessage(BusMessage.OK, "logout", false));
					return reject({"action": "logout"});
				}

				if(~error.message.toLowerCase().indexOf("network error") || ~error.message.toLowerCase().indexOf("timeout of ") || ~[500].indexOf(error.response.status)) {
					return reject({"status": "error", "message": "Oops... Something wrong with API server."});
				}

				/*
				let message = "Oops something went wrong";
				if(error.response) {
					message = error.response.data.error.message;
				}
				*/

				// PubSub.publish(HttpConnect.PUBSUB_TOPIC, new BusMessage(BusMessage.ERROR, uri, message));
				reject(error.response.data);
			}))
	}

	/**
	 * Implement GET method
	 *
	 * @param {String} uri
	 * @param {object} params
	 * @returns {Promise}
	 */
	async get(uri, params={}) {
		let headers = {
			"method": "GET"
		};

		uri += "?" + Object.keys(params)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
			.join("&");

		return this.__fetch(uri, headers);
	}

	/**
	 * Implement DELETE method
	 *
	 * @param {String} uri
	 * @param {object} params
	 * @returns {Promise}
	 */
	async remove(uri, params) {
		let headers = {
			"method": "DELETE"
		};

		uri += "?" + Object.keys(params)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
			.join("&");

		return this.__fetch(uri, headers).catch();
	}

	/**
	 * Implement POST method
	 * @param {String} uri
	 * @param {Object} data
	 * @returns {Promise}
	 */
	async post(uri, data) {
		let headers = {
			"method": "POST",
			"data": data
		};

		return this.__fetch(uri, headers);
	}

	/**
	 * Implement POST method to download
	 * @param {String} uri
	 * @param {Object} data
	 * @returns {Promise}
	 */
	async post_download(uri, data) {
		let headers = {
			"method": "POST",
			"data": data,
			"responseType": "arraybuffer"
		};

		return this.__fetch(uri, headers);
	}

	/**
	 * Implement PUT method
	 * @param {String} uri
	 * @param {Object} data
	 * @returns {Promise}
	 */
	async put(uri, data) {
		let headers = {
			"method": "PUT",
			"data": data
		};

		return this.__fetch(uri, headers);
	}

	/**
	 * Implement DELETE method
	 *
	 * @param {String} uri
	 * @param {object} params
	 * @returns {Promise}
	 */
	async delete(uri, params={}) {
		let headers = {
			"method": "DELETE"
		};

		uri += "?" + Object.keys(params)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
			.join("&");

		return this.__fetch(uri, headers);
	}

}

const instance = new HttpConnect();
export default instance;
