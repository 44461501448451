import { PERMISSION_ADMIN } from "../../libs/consts";
import AbstractService from "../abstract.service";

export default class AuthService extends AbstractService {
	static URI_STATES = new Map([
		["auth.login", "/login"],
		["auth.logout", "/logout"],
		["auth.authorized", "/authorized"]
	]);

	/**
	 * Log user in
	 * @param {object} user_data
	 * @param {string} user_data.username
	 * @param {string} user_data.password
	 * @returns {Promise<any | never>}
	 */
	login(user_data) {
		return this.api_server.post(AuthService.URI_STATES.get("auth.login"), user_data);
	}

	/**
	 * Get user out
	 */
	async logout() {
		return this.api_server.get(AuthService.URI_STATES.get("auth.logout"), {});
	}

	/**
	 * Call authorized endpoint
	 * @returns {Promise<any | never>}
	 */
	async authozied() {
		return this.api_server.get(AuthService.URI_STATES.get("auth.authorized"));
	}

	getUser() {
		return this.registry.getItem("user") || {};
	}
}
