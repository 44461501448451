import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Message } from "semantic-ui-react";

import { onBannerDismiss } from "../../libs/component_utils";

/**
 * Generate success message
 * @param {object} details
 * @returns {JSX.Element | null}
 */
const InventorySuccessMessage = ({ details = {} }) => {
  const intl = useIntl();

  useEffect(() => {
    const timer = setTimeout(onBannerDismiss, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (details.action === "created") {
    return (
      <Message success className="page-success-message" attached onDismiss={onBannerDismiss}>
        {details.type === "site-list"
          ? intl.formatMessage({
              id: "MESSAGE_SITE_LIST_CREATED",
              defaultMessage: "Site List successfully created",
            })
          : intl.formatMessage({
              id: "MESSAGE_DEAL_CREATED",
              defaultMessage: "Deal successfully created",
            })}
      </Message>
    );
  }

  if (details.action === "edited") {
    return (
      <Message success className="page-success-message" attached onDismiss={onBannerDismiss}>
        {details.type === "site-list"
          ? intl.formatMessage({
              id: "MESSAGE_SITE_LIST_EDIT",
              defaultMessage: "Site List successfully edited",
            })
          : intl.formatMessage({
              id: "MESSAGE_DEAL_EDIT",
              defaultMessage: "Deal successfully edited",
            })}
      </Message>
    );
  }

  return null;
};

InventorySuccessMessage.propTypes = {
  details: PropTypes.object.isRequired,
};

export default InventorySuccessMessage;
