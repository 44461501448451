import {isDigit} from "../../libs/common_utils";
import {Types} from "../enum/goal";

export default class Goal {
	constructor(goal_type, goal_value) {
		this.goal_type = Types.assign(goal_type || Types.CPA);
		this.goal_value = parseFloat(goal_value) || 0;
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new Goal(json.goal_type, json.goal_value);
	}

	/**
	 * verify that goal_value field is valid
	 * @param {string} goal_value
	 * @param {number} min
	 * @param {number} max
	 * @return {boolean}
	 */
	static isValid(goal_value, min, max) {
		const value = parseFloat(goal_value);
		return isDigit(value) && value >= min && value <= max;
	};

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		return {
			...this
		}
	}
}