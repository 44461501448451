/* eslint-disable @calm/react-intl/missing-formatted-message */

import React from "react";
import {getReadableClientName} from "../libs/common_utils";
import {Config} from "../config/api";

const BodyFooter = React.memo(() => {
	return (
		<div className="ui vertical footer segment" style={{"border": "none"}}>
			<div className="ui container center aligned">
				&copy; Copyright {(new Date()).getFullYear()} {getReadableClientName(Config.public_client)}
			</div>
		</div>);
});

export default BodyFooter;
