import PropTypes from "prop-types";
import React, { useCallback, useReducer, useState } from "react";
import {
	Button,
	Grid,
	Icon,
	Input,
	Message,
	Pagination,
	Segment,
	Table,
	Form,
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import UsersGridContext from "./context";
import { usersActions, userGridReducer } from "./reducers";
import {
	isNewTabClick,
	onBannerDismiss,
	search_query_becomes_empty,
	should_do_search,
	useMounted,
} from "../../../libs/component_utils";
import lightFormat from "date-fns/lightFormat";
import { isNil, parseAPIDateTime } from "../../../libs/common_utils";
import Pager from "../../../models/pager";
import UsersService from "../../../services/users";
import { Config } from "../../../config/api";
import { user_roles } from "../fixtures";

const initialState = {
	"users": [],
	"pager": new Pager(),
	"total_pages": 0,
};

export const UsersPage = ({ history }) => {
	const intl = useIntl();
	const [state, dispatch] = useReducer(userGridReducer, initialState);
	const [gridLoading, setGridLoading] = useState(true);
	const showSuccessMessage = !isNil(history.location.state);
	const isMounted = useMounted();
	const services = React.useRef(new Map([["users", new UsersService()]]));

	const query = React.useRef(""),
		timer = React.useRef(0);

	/**
	 * initial load
	 */
	React.useEffect(() => {
		getList(state.pager);
	}, []);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, { activePage }) => {
		state.pager.setPage(activePage);
		getList(state.pager);
	};

	/**
	 * get to create page
	 */
	const navigateToCreatePage = () => {
		history.push("/admin/user/create");
	};

	/**
	 * navigate user to edit page
	 */
	const navigateToEditPage = useCallback(
		(id) => {
			history.push(
				`/admin/user/edit/${id}`,
				state.users.find((user) => user.id === id)
			);
		},
		[state]
	);

	/**
	 * get edit page URL by campaign id
	 */
	const getEditPageHref = useCallback((id) => `/admin/user/edit/${id}`, []);

	/**
	 * load users from API
	 * @param {object|null} pager
	 * @return {Promise<void>}
	 */
	const getList = async (pager = null) => {
		const users = services.current.get("users");
		try {
			setGridLoading(true);

			let params = {};
			if (pager) {
				params = Object.assign(params, pager.toJson());
			}

			if (query.current.length) {
				params["search"] = query.current;
			}

			const response = await users.list(params);
			/** @namespace response.data **/
			let meta = response.meta;
			meta.page = 1;

			dispatch({
				"type": usersActions.INIT,
				"data": response.data,
				"pager": meta,
			});
		} catch (e) {
			// ignore error
			console.log(e);
		} finally {
			if (isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * do a search
	 * @param e
	 * @param {string} value
	 */
	const handleSearch = async (e, { value: searchQuery }) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if (timer.current) {
			clearTimeout(timer.current);
		}

		if (!should_do_search(searchQuery, prev_query)) {
			return;
		}

		state.pager.reset();
		if (search_query_becomes_empty(searchQuery, prev_query)) {
			await getList(state.pager);
			return;
		}

		timer.current = setTimeout(async () => {
			await getList(state.pager);
		}, Config.search_debounce_delay);
	};

	return (
		<Segment basic style={{ "padding": "0" }}>
			<UsersGridContext.Provider
				value={{
					navigateToCreatePage,
					navigateToEditPage,
					getEditPageHref,
					getPage,
					handleSearch,
				}}
			>
				{showSuccessMessage && (
					<UsersSuccessMessage details={history.location.state || {}} />
				)}
				<h1>
					{intl.formatMessage({
						id: "HEADING_USERS",
						defaultMessage: "Users",
					})}
				</h1>
				<UsersGrid
					items={state.users}
					loading={gridLoading}
					controls={{ "pager": state.pager }}
				/>
			</UsersGridContext.Provider>
		</Segment>
	);
};

/**
 * Generate success message
 * @param {function} onDismiss
 * @param {object} details
 * @return {*}
 * @constructor
 */
const UsersSuccessMessage = ({ details, onDismiss }) => {
	const intl = useIntl();

	let timer;
	React.useEffect(() => {
		timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onDismiss || onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_USER_CREATED",
					defaultMessage: "User successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onDismiss || onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_USER_UPDATED",
					defaultMessage: "{name} profile updated",
				}, {
					name: details.username,
				})}
			</Message>
		);
	}

	return null;
};
UsersSuccessMessage.propTypes = {
	"details": PropTypes.object.isRequired,
};

/**
 * Render grid
 * @param {array} items
 * @param {boolean} loading
 * @param {object} controls
 * @return {JSX.Element}
 * @constructor
 */
export const UsersGrid = ({ items, loading, controls }) => {
	const intl = useIntl();
	const context = React.useContext(UsersGridContext);
	const [searchQuery, setSearchQuery] = React.useState("");
	const isMounted = useMounted();

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_search_input",
		};
	};

	// set effect to on query change, added listener to reset
	React.useLayoutEffect(() => {
		const el = document.getElementById("clear_search_input");
		if (el && searchQuery.length > 0) {
			el.addEventListener("click", clearSearch, false);
		}

		if (isMounted.current) {
			context.handleSearch(null, { value: searchQuery });
		}

		return () => {
			if (el) {
				el.removeEventListener("click", clearSearch);
			}
		};
	}, [searchQuery]);

	/**
	 * set search value and trigger search
	 * @param e
	 * @param value
	 * @return {Promise<void>}
	 */
	const handleSearch = async (e, { value }) => {
		setSearchQuery(value);
	};

	/**
	 * clear search query
	 */
	const clearSearch = () => {
		setSearchQuery("");
	};

	return (
		<>
			<Grid className="common_grid">
				<Grid.Row>
					<Grid.Column width={12}>
						<Form
							autoComplete="off"
							noValidate
							size="tiny"
							style={{ "marginTop": "15px" }}
						>
							<Form.Group>
								<Form.Field>
									<label>
										{intl.formatMessage({
											id: "LABEL_USERNAME",
											defaultMessage: "Username",
										})}
									</label>
									<Input
										onChange={handleSearch}
										value={searchQuery}
										disabled={loading}
										icon={searchQuery.length ? getCloseIcon() : null}
										placeholder={intl.formatMessage({
											id: "HINT_USERNAME",
											defaultMessage: "Search by username",
										})}
										name="username"
										style={{ "width": "250px" }}
									/>
								</Form.Field>
							</Form.Group>
						</Form>
					</Grid.Column>
					<Grid.Column
						width={4}
						style={{ marginBottom: "1.6em" }}
						textAlign="right"
						verticalAlign="bottom"
					>
						<Button
							primary
							compact
							className="text__uppercase"
							onClick={() => context.navigateToCreatePage()}
						>
							{intl.formatMessage({
								id: "BTN_CREATE_USER",
								defaultMessage: "Create User",
							})}
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Segment basic style={{ "padding": "0" }} loading={loading}>
				<Table className="custom-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_USERNAME",
									defaultMessage: "Username",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_FIRST_NAME",
									defaultMessage: "First name",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_LAST_NAME",
									defaultMessage: "Last name",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_ROLE",
									defaultMessage: "Role",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_LAST_LOGIN",
									defaultMessage: "Last login",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" style={{ "width": "70px" }}>
								&nbsp;
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items.map((item) => {
							return <UserGridItem key={item.id} {...item} />;
						})}
						{!items.length && (
							<Table.Row>
								<Table.Cell colSpan="5" textAlign="center">
									{intl.formatMessage({
										id: "EMPTY_SEARCH_RESULTS",
										defaultMessage: "No results found",
									})}
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.Cell colSpan="12" textAlign="right">
								{controls.pager.total_pages > 1 && (
									<Pagination
										size="mini"
										activePage={controls.pager.page}
										totalPages={controls.pager.total_pages}
										firstItem={null}
										lastItem={null}
										onPageChange={context.getPage}
									/>
								)}
							</Table.Cell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</>
	);
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const UserGridItem = (item) => {
	const intl = useIntl();
	const context = React.useContext(UsersGridContext);

	return (
		<Table.Row className={item.status ? "" : "inactive"}>
			<Table.Cell className="grid-item-title">{item.username}</Table.Cell>
			<Table.Cell>{item.first_name}</Table.Cell>
			<Table.Cell>{item.last_name}</Table.Cell>
			<Table.Cell>{user_roles(intl)[item.user_role] || item.user_role}</Table.Cell>
			<Table.Cell>
				{item.last_login ? lightFormat(
					parseAPIDateTime(item.last_login),
					Config.fullDateTimeFormat
				) : "–"}
			</Table.Cell>
			<Table.Cell data-clickable="0" textAlign="center">
				<a
					href={context.getEditPageHref(item.id)}
					onClick={(e) => {
						if (isNewTabClick(e)) return;
						e.preventDefault();
						e.stopPropagation();
						context.navigateToEditPage(item.id);
					}}
				>
					<Icon name="edit" className="control" />
				</a>
			</Table.Cell>
		</Table.Row>
	);
};
