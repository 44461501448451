import React, { useState } from "react";

import {
	Button,
	Divider,
	Dropdown,
	Form,
	Input,
	Radio, Segment, TextArea,
} from "semantic-ui-react";
import { useIntl } from "react-intl";

import {useForm} from "../../../libs/component_utils";
import "react-datepicker/dist/react-datepicker.css";
import PixelsService from "../../../services/pixels";
import T1Service from "../../../services/t1";
import AgenciesService from "../../../services/agencies";
import PropTypes from "prop-types";
import {Config} from "../../../config/api";
import {hasOnlyNumbers} from "../../../libs/common_utils";

/**
 * check if we should do a search
 * @param {string} query
 * @param {string} prev_query
 * @return {boolean}
 */
const should_do_search = (query, prev_query) => {
	return prev_query !== query;
};

/**
 * Roi Pixel create page component
 * @return {*}
 * @constructor
 */
const PixelRoiForm = ({
	formSending,
	initialData,
	onSubmit,
	onCancel,
	submitText,
	formType = "create"
}) => {
	const services = React.useRef(
		new Map([
			["pixels", new PixelsService()],
			["t1", new T1Service()],
			["agencies", new AgenciesService()],
		])
	);
	let _isMounted = React.useRef(false),
		query = React.useRef(""),
		timer = React.useRef(0);
	const intl = useIntl();
	const editMode = formType.toString() === "edit";
	const agency = services.current.get("agencies").getSelectedAgency() || 0;

	// loaded page data
	const [pageData, setPageData] = useState(() => {
		return {
			"advertisersLoading": true,
			"advertisers": [],
			"brandNames": (initialData.brand_names.length)? initialData.brand_names.map(brand => ({
				"text": brand,
				"value": brand
			})): [],
			"brandsLoading": false
		};
	});

	/**
	 * initially loading advertisers list
	 */
	React.useEffect(() => {
		const service = services.current.get("t1");
		(async () => {
			try {
				const r = await service.advertisers(agency);
				setPageData({
					...pageData,
					"advertisersLoading": false,
					"advertisers": pageData.advertisers.concat(
						r.data.map(({ id, title }) => ({
							"key": id,
							"text": title,
							"value": id,
						}))
					),
				});
			} catch (e) {
				console.error(e);
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, []);

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const addPixelForm = async () => {
		try {
			await onSubmit(values);
		} catch (e) {
			console.error(e);
		}
	};

	const {values, errors, onChange, updateValues, onSubmit: handleSubmit} = useForm(
		addPixelForm,
		initialData,
		() => {
			const errors = {};

			if (values.advertiser_id === null) {
				errors.advertiser_id = intl.formatMessage({
					id: "ERROR_EMPTY_ADVERTISER_ID",
					defaultMessage: "Please select an advertiser.",
				});
			}

			if (values.brand_names.length < 1) {
				errors.brand_names = intl.formatMessage({
					id: "VALIDATOR_SELECT_BRAND",
					defaultMessage: "Please select at least one Brand",
				});
			}

			if(values.attribution_level === "sku") {
				if(values.sku_ids.length < 1) {
					errors.sku_ids = intl.formatMessage({
						id: "VALIDATOR_SKU_NUMBERS",
						defaultMessage: "Field SKU ID should contain list of numbers separated by comma",
					});
				} else {
					const isNumbersList = values.sku_ids.split(",").map(value => value.trim()).every(value => (value.length && hasOnlyNumbers(value)));
					if(!isNumbersList) {
						errors.sku_ids = intl.formatMessage({
							id: "VALIDATOR_SKU_NUMBERS",
							defaultMessage: "Field SKU ID should contain list of numbers separated by comma",
						});
					}
				}
			}

			return errors;
		}
	);

	/**
	 * do a search
	 * @param {React.SyntheticEvent} e
	 * @param {object} data
	 */
	const handleSearch = (e, data) => {
		let prev_query = query.current || "";
		query.current = data.searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(query.current, prev_query)) {
			return;
		}

		/*
		if(search_query_becomes_empty(searchQuery, prev_query)) {
			filter.setCampaignName(searchQuery);
			onChange(filter.toJson());
			document.getElementById("campaign_name_id").value = "";
			return;
		}
		 */

		timer.current = setTimeout(async () => {
			try {
				setPageData({
					...pageData,
					"brandsLoading": true
				});

				const r = await services.current.get("pixels").get_brands(query.current);
				setPageData({
					...pageData,
					"brandNames": r.data.map(brand => ({
						"text": brand,
						"value": brand
					})).concat(values.brand_names.map(brand => ({
							"text": brand,
							"value": brand
						}))),
					"brandsLoading": false
				})
			} catch (e) {
				console.error(e);
				setPageData({
					...pageData,
					"brandsLoading": false
				});
			}

		}, Config.search_debounce_delay);
	};

	const attribution_hint = values.attribution_level === "sku"? intl.formatMessage({
		id: "HINT_ATTRIBUTION_LOGIC_WITH_SKU",
		defaultMessage: "Please enter a (list of) brand(s) and SKU's to attribute conversion and revenue to, for this ROI Pixel. Purchase events that contain the selected brand(s) and SKU's will be used for ROI calculation"
	}) : intl.formatMessage({
		id: "HINT_ATTRIBUTION_LOGIC",
		defaultMessage: "Please enter a (list of) brand(s) to attribute conversion and revenue to, for this ROI Pixel. Purchase events that contain the selected brand(s) will be used for ROI calculation"
	});

	return (
		<Form
			onSubmit={handleSubmit}
			size="small"
			loading={formSending}
			noValidate
			error={!!Object.keys(errors).length}
			autoComplete="off"
		>
			<Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_STATUS",
						defaultMessage: "Status",
					})}
				</label>
				<Radio
					name="status"
					label={intl.formatMessage({
						id: "STATUS_ACTIVE",
						defaultMessage: "Active",
					})}
					value={1}
					checked={!!values.status}
					onChange={onChange}
				/>
				<Radio
					style={{ "marginLeft": "15px" }}
					name="status"
					label={intl.formatMessage({
						id: "STATUS_INACTIVE",
						defaultMessage: "Inactive",
					})}
					value={0}
					checked={!values.status}
					onChange={onChange}
				/>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("title")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_PIXEL_NAME",
						defaultMessage: "Pixel Name",
					})}
				</label>
				<div className="custom-error-container">
					<Input
						name="title"
						required
						minLength={1}
						maxLength={256}
						defaultValue={values.title}
						onBlur={onChange}
					/>
					<div className="custom-error">{errors["title"]}</div>
				</div>
			</Form.Field>

			<Form.Field
				inline
				error={errors.hasOwnProperty("advertiser_id")}
				required
			>
				<label>
					{intl.formatMessage({
						id: "LABEL_ADVERTISER",
						defaultMessage: "Advertiser",
					})}
				</label>
				<div className="custom-error-container">
					<Dropdown
						required
						search
						selection
						disabled={editMode}
						options={pageData.advertisers}
						loading={pageData.advertisersLoading}
						placeholder={intl.formatMessage({
							id: "HINT_ADVERTISER",
							defaultMessage: "Select advertiser",
						})}
						noResultsMessage={intl.formatMessage({
							id: "EMPTY_SEARCH_RESULTS",
							defaultMessage: "No results found",
						})}
						name="advertiser_id"
						value={values.advertiser_id}
						onChange={onChange}
					/>
					<div className="custom-error">{errors["advertiser_id"]}</div>
				</div>
			</Form.Field>
			<Form.Field
				inline
				style={{"display": "flex"}}
			>
				<label style={{"minWidth": "180px"}}>
					{intl.formatMessage({
						id: "LABEL_ATTRIBUTION_LOGIC",
						defaultMessage: "Attribution Logic"
					})}
				</label>
				<span>{attribution_hint}</span>
			</Form.Field>
			<Segment secondary padded style={{"fontSize": "inherit"}}>
				<Form.Field
					inline
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_ATTRIBUTION_LEVEL",
							defaultMessage: "Attribution Level",
						})}
					</label>
					<Radio
						name="attribution_level"
						label={intl.formatMessage({
							id: "LABEL_BRAND",
							defaultMessage: "Brand",
						})}
						value="brand"
						checked={values.attribution_level === "brand"}
						onChange={onChange}
					/>
					<Radio
						style={{ "marginLeft": "15px" }}
						name="attribution_level"
						label={intl.formatMessage({
							id: "LABEL_BRAND_ITEM_SKU",
							defaultMessage: "Brand & Item (SKU)",
						})}
						value="sku"
						checked={values.attribution_level === "sku"}
						onChange={onChange}
					/>
				</Form.Field>
				<Form.Field
					inline required
					error={errors.hasOwnProperty("brand_names")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_BRANDS_CONTAINS",
							defaultMessage: "Brand Contains",
						})}
					</label>
					<div className="custom-error-container" style={{"width": "50%"}}>
						<Dropdown
							required
							search={options => options}
							selection
							multiple
							options={pageData.brandNames}
							loading={pageData.brandsLoading}
							placeholder={intl.formatMessage({
								id: "PLACEHOLDER_SELECT_BRAND",
								defaultMessage: "Select brand(s)",
							})}
							noResultsMessage={pageData.brandsLoading? "" : query.current.length? intl.formatMessage({
								id: "PLACEHOLDER_NOT_FOUND",
								defaultMessage: "Not found",
							}) : intl.formatMessage({
								id: "PLACEHOLDER_START_TYPING",
								defaultMessage: "Start typing to search...",
							})}
							name="brand_names"
							value={values.brand_names}
							onSearchChange={handleSearch}
							onChange={(a, b) => {
									updateValues({
										...values,
										"brand_names": b.value
									});
								}
							}
							style={{"width": "100%"}}
						/>
						<div className="custom-error">{errors["brand_names"]}</div>
					</div>
				</Form.Field>
				{values.attribution_level === "sku" && <Form.Field
					required
					inline
					error={errors.hasOwnProperty("sku_ids")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_SKU_CONTAINS",
							defaultMessage: "SKU Contains",
						})}
					</label>
					<div className="custom-error-container" style={{"width": "50%"}}>
						<TextArea rows="10" name="sku_ids" required style={{"width": "100%"}} defaultValue={values.sku_ids} onBlur={onChange}/>
						<div className="custom-error">{errors["sku_ids"]}</div>
					</div>
				</Form.Field>}
			</Segment>
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<Form.Field align="right">
				<Button size="tiny" type="button" onClick={onCancel}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit">
					{submitText}
				</Button>
			</Form.Field>
		</Form>
	);
};
PixelRoiForm.propTypes = {
	"formSending": PropTypes.bool.isRequired,
	"submitText": PropTypes.string.isRequired,
	"formType": PropTypes.string
};

export default PixelRoiForm;
