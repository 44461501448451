import {date_ranges} from "../campaign/index/models/filter";
import DatePicker from "react-datepicker";
import React, {useState} from "react";
import {Dropdown} from "semantic-ui-react";
import {subYears} from "date-fns";
import subDays from "date-fns/subDays";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";

const DateRangeControl = ({filter, onChange}) => {
  const intl = useIntl();
  const [reportDateRanges, setReportDateRanges] = useState({
    "start_date": filter.start_date,
    "end_date": filter.end_date
  });

  /**
   * update reporting period
   * @param e
   * @param option
   */
  const handleReportingPeriod = (e, option) => {
    let days_substract;
    switch(option.value) {
      case("14days"):
        days_substract = 14;
        break;

      case("7days"):
        days_substract = 7;
        break;

      case("30days"):
        days_substract = 30;
        break;

      case("yesterday"):
      default:
        days_substract = 1;
    }

    setReportDateRanges({
      "start_date": subDays(new Date(), days_substract),
      "end_date": new Date()
    });
    filter.setReportingPeriod(option.value);
  };

  /**
   * handler calendar on change statement
   */
  const onChangeDateRanges = dates => {
    const [start, end] = dates;
    setReportDateRanges({
      "start_date": start,
      "end_date": end
    });
  };

  /**
   * handler calendar on close
   */
  const onCloseDateRanges = () => {
    let filterDate = {...reportDateRanges};
    if(reportDateRanges.end_date == null) {
      setReportDateRanges({
        ...reportDateRanges,
        "end_date": reportDateRanges.start_date
      });
      filterDate.end_date = reportDateRanges.start_date;
    }

    filter.setStartDate(filterDate.start_date);
    filter.setEndDate(filterDate.end_date);
    onChange(filter.toJson());
  }

  return <DatePicker
    selected={reportDateRanges.start_date}
    onChange={onChangeDateRanges}
    onCalendarClose={onCloseDateRanges}
    startDate={reportDateRanges.start_date}
    endDate={reportDateRanges.end_date}
    maxDate={new Date()}
    minDate={subYears(new Date(), 1)}
    selectsRange
  >
    <div>
      <Dropdown
        selection
        options={date_ranges(intl)}
        value={filter.reporting_period}
        onChange={handleReportingPeriod}
        name="reporting_period"
        style={{"display": "flex", "margin": "5px"}}
      />
    </div>
  </DatePicker>
}

DateRangeControl.propTypes = {
  "filter": PropTypes.object.isRequired,
  "onChange": PropTypes.func.isRequired
};

export default DateRangeControl;
