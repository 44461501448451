import React, {useCallback, useContext, useReducer, useState} from "react";

import {useIntl} from "react-intl";
import CreativesGridContext from "../context";
import {Button, Divider, Grid, Menu, Message, Pagination, Segment, Table} from "semantic-ui-react";
import {NavLink, Redirect} from "react-router-dom";
import FilterControl from "./filter_control";
import uuid from "uuid/v4";
import PropTypes from "prop-types";
import {getApiDate, isApiDateTimeExpired, isNil} from "../../../../libs/common_utils";
import {OnlineContext} from "../../../../context/online-context";
import Pager from "../../../../models/pager";
import Filter from "../models/filter";
import {creativeGridReducer, creativesActions as cA} from "../reducers";
import CreativesService from "../../../../services/creatives";
import {useFlags} from "launchdarkly-react-client-sdk";
import {onBannerDismiss} from "../../../../libs/component_utils";

const initialState = {
  "creatives": [],
  "pager": new Pager(),
  "filter": new Filter()
};

export const AdminCreativesApprovalsPage = ({history}) => {
  const intl = useIntl();
  const {currentUserIsAdmin} = useContext(OnlineContext);
  const [state, dispatch] = useReducer(creativeGridReducer, initialState, undefined);
  const [gridLoading, setGridLoading] = useState(true);
  const showSuccessMessage = !isNil(history.location.state);
  let _isMounted = React.useRef(false);
  const services = React.useRef(new Map([["creatives", new CreativesService()]]));

  const {whitelabelDoubleCreativeApproval} = useFlags();

  /**
   * initial load
   */
  React.useEffect(() => {
    _isMounted.current = true;

    // load list of items
    (async () => {
      await getList(state.filter, state.pager);
    })();

    // clear cache
    return () => {
      _isMounted.current = false;
      state.pager.reset();
      state.filter.reset();
    }
  }, []);

  /**
   * get page
   * @param {React.SyntheticEvent} e
   * @param {number} activePage
   */
  const getPage = async (e, {activePage}) => {
    state.pager.setPage(activePage);
    await getList(state.filter, state.pager);
  };

  /**
   * navigate user to edit page
   */
  const navigateToEditPage = useCallback(id => {
    history.push(`/admin/onsite-creatives/edit/${id}`, state.creatives.find(creative => id === creative.id));
  }, [state]);

  /**
   * load creatives from API
   * @param {object} filter
   * @param {object} pager
   */
  const getList = async (filter = null, pager = null) => {
    const creatives = services.current.get("creatives");
    try {
      setGridLoading(true);

      let params = {};
      if (filter) {
        params = Object.assign(params, filter.toRequestJson());
      }

      if (pager) {
        params = Object.assign(params, pager.toJson());
      }

      const r = await creatives.get_creative_approval_list(params);

      let meta = r.meta;
      meta.page = pager.page;
      dispatch({"type": cA.INIT, "data": r.data, "pager": meta});
    } catch (e) {
      // ignore error
      console.error(e);
    } finally {
      if(_isMounted.current) {
        setGridLoading(false);
      }
    }
  };

  /**
   * Update filter object and make call to API
   * @param {object} json
   */
  const filtering = async json => {
    state.pager.reset();
    state.filter.fromJson(json);
    await getList(state.filter, state.pager);
  };

  /**
   * check that user has access to this page
   * @return {boolean}
   */
  const accessRestricted = () => {
    return !whitelabelDoubleCreativeApproval || !currentUserIsAdmin;
  }

  if(accessRestricted()) {
    return <Redirect to="/" />
  }

  return (
    <Segment basic style={{"padding": "0"}}>
      <CreativesGridContext.Provider value={{
        "getPage": getPage, filtering,
        navigateToEditPage, gridLoading, intl
      }}>
        {showSuccessMessage && (<CreativesSuccessMessage
          details={history.location.state || {}}
        />)}
        <h1>
          {intl.formatMessage({
            id: "HEADING_CREATIVES",
            defaultMessage: "Creatives",
          })}
        </h1>
        <Divider hidden />
        <CreativesApprovalGrid
          items={state.creatives}
          controls={{
            "filter": state.filter,
            "pager": {
              "page": state.pager.page,
              "total_pages": state.pager.total_pages,
              getPage,
            },
          }}
        />
      </CreativesGridContext.Provider>
    </Segment>
  )
};

AdminCreativesApprovalsPage.propTypes = {
  "history": PropTypes.object.isRequired
};


/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const CreativesSuccessMessage = ({details}) => {
  const intl = useIntl();

  let timer;
  React.useEffect(() => {
    timer = setTimeout(onBannerDismiss, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const message = (details.status === "approved")? intl.formatMessage({
      "id": "MESSAGE_CREATIVE_APPROVED",
      "defaultMessage": `Creative "${details.title}" approved`
    }, {
      "creative_name": details.title,
    }) : intl.formatMessage({
    "id": "MESSAGE_CREATIVE_REJECTED",
    "defaultMessage": `Creative "${details.title}" rejected`
  }, {
    "creative_name": details.title,
  });

    return (details.action === "error")? <></> : <Message
        success
        className="page-success-message"
        attached
        onDismiss={onBannerDismiss}
      >
        {message}
      </Message>
};
CreativesSuccessMessage.propTypes = {
  "details": PropTypes.object
};

/**
 * Render creative approval grid
 * @param {array} items
 * @param {object} controls
 */
export const CreativesApprovalGrid = ({ items, controls}) => {
  const context = React.useContext(CreativesGridContext);
  const intl = context.intl;

  return (
    <>
      <Grid className="common_grid">
        <Grid.Row>
          <Grid.Column>
            <Menu pointing secondary>
              <Menu.Item
                as={NavLink}
                active={true}
                to="/admin/onsite-creatives"
                content={intl.formatMessage({
                  id: "LINK_ON_SITE_CREATIVES",
                  defaultMessage: "On-Site Creatives",
                })}
              />
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment basic style={{"padding": "0"}} loading={context.gridLoading}>
        <Grid className="common_grid">
          <Grid.Row>
            <Grid.Column>
              <FilterControl
                filter={controls.filter}
                onChange={context.filtering}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table className="custom-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {intl.formatMessage({
                        id: "LABEL_CREATIVE_NAME",
                        defaultMessage: "Creative Name",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {intl.formatMessage({
                        id: "LABEL_ADVERTISER",
                        defaultMessage: "Advertiser",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_CREATIVE_TYPE",
                        defaultMessage: "Creative Type",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_STATUS",
                        defaultMessage: "Status",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_END_DATE",
                        defaultMessage: "End Date",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left" style={{"width": "30%"}}>
                      {intl.formatMessage({
                        id: "LABEL_REJECTION_REASON",
                        defaultMessage: "Rejection Reason",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{intl.formatMessage({
                      id: "TEXT_ACTION",
                      defaultMessage: "Action",
                    })}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map(item => {
                    return <CreativeGridItem key={uuid()} {...item} />;
                  })}
                  {!items.length && (
                    <GridEmptyRow
                      filterIsEmpty={controls.filter.isEmpty()}
                    />
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="right">{controls.pager.total_pages > 1 && <Pagination
                      size="mini"
                      defaultActivePage={controls.pager.page}
                      totalPages={controls.pager.total_pages}
                      firstItem={null}
                      lastItem={null}
                      onPageChange={context.getPage}
                    />}
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
};
CreativesApprovalGrid.propTypes = {
  "items": PropTypes.array.isRequired,
  "controls": PropTypes.object.isRequired
};

const GridEmptyRow = () => {
  const context = React.useContext(CreativesGridContext);
  const intl = context.intl;

  return (
    <Table.Row>
      <Table.Cell colSpan="7" textAlign="center">
        {intl.formatMessage({
          id: "EMPTY_SEARCH_RESULTS",
          defaultMessage: "No results found",
        })}
      </Table.Cell>
    </Table.Row>
  );
};

/**
 * Generate grid item
 * @param {object} item
 * @return {JSX.Element}
 * @constructor
 */
const CreativeGridItem = item => {
  const context = React.useContext(CreativesGridContext);
  const intl = context.intl;

  const media_type_translation = (item.media_type === "display")? intl.formatMessage({
    id: "MEDIA_TYPE_DISPLAY",
    defaultMessage: "Display"
  }) : intl.formatMessage({
    id: "MEDIA_TYPE_VIDEO",
    defaultMessage: "Video"
  });

  /**
   * get status translation
   * @param {string} status
   * @returns {string}
   */
  const get_approval_status_translation = status => {
    let status_translation = "-";
    switch(status) {
      case("approved"):
        status_translation = intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_APPROVED",
          defaultMessage: "Approved"
        });
        break;

      case("pending"):
        status_translation = intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_PENDING",
          defaultMessage: "Pending"
        });
        break;

      case("rejected"):
        status_translation = intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_REJECTED",
          defaultMessage: "Rejected"
        });
        break;
    }

    return status_translation;
  }

  /**
   * format creative end date
   * @param {string} end_date
   * @returns {string}
   */
  const get_end_date = end_date => {
    let creative_end_date = getApiDate(end_date);
    if(isApiDateTimeExpired(end_date)) {
      const expired_translation = intl.formatMessage({
        id: "TEXT_EXPIRED",
        defaultMessage: "expired"
      });
      creative_end_date = `${creative_end_date} (${expired_translation})`;
    }

    return creative_end_date;
  };

  /**
   * generate actions on creative approval
   * @param {string} status
   * @return {string}
   */
  const get_approval_admin_action = status => {
    let admin_action = "-";
    switch(status) {
      case("approved"):
      case("rejected"):
        admin_action = <Button disabled={true} style={{"width": "120px"}} color="blue">{intl.formatMessage({
          id: "BTN_COMPLETED",
          defaultMessage: "Completed"
        })}</Button>
        break;

      case("pending"):
        admin_action = <Button color="orange" style={{"width": "120px"}}>{intl.formatMessage({
          id: "BTN_REVIEW",
          defaultMessage: "Review"
        })}</Button>
        break;
    }

    return admin_action;
  };

  /**
   * generate css class for a status
   * @param {string} status
   * @returns {string}
   */
  const get_status_css_class = status => {
    let css_class = ""
    switch(status) {
      case("pending"):
        css_class = "orange";
      break;

      case("rejected"):
        css_class = "red";
      break;

      default:
        css_class = "green";
    }

    return css_class;
  };

  return (
    <Table.Row className={"hoverable cursor-pointer" + (item.status? "": " inactive")} onClick={() => context.navigateToEditPage(item.id)}>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>{item.advertiser_name}</Table.Cell>
      <Table.Cell>{media_type_translation}</Table.Cell>
      <Table.Cell className={get_status_css_class(item.status)}>{get_approval_status_translation(item.status)}</Table.Cell>
      <Table.Cell className={isApiDateTimeExpired(item.end_date)? "date_expired" : ""}>{get_end_date(item.end_date)}</Table.Cell>
      <Table.Cell className="text-ellipsis cursor-help" title={item.rejected_reason}>{item.rejected_reason || "-"}</Table.Cell>
      <Table.Cell>{get_approval_admin_action(item.status)}</Table.Cell>
    </Table.Row>
  );
};
CreativeGridItem.propTypes = {
  "id": PropTypes.number.isRequired,
  "title": PropTypes.string.isRequired,
  "status": PropTypes.string.isRequired,
  "media_type": PropTypes.string.isRequired,
  "advertiser_name": PropTypes.string.isRequired,
  "end_date": PropTypes.string.isRequired,
  "rejected_reason": PropTypes.string
};
