/* eslint-disable @calm/react-intl/missing-formatted-message */
import React, {useState, useEffect} from "react";
import StrategyManageContext from "../context";
import {actions as trgtAction, targetValuesControlReducer, resetInitTreeFlag} from "./reducers/audiences";
import {
	Button,
	Divider,
	Form,
	Grid,
	Header, Icon, Input,
	Message, Popup,
	Segment
} from "semantic-ui-react";
import {TargetValuesControl} from "./reducers/audiences";
import AudienceContext from "./contexts/audience";
import {errorToMessage, isDigit} from "../../../../libs/common_utils";
import HttpConnect from "../../../../libs/http_connect";

import "./audiences.css";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import StrategiesService from "../../../../services/strategy";
import {Config} from "../../../../config/api";
import Filter from "./models/filter";
import FilterControl from "./filters";
import {strategy_audience_filters} from "./fixtures";
import {useIntl} from "react-intl";
import audience from "./contexts/audience";

const included = new Map([["audiences", [{
	"operator": "AND", "entities": []
}]], ["pixels", [{
	"operator": "AND", "entities": []
}]]]);
const excluded = new Map();
const opened = new Map();
const initialState = {
	"items": [],
	"filter": new Filter("pixels"),
	"searchResults": null
};

export const AudiencesStep = () => {
	const context = React.useContext(StrategyManageContext);
	const [values, dispatch] = React.useReducer(targetValuesControlReducer, []);
	const [state, setState] = React.useState(initialState);
	const service = context.services.current.get("t1");
	const service_strategy = context.services.current.get("strategies");
	const service_pixels = context.services.current.get("pixels");
	const service_audiences = context.services.current.get("audiences");
	const data_exists_in_db = React.useRef(false);
	const is_t1_edit = (service_strategy instanceof StrategiesService);
	const audiences_has_been_blocked = React.useRef(false);

	const agency = context.services.current.get("agencies").getSelectedAgency() || 0;
	const advertiser_id = context.campaign.advertiser_id;
	const strategy_id = context.strategy_id;
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(true);
	const [_, redraw] = React.useState();
	const intl = useIntl();

	const rootTaxonomyID = React.useRef(0);

	let _isMounted = React.useRef(true);
	let get_continue = React.useRef(true);

	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	useEffect(() => {
		if(loading) {
			document.getElementById("nested-targets").classList.add("tree-loading");
		} else {
			document.getElementById("nested-targets").classList.remove("tree-loading");
		}
	}, [loading]);

	/**
	 * save data to local storage
	 * @param {object} server_data
	 * @param {Map} local_storage
	 */
	const handleExcludedSavedData = (server_data, local_storage) => {
		if(server_data.pixels.length > 0 || server_data.custom_audiences.length > 0) {
			data_exists_in_db.current = true;
			server_data.custom_audiences.forEach(n => {
				local_storage.set(n.id, {"id": n.id, "full_path": n.path, "title": n.title, "type": null});
			});

			server_data.pixels.forEach(n => {
				local_storage.set(n.id, {"id": n.id, "full_path": `${intl.formatMessage({
						id: "LABEL_PIXELS",
						defaultMessage: "Pixels",
					})} - ${n.title}`, "title": n.title, "type": "pixel"});
			});
		}
	}

	useEffect(() => {
		audiences_has_been_blocked.current = false;
		setLoading(true);
		(async() => {
			try {
				const [r, saved] = await Promise.all([service.audiences(advertiser_id),
					service_strategy.get_audiences(strategy_id)]);

				// handle excluded block
				handleExcludedSavedData(saved.data.exclude, excluded);
				fillSelectedDiv("excluded_id", excluded);

				if(Boolean(saved.data.include.custom_audiences.length) || Boolean(saved.data.include.pixels?.operator)) {
					data_exists_in_db.current = true;

					const pixel = (saved.data.include.pixels?.operator)? saved.data.include.pixels : {
						"operator": "AND", "entities": []
					};

					included.set("pixels", [pixel]);
					if(Boolean(included.get("pixels").length)) {
						let pixels = included.get("pixels");
						pixels[0].entities = pixels[0].entities.map(n => {
							return {
								"id": n.id,
								"title": n.title,
								"full_path": `${intl.formatMessage({
									id: "LABEL_PIXELS",
									defaultMessage: "Pixels",
								})} - ${n.title}`,
								"type": "pixel"
							}
						});
						included.set("pixels", pixels);
					}

					included.set("audiences", saved.data.include.custom_audiences);
					if(Boolean(included.get("audiences").length)) {
						let audiences = included.get("audiences");
						audiences = audiences.map(n => {
							return {
								...n,
								"entities": n.entities.map(audience => {
									return {"id": audience.id, "full_path": audience.path, "title": audience.title}
								})
							};
						});
						included.set("audiences", audiences);
					} else {
						included.set("audiences", [{
							"operator": "AND", "entities": []
						}]);
					}
				}

				setTimeout(() => {
					fillSelectedPixelsDiv(included.get("pixels"));
					fillSelectedAudiencesDiv(included.get("audiences"));
				}, 25);

				if (r.data.length) {
					rootTaxonomyID.current = r.data[0].id;
				}
				initialState.items = [...r.data].map(n => {
					n.parent_id = 0;
					n.has_children = true;
					return n;
				});
				dispatch({"type": trgtAction.INIT, "data": initialState.items});
			} catch (e) {
				console.log(e);
				if (e.hasOwnProperty("error") && e.error.hasOwnProperty("message")) {
					audiences_has_been_blocked.current = Boolean(~e.error.message.toLowerCase().search(/have been changed outside/));
					setServerError(errorToMessage(e));
				}
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			resetInitTreeFlag();
			opened.clear();

			resetIncludedMapToInitialState();
			excluded.clear();
			_isMounted.current = false;
			initialState.filter.reset();
		};
	}, []);

	/**
	 * rebuild checkbox tree statuses (for audiences)
	 */
	const rebuildCheckboxesStatuses = () => {
		let nodes = document.querySelectorAll(".custom-checkbox > input[type='checkbox']");
		nodes.forEach(n => {
			const id = !isNaN(n.value) ? parseInt(n.value, 10) : n.value;
			setTimeout(() => {
				n.checked = includedHasAudienceId(id) || includedHasPixelId(id);
			});
		});

		let keys = [];
		if(included.get("audiences")) {
			included.get("audiences").forEach(a => {
				keys = keys.concat(a.entities.map(e => e.id));
			});
		}

		if(included.get("pixels")) {
			keys = keys.concat(included.get("pixels")[0].entities.map(e => e.id));
		}

		keys.forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				setTimeout(() => {
					node.indeterminate = false;
					node.checked = true;
				});
			}
		});

		nodes = document.querySelectorAll(".custom-checkbox > input[type='checkbox']:indeterminate");
		nodes.forEach(n => {
			const id = (!isNaN(n.value))? parseInt(n.value , 10) : n.value;
			if(!excluded.has(id)) {
				n.indeterminate = false;
			}
		});

		Array.from(excluded.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.indeterminate = true;
				node.checked = false;
			}
		});
	};

	/**
	 * dispatch switch audience
	 * @param e
	 * @param id
	 * @param {object} [outOfTreeItem] object to use instead of searching the tree
	 */
	const switchTarget = (e, id, outOfTreeItem = null) => {
		/**
		 * work with pixel block
		 * @param {number} id
		 */
		const handlePixelSwitch = id => {
			const pixels = included.get("pixels")[0];
			if(outOfTreeItem) {
				if(includedHasPixelId(id)) {
					removeFromIncluded(id, pixels);
					excluded.set(id, {...outOfTreeItem});
				} else if(excluded.has(id)) {
					removeFromIncluded(id, pixels);
					excluded.delete(id);
				} else {
					attachToIncluded({...outOfTreeItem}, included.get("pixels")[0]);
					excluded.delete(id);
				}
			} else {
				let found = false;
				const tree = node => {
					return node.forEach(item => {
						if (found) {
							return;
						}

						if (item.id === id) {
							if(includedHasPixelId(id)) {
								removeFromIncluded(id, pixels);
								excluded.set(id, {...item});
							} else if(excluded.has(id)) {
								removeFromIncluded(id, pixels);
								excluded.delete(id);
							} else {
								attachToIncluded({...item}, included.get("pixels")[0]);
								excluded.delete(id);
							}
							found = true;
						} else if (item.child) {
							tree(item.child)
						}
					});
				};

				tree([...values]);
			}

			e.target.checked = false;

			// do rebuild checkbox tree
			rebuildCheckboxesStatuses();

			fillSelectedPixelsDiv(included.get("pixels"));
			fillSelectedDiv("excluded_id", excluded);
		};

		/**
		 * work with audience block
		 * @param {number} id
		 */
		const handleAudienceSwitch = id => {
			let found = false;
			let audiences = included.get("audiences")[included.get("audiences").length - 1];

			// try to find element in audience group
			let audience_found = false;
			included.get("audiences").forEach(a => {
				if(!audience_found && a.entities.find(e => e.id === id)) {
					audience_found = true;
					audiences = a;
				}
			});

			if(outOfTreeItem) {
				if(includedHasAudienceId(id)) {
					removeFromIncluded(id, audiences);
					excluded.set(id, {...outOfTreeItem});
				} else if(excluded.has(id)) {
					removeFromIncluded(id, audiences);
					excluded.delete(id);
				} else {
					attachToIncluded({...outOfTreeItem}, included.get("audiences")[included.get("audiences").length - 1]);
					excluded.delete(id);
				}
			} else {
				const tree = node => {
					return node.forEach(item => {
						if (found) {
							return;
						}

						if (item.id === id) {
							if (includedHasAudienceId(id)) {
								removeFromIncluded(id, audiences);
								excluded.set(id, {...item});
							} else if (excluded.has(id)) {
								removeFromIncluded(id, audiences);
								excluded.delete(id);
							} else {
								attachToIncluded({...item}, included.get("audiences")[included.get("audiences").length - 1]);
								excluded.delete(id);
							}
							found = true;
						} else if (item.child) {
							tree(item.child)
						}
					});
				};

				tree([...values]);
			}

			e.target.checked = false;

			// do rebuild checkbox tree
			rebuildCheckboxesStatuses();

			fillSelectedAudiencesDiv(included.get("audiences"));
			fillSelectedDiv("excluded_id", excluded);
		};

		const data_type = e.target.getAttribute("data-type");
		if(data_type === "pixel") {
			handlePixelSwitch(id);
		} else {
			handleAudienceSwitch(id);
		}
	};

	/**
	 * Put data into included/excluded divs
	 * @param {String} div_id
	 * @param {Map} nodes
	 */
	const fillSelectedDiv = (div_id, nodes) => {
		const element = document.getElementById(div_id);
		element.innerHTML = "";

		let html = "";
		nodes.forEach(x => {
			if(x.type === "pixel") {
				html += `<a class="ui label" data-is-pixel="true" draggable="true" data-title="${x.full_path}">${x.title}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
			} else {
				html += `<a class="ui label" data-is-audience="true" draggable="true" data-title="${x.full_path}">${x.title}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
			}
		});

		element.innerHTML = html;
		// attach event listeners
		Array.from(element.getElementsByClassName("remove_icon")).forEach(node => {
			node.onclick = function(e) {
				let id = e.target.getAttribute("data-id");
				id = (isDigit(id))? parseInt(id, 10) : id;

				let checkbox = document.getElementById(`target_${id}`);
				if (checkbox) checkbox.checked = false;

				if (included.has(id)) {
					included.delete(id);
				} else if(excluded.has(id)) {
					excluded.delete(id);

					if(checkbox) {
						checkbox.indeterminate = false;
					}
				}

				checkbox = document.getElementById(`search_target_${id}`);
				if (checkbox) checkbox.checked = false;

				e.target.parentNode.remove();
			};
		});

		document.querySelectorAll("#excluded_id [draggable]").forEach(node => {
			node.addEventListener("dragstart", handleDragStart);
			node.addEventListener("dragend", handleDragEnd);
		});
	};

	/**
	 * check we have audience selected
	 * @param {number} id
	 * @return {boolean}
	 */
	const includedHasAudienceId = id => {
		let keys = [];
		if(included.get("audiences")) {
			included.get("audiences").forEach(a => {
				keys = keys.concat(a.entities.map(e => e.id));
			});
		}
		return keys.includes(id);
	};

	/**
	 * check we have pixel selected
	 * @param {number} pixel
	 * @return {boolean}
	 */
	const includedHasPixelId = pixel => {
		if(!included.get("pixels") || included.get("pixels").length < 1) {
			return false;
		}

		return Boolean(included.get("pixels")[0].entities.find(p => p.id === pixel));
	};

	/**
	 * remove pixel from map
	 * @param {number} id
	 * @param {object} node
	 */
	const removeFromIncluded = (id, node) => {
		const i = node.entities.findIndex(n => n.id === id);
		if(i !== -1) {
			node.entities.splice(i, 1);
		}
	}

	/**
	 * store pixel to included map
	 * @param {object} pixel
	 * @param {object} node
	 */
	const attachToIncluded = (pixel, node) => {
		node.entities.push(pixel);
	}

	/**
	 * fill in selected pixels
	 * @param {array} nodes
	 */
	const fillSelectedPixelsDiv = nodes => {
		const element = document.querySelector(".included_pixel > .container");
		element.innerHTML = "";

		if(nodes.length < 1 || nodes[0]?.entities.length < 1) {
			return;
		}

		let html = "";
		nodes[0].entities.forEach(x => {
			html += `<a class="ui label" data-is-pixel="true" draggable="true" data-title="${x.full_path}">${x.title}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
		});

		element.innerHTML = html;
		// attach event listeners
		Array.from(element.getElementsByClassName("remove_icon")).forEach(node => {
			node.onclick = function(e) {
				let id = e.target.getAttribute("data-id");
				id = (isDigit(id))? parseInt(id, 10) : id;

				let checkbox = document.getElementById(`target_${id}`);
				if (checkbox) checkbox.checked = false;

				if (includedHasPixelId(id)) {
					removeFromIncluded(id, included.get("pixels")[0]);
				} else if(excluded.has(id)) {
					excluded.delete(id);

					if(checkbox) {
						checkbox.indeterminate = false;
					}
				}

				checkbox = document.getElementById(`search_target_${id}`);
				if (checkbox) checkbox.checked = false;

				e.target.parentNode.remove();
			};
		});

		document.querySelectorAll("#included_id [data-is-pixel='true'][draggable]").forEach(node => {
			node.addEventListener("dragstart", handleDragStart);
			node.addEventListener("dragend", handleDragEnd);
		});
	}

	/**
	 * fill in selected pixels
	 * @param {array} nodes
	 */
	const fillSelectedAudiencesDiv = nodes => {
		nodes.forEach((audience, index) => {
			const element = document.querySelector(`#included_audience_${index} > .container`);
			element.innerHTML = "";

			if(audience?.entities.length < 1) {
				return;
			}

			let html = "";
			audience.entities.forEach(x => {
				html += `<a class="ui label" data-is-audience="true" draggable="true" data-title="${x.full_path}">${x.title}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
			});

			element.innerHTML = html;
			// attach event listeners
			Array.from(element.getElementsByClassName("remove_icon")).forEach(node => {
				node.onclick = function(e) {
					let id = e.target.getAttribute("data-id");
					id = (isDigit(id))? parseInt(id, 10) : id;

					let checkbox = document.getElementById(`target_${id}`);
					if (checkbox) checkbox.checked = false;

					if (includedHasAudienceId(id)) {
						removeFromIncluded(id, audience);
					} else if(excluded.has(id)) {
						excluded.delete(id);

						if(checkbox) {
							checkbox.indeterminate = false;
						}
					}

					checkbox = document.getElementById(`search_target_${id}`);
					if (checkbox) checkbox.checked = false;

					e.target.parentNode.remove();
				};
			});

			document.querySelectorAll("#included_id [data-is-audience='true'][draggable]").forEach(node => {
				node.addEventListener("dragstart", handleDragStart);
				node.addEventListener("dragend", handleDragEnd);
			});
		});
	}

	/**
	 * check that target need to be loaded from API
	 * @param {object} target
	 * @return {boolean}
	 */
	const targetNeedsToBeLoaded = target => {
		return target.has_children && (!target.hasOwnProperty("child") || target?.child?.length < 1);
	};

	/**
	 * dispatch switch visibility
	 * @param e
	 * @param {object} target
	 */
	const switchVisibility = (e, target) => {
		const id = target.id;

		if(targetNeedsToBeLoaded(target)) {
			// target contains children and they are not loaded yet
			setLoading(true);
			(async () => {
				try {
					let r, data = [];
					if(target.type === "pixel") {
						r = await service_pixels.list_by_agency(agency, {advertiser_id, "fetch_all": 1, "pixel_status": "active"});
						data = [...r.data].map(n => {
							n.parent_id = null;
							n.buyable = 1;
							n.full_path = `${intl.formatMessage({
								id: "LABEL_PIXELS",
								defaultMessage: "Pixels",
							})} - ${n.title}`;
							n.has_children = false;
							n.type = target.type || null;
							return n;
						});
					} else {
						r = await service.audiences_children(target.id, advertiser_id, context.campaign.currency_code || Config.defaultCurrency);
						data = [...r.data].map(n => {
							n.parent_id = n.parent_audience_segment_id;
							n.has_children = Boolean(n.child_count > 0);
							n.type = target.type || null;
							return n;
						});
					}

					dispatch({"type": trgtAction.APPEND, "data": data, "root": target});
				} catch(e) {
					console.error(e);
					setServerError(e.error.message);
				} finally {
					setLoading(false);
				}

			})();
		} else {
			e.parentNode.classList.toggle("opened");
			if(e.parentNode.classList.contains("opened")) {
				opened.set(id, "opened ul-container");
			} else {
				opened.set(id, "ul-container");
			}
		}
	};

	/**
	 * Load audiences from backend
	 * @param {object} filter
	 * @param {string} filter.filter_type
	 * @param {string} filter.filter_query
	 */
	const performSearchAudiences = filter => {
		HttpConnect.cancelRequest();

		const query = filter.filter_query.trim();
		if (query === "") {
			setState({
				...state,
				"searchResults": null
			});
			return;
		}

		(async () => {
			setLoading(true);

			try {
				let response, results = [];

				switch (filter.filter_type) {
					case "pixels":
						response = await service_pixels.search_active_list_by_agency(agency,
							{advertiser_id, "pixel_name": query});

						results = response.data.map(({id, title}) => ({
							id,
							title,
							"checkable": 1,
							"full_path": `${intl.formatMessage({
								id: "LABEL_PIXELS",
								defaultMessage: "Pixels",
							})} - ${title}`,
							"type": "pixel",
						}));
						break;

					case "audiences":
						response = await service_audiences.list_by_agency(agency, {advertiser_id, "device_name": query});

						results = response.data.map(({t1_id: id, title}) => ({
							id,
							title,
							"checkable": 1,
							"full_path": `${intl.formatMessage({
								id: "LABEL_DEVICE_IDS",
								defaultMessage: "Device IDs",
							})} - ${title}`,
							"type": null
						}));
						break;

					case "taxonomies":
						response = await service.audiences_children(rootTaxonomyID.current,
							advertiser_id,
							context.campaign.currency_code || Config.defaultCurrency,
							query);

						let tmp = {};
						response.data.filter(x => Boolean(x.full_path)).forEach(x => {
							tmp[x.id] = x;
						});

						results = Object.values(tmp).map(({id, title, full_path, buyable}) => ({
							"id": id,
							title,
							"checkable": Boolean(buyable),
							"full_path": full_path,
							"type": null
						}));
						break;

					default:
						console.error("Unsupported search source:", filter.filter_type);
						break;
				}

				setState({
					...state,
					"searchResults": results
				});
				setServerError("");
			} catch (e) {
				console.error("[performSearchAudiences]", e);
				if (e?.error?.message) setServerError(e.error.message);
			} finally {
				if(_isMounted.current) {
					setLoading(false);
				}
			}
		})();
	};

	/**
	 * Save date in the local db
	 * @return {Promise<void>}
	 */
	const handleSubmit = async () => {
		setLoading(true);
		try {
			let custom_data = [],
				pixels_data = {...included.get("pixels")[0]};

			pixels_data.operator = pixels_data.operator.toUpperCase();
			pixels_data.entities = pixels_data.entities.map(x => x.id);

			if(included.get("audiences")) {
				included.get("audiences").forEach(a => {
					let audience = {...a};
					audience.operator = audience.operator.toUpperCase();
					audience.entities = audience.entities.map(e => e.id)
					custom_data.push(audience);
				});
			}

			const params = {
				"include": {
					"custom_audiences": custom_data.filter(e => e.entities.length > 0),
					"pixels": pixels_data
				}, "exclude": {
					"pixels": Array.from(excluded.values()).filter((x) => x.type === "pixel").map((x) => x.id),
					"custom_audiences":Array.from(excluded.values()).filter((x) => x.type !== "pixel").map((x) => x.id)
				}
			};

			if(data_exists_in_db.current) {
				await service_strategy.update_audiences(strategy_id, params);
			} else {
				await service_strategy.create_audiences(strategy_id, params);
			}

			if(get_continue.current) {
				context.stepNavigation.passAudiences(strategy_id);
			} else {
				context.strategySuccessfullyUpdated(context.strategy.strategy_name);
			}
		} catch(e) {
			console.error(e);
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setLoading(false);
			}
		}
	};

	/**
	 * Reset included map
	 */
	const resetIncludedMapToInitialState = () => {
		included.clear();
		included.set("audiences", [{
			"operator": "and",
			"entities": []
		}]);

		included.set("pixels", [{
			"operator": "and",
			"entities": []
		}]);
	}

	/**
	 * reset included map
	 */
	const resetIncluded = () => {
		resetIncludedMapToInitialState();
		fillSelectedPixelsDiv(included.get("pixels"));
		fillSelectedAudiencesDiv(included.get("audiences"));

		document.querySelectorAll(".custom-checkbox > input[type='checkbox']:checked").forEach(node => {
			node.checked = false;
		});

		redraw(Date.now());
	};

	/**
	 * reset excluded map
	 */
	const resetExcluded = () => {
		excluded.clear();
		fillSelectedDiv("excluded_id", excluded);
		document.querySelectorAll(".custom-checkbox > input[type='checkbox']:indeterminate").forEach(node => {
			node.indeterminate = false;
		});
	};

	/**
	 * Change logical or/and for pixel
	 * @param e
	 * @param {object} target
	 */
	const setPixelLocagicalInclude = (e, {value}) => {
		let pixel = included.get("pixels")[0];
		pixel.operator = value.toUpperCase();
		included.set("pixels", [pixel]);
		redraw(Date.now());
	}

	/**
	 * Change logical or/and for audience group
	 * @param {number} group_id
	 * @param {object} target
	 */
	const setAudienceLogicalInclude = (group_id, {value}) => {
		included.get("audiences")[group_id].operator = value;
		redraw(new Date());
	};

	/**
	 * Check if we reach out last element
	 * @param {number} index
	 * @return {boolean}
	 */
	const isLastAudienceGroup = index => {
		return included.get("audiences").length > (index + 1);
	}

	/**
	 * Add new audience group
	 */
	const addAudienceGroup = () => {
		included.get("audiences").push({
			"entities": [],
			"operator": "and"
		});
		redraw(new Date());
	};

	/**
	 * remove audience group and recalculate ids
	 * @param {number} index
	 */
	const removeAudienceGroup = (index) => {
		included.get("audiences").splice(index, 1);
		fillSelectedAudiencesDiv(included.get("audiences"));
		rebuildCheckboxesStatuses();
		redraw(new Date());
	};

	/**
	 * handler for drag enter area
	 * @param e
	 * @return {boolean}
	 */
	const handleDragEnter = e => {
		if (e.preventDefault) {
			e.preventDefault();
		}

		return false;
	};

	/**
	 * handler for drag leave area
	 * @param e
	 * @return {boolean}
	 */
	const handleDragLeave = e => {
		if (e.preventDefault) {
			e.preventDefault();
		}

		e.target.classList.remove("droppable-over");
		return false;
	};

	/**
	 * handler for drag over area
	 * @param e
	 * @return {boolean}
	 */
	const handleDragOver = e => {
		if (e.preventDefault) {
			e.preventDefault();
		}

		const entity_type = (e.target.dataset.isAudience)? "audience" : "pixel";
		const container_type = e.target.dataset.containerType;
		if(entity_type === "pixel" && container_type === "included") {
			return;
		}

		e.target.classList.add("droppable-over");
		return false;
	};

	/**
	 * handler for drop event
	 * @param e
	 */
	 const handleDragDrop = e => {
		e.stopPropagation();
		let {id, type, container_from} = JSON.parse(e.dataTransfer.getData("text/plain"));
		id = parseInt(id, 10);
		const container_type = e.target.dataset.containerType;

		if(container_type === "excluded" && container_from === "excluded") {
			return false
		}

		if(container_type === "excluded") {
			if(type === "pixel") {
				// exclude from pixel included storage and move it to excluded
				let pixels = [...included.get("pixels")];
				let exclude_index, pixel;
				pixels[0].entities.forEach((entity, i) => {
					if(id == entity.id) {
						exclude_index = i;
						pixel = entity;
					}
				});

				pixels[0].entities.splice(exclude_index, 1);
				excluded.set(id, {...pixel});
			} else {
				let audiences = [...included.get("audiences")];
				let audience, exclude_index, exclude_from_group;
				audiences.forEach((a, group_index) => {
					a.entities.forEach((entity, i) => {
						if(id == entity.id) {
							audience = entity;
							exclude_index = i;
							exclude_from_group = group_index;
						}
					});
				});

				audiences[exclude_from_group].entities.splice(exclude_index, 1);
				excluded.set(id, {...audience});
			}
		} else {
			if(type === "pixel") {
				// we cannot drag pixel from included to excluded
				if(container_from === "included") {
					return;
				}

				let pixels = [...included.get("pixels")];
				let pixel = excluded.get(id);
				pixels[0].entities.push(pixel);
				excluded.delete(id);
			} else {
				const move_to_group = e.target.dataset.group;

				// in case we drop not into droppable area, skip
				if(!move_to_group || type === "pixel") {
					return;
				}

				let audiences = [...included.get("audiences")];
				if(container_from === "excluded") {
					let audience = excluded.get(id);
					audiences[move_to_group].entities.push(audience);
					excluded.delete(id);
				} else {
					let audience, exclude_index, exclude_from_group;
					audiences.forEach((a, group_index) => {
						a.entities.forEach((entity, i) => {
							if(id == entity.id) {
								audience = entity;
								exclude_index = i;
								exclude_from_group = group_index;
							}
						});
					});

					// in case we drop entity into the same group
					if(exclude_from_group === move_to_group) {
						return;
					}

					audiences[exclude_from_group].entities.splice(exclude_index, 1);
					audiences[move_to_group].entities.push(audience);
				}
			}
		}

		fillSelectedAudiencesDiv(included.get("audiences"));
		fillSelectedPixelsDiv(included.get("pixels"));
		fillSelectedDiv("excluded_id", excluded);
		rebuildCheckboxesStatuses();
	};

	/**
	 * handler for drag start event
	 * @param e
	 * @return {boolean}
	 */
	const handleDragStart = function(e) {
		e.target.style.opacity = "0.4";

		const container_from = (e.target.parentNode.getAttribute("data-container-type") === "excluded")? "excluded" : "included";
		const entity_type = (e.target.dataset.isAudience)? "audience" : "pixel";

		let selector = (e.target.dataset.isAudience)? ".included_audience .container,[data-container-type='excluded'].container" : "[data-container-type='excluded'].container";
		if(container_from === "excluded") {
			selector = (e.target.dataset.isAudience)? ".included_audience .container" : ".included_pixel .container";
		}

		document.querySelectorAll(selector).forEach(item => {
			if(this.parentNode !== item) {
				item.classList.add("droppable-active");
			}
		});

		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/plain", JSON.stringify({"id": e.target.querySelector("i").dataset.id,
			"container_from": container_from,
			"type": entity_type}));
		e.target.setAttribute("_data-title", e.target.getAttribute("data-title"));
		e.target.removeAttribute("data-title");
	}

	/**
	 * handler for drag end event
	 * @param e
	 * @return {boolean}
	 */
	const handleDragEnd = function(e) {
		e.target.style.opacity = "1";

		document.querySelectorAll(".container").forEach(item => {
			item.classList.remove("droppable-active");
			item.classList.remove("droppable-over");
		});

		e.target.setAttribute("data-title", e.target.getAttribute("_data-title"));
		e.target.removeAttribute("_data-title");
	};

	const isPixelOrActive = (included.get("pixels") && included.get("pixels")[0].operator.toLowerCase() === "or");
	const isPixelAndActive = !isPixelOrActive;

	return <Segment basic>
		<Message
			style={{ "marginTop": "10px" }}
			error
			hidden={!serverError}
			size="tiny"
			content={serverError}
		/>
		<Form id="audiences_form" onSubmit={handleSubmit} loading={loading}>
			<FilterControl filter={initialState.filter}
										 onChange={performSearchAudiences}
										 loading={loading}
										 title={intl.formatMessage({
											 id: "LABEL_AUDIENCE",
											 defaultMessage: "Audience",
										 })}
										 options={strategy_audience_filters(intl)}
										 is_visible={audiences_has_been_blocked.current} />

			<Grid columns={3} stackable style={{"display": audiences_has_been_blocked.current? "none" : "block"}}>
				<Grid.Row>
					<Grid.Column>
						<Header as="h3">&nbsp;</Header>
						<Segment style={{"overflowY": "scroll", "height": 400, "paddingTop": 0 }} id="nested-targets">
							<AudienceContext.Provider value={{
								switchTarget, switchVisibility, included, excluded, opened,
								"campaign": context.campaign
							}}>
								<div className="nested-targets" style={{ "display": state.searchResults === null ? "block" : "none" }}>
									<TargetValuesControl values={values} />
								</div>
								<div className="search-results-list" style={{ "display": state.searchResults !== null ? "block" : "none" }}>
									{state.searchResults !== null && state.searchResults.length ? (
										<ul>
											{state.searchResults.map(result => (
												<li className={result.checkable? "field custom-checkbox" : "field"} key={result.id}>
													<input
														type="checkbox"
														id={`target_${result.id}`}
														value={result.id}
														checked={includedHasPixelId(result.id) || includedHasAudienceId(result.id)}
														disabled={!result.checkable}
														style={{"opacity": 0}}
														className={!result.checkable? "invisible" : ""}
														data-type={result.type || "audience"}
														onChange={(e) =>
															switchTarget(e, result.id, result)
														}
													/>
													<label
														className="target-label"
														htmlFor={`target_${result.id}`}
													>
														{result.full_path}
													</label>
												</li>
											))}
										</ul>
									) : (
										<p style={{"padding": "15px", "fontWeight": "bolder"}}>
											{intl.formatMessage({
												id: "EMPTY_SEARCH_RESULTS",
												defaultMessage: "No results found",
											})}
										</p>
									)}
								</div>
							</AudienceContext.Provider>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Header as="h3">
							{intl.formatMessage({
								id: "LABEL_INCLUDED_AUDIENCES",
								defaultMessage: "Included Audiences",
							})}{" "}
							<Button compact size='mini' className="btn-clear" type="button" onClick={resetIncluded}>
								{intl.formatMessage({
									id: "BTN_CLEAR",
									defaultMessage: "Clear",
								})}
							</Button>
						</Header>
						<Segment id="included_id" className="assigned-creatives" style={{"padding": "0", "overflowY": "scroll", "height": 400 }}>
							<div className="included_pixel">
								<div>
									{intl.formatMessage({
										id: "LABEL_PIXEL_GROUP",
										defaultMessage: "Pixel Group",
									})} <Popup
									size="tiny"
									content={intl.formatMessage({
										id: "TOOLTIP_ONLY_ONE_PIXEL_GROUP",
										defaultMessage: "Only 1 pixel group allowed",
									})}
									inverted
									trigger={<Icon name="info circle" />}
								/>
								<span>
									<label style={{"marginRight": "15px"}}><Input type="radio" name="pixel_included_logical" value="and"
																																checked={isPixelAndActive}
																																onChange={(e, target) => setPixelLocagicalInclude(e, target)} /> {intl.formatMessage({
										id: "LABEL_AND",
										defaultMessage: "And",
									})}</label>
									<label><Input type="radio" name="pixel_included_logical" value="or"
																checked={isPixelOrActive}
																onChange={(e, target) => setPixelLocagicalInclude(e, target)} /> {intl.formatMessage({
										id: "LABEL_OR",
										defaultMessage: "Or",
									})}</label>
								</span>
								</div>
								<div className="container"
												data-container-type="included"
												onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDragDrop}>&nbsp;</div>
								<div align="center">
									<h3>{intl.formatMessage({
										id: "LABEL_AND",
										defaultMessage: "AND",
									}).toUpperCase()}</h3>
								</div>
							</div>
							{included.get("audiences").map((a, i) => {
								return <div className="included_audience" id={`included_audience_${i}`} key={`audience_${i}`}>
										<div>
											{intl.formatMessage({
												id: "BTN_AUDIENCE_GROUP",
												defaultMessage: "Audience Group",
											})} {i + 1}
											<span>
												<label style={{"marginRight": "15px"}}><Input type="radio" name={`audience_included_${i}`}
													value="and"
													onChange={(e, target) => setAudienceLogicalInclude(i, target)}
													checked={a.operator.toLowerCase() === "and"} /> {intl.formatMessage({
													id: "LABEL_AND",
													defaultMessage: "And",
												})}</label>
												<label><Input type="radio" name={`audience_included_${i}`}
													value="or"
													onChange={(e, target) => setAudienceLogicalInclude(i, target)}
													checked={a.operator.toLowerCase() === "or"}/> {intl.formatMessage({
													id: "LABEL_OR",
													defaultMessage: "Or",
												})}</label>
												{i !== 0 && <Icon name="x" style={{"marginLeft": "10px", "cursor": "pointer"}} onClick={() => removeAudienceGroup(i)}/>}
											</span>
										</div>
									  <div className="container" data-group={i}
												 data-container-type="included"
												 onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDragDrop}>&nbsp;</div>
										<div align="center">{isLastAudienceGroup(i)? <h3>{intl.formatMessage({
											id: "LABEL_AND",
											defaultMessage: "AND",
										}).toUpperCase()}</h3> : <Button color="blue" size="tiny" onClick={() => addAudienceGroup()}>+ {intl.formatMessage({
												id: "BTN_AUDIENCE_GROUP",
												defaultMessage: "Audience Group",
											})}</Button>}</div>
									</div>
								})
							}
						</Segment>
						</Grid.Column>
						<Grid.Column>
						<Header as="h3">
							{intl.formatMessage({
								id: "LABEL_EXCLUDED_AUDIENCES",
								defaultMessage: "Excluded Audiences",
							})}{" "}
							<Button compact size="mini" className="btn-clear" type="button" onClick={resetExcluded}>
								{intl.formatMessage({
									id: "BTN_CLEAR",
									defaultMessage: "Clear",
								})}
							</Button>
						</Header>
						<Segment id="excluded_id" className="assigned-creatives container"
										 data-container-type="excluded"
										 onDragEnter={handleDragEnter}
										 onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDragDrop}
										 style={{"overflowY": "scroll", "height": 400 }}>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<NavigationButtonDiv
				loading={loading}
				step={Steps.AUDIENCES}
				isPG={context.campaign.is_pg}
				onBackClick={() => context.stepNavigation.backToCreatives(strategy_id)}
				onContinueClick={() => context.stepNavigation.passAudiences(strategy_id)}
				onSave={setOperationType}
				is_t1_edit={is_t1_edit}
				onCancelClick={context.getBack}
				skip_save_buttons={audiences_has_been_blocked.current}
			/>
		</Form>
	</Segment>;
};
