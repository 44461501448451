import React from "react";
import { useIntl } from "react-intl";
import { Icon, Popup, Table, Checkbox } from "semantic-ui-react";
import { useMemo, useCallback } from "react";

import { Config } from "../../../config/api";
import { formatMoney, formatNumber, getApiDate } from "../../../libs/common_utils";
import StrategyGridContext from "../index/context";

/**
 * Generate grid item
 * @param {object} item
 * @param {function} item.cellVisible
 * @param {string} item.currency_code
 * @param {number} item.status
 * @param {string} item.title
 * @param {Date} item.start_date
 * @param {Date} item.end_date
 * @param {number} item.strategy_budget
 * @param {number} item.spend_to_date
 * @param {number} item.cpm
 * @param {number} item.ctr
 * @param {number} item.clicks
 * @param {number} item.impressions
 * @param {number} item.id
 * @return {*}
 * @constructor
 */
const StrategyGridItem = (item) => {
	const intl = useIntl();
	const context = React.useContext(StrategyGridContext);
	const cellVisible = item.cellVisible;
	const currency = context.currency;
	const { setStrategyForDuplication, setSelectedStrategies, selectedStrategies  } = context;

  const handleCheckboxChange = useCallback((e, { checked }) => {
    if (checked) {
      setSelectedStrategies(prevStrategies => [...prevStrategies, item.id]);
    } else {
      setSelectedStrategies(prevStrategies => prevStrategies.filter(id => id !== item.id));
    }
  }, [item.id]);

  const isStrategyChecked = selectedStrategies.includes(item.id);


	return (
		<>
			<Table.Row className={item.status ? "" : " inactive"}>
				<Table.Cell>
          <Checkbox
            name={item.title}
            checked={isStrategyChecked}
            onChange={handleCheckboxChange}
          />
        </Table.Cell>
				<Table.Cell className="grid-item-col-name text-ellipsis">
					<Popup
						size="tiny"
						content={item.title}
						inverted
						trigger={<span>{item.title}</span>}
					/>
				</Table.Cell>
				<Table.Cell className={cellVisible("status")}>
					{item.status
						? intl.formatMessage({
								id: "STATUS_ACTIVE",
								defaultMessage: "Active",
						  })
						: intl.formatMessage({
								id: "STATUS_INACTIVE",
								defaultMessage: "Inactive",
						  })}
				</Table.Cell>
				<Table.Cell className={cellVisible("start_date")}>
					{getApiDate(item.start_date, Config.dateTimeFormat)}
				</Table.Cell>
				<Table.Cell className={cellVisible("end_date")}>
					{getApiDate(item.end_date, Config.dateTimeFormat)}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_budget")}>
					{formatMoney(
						item.strategy_budget || context.campaign.budget,
            item.currency_code,
						Config.defaultLocale,
            2
					)}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_spend")}>
					{formatMoney(item.spend_to_date, item.currency_code, Config.defaultLocale, 2)}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_cpm")}>
					{item.cpm ?  formatMoney(item.cpm, item.currency_code, Config.defaultLocale, 2) : "-"}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_ctr")}>
					{item.ctr ? `${item.ctr} %` : "-"}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_clicks")}>
					{formatNumber(item.clicks, Config.defaultLocale)}
				</Table.Cell>
				<Table.Cell className={cellVisible("strategy_impressions")}>
					{formatNumber(item.impressions, Config.defaultLocale)}
				</Table.Cell>
				<Table.Cell data-clickable="0" textAlign="center">
					<a
						href={null}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							context.openChartAccordion();
							context.setStrategySelected(item);
						}}
					>
						<Icon name="line graph" className="control" />
					</a>
					&nbsp;
					<a
						href={null}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setStrategyForDuplication(item);
						}}
					>
						<Icon name="copy outline" className="control" />
					</a>
					&nbsp;
					<a
						href={context.getEditPageHref(item.id)}
						onClick={(e) => {
							if (isNewTabClick(e)) return;
							e.preventDefault();
							e.stopPropagation();
							context.navigateToEditPage(item.id);
						}}
					>
						<Icon name="edit" className="control" />
					</a>
				</Table.Cell>
			</Table.Row>
		</>
	);
};

export default StrategyGridItem;
