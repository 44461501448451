import React, {useState} from "react";
import {Accordion, Checkbox, Confirm, Form, Icon, Table} from "semantic-ui-react";
import { useIntl } from "react-intl";
import {OpenSupplyContext} from "../contexts/open_supply";
import uuid from "uuid";
import PropTypes from "prop-types";

export const OpenSuppliesGrid = ({ open_supplies_market, open_supplies_standard, media_type,
																	 market_loaded, standard_loaded, new_form_mode}) => {


	const intl = useIntl();

	const context = React.useContext(OpenSupplyContext);
	const [activeIndex, setActiveIndex] = useState({"market": true, "standard": new_form_mode});
	const isVideo = media_type === "video";

	/**
	 * set active index
	 * @param e
	 * @param node
	 */
	const updateIndex = async (e, node) => {
		const expand = !activeIndex[node.index] === true;
		// eslint-disable-next-line default-case
		switch(node.index) {
			case("market"):
				if(!market_loaded && expand) {
					await context.GetMarketSupplies();
				}
			break;

			case("standard"):
				if(!standard_loaded && expand) {
					await context.GetStandardSupplies();
				}
				break;
		}

		setActiveIndex({
			...activeIndex,
			[node.index]: !activeIndex[node.index]
		});
	};

	return (
		<>
			<Accordion className="open_supplies">
				<Accordion.Title
					active={activeIndex.market}
					index="market"
					onClick={updateIndex}
				>
					<Icon name="dropdown" />
					{intl.formatMessage({
						id: "OPEN_SUPPLIES_MARKET",
						defaultMessage: "Marketplaces",
					})}
				</Accordion.Title>
				<Accordion.Content active={activeIndex.market}>
					{isVideo? <VideoGrid items={open_supplies_market} type="market" /> : <DisplayGrid items={open_supplies_market} type="market" />}
				</Accordion.Content>

				<Accordion.Title
					active={activeIndex.standard}
					index="standard"
					onClick={updateIndex}
				>
					<Icon name="dropdown" />
					{intl.formatMessage({
						id: "OPEN_SUPPLIES_STANDARD",
						defaultMessage: "Open Exchange & SSP",
					})}
				</Accordion.Title>
				<Accordion.Content active={activeIndex.standard}>
					{isVideo? <VideoGrid items={open_supplies_standard} type="standard" /> : <DisplayGrid items={open_supplies_standard} type="standard" />}
				</Accordion.Content>
			</Accordion>
		</>);
};

/**
 * verify is all possible items checked
 * @param items
 * @return {boolean}
 */
const isItemsChecked = items => {
	return items.length > 0 && items.filter(x => x.checked).length === items.length;
};

/**
 * Generate video grid
 * @param {array} items
 * @param {string} type
 * @return {JSX.Element}
 * @constructor
 */
const VideoGrid = ({items, type}) => {
	const intl = useIntl();

	const context = React.useContext(OpenSupplyContext);
	const checkAll = (type === "market")? context.switchAllMarket : context.switchAllStandard;
	const check = (type === "market")? context.switchMarket : context.switchStandard;


	return <Table className="custom-table">
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell textAlign="center" style={{"width": "20px"}}>
					<Checkbox onChange={(e, item) => checkAll(item.checked)} disabled={!context.enabled || !items.length} checked={isItemsChecked(items)} />
				</Table.HeaderCell>
				<Table.HeaderCell>
					{intl.formatMessage({
						id: "LABEL_SUPPLY_SOURCES",
						defaultMessage: "Supply Sources",
					})}
				</Table.HeaderCell>
				<Table.HeaderCell textAlign="center" style={{"width": "100px"}}>
					{intl.formatMessage({
						id: "LABEL_MOBILE",
						defaultMessage: "Mobile",
					})}
				</Table.HeaderCell>
				<Table.HeaderCell textAlign="center" style={{"width": "100px"}}>
					{intl.formatMessage({
						id: "LABEL_DESKTOP",
						defaultMessage: "Desktop",
					})}
				</Table.HeaderCell>
				<Table.HeaderCell textAlign="center" style={{"width": "150px"}}>
					{intl.formatMessage({
						id: "LABEL_CONNECTED_TV",
						defaultMessage: "Connected TV",
					})}
				</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{items.map(item => {
				return <VideoGridItem key={uuid()} check={check} {...item} enabled={context.enabled} />
			})}
			{!items.length && <GridEmptyItem cols={5} />}
		</Table.Body>
	</Table>
};
VideoGrid.propTypes = {
	"items": PropTypes.array.isRequired
};

/**
 * Generate video grid row
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const VideoGridItem = props => {
	return (
		<>
			<Table.Row>
				<Table.Cell data-clickable="0" textAlign="center">
					<Checkbox defaultChecked={props.checked} disabled={!props.enabled} data_id={props.id} onChange={(e, {data_id, checked}) => {
						props.check(data_id, checked);
					}} />
				</Table.Cell>
				<Table.Cell className="text-ellipsis">{props.title}</Table.Cell>
				<Table.Cell textAlign="center">{Boolean(props.is_mobile) && <Icon name="checkmark box" />}</Table.Cell>
				<Table.Cell textAlign="center">{Boolean(props.is_desktop) && <Icon name="checkmark box" />}</Table.Cell>
				<Table.Cell textAlign="center">{Boolean(props.is_ctv) && <Icon name="checkmark box" />}</Table.Cell>
			</Table.Row>
		</>
	);
};

/**
 * Generate display grid
 * @param {array} items
 * @param {string} type
 * @return {JSX.Element}
 * @constructor
 */
const DisplayGrid = ({items, type}) => {
	const intl = useIntl();

	const context = React.useContext(OpenSupplyContext);
	const checkAll = (type === "market")? context.switchAllMarket : context.switchAllStandard;
	const check = (type === "market")? context.switchMarket : context.switchStandard;

	return <Table className="custom-table">
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell textAlign="center" style={{"width": "20px"}}>
					<Checkbox onChange={(e, item) => checkAll(item.checked)} disabled={!context.enabled || !items.length} checked={isItemsChecked(items)} />
				</Table.HeaderCell>
				<Table.HeaderCell>
					{intl.formatMessage({
						id: "LABEL_SUPPLY_SOURCES",
						defaultMessage: "Supply Sources",
					})}
				</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{items.map(item => {
				return <DisplayGridItem key={uuid()} check={check} enabled={context.enabled} {...item}  />
			})}
			{!items.length && <GridEmptyItem cols={2} />}
		</Table.Body>
	</Table>
};
DisplayGrid.propTypes = {
	"items": PropTypes.array.isRequired
};

/**
 * Generate video grid row
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const DisplayGridItem = props => {
	return (
		<>
			<Table.Row>
				<Table.Cell data-clickable="0" textAlign="center">
					<Checkbox defaultChecked={props.checked} disabled={!props.enabled} data_id={props.id} onChange={(e, {data_id, checked}) => {
						props.check(data_id, checked);
					}} />
				</Table.Cell>
				<Table.Cell className="text-ellipsis">{props.title}</Table.Cell>
			</Table.Row>
		</>
	);
};

const GridEmptyItem = props => {
	const intl = useIntl();

	return <Table.Row>
		<Table.Cell colSpan={props.cols} textAlign="center">
			{intl.formatMessage({
				id: "EMPTY_GENERIC",
				defaultMessage: "No records found",
			})}
		</Table.Cell>
	</Table.Row>
};
