/* eslint-disable no-unreachable */
export const actions = {
	"INIT": "INIT",
	"LOAD_SAVED_DATA": "LOAD_SAVED_DATA",
	"CHECK_ALL": "CHECK_ALL",
	"CHECK": "CHECK",
	"INIT_OPEN_SUPPLIES": "INIT_OPEN_SUPPLIES"
};

const checkedMap = new Map();

/**
 * Init open supplies
 * @param {array} supplies
 * @param {string} rtb_type
 * @param state
 * @return {object} new state
 */
const initOpenSupplies = (supplies, rtb_type, state) => {
	supplies = supplies.map(supply => {
		// supply.checked = (checkedMap.has(supply.id))? checkedMap.get(supply.id) : false;
		return supply;
	});

	if(rtb_type === "MARKETPLACE") {
		return {
			...state,
			"supplies_loaded": true,
			"open_supplies_market": supplies
		}
	}

	return {
		...state,
		"open_supplies_loaded": true,
		"open_supplies_standard": supplies
	}
};

/**
 * Init supplies
 * @param {array} supplies
 * @param {object} pager
 * @param state
 * @return {object} new state
 */
const initSupplies = (supplies, pager, state) => {
	state.pager.setTotal(pager);
	supplies = supplies.map(supply => {
		supply.checked = (checkedMap.has(supply.id))? checkedMap.get(supply.id) : false;
		supply.price = parseFloat(supply.price.toString());
		return supply;
	});

	return {
		...state,
		"supplies_loaded": true,
		supplies
	}
};

/**
 * check or uncheck specific supply
 * @param {number} id
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const checkSupply = (id, flag, state) => {
	const supplies = state.supplies.map(supply => {
		if(supply.id === id) {
			if(!flag) {
				checkedMap.delete(supply.id);
			} else if(flag && !checkedMap.has(supply.id)) {
				checkedMap.set(supply.id, true);
			}

			supply.checked = flag;
		}

		return supply;
	});

	return {
		...state,
		supplies
	}
};

/**
 * get supplies ids that been checked
 * @return {Map<any, any>}
 */
export const getCheckedSupplies = () => {
	return checkedMap;
};

/**
 * Clear supplies map
 */
export const clearChecked = () => {
	checkedMap.clear();
};

/**
 * Load data that already saved
 * @param {object} response
 * @param state
 * @return {object} new state
 */
const loadSavedData = (response, state) => {
	checkedMap.clear();
	if(response.supply_ids) {
		response.supply_ids.forEach(id => {
			checkedMap.set(id, true);
		});
	}

	// duplicate checked supplies logic
	const supplies = state.supplies.map(supply => {
		supply.checked = (checkedMap.has(supply.id))? checkedMap.get(supply.id) : false;
		return supply;
	});

	return {
		...state,
		supplies,
		"selected_supplies": response
	}
};

export const suppliesGridReducer = (state, action) => {
	switch(action.type) {
		case(actions.INIT):
			return initSupplies(action.data, action.pager, state);

		case(actions.INIT_OPEN_SUPPLIES):
			return initOpenSupplies(action.data, action.rtb_type, state);

		case(actions.LOAD_SAVED_DATA):
			return loadSavedData(action.data, state);

		case(actions.CHECK):
			return checkSupply(action.id, action.flag, state);

		default:
			throw new Error("Method is not implement");
	}
};
