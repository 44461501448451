import React, { useCallback, useState } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import UserForm from "../form";
import UsersService from "../../../services/users";

/**
 * User create page component
 * @param history
 * @return {*}
 * @constructor
 */
const UserCreatePage = ({ history }) => {
	const back_url = "/admin/users";
	const service = new UsersService();
	const intl = useIntl();

	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);
	let _isMounted = React.useRef(true);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * form submit handler
	 * @param {object} values
	 * @param {File} file
	 */
	const handleSubmit = async (values) => {
		setLoading(true);

		const data = {
			...values,
			"email": values["username"],
			"entities": [
				{
					"entity_type": "agencies",
					"entity_ids": values["agency_ids"],
				},
				{
					"entity_type": "advertisers",
					"entity_ids": values["advertiser_ids"],
				},
			],
		};

		delete data["agency_ids"];
		delete data["advertiser_ids"];

		try {
			await service.create(data);
			history.push(back_url, { "action": "created" });
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	const initialData = {
		"status": 1,
		"first_name": "",
		"last_name": "",
		"title": "",
		"phone": "",
		"username": "",
		"role": "REPORTER",
		// "entities": ...
		"agency_ids": [],
		"advertiser_ids": [],
	};

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_CREATE_USER",
					defaultMessage: "Create User",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<UserForm
				loading={loading}
				initialData={initialData}
				initialIndeterminateAgencies={[]}
				submitText={intl.formatMessage({
					id: "BTN_CREATE_USER",
					defaultMessage: "Create User",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default UserCreatePage;
