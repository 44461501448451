import React from "react";
import { Form } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import _ from "lodash";

import "../index.css";
import AudienceContext from "../contexts/audience";
import { classNames } from "../../../../../libs/component_utils.js";
import {readablizeNumber} from "../../../../../libs/common_utils";

export const actions = {
	"SWITCH": "SWITCH",
	"VISIBILITY": "VISIBILITY",
	"INIT": "INIT",
	"APPEND": "APPEND"
};

const switchTarget = (id, state) => {
	let found = false;

	const check_all = node => {
		return node.child.map(item => {
			item.included = node.included;
			item.excluded = node.excluded;

			if(item.child) {
				item.child = check_all(item);
			}

			return item;
		});
	};

	const tree = node => {
		return node.map(item => {
			if(found) {
				return item;
			}

			if(item.id === id) {
				if(item.included) {
					item.included = false;
					item.excluded = true;
				} else if(item.excluded) {
					item.included = false;
					item.excluded = false;
				} else {
					item.included = true;
					item.excluded = false;
				}

				if(item.child) {
					item.child = check_all(item);
				}
				found = true;
			} else if(item.child) {
				item.child = tree(item.child)
			}

			return item;
		});
	};

	return tree([...state]);
};

const switchVisibility = (id, state) => {
	let found = false;
	const tree = node => {
		return node.map(item => {
			if(found) {
				return item;
			}

			if(item.id === id && item.hasOwnProperty("opened")) {
				item.opened = !item.opened;
				found = true;
			} else if(item.child) {
				item.child = tree(item.child)
			}

			return item;
		});
	};

	return tree([...state]);
};

const targetRootMap = [{
	"checkable": false,
	"previous_checked": false,
	"included": false,
	"excluded": false,
	"opened": false,
	"uuid": 0,
	"custom": 0,
	"title": "Data Providers",
	"messageId": "LABEL_DATA_PROVIDERS",
	"child": [],
	"type": null,
}, {
	"checkable": false,
	"previous_checked": false,
	"included": false,
	"excluded": false,
	"opened": false,
	"uuid": 1,
	"custom": 1,
	"id": 0,
	"title": "My Audience",
	"messageId": "LABEL_MY_AUDIENCE",
	"type": null,
	"child": [{
		"checkable": false,
		"previous_checked": false,
		"included": false,
		"excluded": false,
		"opened": false,
		"uuid": uuid(),
		"has_children": true,
		"parent_id": 0,
		"custom": 1,
		"id": 2,
		"title": "Pixels",
		"messageId": "LABEL_PIXELS",
		"type": "pixel"
	}]
}];
let treeInit = false;

/**
 * reset the flag to rebuild the tree
 */
export const resetInitTreeFlag = () => {
	treeInit = false;
};

/**
 * populate the tree with recursive function
 * @param {array} root
 * @param {array} data
 * @return {array}
 */
const maxCycles = 50;
const fillTheTree = (root, data) => {
	let recursions = 0;

	/**
	 * find intersections between 2 arrays
	 * @param {array} arr1
	 * @param {array} arr2
	 * @return {Boolean}
	 */
	const hasIntersection = (arr1, arr2) => {
		return arr1.filter(value => arr2.includes(value)).length > 0;
	};

	const tree = node => {
		if(recursions++ > maxCycles) {
			console.error(`Recursion limit of ${recursions} times was reached!`);
			return node;
		}

		return node.map(item => {
			if(item.child && item.child.length > 0) {
				item.child = tree(item.child).filter(x => !!x);
			} else {
				item.child = data.filter(x => x.parent_id === item.id).filter(x => !!x);
			}

			return item;
		});
	};

	if(!treeInit) {
		treeInit = true;
		return [...root].map(n => {
			n.child = n.child.concat(data.filter(d => d.custom === n.custom));
			return n;
		});
	}

	return tree([...root]).filter(x => !!x);
};

/**
 * rebuild response into proper ui structure
 * @param {Array} targets
 * @return {Array}
 */
const init_target_structure = targets => {
	return targets.map(target => {
		return {
			...target,
			"checkable": Boolean(target.buyable),
			"previous_checked": false,
			"included": false,
			"excluded": false,
			"opened": false,
			"uuid": uuid()
		}
	});
};

/**
 * group flat data into convenient structure
 * @param {Array} targets
 * @returns {Object}
 */
let groupedChildren = {};
const groupFlatData = targets => {
	let groupedChildren = {};
	targets.forEach(child => {
		if(!groupedChildren.hasOwnProperty(child.dimension_code)) {
			groupedChildren[child.dimension_code] = {};
		}

		if(!groupedChildren[child.dimension_code].hasOwnProperty(child.parent_value_id)) {
			groupedChildren[child.dimension_code][child.parent_value_id] = new Set();
		}

		groupedChildren[child.dimension_code][child.parent_value_id].add(child);
	});

	return groupedChildren;
};

export const targetValuesControlReducer = (state, action) => {
	let targets = [];
	switch(action.type) {
		case(actions.INIT):
			targets = init_target_structure(action.data);

			/**
			 * find targets that contains non empty parents
			 * @param root
			 * @param children
			 * @return {*}
			 */
			const getTree = (root, children) => {
				let recursions = 0;
				let groupedChildren = groupFlatData(children);

				const build_tree = (node, values) => {
					if(recursions++ > 10000 || Object.keys(values).length < 1) {
						// console.error(`Recursion limit of ${recursions} times was reached!`);
						return;
					}

					node.child = Array.from(values[node.value]);
					delete values[node.value];
					node.child.forEach(n => {
						if(values.hasOwnProperty(n.value)) {
							build_tree(n, values);
						}
					});
				};

				root.forEach(n => {
					const dimension_keys = Object.keys(groupedChildren);
					if(~dimension_keys.indexOf(n.dimension_code) && groupedChildren[n.dimension_code].hasOwnProperty(n.value)) {
						build_tree(n, groupedChildren[n.dimension_code]);
					}
				});

				return root;
			};

			const cloned_map = _.cloneDeep(targetRootMap);
			let targets_tree = getTree(targets.filter(x => !x.parent_id), targets.filter(x => x.parent_id && x.id !== x.parent_id));

			return fillTheTree(cloned_map, targets_tree);

		case(actions.APPEND):
			let tmp = action.data;
			targets = init_target_structure(tmp);
			let found = false;

			const appendChild = (root, id_to_append, children) => {
				return root.map(node => {
					if(node.id === id_to_append) {
						node.opened = true;
						node.child = children;
						found = true;
					}

					if(!found && node.hasOwnProperty("child") && node.child.length) {
						node.child = appendChild(node.child, id_to_append, children);
					}

					return node;
				});
			};

			return appendChild(_.cloneDeep(state), action.root.id, targets);

		case(actions.SWITCH):
			return switchTarget(action.id, state);

		default:
			throw new Error("Method is not implement");
	}
};

export const TargetValuesControl = React.memo(({values}) => {
	const {switchTarget, switchVisibility, excluded, included, opened, campaign} = React.useContext(AudienceContext);
	const intl = useIntl();

	const recursiveCollapseEmptyNodes = node => {
		node.querySelectorAll(":scope > ul > li > div.ul-container").forEach(n => {

			const x = n.querySelector(":scope > ul > li > .ul-container");
			if(!x || x.querySelectorAll("input[type='checkbox']").length < 1) {
				n.style.cssText = "display:none !important";
			} else {
				// recursiveCollapseEmptyNodes(n);
			}
		});
	};

	/**
	 * set items checked for each tree reload
	 */
	React.useLayoutEffect(() => {
		let keys = [];
		if(included.get("pixels")) {
			keys = keys.concat(included.get("pixels")[0].entities.map(e => e.id));
		}

		if(included.get("audiences")) {
			included.get("audiences").forEach(a => {
				keys = keys.concat(a.entities.map(e => e.id));
			});
		}

		keys.forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.checked = true;
			}
		});

		Array.from(excluded.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.indeterminate = true;
			}
		});
	});

	/*
	 * Reload translated values on locale change.
	 * (TODO: optimize)
	 */
	React.useLayoutEffect((a) => {
		document.querySelectorAll("[data-message-id]").forEach((el) => {
			el.textContent = intl.formatMessage({
				id: el.dataset.messageId,
				defaultMessage: el.textContent,
			});
		});
	});

	/**
	 * check that target has children
	 * @param {object} node
	 * @return {boolean}
	 */
	const target_has_children = node => {
		return (node.hasOwnProperty("child") && node.child.length) || (node.hasOwnProperty("has_children") && node.has_children)
	};

	/**
	 * form node title based on its type
	 * @param {object} node
	 * @return {string}
	 */
	const getTitle = (node) => {
		if (node.hasOwnProperty("messageId")) {
			return intl.formatMessage({
				id: node.messageId,
				defaultMessage: node.title,
			});
		}

		let title = [];

		if(node.hasOwnProperty("uniques") && node.uniques > 0) {
			title.push(`Size ${readablizeNumber(node.uniques)}`);
		}

		if(node.hasOwnProperty("retail_cpm") && node.retail_cpm > 0) {
			title.push(`CPM ${campaign.currency_symbol}${node.retail_cpm}`);
		}

		return title.length > 0? `${node.title} (${title.join("; ")})` : node.title;
	};

	const html = (values) => {
		const specialCase = values.some(target => target_has_children(target) && target.checkable);

		return (<>
			{values.map((target) => {
				return (
					<div key={`node_${target.uuid}`} className={classNames(
						"ul-container",
						(opened.has(target.id) ? opened.get(target.id) : target.opened) && "opened",
						target_has_children(target) && "has-children",
						target.checkable && "checkable",
						specialCase && "special-case"
					)}>
						{target_has_children(target) && <div className="arrow-right" onClick={e => switchVisibility(e.target, target)}>&nbsp;</div>}
						<ul>
							<li>
								<Form.Field className="custom-checkbox">
									{target.checkable ? (
										<>
											<input
												type="checkbox"
												onChange={(e) => switchTarget(e, target.id)}
												value={target.id}
												id={`target_${target.id}`}
												data-type={target.type || "audience"}
											/>

											<label
												className="target-label"
												data-message-id={target?.messageId}
												htmlFor={`target_${target.id}`}
											>
												{getTitle(target)}
											</label>
										</>
									) : (
										<label
											className="target-label no-parents"
											data-message-id={target?.messageId}
											onClick={(e) =>
												switchVisibility(
													e.target.parentNode.parentNode.parentNode,
													target
												)
											}
										>
											{getTitle(target)}
										</label>
									)}
								</Form.Field>
								{target.child && target.child.length > 0 && html(target.child)}
							</li>
						</ul>
					</div>);
			})}
		</>)
	};

	return html(values);
}, (prev, next) => {
	return JSON.stringify(prev.values) === JSON.stringify(next.values);
});
TargetValuesControl.propTypes = {
	"values": PropTypes.array.isRequired
};
