import AbstractService from "../abstract.service";
import {DimensionCodes} from "../../models/enum/dimension_codes";

export default class StrategiesService extends AbstractService {
	static URI_STATES = new Map([
		["strategies.duplicate", "/duplicate"],
		["strategies.data", "/strategies-data/{0}"],
		["strategies.get", "/strategy/{0}"],
		["strategies.open_supplies", "/strategy/open-supplies"],
		["strategies.supplies_by_advertiser", "/strategy/supplies/{0}"],
		["strategies.supplies.post", "/build-strategy/{0}/supplies"],
		["strategy.supplies", "/strategy/{0}/supplies"],
		["strategy.creatives", "/strategy/{0}/creatives"],
		["strategy.targets", "/strategy/{0}/targeting"],
		["strategy.audiences", "/strategy/{0}/audiences"],
		["strategy.daypart", "/strategy/{0}/daypart"],

		["strategy.technologies", "/strategy/{0}/technology"],
		["strategy.browsers", "/strategy/browsers"],
		["strategy.inventory", "/strategy/inventory"],
		["strategy.devices", "/strategy/devices"],
		["strategy.os", "/strategy/os"],
		["strategy.models", "/strategy/models"],
		["strategy.open_supplies", "/strategy/open_supplies"],

		["strategies.supply_rules", "/supply-rules/{0}"],
    ["strategies.bulk_edit_strategies", "/bulk-update/strategies"]
	]);


	/**
	 * Get supply rule for a workflow, which describes should be white list used or regular one
	 * @param {number} strategy_id
	 * @param {string} workflow
	 * @returns {Promise<void>}
	 */
	async get_supply_rules(strategy_id, workflow) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategies.supply_rules").format(strategy_id), {
			workflow
		});
	}

	/**
	 * get open supplies
	 * @param {string} rtb_type
	 * @param {string} strategy_type
	 * @param {string} search_query
	 * @return {Promise<any | never>}
	 */
	async get_open_supplies(rtb_type, strategy_type, search_query = "") {
		const params = {rtb_type, strategy_type};
		if(search_query) {
			params["supply_name"] = search_query;
		}
		return this.api_server.get(StrategiesService.URI_STATES.get("strategies.open_supplies"), params);
	}

		/**
		* Save technology into t1
		* @param {number} strategy_id
		* @param {array} params
		* @returns {Promise<any | never>}
		*/
		async create_technology(strategy_id, params) {
			return this.api_server.post(StrategiesService.URI_STATES.get("strategy.technologies").format(strategy_id), params);
		}

		/**
		 * Save technology into t1
		 * @param {number} strategy_id
		 * @param {array} params
		 * @returns {Promise<any | never>}
		 */
		async update_technology(strategy_id, params) {
			return this.api_server.put(StrategiesService.URI_STATES.get("strategy.technologies").format(strategy_id), params);
		}

		/**
		 * Get technology from t1
		 * @param {number} strategy_id
		 * @returns {Promise<any | never>}
		 */
		async get_technology(strategy_id) {
				return this.api_server.get(StrategiesService.URI_STATES.get("strategy.technologies").format(strategy_id));
		}

		/**
		* Get browsers from t1 DB
		* @param {string} search_query
		* @returns {Promise<any | never>}
		*/
		async __get_browsers(search_query = "") {
			let params = {};
			if(search_query) {
				params["technology_name"] = search_query;
			}
			return this.api_server.get(StrategiesService.URI_STATES.get("strategy.browsers"), params);
		}

	/**
	 * Get inventory from t1 DB
	 * @param {string} search_query
	 * @returns {Promise<any | never>}
	 */
	async __get_inventory(search_query = "") {
		let params = {};
		if(search_query) {
			params["technology_name"] = search_query;
		}

		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.inventory"), params);
	}

	/**
	 * Get devices from t1 DB
	 * @param {number|string} parent_id
	 * @param {string} search_query
	 * @returns {Promise<any | never>}
	 */
	async __get_devices(parent_id= 0, search_query = "") {
		let params = {};
		if(parent_id && !isNaN(parent_id)) {
			params["parent_id"] = parent_id;
		}

		if(search_query) {
			params["technology_name"] = search_query;
		}
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.devices"), params);
	}

	/**
	 * Get os from t1 DB
	 * @param {number|string} parent_id
	 * @param {string} search_query
	 * @returns {Promise<any | never>}
	 */
	async __get_os(parent_id= 0, search_query = "") {
		let params = {};
		if(parent_id && !isNaN(parent_id)) {
			params["parent_id"] = parent_id;
		}

		if(search_query) {
			params["technology_name"] = search_query;
		}
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.os"), params);
	}

	/**
	 * Get models from t1 DB
	 * @param {number|string} parent_id
	 * @param {string} search_query
	 * @returns {Promise<any | never>}
	 */
	async __get_models(parent_id= 0, search_query = "") {
		let params = {};
		if(parent_id && !isNaN(parent_id)) {
			params["parent_id"] = parent_id;
		}

		if(search_query) {
			params["technology_name"] = search_query;
		}
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.models"), params);
	}

	/**
	 * Facade to get data by its dimension code
	 * @param {string} code
	 * @param {number} parent_id
	 * @param {string} search_query
	 * @return {Promise}
	 */
	async get_data_by_dimension_code(code, parent_id= 0, search_query = "") {
		switch(code) {
			case(DimensionCodes.DVCE):
				return this.__get_devices(parent_id, search_query);

			case(DimensionCodes.NMDM):
				return this.__get_models(parent_id, search_query);

			case(DimensionCodes.INVT):
				return this.__get_inventory(search_query);

			case(DimensionCodes.BSER):
				return this.__get_browsers(search_query);

			case(DimensionCodes.NMOS):
				return this.__get_os(parent_id, search_query);

			default:
				throw new Error("No dimension code supported");
		}
	}

	/**
	 * Get daypart from t1 DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_daypart(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.daypart").format(strategy_id));
	}

	/**
	 * Save daypart into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_daypart(strategy_id, params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategy.daypart").format(strategy_id), params);
	}

	/**
	 * Save daypart into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_daypart(strategy_id, params) {
		return this.update_daypart(strategy_id, params);
	}

	/**
	 * Get targets from t1 DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_targets(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.targets").format(strategy_id));
	}

	/**
	 * Save targets into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_targets(strategy_id, params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategy.targets").format(strategy_id), params);
  }

	/**
	 * Save targets into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_targets(strategy_id, params) {
		return this.update_targets(strategy_id, params);
	}

	/**
	 * Save audiences into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
  */
	async create_audiences(strategy_id, params) {
		return this.update_audiences(strategy_id, params);
	}

	/**
	 * Save audiences into t1 DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_audiences(strategy_id, params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategy.audiences").format(strategy_id), params);
	}

	/**
	 * Get audiences from t1 DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_audiences(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.audiences").format(strategy_id));
	}

	/**
	 * Save draft supplies
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async save_supplies(strategy_id, params) {
		return this.api_server.post(StrategiesService.URI_STATES.get("strategies.supplies.post").format(strategy_id), params);
	}

	/**
	 * Load list of strategies by campaign id
	 * @param {number} campaign_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async list(campaign_id, params) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategies.data").format(campaign_id), params);
	}

	/**
	 * Get strategy by id
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategies.get").format(strategy_id));
	}

	/**
	 * Get list of supplies by advertiser
	 * @param {number} advertiser_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async supplies_by_advertiser(advertiser_id, params) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategies.supplies_by_advertiser").format(advertiser_id), params);
	}

	/**
	 * store t1 strategy
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async save(params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategies.get").format(params.t1_id), params);
	}

	/**
	 * Get t1 supplies
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get_supplies(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.supplies").format(strategy_id));
	}

	/**
	 * Update existing t1 supplies
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_supplies(strategy_id, params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategy.supplies").format(strategy_id), params);
	}

	/**
	 * Update existing t1 supplies
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_supplies(strategy_id, params) {
		return this.update_supplies(strategy_id, params);
	}

	/**
	 * Get existing t1 creatives
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get_creatives(strategy_id) {
		return this.api_server.get(StrategiesService.URI_STATES.get("strategy.creatives").format(strategy_id));
	}

	/**
	 * Get existing t1 creatives
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_creatives(strategy_id, params) {
		return this.api_server.put(StrategiesService.URI_STATES.get("strategy.creatives").format(strategy_id), params);
	}

	/**
	 * Create draft creatives
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_creatives(strategy_id, params) {
		return this.update_creatives(strategy_id, params);
	}

	async duplicate(id) {
		return this.api_server.post(
			StrategiesService.URI_STATES.get("strategies.duplicate"),
			{ type: "strategy", entity_id: id }
		)
	}

  /**
	 * Bulk edits strategies
	 * @param {object} params
	 * @return {Promise<>}
	 */
  async bulkEditStrategies(params) {
		return this.api_server.post(StrategiesService.URI_STATES.get("strategies.bulk_edit_strategies"), params);
  }
}
