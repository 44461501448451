import React, { useReducer, useState } from "react";
import StrategyManageContext from "../context";
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Grid,
	Icon, Input,
	Label,
	Message, Modal,
	Pagination,
	Table
} from "semantic-ui-react";
import SupplyGridContext from "./contexts/supply";
import Pager from "../../../../models/pager";
import { actions, getChecked, gridReducer, clearChecked } from "./reducers/creatives";
import * as uuid from "uuid";
import PropTypes from "prop-types";
import StrategyCreativesSelected from "../../../../models/strategy_creatives_selected";

import "./creatives.css";
import {Steps} from "../../../../models/enum/strategy";
import {NavigationButtonDiv} from "./navigation_buttons";
import {StrategySaveComponent} from "./save_modal";
import StrategiesService from "../../../../services/strategy";
import {search_query_becomes_empty, should_do_search} from "../../../../libs/component_utils";
import {Config} from "../../../../config/api";
import { useIntl } from "react-intl";
import {useFlags} from "launchdarkly-react-client-sdk";
import {getReadableClientName} from "../../../../libs/common_utils";

const initialState = {
	"creatives": [],
	"selected_creatives": {},
	"loaded": false,
	"pager": new Pager()
};

const save_state = [{
	"step": Steps.MARKETING_OBJECTIVES,
	"status": "loading",
	"messageId": "STEP_MARKETING_OBJECTIVES",
}, {
	"step": Steps.SUPPLY,
	"status": "loading",
	"messageId": "STEP_SUPPLY",
}, {
	"step": Steps.CREATIVES,
	"status": "loading",
	"messageId": "STEP_CREATIVES",
}];

export const CreativesStep = () => {
	const context = React.useContext(StrategyManageContext);
	const service = context.services.current.get("creatives");
	const strategiesService = context.services.current.get("strategies");
	const strategy_id = parseInt(context.strategy_id);
	let _isMounted = React.useRef(true);
	const is_t1_edit = (strategiesService instanceof StrategiesService);
	const creatives_has_been_blocked = React.useRef(false);
	const {whitelabelDoubleCreativeApproval, whitelabelAllowStrategyWithoutCreative} = useFlags();

	const query = React.useRef(""),
		timer = React.useRef(),
		whitelist_flow_enabled = React.useRef(false);

	const [strategySave, setStrategySave] = useState(false);
	const [gridLoading, setGridLoading] = useState(true);
	const [error, setError] = useState("");
	const [formSending, setFormSending] = useState(false);
	const [state, dispatch] = useReducer(gridReducer, initialState);
	const [serverError, setServerError] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const intl = useIntl();

	let get_continue = React.useRef(true);

	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	/**
	 * Get creatives whitelist permission flag
	 * @param {number} strategy_id
	 * @returns {Promise<boolean>}
	 */
	const getWhitelistedPermissionFlag = async strategy_id => {
		let whitelist_enabled = false;
		try {
			const r = await strategiesService.get_supply_rules(strategy_id, "creative");
			whitelist_enabled = (r.meta.status === "ok")? r.data["supply_available"] : whitelist_enabled;
		} catch (e) {
			setServerError(e.error.message);
		}

		return whitelist_enabled;
	};

	/**
	 * load already checked creatives
	 */
	React.useEffect(() => {
		creatives_has_been_blocked.current = false;
		if(!strategy_id) {
			return;
		}

		state.pager.reset();

		(async () => {
			setGridLoading(true);
			try {
				// we have additional flow, in case double-approval flag is enabled, we need to
				// get flag that indicates what list of creatives should be loaded
				let params = state.pager.toJson();
				if(whitelabelDoubleCreativeApproval) {
					if(await getWhitelistedPermissionFlag(strategy_id)) {
						params["approved"] = true;
						whitelist_flow_enabled.current = true;
					}
				}

				params["advertiser_id"] = context.campaign.advertiser_id;
				params["channel_type"] = context.strategy.media_type;
				const agency = context.services.current.get("agencies").getSelectedAgency() || 0;

				const [saved, r] = await Promise.all([strategiesService.get_creatives(strategy_id),
					service.concepts_by_agency(agency, params)]);

				let meta = r.meta;
				meta.page = state.pager.page;

				dispatch({"type": actions.INIT, "data": r.data, "pager": meta});
				dispatch({"type": actions.LOAD_SAVED_DATA, "data": saved.data});
			} catch (e) {
				creatives_has_been_blocked.current = Boolean(~e.error.message.toLowerCase().search(/have been changed outside/));
				setServerError(e.error.message);
			} finally {
				if (_isMounted.current) {
					setGridLoading(false);
				}
			}
		})();

		// clear cache
		return () => {
			state.pager.reset();
			clearChecked();
			_isMounted.current = false;
		};
	}, [strategy_id]);

	React.useLayoutEffect(() => {
		const el = document.getElementById("clear_search_input");
		if(query.current.length > 0) {
			el.addEventListener("click", clearSearch, false);
		}

		return () => {
			if(el) {
				el.removeEventListener("click", clearSearch);
			}
		}
	}, [query.current]);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, { activePage }) => {
		state.pager.setPage(activePage);
		getCreatives(state.pager).then(() => console.log);
	};

	/**
	 * load creatives from API
	 * @param {object} pager
	 * @return {Promise<void>}
	 */
	const getCreatives = async pager => {
		try {
			setGridLoading(true);
			let params = pager.toJson();
			params["advertiser_id"] = context.campaign.advertiser_id;
			params["channel_type"] = context.strategy.media_type;

			if(query.current.length) {
				params["concept_name"] = query.current;
			}

			if(whitelist_flow_enabled.current) {
				params["approved"] = true;
			}

			const agency =
				context.services.current.get("agencies").getSelectedAgency() || 0;
			const r = await service.concepts_by_agency(agency, params);
			let meta = r.meta;
			meta.page = pager.page;
			dispatch({ "type": actions.INIT, "data": r.data, "pager": meta });
		} catch (e) {
			console.error(e);
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * check specific supply
	 * @param {number} id
	 * @param {boolean} flag
	 */
	const check = (id, flag) => {
		dispatch({ "type": actions.CHECK, "id": id, "flag": flag });
	};

	/**
	 * wrapper for form submit
	 */
	const onSubmit = async () => {
		setError("");
		if (!whitelabelAllowStrategyWithoutCreative && getChecked().size < 1) {
			setError(intl.formatMessage({
				id: "ERROR_EMPTY_CREATIVES",
				defaultMessage: "At least one creative must be selected.",
			}));
			return false;
		}

		if(whitelabelAllowStrategyWithoutCreative && getChecked().size < 1) {
			setOpenModal(true);
			return false;
		}

		await handleSubmit();
	};

	/**
	 * do a real API call
	 */
	const handleSubmit = async () => {
		const creatives = StrategyCreativesSelected.fromJson(strategy_id, {
			"creative_ids": Array.from(getChecked().keys()),
		});

		try {
			setFormSending(true);

			if (Boolean(state.selected_creatives.length)) {
				await strategiesService.update_creatives(
					creatives.strategy_id,
					creatives.toJson()
				);
			} else {
				await strategiesService.create_creatives(
					creatives.strategy_id,
					creatives.toJson()
				);
			}

			// if this is a pg campaign, its should be a last step, otherwise redirect to the next step
			if(context.campaign.is_pg) {
				if(is_t1_edit) {
					context.strategySuccessfullyUpdated(context.strategy.strategy_name);
				} else {
					setStrategySave(true);
				}

			} else {
				if(get_continue.current) {
					context.stepNavigation.passCreatives(strategy_id);
				} else {
					context.strategySuccessfullyUpdated(context.strategy.strategy_name);
				}
			}
		} catch (e) {
			setError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setFormSending(false);
			}
		}
	}

	/**
	 * do a search
	 */
	const handleSearch = async (e, {value: searchQuery}) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(searchQuery, prev_query)) {
			return;
		}

		state.pager.reset();
		if(search_query_becomes_empty(searchQuery, prev_query)) {
			await getCreatives(state.pager);
			return;
		}

		timer.current = setTimeout(async () => {
			await getCreatives(state.pager);
		}, Config.search_debounce_delay);
	};

	/**
	 * clear search query
	 */
	const clearSearch = async () => {
		query.current = "";
		document.getElementById("search_query_id").value = "";
		state.pager.reset();
		service.api_server.cancelRequest();
		await getCreatives(state.pager);
	};

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_search_input"
		}
	};

	/**
	 * Close modal dialogue
	 */
	const closeWarningModal = () => {
		setOpenModal(false);
	}

	const getWarningModalContent = empty_whitelisted_flow => {
		return empty_whitelisted_flow? intl.formatMessage({
			id: "BODY_MODAL_NO_APPROVED_CREATIVES_AVAILABLE",
			defaultMessage: "No approved creatives are available for the supply source(s) selected. Please check with your {client_name} representative",
		}, {
			"client_name": getReadableClientName(Config.public_client)
		}) : intl.formatMessage({
			id: "BODY_MODAL_CREATIVES_NOT_SELECTED",
			defaultMessage: "You are trying to create a strategy without creative assignment. A valid creative assignment is required for the strategy to spend",
		});
	}

	return (
		<SupplyGridContext.Provider
			value={{
				getPage,
				check,
			}}
		>
			<Modal
				size="mini"
				open={openModal}
				onClose={() => closeWarningModal()}
			>
				<Modal.Content>
					<p>{getWarningModalContent(whitelist_flow_enabled.current && state.creatives.length < 1)}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button primary onClick={async () => {closeWarningModal(); await handleSubmit()}} size="tiny">
						{intl.formatMessage({
							id: "BTN_OK",
							defaultMessage: "Ok",
						})}
					</Button>
				</Modal.Actions>
			</Modal>
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			{strategySave && <StrategySaveComponent
				save_state={save_state}
				strategy_id={strategy_id}
				campaign_id={context.campaign.id}
				navigation={context.stepNavigation}
				navigate_back={() => context.strategyCreated()}
				resetModal={() => setStrategySave(false)}
			/>}
			<Form
				error={Boolean(error.length)}
				size="small"
				loading={formSending || gridLoading}
				noValidate
				onSubmit={onSubmit}
				autoComplete="off"
			>
				<Form.Field inline>
					<h3 style={{"display": "inline"}}>
						<label>
							{intl.formatMessage({
								id: "LABEL_CREATIVES",
								defaultMessage: "Creatives",
							})}
						</label>
					</h3>
					<Input
						onChange={handleSearch}
						placeholder={intl.formatMessage({
							id: "HINT_CREATIVES",
							defaultMessage: "Search creatives by name",
						})}
						icon={query.current? getCloseIcon() : null}
						name="creative_name"
						id="search_query_id"
						style={{"width": "250px"}}
						disabled={gridLoading}
					/>
				</Form.Field>
				{whitelist_flow_enabled.current && <Message size="tiny"><Icon name="info circle" />
					{intl.formatMessage({
						id: "HINT_ONSITE_SUPPLY_SELECTED",
						defaultMessage: "You have selected one or more onsite supply source(s). Only approved onsite creatives are displayed. To see all creatives, please de-select the onsite supply source(s).",
					})}
				</Message>}
				<Message error>{error}</Message>
				<Form.Field>
					<Grid columns={2}>
						<Grid.Column>
							<CreativesGrid
								items={state.creatives}
								loading={gridLoading}
								controls={{ "pager": state.pager }}
							/>
						</Grid.Column>
						<Grid.Column>
							<CreativesChecked items={getChecked()} />
						</Grid.Column>
					</Grid>
				</Form.Field>
				<Divider hidden />
				<Divider hidden />
				<Divider hidden />
				<NavigationButtonDiv
					loading={formSending || gridLoading}
					step={Steps.CREATIVES}
					isPG={context.campaign.is_pg}
					onBackClick={() => context.stepNavigation.backToSupplies(strategy_id)}
					onSave={setOperationType}
					is_t1_edit={is_t1_edit}
					onCancelClick={context.getBack}
				/>
			</Form>
		</SupplyGridContext.Provider>
	);
};

const CreativesChecked = ({ items }) => {
	const context = React.useContext(SupplyGridContext);
	const intl = useIntl();

	return (
		<>
			<strong>
				{intl.formatMessage({
					id: "LABEL_ASSIGNED_CREATIVES",
					defaultMessage: "Assigned Creatives",
				})}
			</strong>
			<div className="assigned-creatives">
				{Array.from(items.values()).map((i) => {
					return (
						<Label as="a" key={uuid()}>
							{i.title}
							<Icon name="delete" onClick={() => context.check(i.id, false)} />
						</Label>
					);
				})}
			</div>
		</>
	);
};

/**
 * Render creatives grid
 * @constructor
 */
const CreativesGrid = ({ items, loading, controls }) => {
	const context = React.useContext(SupplyGridContext);
	const intl = useIntl();

	return (
		<Table className="custom-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell textAlign="center" style={{ "width": "20px" }}>
						&nbsp;
					</Table.HeaderCell>
					<Table.HeaderCell>
						{intl.formatMessage({
							id: "LABEL_CREATIVE_NAME",
							defaultMessage: "Creative Name",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell style={{"width": "100px"}} textAlign="left">
						{intl.formatMessage({
							id: "LABEL_CREATIVE_ID",
							defaultMessage: "Creative ID",
						})}
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{items.map((item) => {
					return <CreativeGridItem key={uuid()} {...item} />;
				})}
				{!loading && items.length < 1 && (
					<Table.Row>
						<Table.Cell className="no-border" colSpan="3" textAlign="center">
							{intl.formatMessage({
								id: "EMPTY_SEARCH_RESULTS",
								defaultMessage: "No results found",
							})}
						</Table.Cell>
					</Table.Row>
				)}
			</Table.Body>
			<Table.Footer>
				<Table.Row>
					<Table.Cell colSpan="11" textAlign="right">
						{controls.pager.total_pages > 1 && (
							<Pagination
								size="mini"
								activePage={controls.pager.page}
								totalPages={controls.pager.total_pages}
								firstItem={null}
								lastItem={null}
								onPageChange={context.getPage}
							/>
						)}
					</Table.Cell>
				</Table.Row>
			</Table.Footer>
		</Table>
	);
};

/**
 * Generate grid item
 */
const CreativeGridItem = (item) => {
	const context = React.useContext(SupplyGridContext);

	return (
		<>
			<Table.Row>
				<Table.Cell data-clickable="0" textAlign="center">
					<Checkbox
						defaultChecked={item.checked}
						data_id={item.id}
						onChange={(e, { data_id, checked }) => {
							context.check(data_id, checked);
						}}
					/>
				</Table.Cell>
				<Table.Cell className="text-ellipsis">{item.title}</Table.Cell>
				<Table.Cell>{item.id}</Table.Cell>
			</Table.Row>
		</>
	);
};
CreativeGridItem.propTypes = {
	"title": PropTypes.string.isRequired,
	"id": PropTypes.number.isRequired,
};
