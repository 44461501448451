import React from "react";
import { useIntl } from "react-intl";
import { Modal, Header, Button } from "semantic-ui-react";
import { Config } from "../../config/api";

import "./modal.css";
import {RevenueType} from "../../models/enum/pixel_revenue_type";
import {generatePixelSnippet} from "../../libs/common_utils";

const ISO_4217_DOCS_URL = "https://www.iso.org/iso-4217-currency-codes.html";
const SHA256_DOCS_URL = "https://cryptojs.gitbook.io/docs/";

const PixelModal = ({ type = null, open, id, advertiser_id, onClose, values }) => {
	const intl = useIntl();

	let code_snippet_link = generatePixelSnippet(values);
	let code_snippet_example = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&mt_exem=&mt_excl=&<mark>s1=&s1=&v1=&v2=</mark>&gt;&lt;/script&gt;`;
	let code_snippet_example_sha256 = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&<mark>mt_exem=89a3562bdce5291f61dc1f12fb8337e9c039aebb9be71942e5ae42c82e68cc8b&mt_excl=b04f190bdaf9750a785afc33b09ae0e2907c226bfb13d92603c0642af5ef404a</mark>&s1=&s2=&v1=&v2=&gt;&lt;/script&gt;`;
	const revenue_enabled = Boolean(values?.revenue_enabled);

	if(revenue_enabled) {
		const revenue = values.revenue.toLowerCase();
		if(values.revenue_type === RevenueType.FIXED) {
			code_snippet_example = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&mt_exem=&mt_excl=&<mark>${revenue}=500</mark>&gt;&lt;/script&gt;`;
			code_snippet_example_sha256 = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&<mark><mark>mt_exem=89a3562bdce5291f61dc1f12fb8337e9c039aebb9be71942e5ae42c82e68cc8b&mt_excl=b04f190bdaf9750a785afc33b09ae0e2907c226bfb13d92603c0642af5ef404a</mark></mark>&${revenue}=500&gt;&lt;/script&gt;`;
		} else if(values.revenue_type === RevenueType.DYNAMIC) {
			code_snippet_example = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&mt_exem=&mt_excl=&<mark>${values.currency.toLowerCase()}=${Config.defaultCurrency}&${revenue}=500</mark>&gt;&lt;/script&gt;`;
			code_snippet_example_sha256 = `&lt;script async src='//pixel.mathtag.com/event/js?mt_id=${id}&mt_adid=${advertiser_id}&<mark>mt_exem=89a3562bdce5291f61dc1f12fb8337e9c039aebb9be71942e5ae42c82e68cc8b&mt_excl=b04f190bdaf9750a785afc33b09ae0e2907c226bfb13d92603c0642af5ef404a</mark>&${values.currency.toLowerCase()}=${Config.defaultCurrency}&${revenue}=500&gt;&lt;/script&gt;`;
		}
	}

	return (
		<Modal open={open} onClose={onClose}>
			<Modal.Content>
				<Modal.Description>
					<Header>
						{type === "create"
							? intl.formatMessage({
									id: "HEADING_PIXEL_MODAL_CREATE",
									defaultMessage:
										"You’ve created a pixel. Now, add the tag to your website.",
							  })
							: intl.formatMessage({
									id: "HEADING_PIXEL_MODAL_EDIT",
									defaultMessage: "Add the tag to your website",
							  })}
					</Header>
					<p>
						{intl.formatMessage({
							id: "BODY_PIXEL_MODAL_SIMPLE",
							defaultMessage:
								"Using the examples below update the snippet accordingly and paste it in between the '<head></head>' tags of the page(s) you’d like to track:",
						})}
					</p>
					<pre className="pixel-modal-snippet">
						{code_snippet_link}
					</pre>
					{revenue_enabled && <>
						<p>
							{intl.formatMessage(
								{
									id: "BODY_PIXEL_MODAL_CURRENCY",
									defaultMessage: `
							To set up conversion tracking, you’ll then need to dynamically pass to
							the pixel snippet the value of the conversion and its currency by
							setting the v1 (value) and s1 (currency) parameters per example below,
							at runtime. Be sure to pass the conversion value as a number, using a
							period as a decimal delimiter. The currency should be passed as a
							string, using <link>ISO 4217 currency codes</link>:`,
								},
								{
									link: (text) => (
										<a
											href={ISO_4217_DOCS_URL}
											target="_blank"
											rel="noopener noreferrer"
										>
											{text}
										</a>
									),
								}
							)}
						</p>
						<div className="pixel-modal-example">
							<label>
								{intl.formatMessage({
									id: "LABEL_PIXEL_MODAL_EXAMPLE",
									defaultMessage: "Example pixel code based on the parameters selected:",
								})}
							</label>
							<pre dangerouslySetInnerHTML={{ __html: code_snippet_example}}></pre>
						</div>
					</>
					}
						<p>
							{intl.formatMessage(
								{
									id: "BODY_PIXEL_MODAL_USER_ID",
									defaultMessage: `
							To track users across devices, you’ll then need to dynamically pass to
							the pixel snippet the email address of the user and the user ID by
							setting the mt_exem (email) and mt_excl (user ID) parameters per
							example below, at runtime. Be sure to pass both email and user ID
							as <link>hashed value in SHA-256 format</link>:`,
								},
								{
									link: (text) => (
										<a
											href={SHA256_DOCS_URL}
											target="_blank"
											rel="noopener noreferrer"
										>
											{text}
										</a>
									),
								}
							)}
						</p>
						<div className="pixel-modal-example">
							<label>
								{intl.formatMessage({
									id: "LABEL_PIXEL_MODAL_EXAMPLE",
									defaultMessage: "Example pixel code based on the parameters selected:",
								})}
							</label>
							<pre dangerouslySetInnerHTML={{ __html: code_snippet_example_sha256}}></pre>
						</div>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions style={{ "textAlign": "center" }}>
				<Button
					content={intl.formatMessage({
						id: "BTN_DONE",
						defaultMessage: "Done",
					})}
					onClick={onClose}
					positive
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default PixelModal;
