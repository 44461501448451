export default class Registry {
	constructor(storage) {
		this.storage = storage;
	}

	/**
	 * Get stored item by key
	 * @param {String} key
	 * @return {Object|Array}
	 */
	getItem(key) {
	    let item = this.storage.getItem(key);

		let retItem;
	    try {
			retItem = (item)? JSON.parse(item) : item;
		} catch {
	    	console.error(`Cannot parse ${key}, remove it from the storage`);
	    	this.removeItem(key);
		}

		return retItem;
	}

	/**
	 * check that key exists in the storage
	 * @param {String} key
	 * @return {boolean}
	 */
	hasItem(key) {
		return this.storage.hasOwnProperty(key);
	}

	/**
	 * Add new item into storage
	 * @param {String} key
	 * @param {*} value
	 */
	setItem(key, value) {
		this.storage.setItem(key, JSON.stringify(value));
	}

	/**
	 * Remove item by key\keys
	 * @param {String|Array} key
	 */
	removeItem(key) {
		if(Array.isArray(key)) {
			key.forEach(x => this.storage.removeItem(x))
		} else {
			this.storage.removeItem(key);
		}
	}

	/**
	 * Clean whole storage
	 */
	removeAll() {
		this.storage.clear();
	}
}