export default class StrategyAudiencesSelected {
  constructor(included, excluded) {
    this.include = included || [];
    this.exclude = excluded || [];
  }

  /**
   * Parse data from API into a model instance
   * @param {object} json
   */
  static fromResponse({include, exclude}) {
    let local_included = {...include};
    let local_excluded = {...exclude};

    if(!local_included.hasOwnProperty("pixels")) {
      local_included.pixels = {};
    }

    if(!local_included.pixels.hasOwnProperty("entities")) {
      local_included.pixels = {
        "operator": "AND",
        "entities": []
      }
    }

    local_included.pixels.entities = local_included.pixels.entities.map(node => {
      return node.id;
    });

    local_included.custom_audiences.forEach(node => {
      node.entities = node.entities.map(entity => {
        return entity.id;
      });
    });

    local_excluded.custom_audiences = local_excluded.custom_audiences.map(node => {
      return node.id;
    });

    local_excluded.pixels = local_excluded.pixels.map(node => {
      return node.id;
    });

    return new StrategyAudiencesSelected(local_included, local_excluded);
  }

  /**
   * Convert model instance into API format
   * @return {object}
   */
  toJson() {
    return {
      "include": this.include,
      "exclude": this.exclude
    };
  }
}

