import React, { useCallback, useState } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import MarginForm from "../form";
import MarginsService from "../../../services/margins";

/**
 * Margin create page component
 * @param history
 * @return {*}
 * @constructor
 */
const MarginCreatePage = ({ history }) => {
	const back_url = "/admin/margins";

	const intl = useIntl();
	const service = new MarginsService();
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);
	let _isMounted = React.useRef(true);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * form submit handler
	 * @param {object} values
	 * @param {File} file
	 */
	const handleSubmit = async (values) => {
		setLoading(true);

		const data = {
			...values,
			"additional_margin_pct": parseFloat(values["additional_margin_pct"] || 0),
		};

		try {
			await service.create(data);
			history.push(back_url, { "action": "created" });
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	const initialData = {
		"agency_id": null,
		"additional_margin_pct": "0.0000",
	};

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_CREATE_MARGIN",
					defaultMessage: "Create Margin Control",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				info
				size="tiny"
				content={intl.formatMessage({
					id: "BODY_CREATE_MARGIN",
					defaultMessage: "Creating a Margin Control will not apply an additional margin to existing campaigns.",
				})}
			/>
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<MarginForm
				loading={loading}
				initialData={initialData}
				submitText={intl.formatMessage({
					id: "BTN_CREATE_MARGIN",
					defaultMessage: "Create Margin Control",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default MarginCreatePage;
