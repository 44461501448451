import React from "react";
import {Form, Input, Select} from "semantic-ui-react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {statuses} from "./models/filter";
import {Config} from "../../../config/api";
import DateRangeControl from "../../common/date_range_control";

const MIN_SEARCH_SYMBOLS = 3;

/**
 * check if we should do a search
 * @param {string} query
 * @param {string} prev_query
 * @return {boolean}
 */
const should_do_search = (query, prev_query) => {
	if(prev_query.length > 0 && query.length < 1) {
		return true;
	}

	return query.length >= MIN_SEARCH_SYMBOLS;
};

/**
 * handle the case when we had query but user clear the field
 * @param query
 * @param prev_query
 * @return {boolean}
 */
const search_query_becomes_empty = (query, prev_query) => {
	return (prev_query.length > 0 && query.length < 1);
};

const FilterControl = ({filter, onChange}) => {
	const query = React.useRef(""),
		timer = React.useRef();

	const intl = useIntl();

	React.useLayoutEffect(() => {
		if(filter.strategy_name) {
			const el = document.getElementById("clear_strategy_search");
			el.addEventListener("click", clearSearch, false);
		}
	}, [filter.strategy_name]);

	/**
	 * update status
	 * @param e
	 * @param option
	 */
	const handleStatus = (e, option) => {
		filter.setStatus(option.value);
		onChange(filter.toJson());
	};

	/**
	 * do a search
	 */
	const handleSearch = (e, {value: searchQuery}) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(searchQuery, prev_query)) {
			return;
		}

		if(search_query_becomes_empty(searchQuery, prev_query)) {
			filter.setStrategyName(searchQuery);
			onChange(filter.toJson());
			document.getElementById("strategy_name_id").value = "";
			return;
		}

		timer.current = setTimeout(async () => {
			filter.setStrategyName(searchQuery);
			onChange(filter.toJson());
		}, Config.search_debounce_delay);
	};

	/**
	 * clear search query
	 */
	const clearSearch = () => {
		handleSearch(null, {"value": ""});
	};

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_strategy_search"
		}
	};

	return (
		<Form autoComplete="off" noValidate>
			<Form.Group>
				<Form.Field>
					<label>
						{intl.formatMessage({
							id: "LABEL_STRATEGY_STATUS",
							defaultMessage: "Strategy Status",
						})}
					</label>
					<Select
						options={statuses(intl)}
						value={filter.strategy_status}
						onChange={handleStatus}
						name="strategy_status"
					/>
				</Form.Field>
				<Form.Field>
					<label>
						{intl.formatMessage({
							id: "LABEL_STRATEGY",
							defaultMessage: "Strategy",
						})}
					</label>
					<Input
						defaultValue=""
						onChange={handleSearch}
						placeholder={intl.formatMessage({
							id: "HINT_SEARCH_STRATEGY_NAME",
							defaultMessage: "Search strategy by name",
						})}
						id="strategy_name_id"
						icon={filter.strategy_name? getCloseIcon() : false}
						name="strategy_name"
					/>
				</Form.Field>
				<Form.Field className="field-wider">
					<label>
						{intl.formatMessage({
							id: "LABEL_DATE_RANGE",
							defaultMessage: "Date Range",
						})}
					</label>
					<DateRangeControl filter={filter} onChange={onChange} />
				</Form.Field>
			</Form.Group>
		</Form>
	)
};
FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired
};

export default FilterControl;
