import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import zip from "lodash/zip";

import { LocaleContext } from "../../context/locale-context.js";
import LocaleService from "../../services/locale/index.js";
import Registry from "../../libs/register_storage";

const FALLBACK_LOCALE = "en_US";

/**
 * Check if locale matches the range, roughly according to the Extended Filtering
 * alrogithm specified in RFC 4647 (https://datatracker.ietf.org/doc/html/rfc4647#section-3.3.2).
 *
 * @param {string} range - language range supported by the browser, dash-separated
 * @param {string} locale - locale returned by API, underscore-separated
 * @returns {boolean}
 */
function matchLocale(range, locale) {
	const browserParts = range.split("-");
	const apiParts = locale.split("_");
	if (browserParts.length > apiParts.length) {
		return false;
	}

	return zip(browserParts, apiParts).every(
		([browserPart, apiPart]) => [undefined, "*", apiPart].includes(browserPart)
	);
}

/**
 * Find a preferred locale according to navigator.languages.
 *
 * @param {object} locales - locale objects returned by API
 * @returns {string} preferred locale or the default one specified by the API
 */
function pickPreferredLocale(locales) {
	// Fallback to English even if no default locale is specified by the API:
	let defaultLocale = FALLBACK_LOCALE;

	// temporarily disable all locales except than en
	// debugger;
	/*
	for (let browserLocale of navigator.languages) {
		for (let apiLocale of locales) {
			if (matchLocale(browserLocale, apiLocale.locale)) {
				return apiLocale.locale;
			}
		}
	}

	for (let apiLocale of locales) {
		if (apiLocale.is_default) {
			defaultLocale = apiLocale.locale;
		}
	}
	*/
	return defaultLocale;
}

/**
 * A provider component that wraps IntlProvider and allows selecting locale
 * using LocalePicker.
 */
export default function LocaleProvider({ children }) {
	const [availableLocales, setAvailableLocales] = useState(null);
	// TODO: do not hardcode default lang but one from the localStorage or from API
	const [currentLocale, setCurrentLocale] = useState(null);
	const [translation, setTranslation] = useState({});
	const localeService = new LocaleService();
	const registry = new Registry(window.localStorage);
	const localeCache = new Registry(window.sessionStorage);

	useEffect(() => {
		localeService.listLocales().then((r) => {
			setAvailableLocales(r.data);
			setCurrentLocale((currentLocale) => {
				if (currentLocale !== null) {
					return currentLocale;
				}
				if (registry.hasItem("currentLocale")) {
					return registry.getItem("currentLocale");
				}
				return pickPreferredLocale(r.data);
			});
		});
	}, []);

	useEffect(() => {
		if (currentLocale !== null) {
			registry.setItem("currentLocale", currentLocale);

			const cacheKey = `locale_${currentLocale}`;
			/*
			if (localeCache.hasItem(cacheKey)) {
				console.log("Using cached locale messages for", currentLocale);
				setTranslation(localeCache.getItem(cacheKey));
				return;
			}
			*/

			localeService.getTranslationsForLocale(currentLocale).then((r) => {
				localeCache.setItem(cacheKey, r.data);
				setTranslation(r.data);
			});
		}
	}, [currentLocale]);

	return (
		<LocaleContext.Provider
			value={{
				translation,
				currentLocale,
				setCurrentLocale,
				availableLocales,
			}}
		>
			<IntlProvider
				locale={(currentLocale || FALLBACK_LOCALE).split("_")[0]}
				messages={translation}
			>
				{children}
			</IntlProvider>
		</LocaleContext.Provider>
	);
}
