import {Days} from "./enum/days";

export default class StrategyDaypart {
	constructor(id, start_hour, end_hour, days, user_time)
	{
		this.id = id || 0;
		this.start_hour = parseInt(start_hour, 10) || 0;
		this.end_hour = parseInt(end_hour, 10) || 0;
		this.days = days || Days.DEFAULT;
		this.user_time = isNaN(user_time)? 1 : user_time;
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		let days = Days.DEFAULT;
		try {
			days = Days.assign(json.days);
		} catch (e) {}

		return new StrategyDaypart(json.id, json.start_hour, json.end_hour, days,
			json.user_time);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		let json = {
			...this
		};
		delete json.id;
		return json;
	}
}