import React from "react";
import PropTypes from "prop-types";
import {Steps} from "../../../../models/enum/strategy";
import {
	Button,
	Form
} from "semantic-ui-react";
import { useIntl } from "react-intl";

/**
 * create navigation div
 * @param {boolean} props.is_t1_edit
 * @param {boolean} props.loading
 * @return {*}
 * @constructor
 */
export const NavigationButtonDiv = props => {
	if(props?.loading) {
		return "";
	}
	return props.is_t1_edit? <NavigationButtonDivEdit {...props} /> : <NavigationButtonDivCreate {...props} />;
};

const NavigationButtonDivCreate = ({
	step,
	isPG,
	onBackClick,
	onCancelClick,
	onContinueClick,
	onSave,
	skip_save_buttons = false,
	loading = false,
}) => {
	const intl = useIntl();

	if (step === Steps.MARKETING_OBJECTIVES) {
		return (
			<Form.Field align="right" disabled={loading}>
				<Button size="tiny" type="button" onClick={() => onCancelClick()}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" className="continue" type="submit">
					{intl.formatMessage({
						id: "BTN_CONTINUE",
						defaultMessage: "Continue",
					})}
				</Button>
			</Form.Field>
		);
	} else if (step === Steps.TECHNOLOGY || (isPG && step === Steps.CREATIVES)) {
		return (
			<Form.Field align="right" disabled={loading}>
				<Button size="tiny" type="button" onClick={() => onCancelClick()}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" type="button" onClick={onBackClick}>
					{intl.formatMessage({
						id: "BTN_BACK",
						defaultMessage: "Back",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit">
					{intl.formatMessage({
						id: "BTN_CREATE_STRATEGY",
						defaultMessage: "Create Strategy",
					})}
				</Button>
			</Form.Field>
		);
	}

	return (
		<Form.Field align="right" disabled={loading}>
			<Button size="tiny" type="button" onClick={() => onCancelClick()}>
				{intl.formatMessage({
					id: "BTN_CANCEL",
					defaultMessage: "Cancel",
				})}
			</Button>
			<Button size="tiny" type="button" onClick={onBackClick}>
				{intl.formatMessage({
					id: "BTN_BACK",
					defaultMessage: "Back",
				})}
			</Button>
			<Button size="tiny" className="continue" type="submit">
				{intl.formatMessage({
					id: "BTN_CONTINUE",
					defaultMessage: "Continue",
				})}
			</Button>
		</Form.Field>
	);
};
NavigationButtonDivCreate.propTypes = {
	"step": PropTypes.oneOf(Steps.asList()),
	"isPG": PropTypes.bool,
	"onBackClick": PropTypes.func,
	"onContinueClick": PropTypes.func,
	"onCancelClick": PropTypes.func,
	"loading": PropTypes.bool,
	"skip_save_buttons": PropTypes.bool
};

const NavigationButtonDivEdit = ({
	step,
	isPG,
	onBackClick,
	onCancelClick,
	onContinueClick,
	onSave,
	skip_save_buttons = false,
	loading = false,
}) => {
	const intl = useIntl();

	if (step === Steps.MARKETING_OBJECTIVES) {
		return (
			<Form.Field align="right" disabled={loading}>
				<Button size="tiny" type="button" onClick={() => onCancelClick()}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit" onClick={() => onSave(false)}>
					{intl.formatMessage({
						id: "BTN_SAVE_AND_CLOSE",
						defaultMessage: "Save Changes & Close",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit" onClick={() => onSave(true)}>
					{intl.formatMessage({
						id: "BTN_SAVE_AND_CONTINUE",
						defaultMessage: "Save Changes & Continue",
					})}
				</Button>
			</Form.Field>
		);
	} else if (step === Steps.TECHNOLOGY || (isPG && step === Steps.CREATIVES)) {
		if (!skip_save_buttons) {
			return (
				<Form.Field align="right" disabled={loading}>
					<Button size="tiny" type="button" onClick={() => onCancelClick()}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" type="button" onClick={onBackClick}>
						{intl.formatMessage({
							id: "BTN_BACK",
							defaultMessage: "Back",
						})}
					</Button>
					<Button size="tiny" color="green" type="submit">
						{intl.formatMessage({
							id: "BTN_SAVE_AND_CLOSE",
							defaultMessage: "Save Changes & Close",
						})}
					</Button>
				</Form.Field>
			);
		} else {
			return (
				<Form.Field align="right" disabled={loading}>
					<Button size="tiny" type="button" onClick={() => onCancelClick()}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" type="button" onClick={onBackClick}>
						{intl.formatMessage({
							id: "BTN_BACK",
							defaultMessage: "Back",
						})}
					</Button>
					<Button size="tiny" color="green" onClick={() => onContinueClick()}>
						{intl.formatMessage({
							id: "BTN_CLOSE",
							defaultMessage: "Close",
						})}
					</Button>
				</Form.Field>
			);
		}
	} else if (
		Boolean(~[Steps.AUDIENCES, Steps.SUPPLY, Steps.DAYPARTS].indexOf(step)) &&
		skip_save_buttons
	) {
		return (
			<Form.Field align="right" disabled={loading}>
				<Button size="tiny" type="button" onClick={() => onCancelClick()}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" type="button" onClick={onBackClick}>
					{intl.formatMessage({
						id: "BTN_BACK",
						defaultMessage: "Back",
					})}
				</Button>
				<Button size="tiny" color="green" onClick={() => onContinueClick()}>
					{intl.formatMessage({
						id: "BTN_CONTINUE",
						defaultMessage: "Continue",
					})}
				</Button>
			</Form.Field>
		);
	}

	return (
		<Form.Field align="right" disabled={loading}>
			<Button size="tiny" type="button" onClick={() => onCancelClick()}>
				{intl.formatMessage({
					id: "BTN_CANCEL",
					defaultMessage: "Cancel",
				})}
			</Button>
			<Button size="tiny" type="button" onClick={onBackClick}>
				{intl.formatMessage({
					id: "BTN_BACK",
					defaultMessage: "Back",
				})}
			</Button>
			<Button size="tiny" color="green" type="submit" onClick={() => onSave(false)}>
				{intl.formatMessage({
					id: "BTN_SAVE_AND_CLOSE",
					defaultMessage: "Save Changes & Close",
				})}
			</Button>
			<Button size="tiny" color="green" type="submit" onClick={() => onSave(true)}>
				{intl.formatMessage({
					id: "BTN_SAVE_AND_CONTINUE",
					defaultMessage: "Save Changes & Continue",
				})}
			</Button>
		</Form.Field>
	);
};
NavigationButtonDivEdit.propTypes = {
	"step": PropTypes.oneOf(Steps.asList()),
	"isPG": PropTypes.bool,
	"onBackClick": PropTypes.func,
	"onCancelClick": PropTypes.func,
	"onContinueClick": PropTypes.func,
	"onSaveAndContinue": PropTypes.func,
	"onSaveAndClose": PropTypes.func,
	"loading": PropTypes.bool,
	"skip_save_buttons": PropTypes.bool
};
