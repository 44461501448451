import React from "react";
import {Dropdown, Form, Input, Header} from "semantic-ui-react";
import PropTypes from "prop-types";
import {useDebounce} from "../../../../../libs/component_utils";
import {Config} from "../../../../../config/api";
import { useIntl } from "react-intl";


const FilterControl = React.memo(({filter, onChange, loading, is_visible, header, options}) => {
	const [state, setState] = React.useState(filter.toJson());
	const _isMounted = React.useRef(false);
	const intl = useIntl();

	// set effect to on query change, added listener to resert
	React.useLayoutEffect(() => {
		const el = document.getElementById("clear_search_input");
		if(el && state.filter_query.length > 0) {
			el.addEventListener("click", clearSearch, false);
		}

		if(_isMounted.current) {
			performOnChange(state.filter_query);
		}

		return () => {
			if(el) {
				el.removeEventListener("click", clearSearch);
			}
		}
	}, [state.filter_query]);

	// set component is mounted
	React.useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		}
	}, []);

	React.useEffect(() => {
		filter.fromJson(state);
	});

	/**
	 * clear search query
	 */
	const clearSearch = () => {
		setState({
			...state,
			"filter_query": ""
		});
	};

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_search_input"
		}
	};

	/**
	 * handle change filter type
	 * @param e
	 * @param option
	 */
	const handleType = (e, {value}) => {
		setState({
			"filter_query": "",
			"filter_type": value
		});
	};

	/**
	 * handle change filter query
	 * @param e
	 * @param option
	 */
	const handleSearch = (e, {value}) => {
		setState({
			...state,
			"filter_query": value
		});
	};

	/**
	 * run debounced search
	 * @type {function(...[*]): void}
	 */
	const performOnChange = useDebounce(
		Config.search_debounce_delay,
		() => {
			const f = filter.toJson();
			if(!f.filter_query || f.filter_query.length > 2) {
				onChange(f);
			}
		}
	);

	return (
		<Form.Group inline className={is_visible? "hidden" : ""}>
			<Form.Field>
				<Header as="h3">{header}</Header>
			</Form.Field>
			<Form.Field>
				<Dropdown
					selection
					size="mini"
					name="filter_type"
					text={options.find(x => x.value === state.filter_type).text}
					onChange={handleType}
					options={options}
					disabled={loading}
					value={state.filter_type}
				/>
			</Form.Field>
			<Form.Field>
				<Input
					onChange={handleSearch}
					placeholder={intl.formatMessage({
						id: "HINT_SEARCH_BY_NAME",
						defaultMessage: "Search {kind} by name",
					}, {
						kind: options.find(x => x.value === state.filter_type).text
					})}
					name="filter_query"
					icon={state.filter_query? getCloseIcon() : null}
					disabled={loading}
					value={state.filter_query}
					autoComplete="off"
					style={{"width": "250px"}}
				/>
			</Form.Field>
		</Form.Group>
	)
}, (prev, next) => {
	/**
	 * get concatenated necessary options
	 * @param {object} props
	 * @return {object}
	 */
	const concatObjects = props => {
		return Object.assign({"loading": props.loading}, {"is_visible": props.is_visible});
	}

	return JSON.stringify(concatObjects(prev)) === JSON.stringify(concatObjects(next));
});

FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired,
	"loading": PropTypes.bool.isRequired,
	"is_visible": PropTypes.bool
};

export default FilterControl;
