import {DimensionCodes} from "../../../../../models/enum/dimension_codes";

export const strategy_audience_filters = (intl) => [{
	"key": "audiences",
	"value": "audiences",
	"text": intl.formatMessage({
		id: "AUDIENCE_FILTER_DEVICE_IDS",
		defaultMessage: "Device IDs",
	}),
}, {
	"key": "taxonomies",
	"value": "taxonomies",
	"text": intl.formatMessage({
		id: "AUDIENCE_FILTER_DATA_PROVIDERS",
		defaultMessage: "Data Providers",
	}),
}, {
	"key": "pixels",
	"value": "pixels",
	"text": intl.formatMessage({
		id: "AUDIENCE_FILTER_PIXELS",
		defaultMessage: "Pixels",
	}),
}];

export const strategy_location_filters = (intl) => [
	{
		"key": "dmas",
		"value": "dmas",
		"text": intl.formatMessage({
			id: "LOCATION_FILTER_DMAS",
			defaultMessage: "DMAs",
		}),
	}, {
		"key": "regions",
		"value": "regions",
		"text": intl.formatMessage({
			id: "LOCATION_FILTER_REGIONS",
			defaultMessage: "Country/Region/City",
		}),
	},
];

export const strategy_technology_filters = (intl) => [{
		"key": "device_ids",
		"value": DimensionCodes.DVCE,
		"text": intl.formatMessage({
			id: "TECHNOLOGY_FILTER_DEVICE_TYPE",
			defaultMessage: "Device Type",
		}),
	}, {
		"key": "device_model_ids",
		"value": DimensionCodes.NMDM,
		"text": intl.formatMessage({
			id: "TECHNOLOGY_FILTER_DEVICE_MODEL",
			defaultMessage: "Device Model",
		}),
	}, {
		"key": "inventory_type_ids",
		"value": DimensionCodes.INVT,
		"text": intl.formatMessage({
			id: "TECHNOLOGY_FILTER_INVENTORY_TYPE",
			defaultMessage: "Inventory Type",
		}),
	}, {
		"key": "browser_ids",
		"value": DimensionCodes.BSER,
		"text": intl.formatMessage({
			id: "TECHNOLOGY_FILTER_BROWSER",
			defaultMessage: "Browser",
		}),
	}, {
		"key": "os_version_ids",
		"value": DimensionCodes.NMOS,
		"text": intl.formatMessage({
			id: "TECHNOLOGY_FILTER_OS_VERSION",
			defaultMessage: "OS Version",
		}),
	}
];
