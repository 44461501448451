import ReportPage from "../components/report/report/index";
import ReportIndexPage from "../components/report/index";

export const Routes = [{
		"path": "/reports",
		"component": ReportIndexPage,
		"title": ["Reports"]
	}, {
	"path": "/report/:report_slug",
	"component": ReportPage,
	"title": ["Report"]
}];
