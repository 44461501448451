import React, { useReducer, useState, useEffect, useMemo } from "react";
import StrategyManageContext from "../context";
import {actions as trgtAction, targetValuesControlReducer} from "./reducers/locations";
import {
	Button,
	Divider,
	Form,
	Grid,
	Header,
	Message, Popup,
	Segment
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import TargetValuesControl from "./reducers/locations";
import LocationContext from "./contexts/location";
import {isDigit, targetHasChildren} from "../../../../libs/common_utils";
import "./audiences.css";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import {resetInitTreeFlag} from "./reducers/locations";
import StrategiesService from "../../../../services/strategy";
import HttpConnect from "../../../../libs/http_connect";
import FilterControl from "./filters";
import Filter from "./models/filter";
import {strategy_location_filters} from "./fixtures";

const included = new Map();
const excluded = new Map();
const opened = new Map();

const initialState = {
	items: [],
	filter: new Filter("regions"),
	searchResults: null,
};

export const LocationsStep = () => {
	const context = React.useContext(StrategyManageContext);
	const [values, dispatch] = useReducer(targetValuesControlReducer, []);
	const [state, setState] = React.useState(initialState);
	const [hasDMAs, setHasDMAs] = useState(false);

	/** @type {T1Service} */
	const service = context.services.current.get("t1");
	const service_strategy = context.services.current.get("strategies");
	const data_exists_in_db = React.useRef(false);
	const [loading, setLoading] = React.useState(true);
	const locations_has_been_blocked = React.useRef(false);
	const [_, redraw] = React.useState();
	const intl = useIntl();

	const is_t1_edit = (service_strategy instanceof StrategiesService);

	const strategy_id = parseInt(context.strategy_id || 0, 10);
	const [serverError, setServerError] = useState("");

	let _isMounted = React.useRef(true);
	let get_continue = React.useRef(true);

	useEffect(() => {
		if(loading) {
			document.getElementById("nested-targets").classList.add("tree-loading");
		} else {
			document.getElementById("nested-targets").classList.remove("tree-loading");
		}
	}, [loading]);

	/**
	 * save data to local storage
	 * @param {object} server_data
	 * @param {Map} local_storage
	 */
	const handleSavedData = (server_data, local_storage) => {
		if(server_data.location_ids.length > 0 || server_data.dma_ids.length > 0) {
			data_exists_in_db.current = true;
			server_data.location_ids.forEach(n => {
				local_storage.set(n.id, {"id": n.id, "full_path": n.path, "title": n.title, "type": "region"});
			});

			server_data.dma_ids.forEach(n => {
				local_storage.set(n.id, {"id": n.id, "full_path": n.path, "title": n.title, "type": "dma"});
			});
		}
	}

	useEffect(() => {
		locations_has_been_blocked.current = false;
		if(!strategy_id) {
			return;
		}

		(async() => {
			try {
				const [saved] = await Promise.all([service_strategy.get_targets(strategy_id)]);
				const { meta: dmaResultMeta } = await service.dmas();
				const hasDMAs = dmaResultMeta["total_count"] !== 0;

				// handle included block
				handleSavedData(saved.data.include, included);
				fillSelectedDiv("included_id", included);

				// handle excluded block
				handleSavedData(saved.data.exclude, excluded);
				fillSelectedDiv("excluded_id", excluded);
				dispatch({"type": trgtAction.INIT, "data": [], hasDMAs });

				setHasDMAs(hasDMAs);

			} catch (e) {
				locations_has_been_blocked.current = Boolean(~e.error.message.toLowerCase().search(/have been changed outside/));
				setServerError(e.error.message);
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			resetInitTreeFlag();
			opened.clear();
			included.clear();
			excluded.clear();
			_isMounted.current = false;
			initialState.filter.reset();
			setHasDMAs(false);
		};
	}, [strategy_id]);

	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	/**
	 * dispatch switch audience
	 * @param e
	 * @param id
	 * @param {object} [outOfTreeItem] object to use instead of searching the tree
	 */
	const switchTarget = (e, id, outOfTreeItem = null) => {
		if (outOfTreeItem) {
			// Item was found using search and might not be in tree yet,
			// so we add a hint of its value object instead:
			// included.switch(id, {...outOfTreeItem});
			if(included.has(id)) {
				included.delete(id);
				excluded.set(id, {...outOfTreeItem});
			} else if(excluded.has(id)) {
				included.delete(id);
				excluded.delete(id);
			} else {
				included.set(id, {...outOfTreeItem});
				excluded.delete(id);
			}
			redraw(Date.now());
		} else {
			let found = false;
			const tree = node => {
				return node.forEach(item => {
					if (found) {
						return;
					}

					if (item.id === id) {
						/*
						included.switch(id, {...item});
						found = true;
						*/
						if(included.has(id)) {
							included.delete(id);
							excluded.set(id, {...item});
						} else if(excluded.has(id)) {
							included.delete(id);
							excluded.delete(id);
						} else {
							included.set(id, {...item});
							excluded.delete(id);
						}
						found = true;
					} else if (item.child) {
						tree(item.child)
					}
				});
			};

			tree([...values]);
		}

		e.target.checked = false;

		// do rebuild checkbox tree
		let nodes = document.querySelectorAll(".custom-checkbox > input[type='checkbox']:checked");
		nodes.forEach(n => {
			const id = (!isNaN(n.value))? parseInt(n.value , 10) : n.value;
			if(!included.has(id)) {
				n.checked = false;
			}
		});

		Array.from(included.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				setTimeout(() => {
					node.indeterminate = false;
					node.checked = true;
				});
			}
		});

		nodes = document.querySelectorAll(".custom-checkbox > input[type='checkbox']:indeterminate");
		nodes.forEach(n => {
			const id = (!isNaN(n.value))? parseInt(n.value , 10) : n.value;
			if(!excluded.has(id)) {
				n.indeterminate = false;
			}
		});

		Array.from(excluded.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.indeterminate = true;
				node.checked = false;
			}
		});

		fillSelectedDiv("included_id", included);
		fillSelectedDiv("excluded_id", excluded);
		return true;
	};

	/**
	 * reset included map
	 */
	const resetIncluded = () => {
		included.clear();
		fillSelectedDiv("included_id", included);
		document.querySelectorAll(".custom-checkbox > input[type='checkbox']:checked").forEach(node => {
			node.checked = false;
		});
	};

	/**
	 * reset excluded map
	 */
	const resetExcluded = () => {
		excluded.clear();
		fillSelectedDiv("excluded_id", excluded);
		document.querySelectorAll(".custom-checkbox > input[type='checkbox']:indeterminate").forEach(node => {
			node.indeterminate = false;
		});
	};

	/**
	 * Put data into included divs
	 * @param {String} div_id
	 * @param {Map} nodes
	 */
	const fillSelectedDiv = (div_id, nodes) => {
		const element = document.getElementById(div_id);
		element.innerHTML = "";

		let html = "", n;
		nodes.forEach(x => {
			html += `<a class="ui label" data-title="${x.full_path}">${x.title}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
		});

		element.innerHTML = html;
		// attach event listeners
		Array.from(element.getElementsByClassName("remove_icon")).forEach(node => {
			node.onclick = function(e) {
				let id = e.target.getAttribute("data-id");
				id = (isDigit(id))? parseInt(id, 10) : id;

				const checkbox = document.getElementById(`target_${id}`);
				if(included.has(id)) {
					included.delete(id);

					if(checkbox) {
						checkbox.checked = false;
					}
				} else if(excluded.has(id)) {
					excluded.delete(id);

					if(checkbox) {
						checkbox.indeterminate = false;
					}
				}

				e.target.parentNode.remove();
			};
		});
	};

	/**
	 * dispatch switch visibility
	 * @param e
	 * @param {object} target
	 */
	const switchVisibility = (e, target) => {
		const id = target.id;

		if(targetHasChildren(target)) {
			// target contains children and they are not loaded yet
			setLoading(true);

				(async () => {
					try {
						let r, child_type;
						switch (target.type) {
							case "regions_root":
								r = await service.regions();
								child_type = "region_country";
								break;
							case "region_country":
								r = await service.regions(target.id);
								child_type = "region";
								break;
							case "region":
								r = await service.regions(target.id);
								child_type = "region";
								break;
							case "dmas_root":
								r = await service.dmas();
								child_type = "dma_country";
								break;
							case "dma_country":
								r = await service.dmas(target.id);
								child_type = "dma";
								break;
							case "dma":
								console.error('Node type "dma" cannot have children. This is a bug.');
								return;
							default:
								console.error(`Unsupported node type "${target.type}". This is a bug.`);
								return;
						}

						const data = [...r.data].map(n => {
							n.parent_id = target.id;
							n.type = child_type;
							n.is_targetable =
								n.is_targetable || child_type === "region_country";
							n.has_children =
								["dma_country", "region_country"].includes(child_type) ||
								(child_type !== "dma" && Boolean(n.child_count > 0));
							return n;
						});

						dispatch({"type": trgtAction.APPEND, "data": data, "root": target});
					} catch (e) {
						console.error(e);
						setServerError(e.error.message);
					} finally {
						setLoading(false);
					}
				})();
		} else {
			e.parentNode.classList.toggle("opened");
			if(e.parentNode.classList.contains("opened")) {
				opened.set(id, "opened ul-container");
			} else {
				opened.set(id, "ul-container");
			}
		}
	};

	/**
	 * Load Locations from backend.
	 * @param {object} filter
	 * @param {string} filter.filter_type
	 * @param {string} filter.filter_query
	 */
	const performSearchLocations = filter => {
		HttpConnect.cancelRequest();
		const query = filter.filter_query.trim();
		if (query === "") {
			setState({
				...state,
				"searchResults": null
			});
			return;
		}

		(async () => {
			setLoading(true);

			try {
				let response, results = [];

				switch (filter.filter_type) {
					case "regions":
						response = await service.regions(null, query);

						results = response.data.map(({id, title, path, is_targetable}) => ({
							id,
							title,
							"checkable": Boolean(is_targetable),
							"full_path": path,
							"type": "region"
						}));
					break;
					case "dmas":
						response = await service.dmas(null, query);

						results = response.data.map(({id, title, is_targetable}) => ({
							id,
							title,
							"checkable": Boolean(is_targetable),
							"full_path": title,
							"type": "dma"
						}));
						break;

					default:
						console.error("Unsupported search source:", filter.filter_type)
						break;
				}

				setState({
					...state,
					"searchResults": results
				});
			} catch (e) {
				console.error("[performSearchLocations]", e);
			} finally {
				if(_isMounted.current) {
					setLoading(false);
				}
			}
		})();
	};

	React.useLayoutEffect(() => {
		// handle indeterminate nodes
		if(state.searchResults?.length > 0) {
			Array.from(excluded.keys()).forEach(i => {
				const node = document.getElementById(`target_${i}`);
				if(node) {
					node.indeterminate = true;
					node.checked = false;
				}
			});
		}
	}, [state.searchResults])

	/**
	 * Save date in the local db
	 * @return {Promise<void>}
	 */
	const handleSubmit = async () => {
		// clear error first
		setServerError("");
		if (included.size < 1 && excluded.size < 1) {
			setServerError(intl.formatMessage({
				id: "ERROR_EMPTY_LOCATIONS",
				defaultMessage: "At least one location must be selected",
			}));
			return;
		}

		setLoading(true);
		try {
			const data = {
				"include": {
					"location_ids": Array.from(included.values())
						.filter((x) => ["region", "region_country"].includes(x.type))
						.map((x) => x.id),
					"dma_ids": Array.from(included.values())
						.filter((x) => x.type === "dma")
						.map((x) => x.id)
				},
				"exclude": {
					"location_ids": Array.from(excluded.values())
						.filter((x) => ["region", "region_country"].includes(x.type))
						.map((x) => x.id),
					"dma_ids": Array.from(excluded.values())
						.filter((x) => x.type === "dma")
						.map((x) => x.id)
				}
			};

			if(data_exists_in_db.current || is_t1_edit) {
				await service_strategy.update_targets(strategy_id, data);
			} else {
				await service_strategy.create_targets(strategy_id, data);
				data_exists_in_db.current = true;
			}

			if(get_continue.current) {
				context.stepNavigation.passLocations(strategy_id);
			} else {
				context.strategySuccessfullyUpdated(context.strategy.strategy_name);
			}
		} catch(e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setLoading(false);
			}
		}
	};

	const filterOptions = useMemo(() => {
		return !hasDMAs
			? strategy_location_filters(intl).filter(
					(option) => option.key !== "dmas"
			  )
			: strategy_location_filters(intl);
	}, [hasDMAs]);

	return <Segment basic>
		<Message
			style={{ "marginTop": "10px" }}
			error
			hidden={!serverError}
			size="tiny"
			content={serverError}
		/>
		<Form id="audiences_form" onSubmit={handleSubmit}>
			<FilterControl filter={initialState.filter}
										 onChange={performSearchLocations}
										 title={intl.formatMessage({
											 id: "LABEL_LOCATION",
											 defaultMessage: "Location",
										 })}
										 options={filterOptions}
										 loading={loading}
										 is_visible={locations_has_been_blocked.current} />
			<Grid columns={2} stackable style={{"display": locations_has_been_blocked.current? "none" : "block"}}>
				<Grid.Row>
					<Grid.Column>
						<Header as="h3">&nbsp;</Header>
						<Segment style={{"overflowY": "scroll", "height": 868, "paddingTop": 0 }} id="nested-targets">
							<LocationContext.Provider value={{
								switchTarget, switchVisibility, included, excluded, opened,
								"campaign": context.campaign
							}}>
								<div className="nested-targets" style={{ "display": state.searchResults === null ? "block" : "none" }}>
									{state.searchResults === null && <TargetValuesControl values={values} />}
								</div>
								<div className="search-results-list" style={{ "display": state.searchResults !== null ? "block" : "none" }}>
									{state.searchResults !== null && state.searchResults.length ? (
										<ul>
											{state.searchResults.map((result) => (
												<li className={result.checkable? "field custom-checkbox" : "field"} key={result.id}>
													<input
														type="checkbox"
														id={`target_${result.id}`}
														value={result.id}
														checked={included.has(result.id)}
														disabled={!result.checkable}
														style={{"opacity": 0}}
														className={!result.checkable? "invisible" : ""}
														onChange={(e) =>
															switchTarget(e, result.id, result)
														}
													/>
													<label
														className="target-label"
														htmlFor={`target_${result.id}`}
													>
														{result.title}
													</label>
												</li>
											))}
										</ul>
									) : (
										<p style={{"padding": "15px", "fontWeight": "bolder"}}>
											{intl.formatMessage({
												id: "EMPTY_SEARCH_RESULTS",
												defaultMessage: "No results found",
											})}
										</p>
									)}
								</div>
							</LocationContext.Provider>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Header as="h3">
							{intl.formatMessage({
								id: "LABEL_INCLUDED_LOCATIONS",
								defaultMessage: "Included Locations",
							})}{" "}
							<Button compact size='mini' className="btn-clear" type="button" onClick={resetIncluded}>
								{intl.formatMessage({
									id: "BTN_CLEAR",
									defaultMessage: "Clear",
								})}
							</Button>
						</Header>
						<Segment id="included_id" className="assigned-creatives" style={{"overflowY": "scroll", "height": 400 }}>
						</Segment>
						<Header as="h3">
							{intl.formatMessage({
								id: "LABEL_EXCLUDED_LOCATIONS",
								defaultMessage: "Excluded Locations",
							})}{" "}
							<Button compact size="mini" className="btn-clear" type="button" onClick={resetExcluded}>
								{intl.formatMessage({
									id: "BTN_CLEAR",
									defaultMessage: "Clear",
								})}
							</Button>
						</Header>
						<Segment id="excluded_id" className="assigned-creatives" style={{"overflowY": "scroll", "height": 400 }}>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<NavigationButtonDiv
				loading={loading}
				step={Steps.LOCATIONS}
				isPG={context.campaign.is_pg}
				onSave={setOperationType}
				is_t1_edit={is_t1_edit}
				skip_save_buttons={locations_has_been_blocked.current}
				onBackClick={() => context.stepNavigation.backToAudiences(strategy_id)}
				onContinueClick={() => context.getBack(true)}
				onCancelClick={context.getBack}
			/>
		</Form>
	</Segment>;
};
