import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Segment, Card, Image, Message } from "semantic-ui-react";
import { useIntl } from "react-intl";

import { onBannerDismiss } from "../../../libs/component_utils";
import REPORT_TYPES from "../fixtures/report-types";

const ReportIndexPage = ({ history }) => {
	const intl = useIntl();
	const showErrorMessage = history.location.state?.action === "error";

	return (
		<Segment basic style={{ "padding": "0" }}>
			{showErrorMessage && (
				<Message
				error
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
				>
					{history.location.state?.msg}
				</Message>
			)}

			<h1>
				{intl.formatMessage({
					id: "HEADING_REPORTS",
					defaultMessage: "Reports",
				})}
			</h1>
			<Card.Group itemsPerRow={4}>
				{Object.entries(REPORT_TYPES).map(([slug, { title, messageId, coverSrc }]) => (
					<ReportCard
						key={slug}
						href={`/report/${slug}`}
						title={title}
						messageId={messageId}
						coverSrc={coverSrc}
					/>
				))}
			</Card.Group>
		</Segment>
	);
};

const ReportCard = ({ href, title, messageId, coverSrc }) => {
	const intl = useIntl();
	return (
		<Card as={Link} to={href}>
			<Image src={coverSrc} wrapped ui={false} />
			<Card.Content>
				<Card.Header>
					{intl.formatMessage({
						id: messageId,
						defaultMessage: title,
					})}
				</Card.Header>
			</Card.Content>
		</Card>
	);
};

ReportCard.propTypes = {
	href: PropTypes.string,
	title: PropTypes.string,
	coverSrc: PropTypes.string,
};

export default ReportIndexPage;
