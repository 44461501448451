import React, { useState, useCallback } from "react";
import { Form, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { useDebounce } from "../../../libs/component_utils";
import { Config } from "../../../config/api";
import PropTypes from "prop-types";

const FilterControl = ({filter, onChange, isVideo}) => {
	const intl = useIntl();
	const [titleValue, setTitleValue] = useState(filter.title);

	const filterTitle = useCallback((value) => {
		if (filter.title === value) return;
		filter.setTitle(value);
		onChange(filter.toJson());
	});

	const filterTitleDebounced = useDebounce(
		Config.search_debounce_delay,
		(value) => {
			if (value.length === 0 || value.length >= 3) {
				filterTitle(value);
			}
		}
	);

	const handleTitleChange = useCallback((e, { value }) => {
		setTitleValue(value);
		filterTitleDebounced(value);
	});

	const handleTitleKeyPress = useCallback((e) => {
		if (e.charCode === 13) {
			filterTitle(e.target.value);
		}
	});

	return (
		<Form autoComplete="off" noValidate size="tiny" style={{"marginTop": "15px"}}>
			<Form.Group>
				<Form.Field>
					<label>
						{
							isVideo ? intl.formatMessage({
								id: "LABEL_VIDEO_CREATIVES",
								defaultMessage: "Video Creatives",
							}) :
							intl.formatMessage({
								id: "LABEL_DISPLAY_CREATIVES",
								defaultMessage: "Display Creatives",
							})
						}
					</label>
					<Input
						icon={
							titleValue !== ""
								? {
									name: "x",
									className: "input-clear-icon",
									link: true,
									onClick: () => {
										setTitleValue("");
										filterTitle("");
									},
								}
								: "search"
						}
						iconPosition={titleValue !== "" ? null : "left"}
						placeholder={
							isVideo ? intl.formatMessage({
								id: "HINT_VIDEO_CREATIVES",
								defaultMessage: "Search video creatives by name",
							}) :
							intl.formatMessage({
								id: "HINT_DISPLAY_CREATIVES",
								defaultMessage: "Search display creatives by name",
							})
						}
						value={titleValue}
						onChange={handleTitleChange}
						onKeyPress={handleTitleKeyPress}
						name="title"
					/>
				</Form.Field>
			</Form.Group>
		</Form>
	);
};
FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired,
	"isVideo": PropTypes.bool.isRequired
};

export default FilterControl;
