import React from "react";

import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {graph_metrics} from "../campaign/fixtures";

am4core.useTheme(am4themes_animated);
let chart;

const ChartDiv = ({data, filter, updated}) => {
	/**
	 * get metric name by value
	 * @param {string} metric
	 * @param {array} fixtures
	 * @return {string}
	 */
	const getMetricName = (metric, fixtures) => {
		return fixtures.find(x => x.value === metric)?.text || metric;
	};

	const intl = useIntl();

	React.useEffect(() => {
		chart = am4core.create("chart_div", am4charts.XYChart);
		chart.legend = new am4charts.Legend();
		chart.legend.layout = "vertical";
		chart.legend.position = "right";
		chart.legend.valign = "top";

		chart.data = data;

		chart.xAxes.push(new am4charts.DateAxis());

		// Create series
		function createAxisAndSeries(field, name, opposite, selected_bullet, color="#ff0000") {
			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			if(chart.yAxes.indexOf(valueAxis) !== 0) {
				valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
			}

			let series = chart.series.push(new am4charts.LineSeries());
			series.stroke = am4core.color(color);
			series.dataFields.valueY = field;
			series.dataFields.dateX = "date";
			series.strokeWidth = 2;
			series.yAxis = valueAxis;
			series.name = name;
			series.tooltipText = "{name}: [bold]{valueY}[/]";
			series.tensionX = 0.8;
			series.showOnInit = true;

			if(field === "metric_2") {
				series.strokeWidth = 2;
				series.strokeDasharray = "3,4";
			}

			let interfaceColors = new am4core.InterfaceColorSet();
			let bullet;
			switch(selected_bullet) {
				case "triangle":
					bullet = series.bullets.push(new am4charts.Bullet());
					bullet.width = 12;
					bullet.height = 12;
					bullet.horizontalCenter = "middle";
					bullet.verticalCenter = "middle";

					let triangle = bullet.createChild(am4core.Triangle);
					triangle.stroke = interfaceColors.getFor("background");
					triangle.strokeWidth = 2;
					triangle.direction = "top";
					triangle.width = 12;
					triangle.height = 12;
					break;
				case "rectangle":
					bullet = series.bullets.push(new am4charts.Bullet());
					bullet.width = 10;
					bullet.height = 10;
					bullet.horizontalCenter = "middle";
					bullet.verticalCenter = "middle";

					let rectangle = bullet.createChild(am4core.Rectangle);
					rectangle.stroke = interfaceColors.getFor("background");
					rectangle.strokeWidth = 2;
					rectangle.width = 10;
					rectangle.height = 10;
					break;
				default:
					bullet = series.bullets.push(new am4charts.CircleBullet());
					bullet.circle.stroke = interfaceColors.getFor("background");
					bullet.circle.strokeWidth = 2;
					break;
			}

			valueAxis.renderer.line.strokeOpacity = 1;
			valueAxis.renderer.line.strokeWidth = 2;
			valueAxis.renderer.line.stroke = series.stroke;
			valueAxis.renderer.labels.template.fill = series.stroke;
			valueAxis.renderer.opposite = opposite;
		}

		createAxisAndSeries("metric_1", getMetricName(filter.metric_1, graph_metrics(intl)), false, "circle", "rgb(28,79,140)");
		createAxisAndSeries("metric_2", getMetricName(filter.metric_2, graph_metrics(intl)), true, "triangle");

		if(data.length < 1) {
			const label = chart.chartContainer.createChild(am4core.Label);
			label.text = intl.formatMessage({
				id: "ERROR_GRAPH_NO_DATA",
				defaultMessage: "No data is available",
			});

			label.opacity = 0.5;
			label.fontSize = 20;
			label.align = "center";
			label.isMeasured = false;
			label.x = am4core.percent(50);
			label.y = am4core.percent(40);
			label.horizontalCenter = "middle";
			label.verticalCenter = "middle";
		}


		chart.cursor = new am4charts.XYCursor();
		/*
		chart.events.onAll(() => {
			const node = document.querySelector("#chart_div g[opacity][transform][filter]");
			if(node) {
				node.remove();
			}
		});
		*/

		return () => {
			if(chart) {
				chart.dispose();
			}
		};
	}, [updated]);

	return (
		<div id="chart_div" style={{"height": "250px", "width": "100%"}}>&nbsp;</div>
	);
};
ChartDiv.propTypes = {
	"data": PropTypes.array.isRequired,
	"filter": PropTypes.object.isRequired,
	"updated": PropTypes.number.isRequired
};

export default ChartDiv;
