import React from "react";
import Pager from "../../models/pager";
import DealsFilter from "./deals/filter";
import SitesFilter from "./site-list/filter";

const InventoryPageContext = React.createContext();

export const InventoryInitialContext = {
  error: false,
  errorMessage: null,
  siteList: {
    index: [],
    mount: false,
    // biome-ignore lint/style/useNamingConvention: server prop
    total_pages: 0,
    loading: false,
    pager: new Pager(),
    filter: new SitesFilter(),
  },
  deals: {
    index: [],
    mount: false,
    // biome-ignore lint/style/useNamingConvention: server prop
    total_pages: 0,
    loading: false,
    pager: new Pager(),
    filter: new DealsFilter(),
    error: false,
    errorMessage: null,
  },
};

export const InventoryReducer = (state, action) => {
  const { data } = action;

  switch (action.type) {
    // Site Lists
    case "SITELIST_UPDATE_FILTERS": {
      const updatedFilters = { ...state };
      updatedFilters.siteList.pager.reset();
      if (data.title) updatedFilters.siteList.filter.setTitle(data.title);
      if (data.status) updatedFilters.siteList.filter.setStatus(data.status);
      return updatedFilters;
    }
    case "SITELIST_LOAD_INDEX": {
      const updatedIndex = { ...state };
      updatedIndex[action.data.index].index = action.data.data;
      updatedIndex[action.data.index].pager.setTotal(action.data.meta);
      return updatedIndex;
    }
    case "SITELIST_LOAD_INDEX_IN_PROGRESS": {
      const updatedLoad = { ...state };
      updatedLoad[action.data.index].loading = action.data.loading;
      return updatedLoad;
    }
    case "SITELIST_LOAD_ERROR":
      return {
        ...state,
        error: action.data.error,
        errorMessage: action.data.errorMessage,
      };

    // Deals
    case "DEALS_UPDATE_FILTERS": {
      const dealsUpdatedFilters = { ...state };
      dealsUpdatedFilters.deals.pager.reset();
      if (data.title) dealsUpdatedFilters.deals.filter.setTitle(data.title);
      if (data.status) dealsUpdatedFilters.deals.filter.setStatus(data.status);
      return dealsUpdatedFilters;
    }
    case "DEALS_LOAD_INDEX": {
      const dealsUpdatedIndex = { ...state };
      dealsUpdatedIndex[action.data.index].index = action.data.data;
      dealsUpdatedIndex[action.data.index].pager.setTotal(action.data.meta);
      return dealsUpdatedIndex;
    }
    case "DEALS_LOAD_INDEX_IN_PROGRESS": {
      const dealsUpdatedLoad = { ...state };
      dealsUpdatedLoad[action.data.index].loading = action.data.loading;
      return dealsUpdatedLoad;
    }
    case "DEALS_LOAD_ERROR": {
      const dealsUpdatedError = { ...state };
      dealsUpdatedError.deals.error = action.data?.error;
      dealsUpdatedError.deals.errorMessage = action.data?.errorMessage;
      dealsUpdatedError.deals.loading = false;
      return dealsUpdatedError;
    }

    default:
      throw new Error("Method is not implemented");
  }
};

export default InventoryPageContext;
