import AbstractService from "../abstract.service";

export default class StrategiesDraftService extends AbstractService {
	static URI_STATES = new Map([
		["strategies.get", "/build-strategy/{0}"],
		["strategies.update", "/build-strategy/{0}"],
		["strategies.save", "/build-strategy"],
		["strategies.supplies", "/build-strategy/{0}/supplies"],
		["strategies.creatives", "/build-strategy/{0}/creatives"],
		["strategies.audiences", "/build-strategy/{0}/audiences"],
		["strategies.targets", "/build-strategy/{0}/targeting"],
		["strategies.daypart", "/build-strategy/{0}/daypart"],
		["strategies.technology", "/build-strategy/{0}/technology"],

		["strategies.settings", "/strategy_settings/{0}"],

		["strategies.create_t1", "/create-strategy/{0}"],
		["strategies.create_t1_supplies", "/create-strategy/{0}/supplies"],
		["strategies.create_t1_creatives", "/create-strategy/{0}/creatives"],
		["strategies.create_t1_audiences", "/create-strategy/{0}/audiences"],
		["strategies.create_t1_targets", "/create-strategy/{0}/targeting"],
		["strategies.create_t1_daypart", "/create-strategy/{0}/daypart"],
		["strategies.create_t1_technology", "/create-strategy/{0}/technology"],
		["strategies.create_t1_targets_technology", "/create-strategy/{0}/targeting-technology"],

		["strategies.supply_rules", "/build-supply-rules/{0}"]
	]);

	/**
	 * Get supply rule for a workflow, which describes should be white list used or regular one
	 * @param {number} strategy_id
	 * @param {string} workflow
	 * @returns {Promise<void>}
	 */
	async get_supply_rules(strategy_id, workflow) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.supply_rules").format(strategy_id), {
			workflow
		});
	}

	/**
	 * Get strategy settings
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async settings(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.settings").format(strategy_id));
	}

	/**
	 * Get technology from local DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_technology(strategy_id) {
		// return new Promise(r => r({"data": {"browser_ids": [], "device_ids": [], "device_model_ids": [], "inventory_type_ids": [], "os_version_ids": []}, "meta": {"status": "ok"}}));
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategy_id));
	}

	/**
	 * Save technology into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_technology(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategy_id), params);
	}

	/**
	 * Save technology into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_technology(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategy_id), params);
	}

	/**
	 * Get daypart from local DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_daypart(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategy_id));
	}

	/**
	 * Save daypart into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_daypart(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategy_id), params);
	}

	/**
	 * Save daypart into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_daypart(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategy_id), params);
	}

	/**
	 * Get audiences from local DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_audiences(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategy_id));
	}

	/**
	 * Save audiences into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_audiences(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategy_id), params);
	}

	/**
	 * Save audiences into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_audiences(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategy_id), params);
	}

	/**
	 * Get targets from local DB
	 * @param {number} strategy_id
	 * @returns {Promise<any | never>}
	 */
	async get_targets(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategy_id));
	}

	/**
	 * Save targets into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async create_targets(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategy_id), params);
	}

	/**
	 * Save targets into local DB
	 * @param {number} strategy_id
	 * @param {array} params
	 * @returns {Promise<any | never>}
	 */
	async update_targets(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategy_id), params);
	}

	/**
	 * Get draft supplies
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get_supplies(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategy_id));
	}

	/**
	 * Create draft supplies
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_supplies(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategy_id), params);
	}

	/**
	 * Update existing draft supplies
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_supplies(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategy_id), params);
	}

	/**
	 * Get draft creatives
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get_creatives(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategy_id));
	}

	/**
	 * Create draft creatives
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_creatives(strategy_id, params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategy_id), params);
	}

	/**
	 * Update existing draft creatives
	 * @param {number} strategy_id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_creatives(strategy_id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategy_id), params);
	}

	/**
	 * Get draft version of strategy
	 * @param {number} strategy_id
	 * @return {Promise<>}
	 */
	async get(strategy_id) {
		return this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.get").format(strategy_id));
	}

	/**
	 * store local strategy
	 * @param params
	 * @return {Promise<>}
	 */
	async save(params) {
		if(!params.id) {
			return this.add(params);
		}

		return this.update(params.id, params);
	}

	/**
	 * add new local strategy
	 * @param params
	 * @return {Promise<>}
	 */
	async add(params) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.save"), params);
	}

	/**
	 * update existing strategy
	 * @param id
	 * @param params
	 * @return {Promise<>}
	 */
	async update(id, params) {
		return this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.update").format(id), params);
	}

	/**
	 * create strategy in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1").format(id), {});
	}

	/**
	 * create strategy supply in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_supply(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_supplies").format(id), {});
	}

	/**
	 * create strategy creatives in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_creatives(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_creatives").format(id), {});
	}

	/**
	 * create strategy audiences in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_audiences(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_audiences").format(id), {});
	}

	/**
	 * create strategy targets in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_targets(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_targets").format(id), {});
	}

	/**
	 * Save targets and technologies in one call
	 * @param {number} if
	 * @returns {Promise<any | never>}
	 */
	async create_t1_targets_technologies(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_targets_technology").format(id), {});
	}

	/**
	 * create strategy daypart in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_daypart(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_daypart").format(id), {});
	}

	/**
	 * create strategy technology in t1
	 * @param {number} id
	 * @return {Promise}
	 */
	async create_t1_technology(id) {
		return this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1_technology").format(id), {});
	}
}
