import React from "react";
import { Form } from "semantic-ui-react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";
import uuid from "uuid/v4";
import _ from "lodash";

import "../index.css";
import LocationContext from "../contexts/location";
import { classNames } from "../../../../../libs/component_utils.js";

export const actions = {
	"VISIBILITY": "VISIBILITY",
	"INIT": "INIT",
	"APPEND": "APPEND"
};

const targetRootMap = [{
	"checkable": false,
	"previous_checked": false,
	"has_children": true,
	"included": false,
	"opened": false,
	"id": -1,
	"uuid": uuid(),
	"title": "Countries / Regions / Cities",
	"messageId": "LABEL_REGIONS",
	"full_path": "Countries / Regions / Cities",
	"type": "regions_root"
}, {
	"checkable": false,
	"previous_checked": false,
	"has_children": true,
	"included": false,
	"opened": false,
	"id": 0,
	"uuid": uuid(),
	"title": "DMAs",
	"messageId": "LABEL_DMAS",
	"full_path": "DMAs",
	"type": "dmas_root"
}];

let treeInit = false;

/**
 * reset the flag to rebuild the tree
 */
export const resetInitTreeFlag = () => {
	treeInit = false;
};

/**
 * populate the tree with recursive function
 * @param {array} root
 * @param {array} data
 * @return {array}
 */
const maxCycles = 50;
const fillTheTree = (root, data) => {
	let recursions = 0;

	const tree = node => {
		if(recursions++ > maxCycles) {
			console.error(`Recursion limit of ${recursions} times was reached!`);
			return node;
		}

		return node.map(item => {
			if(item.child && item.child.length > 0) {
				item.child = tree(item.child).filter(x => !!x);
			} else {
				item.child = data.filter(x => x.parent_id === item.id).filter(x => !!x);
			}

			return item;
		});
	};

	if(!treeInit) {
		treeInit = true;
		return [...root].map(n => {
			delete n.child;
			return n;
		});
	}

	return tree([...root]).filter(x => !!x);
};

/**
 * rebuild response into proper ui structure
 * @param {Array} targets
 * @return {Array}
 */
const init_target_structure = targets => {
	return targets.map(target => {
		return {
			...target,
			"checkable": Boolean(target.is_targetable),
			"full_path": target.path? target.path : target.title,
			"previous_checked": false,
			"included": false,
			"opened": false,
			"uuid": uuid()
		}
	});
};

export const targetValuesControlReducer = (state, action) => {
	let targets = [];
	switch(action.type) {
		case(actions.INIT):
			targets = init_target_structure(action.data);
			const rootBranches = _.cloneDeep(targetRootMap);
			if(!action.hasDMAs){
				rootBranches.pop();
			}
			return fillTheTree(rootBranches, []);

		case(actions.APPEND):
			let tmp = action.data;
			targets = init_target_structure(tmp);
			let found = false;

			const appendChild = (root, id_to_append, children) => {
				return root.map(node => {
					if(node.id === id_to_append) {
						node.opened = true;
						node.child = children;
						found = true;
					}

					if(!found && node.hasOwnProperty("child") && node.child.length) {
						node.child = appendChild(node.child, id_to_append, children);
					}

					return node;
				});
			};

			return appendChild(_.cloneDeep(state), action.root.id, targets);

		default:
			throw new Error("Method is not implement");
	}
};

const TargetValuesControl = ({values}) => {
	const {switchTarget, switchVisibility, included, excluded, opened} = React.useContext(LocationContext);
	const intl = useIntl();

	/**
	 * set items checked for each tree reload
	 */
	React.useLayoutEffect(() => {
		// remove empty nodes
		document.querySelectorAll(".nested-targets > div.ul-container").forEach(n => {
			if(n.querySelectorAll("input[type='checkbox']").length < 1) {
				// n.style.display = "none";
			} else {
				// recursiveCollapseEmptyNodes(n);
			}
		});

		Array.from(included.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.checked = true;
			}
		});

		Array.from(excluded.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				node.indeterminate = true;
			}
		});
	});

	/*
	 * Reload translated values on locale change.
	 * (TODO: optimize)
	 */
	React.useLayoutEffect((a) => {
		document.querySelectorAll("[data-message-id]").forEach((el) => {
			el.textContent = intl.formatMessage({
				id: el.dataset.messageId,
				defaultMessage: el.textContent,
			});
		});
	});

	/**
	 * check that target has children
	 * @param {object} node
	 * @return {boolean}
	 */
	const target_has_children = node => {
		return (node.hasOwnProperty("child") && node.child.length) || (node.hasOwnProperty("has_children") && node.has_children)
	};

	/**
	 * form node title
	 * @param {object} node
	 * @return {string}
	 */
	const getTitle = (node) => {
		if (node.hasOwnProperty("messageId")) {
			return intl.formatMessage({
				id: node.messageId,
				defaultMessage: node.title,
			});
		}
		return node.title;
	};

	const html = (values) => {
		const specialCase = values.some(target => target_has_children(target) && target.checkable);

		return (<>
			{values.map((target) => {
				return (
					<div key={`node_${target.uuid}`} className={classNames(
						"ul-container",
						(opened.has(target.id) ? opened.get(target.id) : target.opened) && "opened",
						target_has_children(target) && "has-children",
						target.checkable && "checkable",
						specialCase && "special-case"
					)}>
						{target_has_children(target) && <div className="arrow-right" onClick={e => switchVisibility(e.target, target)}>&nbsp;</div>}
						<ul>
							<li>
								<Form.Field className="custom-checkbox">
									{target.checkable ? (
										<>
											<input
												type="checkbox"
												onChange={(e) => switchTarget(e, target.id)}
												value={target.id}
												id={`target_${target.id}`}
											/>

											<label
												className="target-label"
												data-message-id={target?.messageId}
												htmlFor={`target_${target.id}`}
											>
												{getTitle(target)}
											</label>
										</>
									) : (
										<label
											className="target-label no-parents"
											data-message-id={target?.messageId}
											onClick={(e) =>
												switchVisibility(
													e.target.parentNode.parentNode.parentNode,
													target
												)
											}
										>
											{getTitle(target)}
										</label>
									)}
								</Form.Field>
								{target.child && target.child.length > 0 && html(target.child)}
							</li>
						</ul>
					</div>);
			})}
		</>)
	};

	return html(values);
};
TargetValuesControl.propTypes = {
	"values": PropTypes.array.isRequired
};

export default React.memo(TargetValuesControl,(prev, next) => {
	return JSON.stringify(prev.values) === JSON.stringify(next.values);
});
