/* eslint-disable no-unreachable */
export const actions = {
	"INIT": "INIT",
	"CHECK_ALL": "CHECK_ALL",
	"CHECK": "CHECK",
	"LOAD_SAVED_DATA": "LOAD_SAVED_DATA"
};

const checkedMap = new Map();

/**
 * Init creatives
 * @param {array} creatives
 * @param {object} pager
 * @param state
 * @return {object} new state
 */
const init = (creatives, pager, state) => {
	state.pager.setTotal(pager);
	creatives = creatives.map(creative => {
		creative.checked = checkedMap.has(creative.id);
		return creative;
	});

	return {
		...state,
		creatives
	}
};

/**
 * check or uncheck specific creative
 * @param {number} id
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const check = (id, flag, state) => {
	const creatives = state.creatives.map(creative => {
		if(creative.id === id) {
			if(flag && !checkedMap.has(creative.id)) {
				checkedMap.set(creative.id, creative);
			}

			creative.checked = flag;
		}

		return creative;
	});

	if(!flag) {
		checkedMap.delete(id);
	}

	return {
		...state,
		creatives
	}
};

/**
 * get supplies ids that been checked
 * @return {Map<any, any>}
 */
export const getChecked = () => {
	return checkedMap;
};

/**
 * Clear supplies map
 */
export const clearChecked = () => {
	checkedMap.clear();
};

/**
 * Load data that already saved
 * @param {object} response
 * @param state
 * @return {object} new state
 */
const loadSavedData = (response, state) => {
	checkedMap.clear();
	response.creative_ids.forEach(item => {
		checkedMap.set(item.id, item);
	});

	// let's rebuild creatives list in case selected was loaded before initial list
	const creatives = state.creatives.map(c => {
		c.checked = checkedMap.has(c.id);
		return c;
	});

	return {
		...state,
		creatives,
		"selected_creatives": response.creative_ids
	}
};

export const gridReducer = (state, action) => {
	switch(action.type) {
		case(actions.INIT):
			return init(action.data, action.pager, state);

		case(actions.LOAD_SAVED_DATA):
			return loadSavedData(action.data, state);

		case(actions.CHECK):
			return check(action.id, action.flag, state);

		default:
			throw new Error("Method is not implement");
	}
};