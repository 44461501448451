export default class DealsFilter {
  constructor() {
    this.reset();
  }

  /**
   * update campaign status
   * @param {string} value
   */
  setStatus(value) {
    this.status = value;
  }

  /**
   * update filter name
   * @param {string} value
   */
  setTitle(value) {
    this.title = value.trim().toLowerCase();
  }

  /**
   * update deal type filter
   * @param {string} value
   */
  setType(value) {
    this.dealType = value;
  }

  setId(value) {
    this.id = value;
  }

  /**
   * unpack item from json format
   * @param {object} item
   */
  fromJson(item) {
    this.status = item.status;
    this.title = item.title;
    this.id = item.id;
    this.dealType = item.deal_type;
  }

  /**
   * reset filter data
   */
  reset() {
    this.status = "all";
    this.title = "";
    this.id = "";
    this.dealType = "all";
  }

  /**
   * Transform data into API object
   * @returns {object}
   */
  toJson() {
    return {
      status: this.status,
      title: this.title,
      id: this.id,
    };
  }

  /**
   * transform data into request json
   * @returns {object}
   */
  toRequestJson() {
    const params = {};

    if (this.status !== "all") {
      params.status = this.status === "active" ? 1 : 0;
    }

    if (this.title !== "") {
      params.supply_name = this.title;
    }

    if (this.id !== "") {
      params.id = this.id;
    }

    if (this.dealType !== "all") {
      params.deal_type = this.dealType;
    }

    return params;
  }

  /**
   * check that user didn't touch filter
   * @return {boolean}
   */
  isEmpty() {
    return this.status === "all" && this.title === "" && this.id === "" && this.dealType === "all";
  }
}
