import React, {useCallback, useState} from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PixelForm from "../form";
import PixelModal from "../modal";
import PixelsService from "../../../services/pixels";
import {RevenueType} from "../../../models/enum/pixel_revenue_type";

/**
 * Pixel create page component
 * @param history
 * @return {*}
 * @constructor
 */
const PixelCreatePage = ({ history }) => {
	const back_url = "/pixels";
	const service = new PixelsService();
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);
	const [modalData, setModalData] = useState(null);
	let _isMounted = React.useRef(true);
	const intl = useIntl();

	/**
	 * navigate user back
	 */
	const navigateToGrid = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * form submit handler
	 * @param {object} values
	 */
	const handleSubmit = async (values) => {
		setLoading(true);

		try {
			const r = await service.create(values);
			setModalData(r.data);
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	const initialData = {
		"status": true,
		"title": "",
		"advertiser_id": null,
		"revenue_enabled": 0,
		"revenue": "V1",
		"revenue_currency": "",
		"revenue_currency_fixed": "",
		"revenue_type": RevenueType.DYNAMIC
	};

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_CREATE_PIXEL",
					defaultMessage: "Create Pixel",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<PixelForm
				loading={loading}
				initialData={initialData}
				submitText={intl.formatMessage({
					id: "BTN_CREATE_PIXEL",
					defaultMessage: "Create Pixel",
				})}
				onSubmit={handleSubmit}
				onCancel={navigateToGrid}
			/>
			<PixelModal
				open={Boolean(modalData)}
				type="create"
				id={modalData?.id}
				advertiser_id={modalData?.advertiser_id}
				onClose={navigateToGrid}
				values={modalData}
			/>
		</Segment>
	);
};

export default PixelCreatePage;
