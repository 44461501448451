export default class StrategiesBulkEditModel {
	constructor({
		ids = null,
		status = null,
		pacingType = null,
		pacingInterval = null,
		pacingAmount = null,
		frequencyOptimization = null,
		frequencyType = null,
		frequencyAmount = null,
		frequencyInterval = null,
		minBid = null,
		maxBid = null
	} = {}) {
		const model = {
			ids: ids,
			status: status !== null ? parseInt(status) : null,
			pacing_type: pacingType,
			pacing_interval: pacingInterval,
			pacing_amount: pacingAmount !== null ? parseInt(pacingAmount) : null,
			frequency_optimization: frequencyOptimization,
			frequency_type: frequencyType,
			frequency_amount: frequencyAmount !== null ? parseInt(frequencyAmount) : null,
			frequency_interval: frequencyInterval,
			min_bid: minBid !== null ? parseFloat(minBid) : null,
			max_bid: maxBid !== null ? parseFloat(maxBid) : null
		};

		Object.keys(model).forEach(key => model[key] === null && delete model[key]);

		return model;
	}
}
