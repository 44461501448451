import { addDays, addHours, isAfter, isBefore, setHours, setMinutes } from "date-fns";
import lightFormat from "date-fns/lightFormat";
import omit from "lodash/omit";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Button, Checkbox, Divider, Form, Input, Message, Radio, Select, TextArea } from "semantic-ui-react";

import "./styles.css";

import { Config } from "../../../config/api";
import { errorToMessage } from "../../../libs/common_utils";
import { useForm } from "../../../libs/component_utils";
import DealService from "../../../services/inventory/deals";
import OrganizationPicker from "../../common/organization-picker";
import useDealForm from "./hooks";

const service = new DealService();
const DEALS_API_FORM_KEY_VALUES = {
  dealName: "title",
  dealDescription: "description",
  dealType: "deal_type",
  dealId: "deal_id",
  publisher: "publisher_id",
  exchange: "supply_source_id",
  channel: "channels",
  currency: "currency",
  dealPrice: "price",
  priceType: "price_type",
  startDate: "start_date",
  endDate: "end_date",
  status: "status",
  priceMethod: "price_method",
  permissions: "permissions",
  agencyIds: "agency_ids",
  advertiserIds: "advertiser_ids",
};

const formatDateTime = (date) => lightFormat(date, "yyyy-MM-dd HH:mm:ss");
const prepareValues = (values) => {
  const preparedValues = { [DEALS_API_FORM_KEY_VALUES.priceMethod]: "CPM" };

  const permissionApiRef = {
    organizationIds: "organization_ids",
    agencyIds: "agency_ids",
    advertiserIds: "advertiser_ids",
  };

  for (const [key, value] of Object.entries(DEALS_API_FORM_KEY_VALUES)) {
    if (values[key] === undefined) continue;

    switch (key) {
      case "startDate": {
        preparedValues[value] = formatDateTime(values[key]);
        break;
      }
      case "endDate": {
        preparedValues[value] = values.neverEnds
          ? setHours(setMinutes(new Date().setFullYear(2999, 11, 31), 0), 0)
          : values[key];

        preparedValues[value] = formatDateTime(preparedValues[value]);
        break;
      }
      case "dealPrice": {
        preparedValues[value] = Number(values[key]);
        break;
      }
      case "status": {
        preparedValues[value] = values[key] ? 1 : 0;
        break;
      }
      case "agencyIds": {
        preparedValues[DEALS_API_FORM_KEY_VALUES.permissions] = {
          [permissionApiRef.organizationIds]: [], // not used
          [permissionApiRef.advertiserIds]: [],
          [permissionApiRef.agencyIds]: values.agencyIds,
        };
        break;
      }
      case "advertiserIds": {
        preparedValues[DEALS_API_FORM_KEY_VALUES.permissions] = {
          ...preparedValues[DEALS_API_FORM_KEY_VALUES.permissions],
          [permissionApiRef.advertiserIds]: values[key],
        };
        break;
      }
      default: {
        if (values[key] !== undefined) {
          preparedValues[value] = values[key];
        }
      }
    }
  }

  return preparedValues;
};

const getNewEndDate = (startDate) => {
  const today = new Date();
  const newEndDate = isAfter(startDate, today) ? startDate : today;
  return addDays(addHours(newEndDate, 1), 1);
};

const DealForm = ({
  initialData,
  onSubmit,
  onCancel,
  submitButtonLabel = "Create Site List",
  formType = "create",
  initialIndeterminateAgencies = [],
}) => {
  const intl = useIntl();
  const [serverError, setServerError] = useState("");
  const [formSending, setFormSending] = useState(false);
  const { isLoading, isMounted, currencies, formRules, publishers, dealChannels, supplySources } = useDealForm({
    intl,
    onError: setServerError,
  });

  const prepareToCreate = async (values) => {
    let jsonValues = prepareValues(values);

    if (jsonValues[DEALS_API_FORM_KEY_VALUES.dealDescription] === "") {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.dealDescription);
    }
    if (
      jsonValues[DEALS_API_FORM_KEY_VALUES.publisher] === null ||
      jsonValues[DEALS_API_FORM_KEY_VALUES.publisher] === ""
    ) {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.publisher);
    }

    await service.create(jsonValues);
  };

  const filterStartPassedTime = (time) => {
    const selectedDate = new Date(time);
    return new Date().getTime() < selectedDate.getTime();
  };

  const filterEndPassedTime = (time) => {
    const selectedDate = new Date(time);
    return values.startDate.getTime() < selectedDate.getTime();
  };

  const prepareToEdit = async (values) => {
    let jsonValues = prepareValues(values);

    if (Boolean(jsonValues[DEALS_API_FORM_KEY_VALUES.publisher]) === false) {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.publisher);
    }
    await service.update(initialData.id, jsonValues);
  };

  /**
   * Hadle Deal save data from the form
   * @return {Promise<void>}
   */
  const handleSaveForm = async () => {
    try {
      setFormSending(true);

      if (formType === "create") {
        await prepareToCreate(values);
      }
      if (formType === "edit" && initialData.id) {
        await prepareToEdit(values);
      }

      onSubmit();
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (isMounted) {
        setFormSending(false);
      }
    }
  };

  const postValidation = (_, fields) => {
    const newErrors = {};
    for (const field of Object.keys(formRules)) {
      const error = formRules[field](fields[field], fields);
      if (error) {
        newErrors[field] = error;
      }
    }
    return newErrors;
  };

  const {
    values,
    errors,
    onSwitch,
    onChange,
    updateValues,
    onSubmit: handleSubmit,
    setErrors,
  } = useForm(handleSaveForm, initialData, postValidation);

  return (
    <>
      <Message style={{ marginTop: "10px" }} error hidden={!serverError} size="tiny" content={serverError} />
      <Form
        size="small"
        noValidate
        error={Boolean(Object.keys(errors).length)}
        autoComplete="off"
        loading={isLoading || formSending}
        onSubmit={handleSubmit}
      >
        <Form.Field inline error={errors.status}>
          <label>
            {intl.formatMessage({
              id: "LABEL_STATUS",
              defaultMessage: "Status",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio
              label={intl.formatMessage({
                id: "STATUS_ACTIVE",
                defaultMessage: "Active",
              })}
              name="status"
              value={1}
              checked={Boolean(values.status)}
              onChange={onChange}
            />
            <Radio
              name="status"
              label={intl.formatMessage({
                id: "STATUS_INACTIVE",
                defaultMessage: "Inactive",
              })}
              value={0}
              checked={!values.status}
              onChange={onChange}
            />
          </div>
        </Form.Field>

        <Form.Field inline required error={errors.dealName}>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_NAME",
              defaultMessage: "Deal Name",
            })}
          </label>
          <Input name="dealName" className="input-field-wider" value={values.dealName} onChange={onChange} />
          {errors.dealName && <div className="custom-error">{errors.dealName}</div>}
        </Form.Field>

        <Form.Field inline error={errors.dealDescription}>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_DESCRIPTION",
              defaultMessage: "Description",
            })}
          </label>
          <div className="ui input input-field-wider">
            <TextArea rows="2" name="dealDescription" value={values.dealDescription} onChange={onChange} />
          </div>
          {errors.dealDescription && <div className="custom-error">{errors.dealDescription}</div>}
        </Form.Field>

        <Form.Field inline required error={errors.dealId}>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_ID",
              defaultMessage: "Deal ID",
            })}
          </label>
          <Input name="dealId" className="input-field-wider" value={values.dealId} onChange={onChange} />
          <div className="custom-error">{errors.dealId}</div>
          {errors.dealId && <div className="custom-error">{errors.dealId}</div>}
        </Form.Field>

        <Form.Field inline error={errors.dealType}>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_TYPE",
              defaultMessage: "Deal Type",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio label="PMP" name="dealType" value="PMP" checked={values.dealType === "PMP"} onChange={onChange} />
            <Radio
              label="PG"
              name="dealType"
              value="PG"
              checked={values.dealType === "PG"}
              onChange={(e, input) => {
                if (input.checked) {
                  updateValues({
                    ...values,
                    dealType: "PG",
                    neverEnds: false,
                    endDate: getNewEndDate(values.startDate),
                    priceType: "FIXED",
                  });
                } else {
                  onChange(e, input);
                }
              }}
            />
          </div>
          {errors.dealType && <div className="custom-error">{errors.dealType}</div>}
        </Form.Field>

        <Form.Field inline required error={Boolean(errors.endDate || errors.startDate)}>
          <label>
            {intl.formatMessage({
              id: "LABEL_START_END_DATE",
              defaultMessage: "Start/End Date",
            })}
          </label>
          <div className="ui input multi-fields">
            <DatePicker
              selected={values.startDate}
              filterTime={filterStartPassedTime}
              minDate={addHours(new Date(), 1)}
              minTime={setMinutes(addHours(new Date(), 1), 0)}
              maxTime={setMinutes(addHours(new Date(), 23), 59)}
              onChange={(date) => {
                const toUpdate = { ...values, startDate: date };
                if (isBefore(values.endDate, date) && !values.neverEnds) {
                  toUpdate.endDate = getNewEndDate(date);
                }
                updateValues(toUpdate);
              }}
              showTimeSelect
              timeFormat={Config.timeFormat}
              timeIntervals={15}
              timeCaption="time"
              dateFormat={Config.fullDateTimeFormat}
              disabled={values.id && isBefore(values.startDate, new Date())}
            />
            <DatePicker
              selected={values.neverEnds ? "" : values.endDate}
              minDate={values.startDate}
              filterTime={filterEndPassedTime}
              onChange={(date) => {
                updateValues({ ...values, endDate: date });
              }}
              showTimeSelect
              timeFormat={Config.timeFormat}
              timeIntervals={15}
              timeCaption="time"
              dateFormat={Config.fullDateTimeFormat}
              disabled={Boolean(values.neverEnds)}
            />
            <Checkbox
              label="Never ends"
              name="neverEnds"
              value={1}
              checked={Boolean(values.neverEnds)}
              onClick={(e, input) => {
                if (values.dealType !== "PG") {
                  if (input.checked) {
                    onSwitch(e, input);
                  } else {
                    updateValues({ ...values, neverEnds: false, endDate: getNewEndDate(values.startDate) });
                  }
                }
              }}
              disabled={values.dealType === "PG"}
            />
          </div>
          {errors.startDate && <div className="custom-error">{errors.startDate}</div>}
          {errors.endDate && <div className="custom-error">{errors.endDate}</div>}
        </Form.Field>

        <Form.Field inline error={Boolean(errors.priceType)}>
          <label>
            {intl.formatMessage({
              id: "LABEL_PRICE_TYPE",
              defaultMessage: "Price Type",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio
              label="Floor"
              name="priceType"
              value="FLOOR"
              checked={values.priceType === "FLOOR"}
              onChange={onChange}
              disabled={values.dealType === "PG"}
            />
            <Radio
              label="Fixed"
              name="priceType"
              value="FIXED"
              checked={values.priceType === "FIXED"}
              onChange={onChange}
            />
          </div>
        </Form.Field>

        <Form.Field inline required error={errors.dealPrice}>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_PRICE",
              defaultMessage: "Deal Price (CPM)",
            })}
          </label>
          <div className="ui input multi-fields">
            <Select
              search
              selection
              clearable
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER_SELECT_CURRENCY",
                defaultMessage: "Select Currency",
              })}
              value={values.currency}
              options={currencies}
              loading={isLoading}
              onChange={onChange}
              name="currency"
            />

            <Input placeholder="0.00" type="number" name="dealPrice" value={values.dealPrice} onChange={onChange} />
          </div>
          {errors.dealPrice && <div className="custom-error">{errors.dealPrice}</div>}
        </Form.Field>

        <Form.Field inline required error={errors.exchange}>
          <label>
            {intl.formatMessage({
              id: "LABEL_EXCHANGE",
              defaultMessage: "Exchange",
            })}
          </label>
          <Select
            name="exchange"
            search
            selection
            clearable
            placeholder="Select Exchange"
            value={values.exchange}
            onChange={onChange}
            loading={isLoading}
            options={supplySources}
          />
          {errors.exchange && <div className="custom-error">{errors.exchange}</div>}
        </Form.Field>

        <Form.Field inline>
          <label>
            {intl.formatMessage({
              id: "LABEL_PUBLISHER",
              defaultMessage: "Publisher",
            })}
          </label>
          <Select
            name="publisher"
            search
            selection
            clearable={(formType === "edit" && initialData.publisher === null) || formType === "create"}
            placeholder="Select Publisher"
            value={values.publisher}
            onChange={onChange}
            loading={isLoading}
            options={publishers}
          />
        </Form.Field>

        <Form.Field inline required error={Boolean(errors.channel)}>
          <label>
            {intl.formatMessage({
              id: "LABEL_CHANNEL",
              defaultMessage: "Channel",
            })}
          </label>
          <Select
            name="channel"
            placeholder="Select Channel"
            value={values.channel}
            onChange={onChange}
            loading={isLoading}
            options={dealChannels}
            multiple
          />
          {errors.channel && <div className="custom-error">{errors.channel}</div>}
        </Form.Field>

        <Form.Field inline>
          <label>
            {intl.formatMessage({
              id: "LABEL_PERMISSIONS",
              defaultMessage: "Permissions",
            })}
          </label>

          <OrganizationPicker
            values={values}
            preventAdvertisers
            // these will ge passed from backend on edit page, always empty on create page:
            initialIndeterminateAgencies={initialIndeterminateAgencies}
            onChange={updateValues}
            onError={(error) =>
              setErrors({
                ...errors,
                agencyIds: errorToMessage(error) || "Unknown error fetching data from server",
              })
            }
            refKeys={{
              agencyIds: "agencyIds",
              advertiserIds: "advertiserIds",
            }}
          />
        </Form.Field>

        <Divider hidden />
        <Form.Field align="right" className="create-controls">
          <Button size="tiny" type="button" onClick={() => onCancel(values)}>
            {intl.formatMessage({
              id: "BTN_CANCEL",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button size="tiny" className="create" type="button" onClick={handleSubmit} disabled={formSending}>
            {submitButtonLabel}
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};

export default DealForm;
