import PixelCreatePage from "../components/pixel/create";
import PixelRoiCreatePage from "../components/pixel/create/roi";
import PixelEditPage from "../components/pixel/edit";
import PixelRoiEditPage from "../components/pixel/edit/roi";
import { PixelsPage } from "../components/pixel/index";

export const Routes = (flags = {}) => {
  const { whitelabelEventPixelCreateButton, whitelabelRoiPixelEnabled } = flags;

  const routes = [
    {
      component: PixelsPage,
      path: "/pixels",
      title: ["Pixels"],
    },
    {
      component: PixelEditPage,
      path: "/pixel/edit/:pixel_id",
      title: ["Pixels", "Edit"],
    },
  ];

  if (whitelabelEventPixelCreateButton) {
    routes.push({
      component: PixelCreatePage,
      path: "/pixel/create",
      title: ["Pixels", "Create"],
    });
  }

  if (whitelabelRoiPixelEnabled) {
    routes.push(
      {
        component: PixelRoiCreatePage,
        path: "/pixel-roi/create",
        title: ["Pixels", "ROI", "Create"],
      },
      {
        component: PixelRoiEditPage,
        path: "/pixel-roi/edit/:pixel_id",
        title: ["Pixels", "ROI", "Edit"],
      },
    );
  }

  return routes;
};
