export default class StrategyCreativesSelected {
	constructor(strategy_id, creative_ids) {
		this.strategy_id = strategy_id || 0;
		this.creative_ids = creative_ids || [];
	}

	/**
	 * Parse data from API into a model instance
	 * @param {number} strategy_id
	 * @param {object} json
	 */
	static fromJson(strategy_id, { creative_ids }) {
		return new StrategyCreativesSelected(strategy_id, creative_ids);
	}

	/**
	 * Parse data from API into a model instance
	 * @param {object} json
	 */
	static fromResponse(strategy_id, {creative_ids}) {
		return new StrategyCreativesSelected(strategy_id, creative_ids.map(node => {
			return node.id
		}));
	}

	/**
	 * Check if model instance is valid
	 * @return {boolean}
	 */
	isValid() {
		return this.creative_ids.length;
	}

	/**
	 * Convert model instance into API format
	 * @return {object}
	 */
	toJson() {
		return {
			"creative_ids": this.creative_ids,
		};
	}
}
