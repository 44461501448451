import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil } from "lodash";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Segment, Tab } from "semantic-ui-react";

import { Config } from "../../config/api";
import { OnlineContext } from "../../context/online-context";
import { useDebounce } from "../../libs/component_utils";
import InventorySuccessMessage from "./InventorySuccessMessage";
import InventoryPageContext, { InventoryInitialContext, InventoryReducer } from "./context";
import Deals from "./deals";
import { loadDeals, loadSiteLists } from "./services";
import SiteLists from "./site-list";

const TAB_URLS = ["/inventory/deals", "/inventory/site-list"];

function InventoryPage() {
  const { whitelabelDeals = false } = useFlags();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const showSiteListSuccessMessage = !isNil(location?.state) && ["site-list", "deals"].includes(location?.state.type);
  const currentTabIndex = TAB_URLS.indexOf(location.pathname);
  const { agency, currentUserIsAdmin } = useContext(OnlineContext);

  // State
  const [currentTab, setCurrentTab] = useState(currentTabIndex !== -1 ? currentTabIndex : 0);
  const [state, dispatch] = useReducer(InventoryReducer, InventoryInitialContext);

  // Tabs
  const TabLoadFns = useMemo(() => {
    if (currentUserIsAdmin && whitelabelDeals) return [loadDeals, loadSiteLists];
    return [loadSiteLists];
  }, [currentUserIsAdmin, whitelabelDeals]);

  const TabPanes = useMemo(() => {
    const tabs = [];

    if (currentUserIsAdmin && whitelabelDeals) {
      tabs.push({
        menuItem: {
          content: formatMessage({
            defaultMessage: "Deals",
            id: "INVENTORY_TAB_DEALS",
          }),
          key: "INVENTORY_TAB_DEALS",
          page: state.deals.pager.page,
          to: TAB_URLS[0],
        },
        render: () => (
          <Segment loading={state.deals.loading} basic style={{ padding: "0" }}>
            <Deals />
          </Segment>
        ),
      });
    }

    tabs.push({
      menuItem: {
        content: formatMessage({
          defaultMessage: "Site Lists",
          id: "INVENTORY_TAB_SITE_LIST",
        }),
        key: "INVENTORY_TAB_SITELIST",
        page: state.siteList.pager.page,
        to: TAB_URLS[1],
      },
      render: () => (
        <Segment loading={state.siteList.loading} basic style={{ padding: "0" }}>
          <SiteLists />
        </Segment>
      ),
    });

    return tabs;
  }, [currentUserIsAdmin, formatMessage, state, whitelabelDeals]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on tab/agency update
  useEffect(() => {
    if (!Number.isNaN(currentTab)) {
      if (agency) {
        const { page } = TabPanes[currentTab].menuItem;
        TabLoadFns[currentTab](state, dispatch, page, agency);
      }
    }
  }, [currentTab, agency]);

  const debouncedLoadSiteList = useDebounce(Config.search_debounce_delay, loadSiteLists);
  const debouncedLoadDeals = useDebounce(Config.search_debounce_delay, loadDeals);

  return (
    <InventoryPageContext.Provider
      value={{
        state,
        dispatch,
        loadSiteLists: debouncedLoadSiteList,
        loadDeals: debouncedLoadDeals,
      }}
    >
      {showSiteListSuccessMessage && <InventorySuccessMessage details={location.state ?? {}} />}
      <h1>
        {formatMessage({
          id: "HEADING_INVENTORY",
          defaultMessage: "Inventory",
        })}
      </h1>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={TabPanes}
        onTabChange={(_e, data) => {
          setCurrentTab(data.activeIndex);
        }}
      />
    </InventoryPageContext.Provider>
  );
}

export default InventoryPage;
