import AbstractService from "../abstract.service";
export default class DealService extends AbstractService {
  static URI_STATES = new Map([
    ["deals.list", "/deals/{0}"],
    ["deals.get", "/deal/{0}"],
    ["deals.put", "/deal/{0}"],
    ["deals.post", "/deal"],
  ]);

  /**
   * Get available audiences
   * @param {number} agencyId
   * @param {object} params
   */
  async list(agencyId, params) {
    return await this.api_server.get(DealService.URI_STATES.get("deals.list").format(agencyId), params);
  }

  /**
   * post new deal
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(DealService.URI_STATES.get("deals.post"), params);
  }

  /**
   * edit deal
   * @param {object} params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(DealService.URI_STATES.get("deals.put").format(id), params);
  }

  /**
   * Get deal by id
   * @param {number} id
   */
  async get(id) {
    return await this.api_server.get(DealService.URI_STATES.get("deals.get").format(id), {});
  }
}
