/* eslint-disable no-unreachable */
export const pixelsActions = {
	"SORTING": "SORTING",
	"FILTERING": "FILTERING",
	"INIT": "INIT",
};

/**
 * Init items
 * @param {array} pixels
 * @param {object} meta
 * @param {number} meta.total_count
 * @param {number} meta.total
 * @param state
 * @return {object} new state
 */
const initItems = (pixels, meta, state) => {
	// rebuild campaign request in according to UI necessity
	state.pager.setTotal(meta);

	return {
		...state,
		pixels,
	};
};

export const pixelGridReducer = (state, action) => {
	switch (action.type) {
		case pixelsActions.INIT:
			return initItems(action.data, action.pager, state);

		default:
			throw new Error("Method is not implemented");
	}
};
