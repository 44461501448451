import subDays from "date-fns/subDays";
import {getApiDate} from "../../../../libs/common_utils";
import {parse} from "date-fns";

export const statuses = (intl) => [
	{
		"text": intl.formatMessage({
			id: "STATUS_ALL",
			defaultMessage: "All",
		}),
		"value": "all",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_ACTIVE",
			defaultMessage: "Active",
		}),
		"value": "active",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_INACTIVE",
			defaultMessage: "Inactive",
		}),
		"value": "inactive",
	},
];

export const date_ranges = (intl) => [
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_YESTERDAY",
			defaultMessage: "Yesterday",
		}),
		"value": "yesterday",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_7DAYS",
			defaultMessage: "Last 7 days",
		}),
		"value": "7days",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_14DAYS",
			defaultMessage: "Last 14 days",
		}),
		"value": "14days",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_30DAYS",
			defaultMessage: "Last 30 days",
		}),
		"value": "30days",
	},
];

export default class Filter
{
	constructor() {
		this.reset();
	}

	/**
	 * update status
	 * @param {string} value
	 */
	setStatus(value) {
		this.strategy_status = value.toLowerCase();
	}

	/**
	 * update reporting period
	 * @param {string} value
	 */
	setReportingPeriod(value) {
		this.reporting_period = value.toLowerCase();
	}

	/**
	 * update start_date
	 * @param {string} value
	 */
	setStartDate(value) {
		this.start_date = value;
	}

	/**
	 * update end_date
	 * @param {string} value
	 */
	setEndDate(value) {
		this.end_date = value;
	}

	/**
	 * update strategy name
	 * @param {string} value
	 */
	setStrategyName(value) {
		this.strategy_name = value;
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.strategy_status = item.strategy_status;
		this.advertiser_id = item.advertiser_id;
		this.reporting_period = item.reporting_period;
		this.strategy_name = item.strategy_name;
		// this.start_date = new Date(Date.parse(item.start_date));
		// this.end_date = new Date(Date.parse(item.end_date));
		// note: its a way to work with timezone dates without losing days
		// this.start_date = parse(item.start_date, "yyyy-MM-dd", new Date());
		// this.end_date = parse(item.end_date, "yyyy-MM-dd", new Date());
		this.start_date = item.start_date;
		this.end_date = item.end_date;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.strategy_status = "all";
		this.reporting_period = "7days";
		this.strategy_name = "";
		this.start_date = subDays(new Date(), 7);
		this.end_date = new Date();
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"strategy_status": this.strategy_status,
			"reporting_period": this.reporting_period,
			"strategy_name": this.strategy_name,
			"start_date": (this.start_date instanceof Date)? getApiDate(this.start_date) : this.start_date,
			"end_date": (this.end_date instanceof Date)? getApiDate(this.end_date) : this.end_date
		}
	}

	/**
	 * transform data for graph api call
	 * @returns {{end_date: string, start_date: string}}
	 */
	toGraphJson() {
		return {
			"start_date": (this.start_date instanceof Date)? getApiDate(this.start_date) : this.start_date,
			"end_date": (this.end_date instanceof Date)? getApiDate(this.end_date) : this.end_date
		}
	}

	/**
	 * transform data into request json
	 * @returns {object}
	 */
	toRequestJson() {
		let r = {};
		if(this.strategy_status !== "all") {
			r["strategy_status"] = this.strategy_status;
		}

		if(this.strategy_name !== "") {
			r["strategy_name"] = this.strategy_name;
		}

		return Object.assign(r, this.toGraphJson());
	}

	/**
	 * check that user didn't touch filter
	 * @return {boolean}
	 */
	isEmpty() {
		return this.strategy_status === "all" && this.strategy_name === "";
	}
}
