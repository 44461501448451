import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";

import AudienceForm from "../form";
import AudiencesService from "../../../services/audiences/index.js";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.audience_id
 * @return {*}
 * @constructor
 */
const AudienceEditPage = ({history, match}) => {
	const back_url = "/audiences";
	const service = new AudiencesService();
	const audience_id = parseInt(match.params.audience_id, 10) || 0;
	let _isMounted = React.useRef(false);
	const intl = useIntl();

	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * handle 404
	 */
	const handle404 = () => {
		history.push(back_url, {"action": "error", "msg": "No audience found"});
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const handleSubmit = async (values, file) => {
		setLoading(true);

		// get object that contain only updated values
		let diff = Object.keys(initialObject.current).reduce((diff, key) => {
			if (values[key] === initialObject.current[key]) return diff;
			return {
				...diff,
				[key]: values[key]
			}
		}, {});

		const form = new FormData();
		Object.keys(diff).forEach((k) => {
			form.append(k, diff[k]);
		});
		if(diff?.status) {
			form.set("status", values.status ? "1" : "0");
		}

		if(file) {
			form.append("segment_file", file);
		}

		try {
			await service.update(audience_id, form);
			history.push(back_url, { "action": "created" });
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	if(audience_id < 1) {
		handle404();
	}

	const [initialData, setInitialData] = useState(null);
	const initialObject = React.useRef(null);

	useEffect(() => {
		_isMounted.current = true;
		(async () => {
			try {
				const r = await service.get(audience_id);
				initialObject.current = {...r.data};
				setInitialData(r.data);
			} catch (e) {
				handle404();
			}
		})();

		return () => {
			_isMounted.current = false;
		}
	}, [audience_id]);

	if (initialData === null) {
		return (<Segment disabled tertiary textAlign="center" className="loading">
			&nbsp;
		</Segment>);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_UPLOAD_DEVICE_ID",
					defaultMessage: "Upload Device ID",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<AudienceForm
				initialData={initialData}
				formType="edit"
				loading={loading}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default AudienceEditPage;
