export default class Pager
{
	static DEFAULT_LIMIT = 20;
	constructor(limit=Pager.DEFAULT_LIMIT) {
		this.page = 1;
		this.limit = limit;
		this.offset = 0;
		this.total_pages = 0;
	}

	/**
	 * Calculate how many pages do we have
	 * @param {object} meta
	 * @param {number} meta.total_count
	 * @param {number} meta.total
	 */
	setTotal(meta) {
		this.total_pages = (meta.total_count)? Math.ceil(meta.total_count / this.limit) : 1;
	}

	/**
	 * Get to the page
	 * @param {number} new_page
	 */
	setPage(new_page) {
		this.page = new_page;
		this.offset = (this.page - 1) * this.limit;
	}

	/**
	 * reset pager to the defaults
	 */
	reset() {
		this.page = 1;
		this.offset = 0;
		this.total_pages = 0;
	}

	/**
	 * Transform data into API object
	 * @returns {{page_limit: number, page_offset: number}}
	 */
	toJson() {
		return {
			"page_limit": this.limit,
			"page_offset": this.offset
		}
	}
}
