/* eslint-disable no-unreachable */
import {RtbTypes} from "../../../../../../models/enum/supply";

export const actions = {
	"INIT_MARKETPLACE": "INIT_MARKETPLACE",
	"INIT_STANDARD": "INIT_STANDARD",
	"SWITCH_ALL": "SWITCH_ALL",
	"CHECK": "CHECK",
	"LOAD_SAVED": "LOAD_SAVED",
	"SEARCH": "SEARCH"
};

const marketMap = new Map();
const standardMap = new Map();

/**
 * Reload both supplies containers
 * @param {array} r
 * @param {object} state
 * @return {object} new state
 */
const reloadBoth = (r, state) => {
	let [market_response, standard_response] = r;
	if(!state.market_loaded && state.selected_supplies?.supply_ids) {
		market_response.forEach(n => {
			if(~state.selected_supplies.supply_ids.indexOf(n.id)) {
				marketMap.set(n.id, true);
			}
		});
	}

	market_response = market_response.map(supply => {
		supply.checked = (marketMap.has(supply.id))? marketMap.get(supply.id) : false;
		return supply;
	});

	if(!state.standard_loaded && state.selected_supplies?.supply_ids) {
		standard_response.forEach(n => {
			if(~state.selected_supplies.supply_ids.indexOf(n.id)) {
				standardMap.set(n.id, true);
			}
		});
	}

	standard_response = standard_response.map(supply => {
		supply.checked = (standardMap.has(supply.id))? standardMap.get(supply.id) : false;
		return supply;
	});

	return {
		...state,
		"market_loaded": true,
		"open_supplies_market": market_response,
		"standard_loaded": true,
		"open_supplies_standard": standard_response
	}
};

/**
 * Init marketplace supplies
 * @param {array} supplies
 * @param {object} state
 * @return {object} new state
 */
const initMarketplace = (supplies, state) => {
	// in case we have already selected items got from API let's handle this for the first run
	if(!state.market_loaded && state.selected_supplies?.supply_ids) {
		supplies.forEach(n => {
			if(~state.selected_supplies.supply_ids.indexOf(n.id)) {
				marketMap.set(n.id, true);
			}
		});
	}

	supplies = supplies.map(supply => {
		supply.checked = (marketMap.has(supply.id))? marketMap.get(supply.id) : false;
		return supply;
	});

	return {
		...state,
		"market_loaded": true,
		"open_supplies_market": supplies
	}
};

/**
 * Init standard supplies
 * @param {array} supplies
 * @param {object} state
 * @return {object} new state
 */
const initStandard = (supplies, state) => {
	// in case we have already selected items got from API let's handle this for the first run
	if(!state.standard_loaded && state.selected_supplies?.supply_ids) {
		supplies.forEach(n => {
			if(~state.selected_supplies.supply_ids.indexOf(n.id)) {
				standardMap.set(n.id, true);
			}
		});
	}

	supplies = supplies.map(supply => {
		supply.checked = (standardMap.has(supply.id))? standardMap.get(supply.id) : false;
		return supply;
	});

	return {
		...state,
		"standard_loaded": true,
		"open_supplies_standard": supplies
	}
};

/**
 * check or uncheck specific supply
 * @param {string} target
 * @param {number} id
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const checkSupply = (target, id, flag, state) => {
	const nodes = (target === RtbTypes.MARKET)? [...state.open_supplies_market] : [...state.open_supplies_standard];
	let n = nodes.find(x => x.id === id);
	if(n) {
		n.checked = flag;
	}

	if(target === RtbTypes.MARKET) {
		if(flag) {
			marketMap.set(n.id, flag);
		} else {
			marketMap.delete(n.id);
		}

		return {
			...state,
			"open_supplies_market": nodes
		}
	}

	if(flag) {
		standardMap.set(n.id, flag);
	} else {
		standardMap.delete(n.id);
	}

	return {
		...state,
		"open_supplies_standard": nodes
	}
};

/**
 * switch checked flag for all entities
 * @param {string} target
 * @param {boolean} flag
 * @param {object} state
 * @return {object} new state
 */
const switchAll = (target, flag, state) => {
	if(target === RtbTypes.MARKET) {
		marketMap.clear();

		return {
			...state,
			"open_supplies_market": state.open_supplies_market.map(n => {
				if(flag) {
					marketMap.set(n.id, flag);
				}

				return {
					...n,
					"checked": flag
				}
			})
		}
	}

	standardMap.clear();
	return {
		...state,
		"open_supplies_standard": state.open_supplies_standard.map(n => {
			if(flag) {
				standardMap.set(n.id, flag);
			}

			return {
				...n,
				"checked": flag
			}
		})
	}
};

/**
 * get supplies ids that been checked
 * @return {Map<any, any>}
 */
export const getCheckedMarket = () => {
	return marketMap;
};

/**
 * get supplies ids that been checked
 * @return {Map<any, any>}
 */
export const getCheckedStandard = () => {
	return standardMap;
};

/**
 * get supplies ids that been checked
 * @return {Map<any, any>}
 */
export const getChecked = () => {
	return new Map([...marketMap, ...standardMap]);
};

/**
 * Clear supplies map
 */
export const clearChecked = () => {
	marketMap.clear();
	standardMap.clear();
};

/**
 * Load data that already saved
 * @param {object} response
 * @param state
 * @return {object} new state
 */
const loadSavedData = (response, state) => {
	// we need an additional handler to load data propery in case something was already loaded
	const market = [...state.open_supplies_market];
	const standard = [...state.open_supplies_standard];
	if(response?.supply_ids) {
		if(state.market_loaded) {
			market.forEach(n => {
				if(~response.supply_ids.indexOf(n.id)) {
					marketMap.set(n.id, true);
					n.checked = true;
				}
			});
		}

		if(state.standard_loaded) {
			standard.forEach(n => {
				if(~response.supply_ids.indexOf(n.id)) {
					standardMap.set(n.id, true);
					n.checked = true;
				}
			});
		}
	}

	return {
		...state,
		"selected_supplies": response,
		"open_supplies_standard": standard,
		"open_supplies_market": market
	}
};

export const Reducer = (state, action) => {
	switch(action.type) {
		case(actions.INIT_MARKETPLACE):
			return initMarketplace(action.data, state);

		case(actions.INIT_STANDARD):
			return initStandard(action.data, state);

		case(actions.LOAD_SAVED):
			return loadSavedData(action.data, state);

		case(actions.SEARCH):
			return reloadBoth(action.data, state);

		case(actions.CHECK):
			return checkSupply(action.target, action.id, action.flag, state);

		case(actions.SWITCH_ALL):
			return switchAll(action.target, action.flag, state);

		default:
			throw new Error("Method is not implement");
	}
};
