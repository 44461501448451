import React, { useCallback, useState, useEffect } from "react";

import {Divider, Header, Message, Segment} from "semantic-ui-react";
import { useIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";
import CreativesService from "../../../services/creatives/index.js";
import CreativeVideoForm from "../form/video";
import CreativeDisplayForm from "../form/display";
import {useFlags} from "launchdarkly-react-client-sdk";

/**
 * Creative edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.creative_id
 * @param {string} creative_type
 * @return {*}
 * @constructor
 */
const CreativeEditPage = ({history, match, creative_type}) => {
	const intl = useIntl();
	const creativesService = new CreativesService();
	const creative_id = parseInt(match.params.creative_id, 10) || 0;
	let _isMounted = React.useRef(false);
	const isVideo = creative_type === "video";
	const back_url = (isVideo)? "/creatives/video" : "/creatives/display";
	const {whitelabelDoubleCreativeApproval} = useFlags();

	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * handle 404
	 */
	const handle404 = () => {
		history.push(back_url, {"action": "error", "msg": "No creative found"});
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const handleSubmit = async params => {
		setLoading(true);
		try {
			let r;
			if(isVideo) {
				r = await creativesService.update_video(creative_id, params);
			} else {
				r = await creativesService.update_display(creative_id, params);
			}

			history.push(back_url, {"action": "updated", "name": r.data?.title || "Unknown"});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setLoading(false);
			}
		}
	};

	if(creative_id < 1) {
		handle404();
	}

	const [initialData, setInitialData] = useState(null);
	useEffect(() => {
		_isMounted.current = true;
		(async () => {
			try {
				let r;
				if(isVideo) {
					r = await creativesService.get_video(creative_id);
				} else {
					r = await creativesService.get_display(creative_id);
				}
				setInitialData(r.data);
			} catch (e) {
				handle404();
			}
		})();

		return () => {
			_isMounted.current = false;
		}
	}, [creative_id]);

	if (initialData === null) {
		return (<Segment disabled tertiary textAlign="center" className="loading">
			&nbsp;
		</Segment>);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{isVideo
					? intl.formatMessage({
							id: "HEADING_EDIT_VIDEO_CREATIVE",
							defaultMessage: "Edit Video Creative",
					  })
					: intl.formatMessage({
							id: "HEADING_EDIT_DISPLAY_CREATIVE",
							defaultMessage: "Edit Display Creative",
					  })}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			{isVideo? <CreativeVideoForm
				loading={loading}
				formType="edit"
				initialData={initialData}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/> : <CreativeDisplayForm
				loading={loading}
				formType="edit"
				initialData={initialData}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				onsite_enabled={whitelabelDoubleCreativeApproval}
			/>}
		</Segment>
	);
};

export default CreativeEditPage;
