import { addDays, addHours, addMonths, isAfter, isBefore, setHours, setMinutes } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Input,
  Message,
  Radio,
  Select,
} from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

import { Config } from "../../../config/api";
import { LangContext } from "../../../context";
import { parseAPIDateTimeWithTimeZone } from "../../../libs/common_utils";
import { useDebounce, useForm } from "../../../libs/component_utils";
import { freq_optimization_enabled_by_goal_type } from "../../../models/campaign";
import Pager from "../../../models/pager";
import AgenciesService from "../../../services/agencies";
import CommonService from "../../../services/common";
import SiteListService from "../../../services/inventory/site-list";
import T1Service from "../../../services/t1";
import DecimalInput from "../../common/decimal_input";
import SitesFilter from "../../inventory/site-list/filter";
import { daily_pacing_options, spend_cap_automatic_options } from "../fixtures";
import FrequencyCapComponent from "../form/frequency-cap";
import GoalTypeComponent from "../form/goal";
import { BudgetFlight, budgetFlightsValidation } from "./budget-flight";

function addFlight(flights, tz) {
	const newFlights = [...flights];
  const today = addHours(new Date(), 1);
  const nextDate = addDays(setMinutes(setHours(today, 0), 0), 1);
  const lastFlightEndDate = addHours(newFlights[newFlights.length - 1].end_date, 1);
	const budgetFlightStart = isAfter(lastFlightEndDate, nextDate) ? lastFlightEndDate : nextDate;

	newFlights.push(
		{
			"start_date": budgetFlightStart,
			"end_date": addMonths(budgetFlightStart, 1),
			"total_impression_budget": null,
			"total_budget": 0,
		}
	)

	return newFlights;
}

/**
 * Campaign create page component
 * @param history
 * @return {*}
 * @constructor
 */
const CampaignForm = ({
	initialData,
	onSubmit,
	onCancel,
	formType = "create",
	submitText = "Create Campaign",
}) => {
	const intl = useIntl();
	const t1Service = new T1Service();
	const agenciesService = new AgenciesService();
	const commonService = new CommonService();
	const siteListService = new SiteListService();
	let _isMounted = React.useRef(false);

	const lang = React.useContext(LangContext);
	const {
		whitelabelSupplyPgFlag,
		whitelabelSupplyPmpFlag,
		whitelabelSupplyAudienceFlag,
		whitelabelSupplyOpenFlag,
		whitelabelShowMargin,
    whitelabelCtvCampaign,
    whitelabelOnSiteCampaign,
	} = useFlags();
  const hasCTV = useMemo(() => whitelabelCtvCampaign ?? false, []);
  const hasOnSiteCampaign = useMemo(() => whitelabelOnSiteCampaign ?? false, []);
	const supplyLabels = {
		"whitelabelSupplyPgFlag": "Programmatic Guaranteed",
		"whitelabelSupplyPmpFlag": "PMP",
		"whitelabelSupplyAudienceFlag": lang["campaign"]["supply_type"],
		"whitelabelSupplyOpenFlag": "Open Supply",
	};

	const isPG = (campaignType) => campaignType.toLowerCase() === "pg";
	const isEdit = Boolean(formType.toLowerCase() === "edit");

	const [serverError, setServerError] = useState("");
	const [formSending, setFormSending] = useState(false);

	/**
	 * Save campaign data
	 * @return {Promise<void>}
	 */
	const addCampaignForm = async () => {
		try {
			setFormSending(true);
			await onSubmit(values);
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setFormSending(false);
			}
		}
	};

	/**
	 * Check if merit_pixel_id field should be present
	 * @param {string} goal_type
	 * @returns {boolean}
	 */
	const shouldHaveMeritPixelId = (goal_type) => {
		return Boolean(["roi", "cpa"].includes(goal_type.toLowerCase()));
	};

	/**
	 * do a custom validation
	 * @param e
	 * @param {object} fields
	 */
	const postValidation = (e, fields) => {
		let errors = {};

		if (!fields.advertiser_id || fields.advertiser_id < 1) {
			errors["advertiser_id"] = "Advertiser is a required field";
			errors.advertiser_id = intl.formatMessage({
				id: "ERROR_EMPTY_ADVERTISER_ID",
				defaultMessage: "Please select an advertiser.",
			});
		}

		if (
			shouldHaveMeritPixelId(fields.goal_type) &&
			(!fields.merit_pixel_id || fields.merit_pixel_id < 1)
		) {
			errors.merit_pixel_id = intl.formatMessage({
				id: "ERROR_EMPTY_MERIT_PIXEL_ID",
				defaultMessage: "Please select a Conversion Pixel.",
			});
		}

		const budgetFlightErrors = budgetFlightsValidation(fields, intl);
		const isBudgetFlightErrors = budgetFlightErrors.filter(e => e).length > 0;
		if(isBudgetFlightErrors) errors["budget_flights"] = budgetFlightErrors;

		return errors;
	};

	const {
		values,
		errors,
    onSwitch,
		updateValues,
		setErrors,
		onChange,
		onSubmit: handleSubmit,
		loadData,
	} = useForm(addCampaignForm, initialData, postValidation);
  const sortedData = initialData.budget_flights.sort((a, b) => a.start_date - b.start_date);
  const earliestDate = sortedData[0].start_date;
	const campaignStarted = isBefore(
		earliestDate,
		parseAPIDateTimeWithTimeZone(new Date(), initialData.time_zone)
	);
	const cannotBeEdited = isEdit && campaignStarted;

	const [advertisers, setAdvertisers] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [timeZones, setTimeZones] = useState([]);
	const [goalTypes, setGoalTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [prevSiteLists, setPrevSiteLists] = useState([]);
	const [siteLists, setSiteLists] = useState([]);
	const [siteListLoad, setSiteListsLoad] = useState(false);

	/**
	 * check that we have all necessary goal types
	 * @param {array} loadedGoals
	 * @param {object} goal
	 */
	const verifyCorrectGoalType = (loadedGoals, goal) => {
		if (!loadedGoals.find((x) => x.value === goal)) {
			setServerError(
				intl.formatMessage({
					id: "ERROR_INVALID_GOAL_TYPE",
					defaultMessage:
						"You have saved a campaign with invalid goal type. This campaign cannot be properly saved",
				})
			);
		}
	};

	useEffect(() => {
		// load list of advertisers
		const agency = agenciesService.getSelectedAgency() || 0;

		(async () => {
			try {
				const [advertisersResp, currenciesResp, timeZonesResp, goalTypesResp] =
					await Promise.all([
						t1Service.advertisers(agency),
						commonService.getCurrencies(),
						commonService.getTimeZones(),
						commonService.getGoalTypes()
					]);

				setAdvertisers(
					advertisersResp.data.map(({ id, title }) => ({
						"key": id,
						"text": title,
						"value": id,
					}))
				);
				setCurrencies(
					currenciesResp.data.map(({ id, title, currency_code, symbol }) => ({
						"key": id,
						"text": `${title} (${currency_code})`,
						"value": currency_code,
						"symbol": symbol,
					}))
				);
				setTimeZones(
					timeZonesResp.data.map(({ id, title, value }) => ({
						"key": id,
						"text": title,
						"value": value,
					}))
				);
				setGoalTypes(
					goalTypesResp.data.map((item) => ({
						...item,
						"key": item.id,
						"text": item.title,
					}))
				);

				verifyCorrectGoalType(goalTypesResp.data, values.goal_type);
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		})();

		_isMounted.current = true;
		// clear cache
		return () => {
			_isMounted.current = false;
		};
	}, []);

	const requestSiteLists = (filters, successCallback, errorCallback) => {
		const filter = new SitesFilter();
		filter.fromJson(filters);
		const pager = new Pager();
		pager.reset();

		siteListService
			.list({...filter.toRequestJson(), ...pager.toJson()})
			.then((response) => {
				const data = response.data.map((item) => ({
					"key": `${item.id} - ${item.title}`,
					"text": `${item.id} - ${item.title}`,
					"value": item.id
				}));
				successCallback(data);
			})
			.catch(() => {
				errorCallback();
			})
	}

	const loadSiteListOptions = useDebounce(Config.search_debounce_delay, (query) => {
		setSiteListsLoad(true);

		requestSiteLists(
			{ title: query, status: "active", id: "" },
			(data) => {
				setSiteLists(data);
				setSiteListsLoad(false);
			},
			() => {
				setSiteLists([]);
				setSiteListsLoad(false);
			}
		)
	});

	useEffect(() => loadSiteListOptions(""), []);
	useEffect(() => {
		const id = values.site_list_ids[0];
		if(!id) return;

		requestSiteLists(
			{ title: "", status: "all", id: id },
			(data) => setPrevSiteLists(data),
			() => setPrevSiteLists(data)
		)
	}, [values.site_list_ids[0]])

	// effect, to control correct state of frequency optimized
	useEffect(() => {
		if (
			!freq_optimization_enabled_by_goal_type(values.goal_type) &&
			values.frequency_optimization === 1
		) {
			loadData({
				...values,
				"frequency_optimization": 0,
			});
		}
	}, [values.goal_type]);

	useEffect(() => {
		if (!isEdit && values.advertiser_id) {
			updateValues({ "merit_pixel_id": null });
		}
	}, [values.advertiser_id]);

	useEffect(() => {
		if(!whitelabelShowMargin && values.hasOwnProperty("margin_pct")) {
			updateValues({ "margin_pct": null });
		}
	}, [])

	/**
	 * handle start date change
	 * @param {Date} date
	 */
	const handleStartDateChange = (date) => {
		onChange(null, { "name": "start_date", "value": date });
	};

	/**
	 * Find advertiser by id
	 * @param {array} advertisers
	 * @param {number} selected_id
	 * @return {string}
	 */
	const getAdvertiserName = (advertisers, selected_id) => {
		return advertisers.find((a) => a.value === selected_id)?.text || "-";
	};

	/**
	 * Find timezone by id
	 * @param {array} zones
	 * @param {string} selected_id
	 * @return {string}
	 */
	const getTimeZoneName = (zones, selected_id) => {
		if (isLoading) {
			return "-";
		}
		return (
			zones.find((a) => a.value.toLowerCase() === selected_id.toLowerCase())
				?.text || selected_id
		);
	};

	/**
	 * Find currency by id
	 * @param {array} currencies
	 * @param {string} selected_id
	 * @return {string}
	 */
	const getCurrency = (currencies, selected_id) => {
		if (isLoading) {
			return "-";
		}
		return (
			currencies.find(
				(a) => a.value.toLowerCase() === selected_id.toLowerCase()
			)?.text || selected_id
		);
	};

	// second supply flags are disabled
	const secondSupplyFLagsAreDisabled =
		!whitelabelSupplyPmpFlag &&
		!whitelabelSupplyAudienceFlag &&
		!whitelabelSupplyOpenFlag;

	const generateCampaignTypeDropdown = () => {
		/**
		 * generate supply text based on LD flags
		 * @return {string}
		 */
		const getOptionText = () => {
			const option = [];
			if (whitelabelSupplyPmpFlag) {
				option.push(supplyLabels["whitelabelSupplyPmpFlag"]);
			}

			if (whitelabelSupplyAudienceFlag) {
				option.push(supplyLabels["whitelabelSupplyAudienceFlag"]);
			}

			if (whitelabelSupplyOpenFlag) {
				option.push(supplyLabels["whitelabelSupplyOpenFlag"]);
			}

			return option.join(" / ");
		};

		if (!secondSupplyFLagsAreDisabled && whitelabelSupplyPgFlag) {
			const options = [{ "text": "Programmatic Guaranteed", "value": "PG" }];
			options.push({
				"text": getOptionText(),
				"value": `${Config.strategy_supply_type.toUpperCase()}/PMP/OPEN`,
			});

			return (
				<Select
					selection
					options={options}
					value={values.campaign_type}
					name="campaign_type"
					onChange={onChange}
					className="input-field-wider"
				/>
			);
		} else {
			if (whitelabelSupplyPgFlag) {
				return "Programmatic Guaranteed";
			} else {
				return getOptionText();
			}
		}
	};

	const PG = isPG(values.campaign_type);

	const siteListOptions = useMemo(() => {
		return [...prevSiteLists, ...siteLists].reduce((obj, item) => {
			obj[item.value] = item;
			return obj;
		}, {});
	}, [prevSiteLists, siteLists]);

  const selectedCurrencySymbol = useMemo(() => currencies.find((x) => x.value === values.currency)?.symbol, [currencies, values.currency]);
	const selectedSiteListId = values.site_list_ids[0];

	return (
		<>
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>

			{
				Config.public_client === "walmart" && <div style={{position: "absolute", right: 0, width: "35%"}}>
					<Message info>
						<p><span style={{"fontWeight": "bolder"}}>IMPORTANTE:</span> Todas tus campañas en Walmart DSP contemplan incluidos los siguientes costos:</p>
						<ul>
							<li>Tech Fee Estándar (12%) incluyendo: Fee Plataforma/Fee Optimización/PMP estándar fee/PMP optimizado fee/PG fee/Fee identificación</li>
							<li>Fee Audiencia1st Party (25%)</li>
							<li>Fee Ventas Atribuidas (ROAS) (5%)</li>
						</ul>
					</Message>
				</div>
			}
			<Form
				onSubmit={handleSubmit}
				size="small"
				loading={isLoading || formSending}
				noValidate
				error={Boolean(Object.keys(errors).length)}
				autoComplete="off"
			>
				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Radio
						name="status"
						label={intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
						})}
						value={1}
						checked={Boolean(values.status)}
						onChange={onChange}
					/>
					<Radio
						style={{ "marginLeft": "15px" }}
						name="status"
						label={intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
						})}
						value={0}
						checked={!Boolean(values.status)}
						onChange={onChange}
					/>
				</Form.Field>

				<Form.Field
					required
					inline
					error={errors.hasOwnProperty("campaign_name")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_CAMPAIGN_NAME",
							defaultMessage: "Campaign Name",
						})}
					</label>
					<Input
						name="campaign_name"
						required
						maxLength="256"
						defaultValue={values.campaign_name}
						onBlur={onChange}
						className="input-field-wider"
					/>
					<div className="custom-error">{errors["campaign_name"]}</div>
				</Form.Field>

				<Form.Field
					required={!isEdit}
					inline
					error={errors.hasOwnProperty("campaign_type")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_CAMPAIGN_TYPE",
							defaultMessage: "Campaign Type",
						})}
					</label>
					{isEdit ? (PG ? "Programmatic Guanranteed" : values.campaign_type ) : generateCampaignTypeDropdown()}
					<div className="custom-error">{errors["campaign_type"]}</div>
				</Form.Field>

				{(hasCTV || hasOnSiteCampaign) && (
        <Grid columns={2} divided style={{ width: "34%", marginBottom: 10 }}>
          <GridRow>
          {hasCTV &&<GridColumn>
            <Form.Field
                inline
              >
                <label>
                  {intl.formatMessage({
                    id: "LABEL_CAMPAIGN_CTV",
                    defaultMessage: "CTV Campaign",
                  })}
                </label>
                  <Checkbox
                    name="ctv"
                    value={1}
                    checked={Boolean(values.ctv)}
                    disabled={cannotBeEdited}
                    onClick={(e, input) => { if(!cannotBeEdited) {
                      onSwitch(e, input);
                      updateValues({ "onsite": 0 });
                    }}}
                  />
              </Form.Field>
            </GridColumn>}
            {hasOnSiteCampaign && <GridColumn style={{ boxShadow: "none" }}>
              <Form.Field
                  inline
                >
                  <label>
                    {intl.formatMessage({
                      id: "LABEL_CAMPAIGN_ONSITE",
                      defaultMessage: "On-site Campaign",
                    })}
                  </label>
                    <Checkbox
                      name="onsite"
                      value={1}
                      checked={Boolean(values.onsite)}
                      disabled={cannotBeEdited}
                      onClick={(e, input) => { if(!cannotBeEdited) {
                        onSwitch(e, input);
                        updateValues({ "ctv": 0 });
                      }}}
                    />
                </Form.Field>
            </GridColumn>}
          </GridRow>
        </Grid>
        )}

				<Form.Field
					required={!isEdit}
					inline
					error={errors.hasOwnProperty("advertiser_id")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_ADVERTISER",
							defaultMessage: "Advertiser",
						})}
					</label>
					{isEdit ? (
						getAdvertiserName(advertisers, values.advertiser_id)
					) : (
						<Dropdown
							search
							selection
							placeholder={intl.formatMessage({
								id: "HINT_ADVERTISER",
								defaultMessage: "Select advertiser",
							})}
							options={advertisers}
							name="advertiser_id"
							value={values.advertiser_id}
							onChange={onChange}
						/>
					)}
					<div className="custom-error">{errors["advertiser_id"]}</div>
				</Form.Field>

				<Form.Field
					required={!cannotBeEdited}
					inline
					error={errors.hasOwnProperty("time_zone")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_TIME_ZONE",
							defaultMessage: "Time Zone",
						})}
					</label>
					{cannotBeEdited ? (
						getTimeZoneName(timeZones, values.time_zone)
					) : (
						<Select
							selection
							options={timeZones}
							loading={isLoading}
							disabled={formType.toLowerCase() === "edit" && campaignStarted}
							value={values.time_zone}
							name="time_zone"
							onChange={onChange}
							className="input-field-wider"
						/>
					)}
					<div className="custom-error">{errors["time_zone"]}</div>
				</Form.Field>

				<Form.Field inline required={!cannotBeEdited}>
					<label style={{ "margin": "0 .85714286em 0 0" }}>
						{intl.formatMessage({
							id: "LABEL_CURRENCY",
							defaultMessage: "Currency",
						})}
					</label>
					{cannotBeEdited ? (
						getCurrency(currencies, values.currency)
					) : (
						<Select
							selection
							value={values.currency}
							options={currencies}
							loading={isLoading}
							onChange={onChange}
							name="currency"
						/>
					)}
				</Form.Field>

				<h4 className="ui header">
					{intl.formatMessage({
						id: "LABEL_BUDGET_FLIGHTS",
						defaultMessage: "Budget Flights",
					})}
				</h4>
				<Divider />
				{
					values["budget_flights"].map((_f, index) => (
						<BudgetFlight
							key={index}
							index={index}
							intl={intl}
							values={values}
							errors={errors}
							setErrors={setErrors}
							updateValues={updateValues}
							isPG={PG}
              currency={selectedCurrencySymbol}
						/>
					))
				}
				<Divider />
				{
					!PG && <Form.Field align="right">
						<a
							className="ui button tiny secondary"
							onClick={() => {
								const newFlights = addFlight(values["budget_flights"], values["time_zone"]);
								updateValues({ ...values, "budget_flights": newFlights });
							}}
						>
							{intl.formatMessage({
								id: "LABEL_ADD_FLIGHT",
								defaultMessage: "Add Flight",
							})}
							<Icon style={{ marginLeft: "6px" }} className="plus circle" />
						</a>
					</Form.Field>
				}
				<GoalTypeComponent
					goalTypes={goalTypes}
					loading={isLoading}
					disabled={cannotBeEdited}
					onChange={onChange}
					values={values}
					errors={errors}
					currency={currencies.find((x) => x.value === values.currency)?.symbol}
				/>
				{!PG && (
					<NonPGFieldsComponent
						currencies={currencies}
						onChange={onChange}
						errors={errors}
						values={values}
					/>
				)}
				{
					whitelabelShowMargin && <Form.Field inline error={errors.hasOwnProperty("margin_pct")} required>
						<label>
							{intl.formatMessage({
								id: "LABEL_MARGIN",
								defaultMessage: "Margin",
							})}
						</label>
						<DecimalInput
							name="margin_pct"
							required
							type="number"
							min={0}
							max={1000}
							step={0.0001}
							places={4}
							defaultValue={values.margin_pct}
							label="%"
							onBlur={onChange}
						/>
						<div className="custom-error">{errors["margin_pct"]}</div>
					</Form.Field>
				}
				{
					!PG && <Form.Group inline widths={1}>
						<Form.Field>
							<label>
								{intl.formatMessage({
									id: "LABEL_SITE_LISTS",
									defaultMessage: "Site List",
								})}
							</label>
							{
								values.site_list_ids.length <= 1 && <Dropdown
									search
									selection
									clearable
									loading={siteListLoad}
									options={Object.values(siteListOptions)}
									value={selectedSiteListId || ""}
									placeholder={intl.formatMessage({
										id: "PLACEHOLDER_SITE_LISTS",
										defaultMessage: "Search Site List",
									})}
									onSearchChange={(e, data) => loadSiteListOptions(data.searchQuery)}
									selectOnNavigation={false}
									selectOnBlur={false}
									onChange={(e, data) => {
										const list = data.value && data.value !== "" ? [data.value] : [];
										updateValues({ site_list_ids: list }) }
									}
								/>
							}
							{
								values.site_list_ids.length > 1 && <div style={{float: "right"}}>
									<Message negative>
										{intl.formatMessage({
											id: "SITE_LIST_CAMPAIGN_SELECTION_NOTICE",
											defaultMessage: "Multiple Site Lists not supported",
										})}
									</Message>
								</div>
							}
						</Form.Field>
					</Form.Group>
				}
				<Divider hidden />
				<Divider hidden />
				<Divider hidden />
				<Form.Field align="right" className="create-controls">
					<Button size="tiny" type="button" onClick={() => onCancel(values)}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" className="create" type="submit">
						{submitText}
					</Button>
				</Form.Field>
			</Form>
		</>
	);
};

const NonPGFieldsComponent = ({ currencies, onChange, errors, values }) => {
	const intl = useIntl();
  const { whitelabelCampaignBidOnCrossDevice = false } = useFlags();
  const alwaysBidOnCrossDevice = useMemo(() => whitelabelCampaignBidOnCrossDevice ?? false, []);

	return (
		<>
			<Form.Group inline>
				<Form.Field required>
					<label>
						{intl.formatMessage({
							id: "LABEL_DAILY_PACING",
							defaultMessage: "Daily Pacing",
						})}
					</label>
				</Form.Field>
				<Form.Field>
					<Select
						fluid
						name="spend_cap_type"
						onChange={onChange}
						options={daily_pacing_options(intl)}
						value={values.spend_cap_type}
					/>
				</Form.Field>
				<Form.Field disabled={values.spend_cap_type === "no-limit"}>
					<Select
						name="spend_cap_automatic"
						options={spend_cap_automatic_options(intl)}
						onChange={onChange}
						value={values.spend_cap_automatic}
					/>
				</Form.Field>
				<Form.Field
					error={errors.hasOwnProperty("spend_cap_amount")}
					disabled={
						values.spend_cap_type === "no-limit" ||
						Boolean(values.spend_cap_automatic)
					}
				>
					<DecimalInput
						name="spend_cap_amount"
						type="number"
						min={1}
						max={9999999.99}
						step={0.01}
						places={2}
						required={
							!Boolean(values.spend_cap_automatic) &&
							values.spend_cap_type !== "no-limit"
						}
						autoComplete="off"
						defaultValue={values.spend_cap_amount}
						onBlur={onChange}
						label={
							!currencies.length
								? "X"
								: currencies.find((x) => x.value === values.currency).symbol
						}
					/>{" "}
					{intl.formatMessage({
						id: "LABEL_PER_DAY",
						defaultMessage: "per Day",
					})}
					<div className="custom-error" style={{ "marginLeft": "0" }}>
						{errors["spend_cap_amount"]}
					</div>
				</Form.Field>
			</Form.Group>

			<FrequencyCapComponent
				onChange={onChange}
				values={values}
				errors={errors}
			/>

			{!alwaysBidOnCrossDevice && <Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_BID_ON_CROSS_DEVICE",
						defaultMessage: "Bid on Cross Device",
					})}
				</label>
				<Radio
					name="restrict_targeting_to_same_device_id"
					label={intl.formatMessage({
						id: "LABEL_ACTIVE",
						defaultMessage: "Active",
					})}
					value={0}
					checked={!Boolean(values.restrict_targeting_to_same_device_id)}
					onChange={onChange}
				/>
				<Radio
					style={{ "marginLeft": "15px" }}
					name="restrict_targeting_to_same_device_id"
					label={intl.formatMessage({
						id: "LABEL_INACTIVE",
						defaultMessage: "Inactive",
					})}
					value={1}
					checked={Boolean(values.restrict_targeting_to_same_device_id)}
					onChange={onChange}
				/>
			</Form.Field>}
		</>
	);
};
NonPGFieldsComponent.propTypes = {
	"currencies": PropTypes.array.isRequired,
	"values": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired,
	"errors": PropTypes.object.isRequired,
};

export default CampaignForm;
