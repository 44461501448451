import React, {useCallback, useState} from "react";

import {Divider, Header, Message, Segment} from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import CreativeDisplayForm from "../form/display";
import CreativeVideoForm from "../form/video";
import CreativesService from "../../../services/creatives";
import {default_button_text} from "../fixtures";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useIntl} from "react-intl";


let initialData = {
	"status": 1,
	"title": "",
	"advertiser_id": null,
	"clickthrough_url": "",
	"landing_page_url": "",
};

/**
 * Creative create page component
 * @param history
 * @param {string} creative_type
 * @return {JSX.Element}
 * @constructor
 */
const CreativeCreatePage = ({history, creative_type}) => {
	const intl = useIntl();
	const service = new CreativesService();
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);
	let _isMounted = React.useRef(true);
	const isVideo = creative_type === "video";
	const {whitelabel1PDisplayCreative, whitelabel3PDisplayCreative, whitelabelDoubleCreativeApproval} = useFlags();
	const assetRestriction = !(whitelabel1PDisplayCreative && whitelabel3PDisplayCreative);
	let back_url = (isVideo)? "/creatives/video" : "/creatives/display";

	if(isVideo) {
		initialData = Object.assign(initialData, {
			"third_party": 0,
			"companion_ad": 0,
			"brand_name": "",
			"ad_title": "",
			"logo_url": "",
			"button_color": "#f2711c",
			"button_text_type": "standard",
			"button_text": default_button_text(intl)[0].value,
			"deep_link": "",
			"custom_vast_url": "",
			"external_identifier": ""
		});
	} else {
		initialData = Object.assign(initialData, {
			"third_party": (!assetRestriction || whitelabel1PDisplayCreative)? 0 : 1,
			"mraid": 0,
			"mraid_type": "",
			"tag": "",
			"ad_server": "",
			"tpas_tag_id": "",
			"tag_type": "",
			"ad_format": "",
			"is_expandable": 0,
			"expansion_trigger": "",
			"expansion_direction": "",
			"dimension": "standard",
			"dimensions": "",
			"file_type": "",
			"creative_type": whitelabelDoubleCreativeApproval ? "on_site" : "off_site",
			"end_date": ""
		});
	}

	React.useEffect(() => {
		return () => {
			_isMounted.current = false;
		}
	}, []);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * Verify that onsite creative we make
	 * @param {object} values
	 * @returns {boolean}
	 */
	const isOnsiteClicked = values => {
		return whitelabelDoubleCreativeApproval && values.hasOwnProperty("end_date");
	}

	/**
	 * form submit handler
	 * @param {object} values
	 * @param {File} file
	 */
	const handleSubmit = async (values, file) => {
		let back_url = "/creatives/video";
		setLoading(true);
		const form = new FormData();
		Object.keys(values).forEach(k => {
			form.append(k, values[k]);
		});

		if(file) {
			form.append("creative_file", file);
		}

		try {
			if(isVideo) {
				await service.create_video(form);
			} else {
				back_url = "/creatives/display";
				if(isOnsiteClicked(values)) {
					await service.create_local(form);
					back_url = "/creatives/approve";
				} else {
					await service.create_display(form);
				}
			}

			history.push(back_url, {"action": "created"});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setLoading(false);
			}
		}
	};

	return (
		<Segment basic>
			<Header as="h2">
				{isVideo
					? intl.formatMessage({
							id: "HEADING_CREATE_VIDEO_CREATIVE",
							defaultMessage: "Create Video Creative",
					  })
					: intl.formatMessage({
							id: "HEADING_CREATE_DISPLAY_CREATIVE",
							defaultMessage: "Create Display Creative",
					  })}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			{isVideo? <CreativeVideoForm
					loading={loading}
					initialData={initialData}
					submitText={intl.formatMessage({
						id: "BTN_CREATE_CREATIVE",
						defaultMessage: "Create Creative",
					})}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
				/> : <CreativeDisplayForm
					loading={loading}
					initialData={initialData}
					submitText={intl.formatMessage({
						id: "BTN_CREATE_CREATIVE",
						defaultMessage: "Create Creative",
					})}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					assetRestriction={assetRestriction}
					onsite_enabled={whitelabelDoubleCreativeApproval}
				/>}
		</Segment>
	);
};

export default CreativeCreatePage;
