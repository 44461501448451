export const Common = {
	"asList": function() {
		return Object.keys(this).filter(x => typeof this[x] !== "function").map(x => this[x]);
	},
	"assign": function(value) {
		try {
			if(!~this.asList().indexOf(value)) {
				throw Error(`${value} is not a valid member`);
			}
		} catch(e) {
			throw e;
		}

		return value;
	}
};