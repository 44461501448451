import { Container, Segment } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../libs/common_utils";
import HeaderMenu from "../header.menu";

const Body = ({ children, title }) => {
  if (title) {
    document.title = [capitalizeFirstLetter(process.env.REACT_APP_TITLE), ...title].join(" :: ");
  }

  return (
    <>
      <HeaderMenu {...children.props} />
      <Container fluid>
        <Segment clearing basic>
          {children}
        </Segment>
      </Container>
    </>
  );
};

export default Body;
