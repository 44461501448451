export default class Filter
{
	constructor(default_type) {
		this.default_type = default_type;
		this.reset();
	}

	/**
	 * update filter search query
	 * @param {string} value
	 */
	setQuery(value) {
		this.filter_query = value;
	}

	/**
	 * update filter type name
	 * @param {string} value
	 */
	setType(value) {
		this.filter_type = value;
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.filter_type = item.filter_type;
		this.filter_query = item.filter_query;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.filter_type = this.default_type;
		this.filter_query = "";
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"filter_type": this.filter_type,
			"filter_query": this.filter_query
		}
	}
}
