import React, { useCallback, useState, useEffect } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";
import MarginForm from "../form";
import MarginsService from "../../../services/margins";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.margin_id
 * @return {*}
 * @constructor
 */
const MarginEditPage = ({ history, match }) => {
	const back_url = "/admin/margins";

	const intl = useIntl();
	const service = new MarginsService();
	const margin_id = parseInt(match.params.margin_id, 10) || 0;
	let _isMounted = React.useRef(false);

	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * handle 404
	 */
	const handle404 = () => {
		history.push(back_url, { "action": "error", "msg": "No audience found" });
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const handleSubmit = async (values) => {
		setLoading(true);

		const data = {
			...values,
			"additional_margin_pct": parseFloat(values["additional_margin_pct"] || 0),
		};

		try {
			await service.update(margin_id, data);
			history.push(back_url, {
				"action": "updated",
				"agency_name": initialData.agency_name,
			});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	if (margin_id < 1) {
		handle404();
	}

	const [initialData, setInitialData] = useState(null);

	useEffect(() => {
		_isMounted.current = true;
		(async () => {
			try {
				const r = await service.get(margin_id);
				setInitialData({
					...r.data,
					"additional_margin_pct": r.data["additional_margin_pct"].toFixed(4),
				});
			} catch (e) {
				console.error(e);
				handle404();
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, [margin_id]);

	if (initialData === null) {
		return (
			<Segment disabled tertiary textAlign="center" className="loading">
				&nbsp;
			</Segment>
		);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_EDIT_MARGIN",
					defaultMessage: "Update Margin Control",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				info
				size="tiny"
				content={intl.formatMessage({
					id: "BODY_EDIT_MARGIN",
					defaultMessage: "New Additional Margin % will only apply to new campaigns or when re-saving existing campaigns.",
				})}
			/>
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<MarginForm
				initialData={initialData}
				formType="edit"
				loading={loading}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default MarginEditPage;
