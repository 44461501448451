import { addDays, addHours } from "date-fns";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Divider, Header, Segment } from "semantic-ui-react";

import DealForm from "./DealForm";

const parentUrl = "/inventory/deals";
const todayPlusOneHour = addHours(new Date(), 1);
const initialData = {
  status: true,
  dealName: "",
  dealDescription: "",
  dealId: "",
  dealType: "PMP",
  startDate: todayPlusOneHour,
  endDate: addDays(todayPlusOneHour, 1),
  neverEnds: false,
  priceType: "FIXED",
  dealPrice: "",
  currency: "",
  exchange: "",
  publisher: null,
  channel: [],
  agencyIds: [],
  advertiserIds: [],
};

const DealsCreatePage = () => {
  const intl = useIntl();
  const history = useHistory();

  const handleFormSubmit = useCallback(() => {
    history.push(parentUrl, { action: "created", type: "deals" });
  }, [history]);

  const handleFormCancel = useCallback(() => {
    history.push(parentUrl);
  }, [history]);

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_CREATE_DEAL",
          defaultMessage: "Create Deal",
        })}
      </Header>
      <Divider />
      <DealForm
        initialData={initialData}
        submitButtonLabel={intl.formatMessage({
          id: "HEADING_CREATE_DEAL",
          defaultMessage: "Create Deal",
        })}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </Segment>
  );
};

export default DealsCreatePage;
