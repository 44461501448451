import subDays from "date-fns/subDays";
import {format} from "date-fns";
import {getApiDate} from "../../../../libs/common_utils";

export const campaign_statuses = (intl) => [
	{
		"text": intl.formatMessage({
			id: "STATUS_ALL",
			defaultMessage: "All",
		}),
		"value": "all",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_ACTIVE",
			defaultMessage: "Active",
		}),
		"value": "active",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_INACTIVE",
			defaultMessage: "Inactive",
		}),
		"value": "inactive",
	},
];

export const date_ranges = (intl) => [
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_YESTERDAY",
			defaultMessage: "Yesterday",
		}),
		"value": "yesterday",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_7DAYS",
			defaultMessage: "Last 7 days",
		}),
		"value": "7days",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_14DAYS",
			defaultMessage: "Last 14 days",
		}),
		"value": "14days",
	},
	{
		"text": intl.formatMessage({
			id: "DATE_RANGE_30DAYS",
			defaultMessage: "Last 30 days",
		}),
		"value": "30days",
	},
];

export default class Filter
{
	constructor() {
		this.reset();
	}

	/**
	 * update campaign status
	 * @param {string} value
	 */
	setStatus(value) {
		this.campaign_status = value.toLowerCase();
	}

	/**
	 * update reporting period
	 * @param {string} value
	 */
	setReportingPeriod(value) {
		this.reporting_period = value.toLowerCase();
	}

	/**
	 * update start_date
	 * @param {string} value
	 */
	setStartDate(value) {
		this.start_date = value;
	}

	/**
	 * update end_date
	 * @param {string} value
	 */
	setEndDate(value) {
		this.end_date = value;
	}

	/**
	 * update advertiser id
	 * @param {string} value
	 */
	setAdvertiser(value) {
		this.advertiser_id = parseInt(value, 10);
	}

	/**
	 * update campaign name
	 * @param {string} value
	 */
	setCampaignName(value) {
		this.campaign_name = value;
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.campaign_name = item.campaign_name;
		this.campaign_status = item.campaign_status;
		this.advertiser_id = item.advertiser_id;
		this.reporting_period = item.reporting_period;
		this.start_date = item.start_date;
		this.end_date = item.end_date;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.campaign_status = "all";
		this.campaign_name = "";
		this.advertiser_id = 0;
		this.reporting_period = "7days";
		this.start_date = subDays(new Date(), 7);
		this.end_date = new Date();
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"campaign_name": this.campaign_name,
			"campaign_status": this.campaign_status,
			"advertiser_id": this.advertiser_id,
			"reporting_period": this.reporting_period,
			"start_date": (this.start_date instanceof Date)? getApiDate(this.start_date) : this.start_date,
			"end_date": (this.end_date instanceof Date)? getApiDate(this.end_date) : this.end_date
		}
	}

	/**
	 * transform data for graph api call
	 * @returns {{end_date: string, start_date: string}}
	 */
	toGraphJson() {
		return {
			"start_date": (this.start_date instanceof Date)? getApiDate(this.start_date) : this.start_date,
			"end_date": (this.end_date instanceof Date)? getApiDate(this.end_date) : this.end_date
		}
	}

	/**
	 * transform data into request json
	 * @returns {object}
	 */
	toRequestJson() {
		let r = {};
		if(this.campaign_status !== "all") {
			r["campaign_status"] = this.campaign_status;
		}

		if(this.advertiser_id > 0) {
			r["advertiser_id"] = this.advertiser_id;
		}

		if(this.campaign_name !== "") {
			r["campaign_name"] = this.campaign_name;
		}

		return Object.assign(r, this.toGraphJson());
	}

	/**
	 * check that user didn't touch filter
	 * @return {boolean}
	 */
	isEmpty() {
		return this.campaign_status === "all" && this.advertiser_id === 0 && this.campaign_name === "";
	}
}
