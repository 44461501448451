import React from "react";
import PropTypes from "prop-types";
import {Form, Input, TextArea} from "semantic-ui-react";
import {useIntl} from "react-intl";

const VideoCreativeThirdPartyComponent = ({values, errors, onChange}) => {
  const intl = useIntl();
  return <>
    <Form.Field inline error={errors.hasOwnProperty("custom_vast_url")} required>
      <label>
        {intl.formatMessage({
          id: "LABEL_VAST_URL",
          defaultMessage: "VAST URL",
        })}
      </label>
      <div style={{"display": "inline-block"}}>
        <TextArea
          required
          style={{"width": "650px"}}
          placeholder={intl.formatMessage({
            id: "PLACEHOLDER_INSERT_VAST_URL",
            defaultMessage: "Insert VAST URL here",
          })}
          name="custom_vast_url"
          rows="5"
          defaultValue={values.custom_vast_url}
          onBlur={onChange}
        />
      </div>
      <div className="custom-error">{errors["custom_vast_url"]}</div>
    </Form.Field>
    <Form.Field
      inline
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_3PAS_TAG_ID",
          defaultMessage: "3PAS TAG ID",
        })}
      </label>
      <Input
        name="external_identifier"
        defaultValue={values.external_identifier}
        placeholder={intl.formatMessage({
          id: "PLACEHOLDER_3PAS_TAG_ID_VIDEO_CREATIVE",
          defaultMessage: "Placement id for the tag in 3rd party server...",
        })}
        style={{"width": "300px"}}
        maxLength="64"
        onBlur={onChange}
      />
    </Form.Field>
    <Form.Field
      inline
      error={errors.hasOwnProperty("landing_page_url")}
      required
    >
      <label>
        {intl.formatMessage({
          id: "LABEL_LANDING_PAGE_URL",
          defaultMessage: "Landing Page URL",
        })}
      </label>
      <Input
        type="url"
        pattern="https?://.*\..*"
        title={intl.formatMessage({
          id: "ERROR_URL_PATTERN",
          defaultMessage: "must be a valid non-local HTTP URL",
        })}
        required
        name="landing_page_url"
        defaultValue={values.landing_page_url}
        placeholder="http://www.example.com/"
        style={{"width": "300px"}}
        onBlur={onChange}
      />
      <div className="custom-error">{errors["landing_page_url"]}</div>
    </Form.Field>
  </>
}
VideoCreativeThirdPartyComponent.propTypes = {
  "values": PropTypes.shape({
    "custom_vast_url": PropTypes.string.isRequired,
    "landing_page_url": PropTypes.string.isRequired,
    "external_identifier": PropTypes.string
  }).isRequired,
  "errors": PropTypes.object.isRequired,
  "onChange": PropTypes.func.isRequired
};

export default VideoCreativeThirdPartyComponent;
