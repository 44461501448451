import {Common} from "./index";

export const Intervals = Object.freeze({
	"HOUR": "hour",
	"DAY": "day",
	...Common
});

export const Types = Object.freeze({"ASAP": "asap",
	"EVEN": "even",
	...Common
});
