import React from "react";
import {Button, Modal} from "semantic-ui-react";
import { useIntl } from "react-intl";

/**
 * Navigation blocker component
 * @constructor
 * @return {JSX.Element}
 */
export const NavigationBlockerModal = ({unblock, close}) => {
	const intl = useIntl();
	return <Modal open dimmer="blurring" size="mini">
		<Modal.Header>
			{intl.formatMessage({
				id: "HEADING_UNSAVED_CHANGES",
				defaultMessage: "Unsaved Changes",
			})}
		</Modal.Header>
		<Modal.Content>
			{intl.formatMessage({
				id: "BODY_UNSAVED_CHANGES",
				defaultMessage: "Proceeding will result in losing all unsaved changes, do you want to continue?",
			})}
		</Modal.Content>
		<Modal.Actions>
			<Button size="mini" onClick={close}>
				{intl.formatMessage({
					id: "BTN_CANCEL",
					defaultMessage: "Cancel",
				})}
			</Button>
			<Button size="mini" primary onClick={unblock}>
				{intl.formatMessage({
					id: "BTN_OK",
					defaultMessage: "OK",
				})}
			</Button>
		</Modal.Actions>
	</Modal>;
};
