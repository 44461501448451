import { Helmet } from "react-helmet";

const Pendo = ({ organization, agency, is_internal, t1_user_id }) => {
  const pendoLoader = setInterval(() => {
    if (window.pendo) {
      const userType = is_internal ? "internal" : "external";

      window.pendo.initialize({
        // biome-ignore lint/style/useNamingConvention: 3rd party props
        account: { agency_id: agency, id: organization, user_type: userType },
        visitor: { id: t1_user_id },
      });

      clearInterval(pendoLoader);
    }
  }, 50);

  setTimeout(() => {
    if (pendoLoader) {
      clearInterval(pendoLoader);
    }
  }, 5000);

  window.pendo_key = process.env.REACT_APP_PENDO_API_KEY;

  return (
    <Helmet>
      <script>initPendo(this.pendo_key);</script>
    </Helmet>
  );
};

export default Pendo;
