import {Common} from "./index";

export const Steps = Object.freeze({"MARKETING_OBJECTIVES": "marketing_objectives",
	"SUPPLY": "supply",
	"CREATIVES": "creatives",
	"AUDIENCES": "audiences",
	"LOCATIONS": "locations",
	"DAYPARTS": "dayparts",
	"TECHNOLOGY": "technology",
	...Common
});