import React, { useCallback, useState, useEffect, useContext } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";
import UserForm from "../form";
import UsersService from "../../../services/users";
import { OnlineContext } from "../../../context/online-context";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.user_id
 * @return {*}
 * @constructor
 */
const UserProfilePage = ({ history, match, self = true }) => {
	const back_url = "/";
	const service = new UsersService();

	const intl = useIntl();

	const { t1UserId: user_id } = useContext(OnlineContext);

	let _isMounted = React.useRef(false);

	const [serverError, setServerError] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * handle 404
	 */
	const handle404 = () => {
		history.push(back_url, {
			"action": "error",
			"msg": "Error loading user profile",
		});
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const handleSubmit = async (values) => {
		setLoading(true);

		const data = { ...values };
		delete data["agency_ids"];
		delete data["advertiser_ids"];

		try {
			await service.update(user_id, data);
			if (_isMounted.current) {
				setSuccess(true);
			}
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	if (user_id < 1) {
		handle404();
	}

	const [initialData, setInitialData] = useState(null);
	useEffect(() => {
		_isMounted.current = true;
		(async () => {
			try {
				const r = await service.get(user_id);
				setInitialData({
					"status": r.data?.status,
					"first_name": r.data["first_name"],
					"last_name": r.data["last_name"],
					"title": r.data["title"],
					"phone": r.data["phone"],
					"username": r.data["username"],
				});
			} catch (e) {
				handle404();
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, [user_id]);

	if (initialData === null) {
		return (
			<Segment disabled tertiary textAlign="center" className="loading">
				&nbsp;
			</Segment>
		);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_EDIT_USER",
					defaultMessage: "Edit User",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				success
				hidden={!success}
				size="tiny"
				content={intl.formatMessage({
					id: "MESSAGE_PROFILE_UPDATED",
					defaultMessage: "Your profile has been updated.",
				})}
			/>
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<UserForm
				initialData={initialData}
				initialIndeterminateAgencies={[]}
				formType="profile"
				loading={loading}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default UserProfilePage;
