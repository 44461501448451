import React, { useState, useRef } from "react";

import {
	Button,
	Divider,
	Dropdown,
	Form,
	Input,
	Message,
	Radio,
	Ref,
} from "semantic-ui-react";
import { FileInputComponent } from "../../common/file_input";

import { useForm } from "../../../libs/component_utils";
import "react-datepicker/dist/react-datepicker.css";
import AudiencesService from "../../../services/audiences";
import T1Service from "../../../services/t1";
import AgenciesService from "../../../services/agencies";
import { Config } from "../../../config/api";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

/**
 * Audience create page component
 * @return {*}
 * @constructor
 */
const AudienceForm = ({
	initialData,
	onSubmit,
	onCancel,
	formType = "create",
	submitText = "Upload Device ID",
}) => {
	const services = React.useRef(
		new Map([
			["audiences", new AudiencesService()],
			["t1", new T1Service()],
			["agencies", new AgenciesService()],
		])
	);
	const editMode = formType.toString() === "edit";
	const agency = services.current.get("agencies").getSelectedAgency() || 0;

	const intl = useIntl();
	const [formSending, setFormSending] = useState(false);

	// loaded page data
	const [pageData, setPageData] = useState(() => {
		return {
			"advertisersLoading": true,
			"advertisers": [],
		};
	});

	/**
	 * initially loading advertisers list
	 */
	React.useEffect(() => {
		const service = services.current.get("t1");
		(async () => {
			try {
				const r = await service.advertisers(agency);
				setPageData({
					...pageData,
					"advertisersLoading": false,
					"advertisers": pageData.advertisers.concat(
						r.data.map(({ id, title }) => ({
							"key": id,
							"text": title,
							"value": id,
						}))
					),
				});
			} catch (e) {
				console.log(e);
			}
		})();
	}, []);

	const fileRef = useRef();

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const addAudienceForm = async () => {
		try {
			setFormSending(true);

			await onSubmit(
				values,
				fileRef.current.querySelector("[type='file']")?.files[0]
			);
		} catch (e) {
			console.error(e);
		} finally {
			setFormSending(false);
		}
	};

	/**
	 * check if file was attached
	 * @param file
	 * @return {boolean}
	 */
	const fileAttached = (file) => {
		return Boolean(file && file.files.length);
	};

	const { values, errors, onChange, onSubmit: handleSubmit } = useForm(
		addAudienceForm,
		initialData,
		() => {
			const errors = {};

			if (values.advertiser_id === null) {
				errors.advertiser_id = intl.formatMessage({
					id: "ERROR_EMPTY_ADVERTISER_ID",
					defaultMessage: "Please select an advertiser.",
				});
			}

			const fileInput = fileRef.current.querySelector("[type='file']");
			if (!editMode && !fileAttached(fileInput)) {
				errors.audience_file = intl.formatMessage({
					id: "ERROR_EMPTY_AUDIENCE_FILE",
					defaultMessage: "Please select a device ID list.",
				});
			} else if (
				fileAttached(fileInput) &&
				fileInput.files[0].type !== "text/csv"
			) {
				errors.audience_file = intl.formatMessage({
					id: "ERROR_AUDIENCE_FILE_WRONG_FORMAT",
					defaultMessage: "Device ID list must be a CSV file.",
				});
			}

			return errors;
		}
	);

	return (
		<Form
			onSubmit={handleSubmit}
			size="small"
			loading={formSending}
			noValidate
			error={!!Object.keys(errors).length}
			autoComplete="off"
		>
			<Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_STATUS",
						defaultMessage: "Status",
					})}
				</label>
				<Radio
					name="status"
					label={intl.formatMessage({
						id: "STATUS_ACTIVE",
						defaultMessage: "Active",
					})}
					value={1}
					checked={!!values.status}
					onChange={onChange}
				/>
				<Radio
					style={{ "marginLeft": "15px" }}
					name="status"
					label={intl.formatMessage({
						id: "STATUS_INACTIVE",
						defaultMessage: "Inactive",
					})}
					value={0}
					checked={!values.status}
					onChange={onChange}
				/>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("title")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_SEGMENT_NAME",
						defaultMessage: "Segment Name",
					})}
				</label>
				<Input
					name="title"
					required
					minLength={1}
					maxLength={256}
					defaultValue={values.title}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["title"]}</div>
			</Form.Field>

			<Form.Field
				inline
				error={errors.hasOwnProperty("advertiser_id")}
				required
			>
				<label>
					{intl.formatMessage({
						id: "LABEL_ADVERTISER",
						defaultMessage: "Advertiser",
					})}
				</label>

				<Dropdown
					required
					search
					selection
					disabled={editMode}
					options={pageData.advertisers}
					loading={pageData.advertisersLoading}
					placeholder={intl.formatMessage({
						id: "HINT_ADVERTISER",
						defaultMessage: "Select advertiser",
					})}
					noResultsMessage={intl.formatMessage({
						id: "EMPTY_SEARCH_RESULTS",
						defaultMessage: "No results found",
					})}
					name="advertiser_id"
					value={values.advertiser_id}
					onChange={onChange}
				/>
				<div className="custom-error">{errors["advertiser_id"]}</div>
			</Form.Field>

			<Form.Field
				inline
				error={errors.hasOwnProperty("audience_file")}
				required
			>
				<label>
					{intl.formatMessage({
						id: "LABEL_DEVICE_IDS",
						defaultMessage: "Device IDs",
					})}
				</label>
				<Ref innerRef={fileRef}>
					<FileInputComponent
						accept=".csv"
						required={!editMode}
						placeholder={intl.formatMessage({
							id: "HINT_DEVICE_IDS",
							defaultMessage: "Upload a CSV file from your computer",
						})}
					/>
				</Ref>
				<a
					href={`${process.env.PUBLIC_URL}/samples/device_id_upload_example.csv`}
					style={{ marginLeft: 20 }}
				>
					{intl.formatMessage({
						id: "LINK_EXAMPLE_DEVICE_IDS",
						defaultMessage: "Click to download example CSV file",
					})}
				</a>
				<div className="custom-error">{errors["audience_file"]}</div>
			</Form.Field>
			<div
				style={{ "paddingLeft": 162, "display": editMode ? "block" : "none" }}
			>
				<Message
					style={{ "margin": 0, "display": "inline-block" }}
					size="tiny"
					content={intl.formatMessage({
						id: "MESSAGE_DEVICE_ID_APPEND",
						defaultMessage: "Upload Device ID CSV file will not overwrite the existing segment. It will add Device IDs to the segment.",
					})}
				/>
			</div>

			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<Form.Field align="right">
				<Button size="tiny" type="button" onClick={onCancel}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit">
					{submitText}
				</Button>
			</Form.Field>
		</Form>
	);
};
AudienceForm.propTypes = {
	"formType": PropTypes.string,
};

export default AudienceForm;
