import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import PixelsService from "../../../services/pixels";
import {useIntl} from "react-intl";
import {Divider, Header, Message, Segment} from "semantic-ui-react";
import PixelModal from "../modal";
import PixelRoiForm from "../form/roi";

const PixelRoiEditPage = ({history, match}) => {
  const back_url = "/pixels";
  const service = new PixelsService();
  const pixel_id = parseInt(match.params.pixel_id, 10) || 0;
  let _isMounted = React.useRef(false);
  const intl = useIntl();

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(back_url);
  }, []);

  // check pixel is valid
  if (pixel_id < 1) {
    handleCancel();
    return <></>;
  }

  /**
   * form submit handler
   * @param {object} values
   */
  const handleSubmit = async values => {
    setLoading(true);
    const pixel = {
      ...values,
      "status": Boolean(values.status),
      "sku_ids": values.sku_ids && values.attribution_level === "sku" ? values.sku_ids.split(",").map(sku => sku.trim()) : []
    }
    delete pixel["advertiser_id"];
    delete pixel["advertiser_name"];
    delete pixel["attribution_level"];
    delete pixel["id"];

    try {
      await service.update_roi(pixel_id, pixel);
      history.push(back_url, {"action": "updated", "title": values.title});
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await service.get_roi(pixel_id);
        const pixel = {...r.data};
        pixel["attribution_level"] = (pixel.sku_ids.length)? "sku" : "brand";
        pixel["sku_ids"] = pixel.sku_ids.join(", ");

        setInitialData(pixel);
      } catch (e) {
        console.error(e);
        // handle404();
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [pixel_id]);

  if (initialData === null) {
    return (
      <Segment disabled tertiary textAlign="center" className="loading">
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_EDIT_ROI_PIXEL",
          defaultMessage: "Edit ROI Pixel",
        })}
      </Header>
      <Divider />
      <Message
        style={{"marginTop": "10px"}}
        error
        hidden={!serverError}
        size="tiny"
        content={serverError}
      />
      <PixelRoiForm
        initialData={initialData}
        formType="edit"
        formSending={loading}
        submitText={intl.formatMessage({
          id: "BTN_SAVE_CHANGES",
          defaultMessage: "Save Changes",
        })}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <PixelModal
        open={modalOpen}
        type="edit"
        id={initialData?.id}
        advertiser_id={initialData?.advertiser_id}
        onClose={() => setModalOpen(false)}
      />
    </Segment>
  );
}
PixelRoiEditPage.propTypes = {
  "history": PropTypes.object.isRequired,
  "match": PropTypes.object.isRequired
};

export default PixelRoiEditPage;
