import React, { useState, useCallback } from "react";
import { Form, Select, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Config } from "../../../config/api";
import { useDebounce } from "../../../libs/component_utils";
import { statuses } from "./models/filter";
import { useIntl } from "react-intl";

const FilterControl = ({ filter, onChange }) => {
	const intl = useIntl();

	const handleStatus = (e, target) => {
		filter.setStatus(target.value);
		onChange(filter.toJson());
	};

	const [titleValue, setTitleValue] = useState(filter.title);

	const filterTitle = (value) => {
		if (filter.title === value) return;
		filter.setTitle(value);
		onChange(filter.toJson());
	};

	const filterTitleDebounced = useDebounce(
		Config.search_debounce_delay,
		(value) => {
			if (value.length === 0 || value.length >= 3) {
				filterTitle(value);
			}
		}
	);

	const handleTitleChange = useCallback((e, { value }) => {
		setTitleValue(value);
		filterTitleDebounced(value);
	});

	const handleTitleKeyPress = useCallback((e) => {
		if (e.charCode == 13) {
			filterTitle(e.target.value);
		}
	});

	return (
		<Form autoComplete="off" noValidate size="tiny" style={{"marginTop": "15px"}}>
			<Form.Group>
				<Form.Field>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Select
						selection
						options={statuses(intl)}
						value={filter.status}
						onChange={handleStatus}
						name="status"
					/>
				</Form.Field>
				<Form.Field>
					<label>
						{intl.formatMessage({
							id: "LABEL_PIXEL_NAME",
							defaultMessage: "Pixel Name",
						})}
					</label>
					<Input
						icon={
							titleValue !== ""
								? {
										name: "x",
										className: "input-clear-icon",
										link: true,
										onClick: () => {
											setTitleValue("");
											filterTitle("");
										},
								  }
								: "search"
						}
						iconPosition={titleValue !== "" ? null : "left"}
						placeholder={intl.formatMessage({
							id: "HINT_SEARCH_PIXEL_NAME",
							defaultMessage: "Search pixels by name",
						})}
						value={titleValue}
						onChange={handleTitleChange}
						onKeyPress={handleTitleKeyPress}
						name="title"
					/>
				</Form.Field>
			</Form.Group>
		</Form>
	);
};
FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired,
};

export default FilterControl;
