import isDate from "date-fns/isDate";
import isBefore from "date-fns/isBefore";
import { formatAPIDateTime } from "../../libs/common_utils.js";
export default class DateRange {
	constructor(start_date, end_date) {
		this.start_date = (start_date)? new Date(start_date) : "";
		this.end_date = (end_date)? new Date(end_date) : "";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		const start_date = json.start_date || "";
		const end_date = json.end_date || "";

		return new DateRange(start_date.toString(), end_date.toString());
	}

	/**
	 * verify that goal_value field is valid
	 * @param {date} start_date
	 * @param {date} end_date
	 * @return {boolean}
	 */
	static isValid(start_date, end_date) {
		if(!isDate(start_date) || !isDate(end_date)) {
			return false;
		}


		return isBefore(start_date, end_date);
	};

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		return {
			"start_date": this.start_date? formatAPIDateTime(this.start_date) : "",
			"end_date": this.end_date? formatAPIDateTime(this.end_date) : ""
		}
	}
}
