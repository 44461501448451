import React, { useState, useCallback } from "react";
import { Form, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { useDebounce } from "../../../../libs/component_utils";
import { Config } from "../../../../config/api";
import PropTypes from "prop-types";

const FilterControl = ({filter, onChange}) => {
	const intl = useIntl();
	const [titleValue, setTitleValue] = useState(filter.title);

	/**
	 * set filter title and trigger onChange
	 */
	const filterTitle = useCallback((value) => {
		if (filter.title === value) return;
		filter.setTitle(value);
		onChange(filter.toJson());
	}, []);

	const filterTitleDebounced = useDebounce(
		Config.search_debounce_delay,
		(value) => {
			if (value.length === 0 || value.length >= 1) {
				filterTitle(value);
			}
		}
	);

	const handleTitleChange = useCallback((e, { value }) => {
		setTitleValue(value);
		filterTitleDebounced(value);
	}, []);

	const handleTitleKeyPress = useCallback((e) => {
		if (e.charCode === 13) {
			filterTitle(e.target.value);
		}
	}, []);

	return (
		<Form autoComplete="off" noValidate size="tiny" style={{"marginTop": "15px"}}>
			<Form.Group>
				<Form.Field>
					<label>
						{intl.formatMessage({
							id: "LABEL_CREATIVE_NAME",
							defaultMessage: "Creative Name",
						})}
					</label>
					<Input
						icon={
							titleValue !== ""
								? {
									name: "x",
									className: "input-clear-icon",
									link: true,
									onClick: () => {
										setTitleValue("");
										filterTitle("");
									},
								}
								: "search"
						}
						iconPosition={titleValue !== "" ? null : "left"}
						placeholder={intl.formatMessage({
							id: "HINT_CREATIVES",
							defaultMessage: "Search creatives by name",
						})}
						value={titleValue}
						onChange={handleTitleChange}
						onKeyPress={handleTitleKeyPress}
						name="title"
					/>
				</Form.Field>
			</Form.Group>
		</Form>
	);
};
FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired
};

export default FilterControl;
