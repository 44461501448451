import AbstractService from "../abstract.service";


export default class ReportService extends AbstractService {
	static URI_STATES = new Map([
		["reports.run", "/reports"],
		["reports.graph", "/graph"],
	]);

	/**
	 * Run a report
	 * @param {object} data - values of the report form
	 * @returns {object} report result
	 */
	async run(data) {
		return this.api_server.post(
			ReportService.URI_STATES.get("reports.run"),
			data
		);
	}

	/**
	 * Make an API call to download file
	 * @param {object} data - values of the report form
	 * @returns {object} report result
	 */
	async run_download(data) {
		return this.api_server.post_download(
			ReportService.URI_STATES.get("reports.run"),
			data
		);
	}

	/**
	 * Get campaigns graph data
	 * @param {object} params
	 * @returns {Promise}
	 */
	async campaign_graph(params={}) {
		return this.__graph(Object.assign({"type": "campaign"}, params));
	}

	/**
	 * Get strategy graph data
	 * @param {object} params
	 * @returns {Promise}
	 */
	async strategy_graph(params={}) {
		return this.__graph(Object.assign({"type": "strategy"}, params));
	}

	/**
	 * Get graph data
	 * @param {object} params
	 * @returns {Promise}
	 */
	async __graph(params={}) {
		// return {"data": {"entity_id": 827360, "graph_data": [{"date": "2020-10-29", "metric_1": 0.41, "metric_2": 1008}, {"date": "2020-10-20", "metric_1": 0.32, "metric_2": 854}, {"date": "2020-10-21", "metric_1": 0.3, "metric_2": 747}, {"date": "2020-10-30", "metric_1": 0.38, "metric_2": 893}, {"date": "2020-10-14", "metric_1": 0.3, "metric_2": 666}, {"date": "2020-10-22", "metric_1": 0.31, "metric_2": 911}, {"date": "2020-10-06", "metric_1": 0.31, "metric_2": 691}, {"date": "2020-10-11", "metric_1": 0.32, "metric_2": 663}, {"date": "2020-10-05", "metric_1": 0.31, "metric_2": 662}, {"date": "2020-10-28", "metric_1": 0.35, "metric_2": 852}, {"date": "2020-10-13", "metric_1": 0.35, "metric_2": 823}, {"date": "2020-11-02", "metric_1": 0.4, "metric_2": 940}, {"date": "2020-10-17", "metric_1": 0.31, "metric_2": 741}, {"date": "2020-10-16", "metric_1": 0.31, "metric_2": 733}, {"date": "2020-10-25", "metric_1": 0.32, "metric_2": 782}, {"date": "2020-11-01", "metric_1": 0.52, "metric_2": 1182}, {"date": "2020-10-27", "metric_1": 0.42, "metric_2": 1041}, {"date": "2020-10-19", "metric_1": 0.3, "metric_2": 737}, {"date": "2020-11-03", "metric_1": 0.41, "metric_2": 1016}, {"date": "2020-10-18", "metric_1": 0.31, "metric_2": 659}, {"date": "2020-10-15", "metric_1": 0.3, "metric_2": 764}, {"date": "2020-10-31", "metric_1": 0.38, "metric_2": 822}, {"date": "2020-10-09", "metric_1": 0.33, "metric_2": 792}, {"date": "2020-10-12", "metric_1": 0.32, "metric_2": 805}, {"date": "2020-10-10", "metric_1": 0.31, "metric_2": 742}, {"date": "2020-10-07", "metric_1": 0.31, "metric_2": 671}, {"date": "2020-10-24", "metric_1": 0.32, "metric_2": 755}, {"date": "2020-10-23", "metric_1": 0.31, "metric_2": 866}, {"date": "2020-10-26", "metric_1": 0.31, "metric_2": 783}, {"date": "2020-10-08", "metric_1": 0.32, "metric_2": 795}], "metric_1": "total_spend", "metric_2": "impressions", "title": "Liel - Lift Logs test - 827360", "type": "campaign"}, "meta": {"status": "ok"}};
		return this.api_server.get(ReportService.URI_STATES.get("reports.graph"), params);
	}
}
