import AbstractService from "../abstract.service";

export default class AudiencesService extends AbstractService {
	static URI_STATES = new Map([
		["audiences.list_by_agency", "/audiences/{0}"],
		["audiences.get", "/audience/{0}"],
		["audiences.put", "/audience/{0}"],
		["audiences.post", "/audience"],
	]);

	/**
	 * Get available audiences
	 * @param {number} agency
	 * @param {object} params
	 * @param {number} params.advertiser_id
	 * @param {string} params.device_name
	 * @return {Promise<>}
	 */
	async list_by_agency(agency, params) {
		return this.api_server.get(AudiencesService.URI_STATES.get("audiences.list_by_agency").format(agency), params);
	}

	/**
	 * post creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create(params) {
		return this.api_server.post(
			AudiencesService.URI_STATES.get("audiences.post"),
			params
		);
	}

	/**
	 * put creative
	 * @param {number} id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update(id, params) {
		return this.api_server.put(
			AudiencesService.URI_STATES.get("audiences.put").format(id),
			params
		);
	}

	/**
	 * Get creative by id
	 * @param {number} id
	 */
	async get(id) {
		return this.api_server.get(
			AudiencesService.URI_STATES.get("audiences.get").format(id),
			{}
		);
	}
}
