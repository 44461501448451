import { compareAsc } from "date-fns";

/**
 * Returns the date or "NEVER" if the date is after 2999-12-31.
 * @param {Date} date Date to test
 * @return {"NEVER" | Date} The date or "NEVER"
 */
export function checkNever(date) {
  if (!date) return "NEVER";
  const neverDate = new Date("2999-12-31T00:00:00Z");
  const isSameOrAfter = compareAsc(date, neverDate);
  const isEndDateNever = isSameOrAfter === 0 || isSameOrAfter === 1;
  return isEndDateNever ? "NEVER" : date;
}
