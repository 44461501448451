export const user_roles = (intl) => ({
	"ADMIN": intl.formatMessage({
		id: "USER_ROLE_ADMIN",
		defaultMessage: "Admin",
	}),
	"MANAGER": intl.formatMessage({
		id: "USER_ROLE_MANAGER",
		defaultMessage: "Manager",
	}),
	"REPORTER": intl.formatMessage({
		id: "USER_ROLE_REPORTER",
		defaultMessage: "Reporter",
	}),
})
