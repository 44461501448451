import { useContext } from "react";
import { Route } from "react-router-dom";
import { OnlineContext } from "../../context/online-context";
import AgenciesService from "../../services/agencies";
import AgencyPickerModal from "../agency-picker";
import Body from "./Body";

const agenciesService = new AgenciesService();

const PrivateRoute = ({ component: Component, ...restProps }) => {
  const { online, agencyPickerOpen, setAgencyPickerOpen } = useContext(OnlineContext);

  if (!agenciesService.isAgencySelected()) {
    return <AgencyPickerModal open={true} />;
  }

  return (
    <>
      <AgencyPickerModal
        closeIcon
        open={agencyPickerOpen}
        defaultAgencyId={agenciesService.getSelectedAgency()}
        defaultAgencyTitle={agenciesService.getSelectedAgencyTitle()}
        onClose={() => setAgencyPickerOpen(false)}
      />
      <div>
        <Route
          {...restProps}
          render={(routeProps) => {
            const componentProps = { ...routeProps, ...restProps };
            window.referrer = routeProps.location;

            return online ? (
              <Body {...componentProps}>
                <Component {...componentProps} />
              </Body>
            ) : (
              ""
            );
          }}
        />
      </div>
    </>
  );
};

export default PrivateRoute;
