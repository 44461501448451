import {Config} from "../../../config/api";

export const daily_pacing_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "DAILY_PACING_ASAP",
			defaultMessage: "ASAP",
		}),
		"value": "asap",
	},
	{
		"text": intl.formatMessage({
			id: "DAILY_PACING_EVEN",
			defaultMessage: "Even",
		}),
		"value": "even",
	},
	{
		"text": intl.formatMessage({
			id: "DAILY_PACING_NO_LIMIT",
			defaultMessage: "No Limit",
		}),
		"value": "no-limit",
	},
];

export const spend_cap_automatic_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "SPEND_CAP_MANUAL",
			defaultMessage: "Manual",
		}),
		"value": 0,
	},
	{
		"text": intl.formatMessage({
			id: "SPEND_CAP_AUTOMATIC",
			defaultMessage: "Automatic",
		}),
		"value": 1,
	},
];

export const frequency_options = (intl) => [
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_ASAP",
			defaultMessage: "ASAP",
		}),
		"value": "asap",
	},
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_EVEN",
			defaultMessage: "Even",
		}),
		"value": "even",
	},
	{
		"text": intl.formatMessage({
			id: "FREQUENCY_NO_CAP",
			defaultMessage: "No Cap",
		}),
		"value": "no-limit",
	},
];

export const frequency_intervals = (intl) => [
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_HOUR",
			defaultMessage: "Hour",
		}),
		"value": "hour",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_DAY",
			defaultMessage: "Day",
		}),
		"value": "day",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_WEEK",
			defaultMessage: "Week",
		}),
		"value": "week",
	},
	{
		"text": intl.formatMessage({
			id: "FREQENCY_INTERVAL_MONTH",
			defaultMessage: "Month",
		}),
		"value": "month",
	},
];

/**
 * generate campaign types
 * @param {object} lng
 * @return {array}
 */
export const campaign_types = lng => {
	return [
		{"text": lng.campaign.supply_type, "value": `${Config.strategy_supply_type.toUpperCase()}/PMP`},
		{"text": "Programmatic Guaranteed", "value": "PG"},
	];
};

/**
 * Controls display options for various goal types.
 * Option labels are returned by the backend, so we don't include them here.
 */
export const goal_options = [
	{"value": "cpa", "display": "currency"},
	{"value": "roi", "display": "roi"},
	{"value": "cpc", "display": "currency"},
	{"value": "ctr", "display": "percent"},
	{"value": "reach", "display": "currency"},
	{"value": "spend", "display": "currency"},
	{"value": "vcpm", "display": "currency"},
	{"value": "vcr", "display": "percent"},
	{"value": "viewability_rate", "display": "percent"}
];

export const graph_metrics = (intl) => [
	{
		"text": intl.formatMessage({
			id: "LABEL_SPEND",
			defaultMessage: "Spend",
		}),
		"value": "total_spend",
	},
	{
		"text": intl.formatMessage({
			id: "LABEL_IMPRESSIONS",
			defaultMessage: "Impressions",
		}),
		"value": "impressions",
	},
	{
		"text": intl.formatMessage({
			id: "LABEL_CPM",
			defaultMessage: "CPM",
		}),
		"value": "total_spend_cpm",
	},
	{
		"text": intl.formatMessage({
			id: "LABEL_CTR",
			defaultMessage: "CTR",
		}),
		"value": "ctr",
	},
	{
		"text": intl.formatMessage({
			id: "LABEL_CLICKS",
			defaultMessage: "Clicks",
		}),
		"value": "clicks",
	},
];
