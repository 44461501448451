import {isDigit} from "../../libs/common_utils";
import {Intervals, Types} from "../enum/pacing";

export default class Pacing {
	static MIN = 0.01;
	static MAX = 9_999_999.99;

	constructor(pacing_type, pacing_interval, pacing_amount) {
		this.pacing_type = Types.assign(pacing_type || Types.ASAP);
		this.pacing_interval = Intervals.assign(pacing_interval || Intervals.HOUR);
		this.pacing_amount = parseFloat(pacing_amount) || 0;
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new Pacing(json.pacing_type,
			json.pacing_interval, json.pacing_amount);
	}

	/**
	 * verify that pacing amount field is valid
	 * @param {string} pacing_amount
	 * @return {boolean}
	 */
	static isValid(pacing_amount) {
		const value = parseFloat(pacing_amount);
		return isDigit(value) && value >= Pacing.MIN && value <= Pacing.MAX;
	};

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		return {
			...this
		}
	}
}