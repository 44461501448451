/* eslint-disable no-unreachable */
export const strategiesActions = {
	"SORTING": "SORTING",
	"FILTERING": "FILTERING",
	"INIT": "INIT",
	"SET_STRATEGIES": "SET_STRATEGIES",
	"CHECK_ALL": "CHECK_ALL",
	"CHECK": "CHECK",
  "UPDATE": "UPDATE"
};

const getTotalPages = (amount, limit) => {
	return Math.ceil(amount / limit);
};
/**
 * Init strategies
 * @param {array} strategies
 * @param {object} campaign
 * @param {object} pager
 * @param state
 * @return {object} new state
 */
const initStrategies = (strategies, campaign, pager, state) => {
	delete campaign.strategies;

	state.pager.setTotal(pager);
	return {
		...state,
		strategies,
		campaign
	}
};

export const strategiesGridReducer = (state, action) => {
	switch(action.type) {
		case(strategiesActions.INIT):
			return initStrategies(action.data.strategies, {...action.data}, action.pager, state);

    case strategiesActions.UPDATE:
      return {
        ...state,
        strategies: action.data
      }
		default:
			throw new Error("Method is not implement");
	}
};
