import {MarginsPage} from "../../components/margin/index";
import MarginCreatePage from "../../components/margin/create";
import MarginEditPage from "../../components/margin/edit";
import {Routes as commonRoutes} from "../index";
import {Routes as creativeRoutes} from "../creatives";
import {Routes as reportRoutes} from "../report";
import {Routes as pixelRoutes} from "../pixels";
import {UsersPage} from "../../components/user/index";
import UserCreatePage from "../../components/user/create";
import UserEditPage from "../../components/user/edit";
import {AdminCreativesApprovalsPage} from "../../components/creative/index/creative_approval/index_admin";
import AdminCreativeApprovalEditPage from "../../components/creative/edit/creative_approval";

export const Routes = (flags = {}) => {
  const routes = [
    {
      "path": "/admin/margins",
      "component": MarginsPage,
      "title": ["Margin Controls"]
    }, {
      "path": "/admin/margin/create",
      "component": MarginCreatePage,
      "title": ["Margin Controls", "Create"]
    }, {
      "path": "/admin/margin/edit/:margin_id",
      "component": MarginEditPage,
      "title": ["Margin Controls", "Edit"]
    },
    {
      "path": "/admin/users",
      "component": UsersPage,
      "title": ["Users"]
    }, {
      "path": "/admin/user/create",
      "component": UserCreatePage,
      "title": ["Users", "Create"]
    }, {
      "path": "/admin/user/edit/:user_id",
      "component": UserEditPage,
      "title": ["Users", "Edit"]
    }, {
      "path": "/admin/onsite-creatives",
      "component": AdminCreativesApprovalsPage,
      "title": ["On-Site Creatives"]
    }, {
      "path": "/admin/onsite-creatives/edit/:creative_id",
      "component": AdminCreativeApprovalEditPage,
      "title": ["On-Site Creatives", "Edit"]
    }
  ];
  
  return routes.concat(creativeRoutes(flags), pixelRoutes(flags), reportRoutes, commonRoutes);
};
