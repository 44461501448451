import {Common} from "./index";

export const Types = Object.freeze({"HAN": "HAN",
	"FAN": "FAN",
	"PMP": "PMP",
	"OPEN_SUPPLY": "OPEN",
	"PG": "PG",
	...Common
});

export const RtbTypes = Object.freeze({"MARKET": "MARKETPLACE",
	"STANDARD": "STANDARD",
	...Common
});
