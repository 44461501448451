class CreativeModel {
	constructor(id, status, title, advertiser_id, third_party,
							mraid, mraid_type, landing_page_url)
	{
		this.id = id || 0;
		this.status = status || 0;
		this.title = title || "";
		this.advertiser_id = advertiser_id || 0;
		this.third_party = third_party || 0;
		this.mraid = mraid || 0;
		this.mraid_type = mraid_type || "";
		this.landing_page_url = landing_page_url || "";
	}
}

export class CreativeDisplay1PModel extends CreativeModel {
	constructor(id, status, title, advertiser_id, third_party,
							mraid, mraid_type,
							tag, file_type,
							clickthrough_url, landing_page_url)
	{
		super(id, status, title, advertiser_id, third_party,
			mraid, mraid_type, landing_page_url);

		this.tag = tag || "";
		this.file_type = file_type || "";
		this.clickthrough_url = clickthrough_url || "";
		this.landing_page_url = landing_page_url || "";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new CreativeDisplay1PModel(json.id, json.status, json.title, json.advertiser_id, json.third_party,
			json.mraid, json.mraid_type,
			json.tag, json.file_type, json.clickthrough_url, json.landing_page_url);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this
		};

		if(!json.id) {
			delete json.id;
			delete json.file_type;

			// remove landing_page_url for create mode only
			if(!json.landing_page_url) {
				delete json.landing_page_url;
			}
		}

		if(!json.mraid) {
			delete json.mraid_type;
		}

		return json;
	}
}

export class CreativeDisplay3PModel extends CreativeModel {
	constructor(id, status, title, advertiser_id, third_party,
							mraid, mraid_type,
							tag, ad_server, tpas_tag_id,
							tag_type,
							is_expandable, expansion_trigger, expansion_direction,
							dimensions, file_type,
							landing_page_url)
	{
		super(id, status, title, advertiser_id, third_party,
			mraid, mraid_type, landing_page_url);

		this.tag = tag || "";
		this.ad_server = ad_server || "";
		this.tpas_tag_id = tpas_tag_id || "";
		this.tag_type = tag_type || "";
		this.is_expandable = is_expandable || 0;
		this.expansion_trigger = expansion_trigger || "";
		this.expansion_direction = expansion_direction || "";
		this.dimensions = dimensions || "";
		this.file_type = file_type || "";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new CreativeDisplay3PModel(json.id, json.status, json.title, json.advertiser_id, json.third_party,
			json.mraid, json.mraid_type,
			json.tag, json.ad_server, json.tpas_tag_id,
			json.tag_type,
			json.is_expandable, json.expansion_trigger, json.expansion_direction,
			json.dimensions,
			json.file_type, json.landing_page_url);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this
		};

		if(!json.id) {
			delete json.id;

			// remove landing_page_url for create mode only
			if(!json.landing_page_url) {
				delete json.landing_page_url;
			}
		}

		if(!json.mraid) {
			delete json.mraid_type;
		}

		if(Array.isArray(json.expansion_direction)) {
			json.expansion_direction = json.expansion_direction.join(",");
		}

		if (!json.is_expandable) {
			delete json.expansion_trigger;
			delete json.expansion_direction;
		}

		return json;
	}
}
