export default class Filter {
	constructor() {
		this.reset();
	}

	/**
	 * update creative name
	 * @param {string} value
	 */
	setTitle(value) {
		this.title = value.trim().toLowerCase();
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.status = item.status;
		this.title = item.title;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.title = "";
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"title": this.title,
		};
	}

	/**
	 * transform data into request json
	 * @returns {object}
	 */
	toRequestJson() {
		const params = {};

		if (this.title !== "") {
			params["creative_name"] = this.title;
		}

		return params;
	}

	/**
	 * check that user didn't touch filter
	 * @return {boolean}
	 */
	isEmpty() {
		return this.title === "";
	}
}
