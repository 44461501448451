import React from "react";
import headerImg from "../../../../../assets/creative/mobile_header.png";
import backdropImg from "../../../../../assets/creative/video player backdrop.png";
import brandImg from "../../../../../assets/creative/brand_logo.png";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import {Button, Icon, Image as Img, Label} from "semantic-ui-react";

/**
 * banner component
 * @param brand_name
 * @param ad_title
 * @param logo_url
 * @param button_color
 * @param button_text
 * @param deep_link
 * @returns {JSX.Element}
 * @constructor
 */
const BannerCompanionComponent = React.memo(({brand_name, ad_title, logo_url, button_color, button_text, deep_link}) => {
  const intl = useIntl();
  const url = (deep_link)? new URL(deep_link).hostname : "yourwebsite.com";

  return <>
    <strong>
      {intl.formatMessage({
        id: "LABEL_COMPANION_AD_PREVIEW",
        defaultMessage: "Companion Ad Preview",
      })}
    </strong>
    <div className="companion_ad_preview">
      <div className="images_block">
        <Img src={headerImg} />
        <Img src={backdropImg} />
        <a href={deep_link || "/"} target="_BLANK" rel="noopener noreferrer"><Icon name="external" /> {url}</a>
      </div>
      <table width="100%">
        <tbody>
        <tr>
          <td width="40">
            <Img src={logo_url || brandImg} />
          </td>
          <td className="ad_body">
            <div>
              <Label color="yellow" size="tiny">
                {intl.formatMessage({
                  id: "LABEL_AD",
                  defaultMessage: "AD",
                })}
              </Label>
              <span>
									{brand_name || intl.formatMessage({
                    id: "PLACEHOLDER_BRAND_NAME",
                    defaultMessage: "Your Brand Name",
                  })}
								</span>
            </div>
            <div>
              {ad_title || intl.formatMessage({
                id: "PLACEHOLDER_AD_TITLE",
                defaultMessage: "Your Ad Title",
              })}
            </div>
          </td>
          <td width="110">
            <Button
              type="button"
              style={{ "backgroundColor": button_color || "orange" }}
              size="tiny"
            >
              {button_text || intl.formatMessage({
                id: "PLACEHOLDER_BUTTON_TEXT",
                defaultMessage: "YOUR TEXT",
              })}
            </Button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </>;
}, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
BannerCompanionComponent.propTypes = {
  "brand_name": PropTypes.string.isRequired,
  "ad_title": PropTypes.string.isRequired,
  "logo_url": PropTypes.string.isRequired,
  "button_color": PropTypes.string.isRequired,
  "button_text": PropTypes.string.isRequired,
  "deep_link": PropTypes.string.isRequired
};

export default BannerCompanionComponent;
