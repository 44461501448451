export default {
	"advertiser_id": {
		"id": "advertiser_id",
		"access": true,
		"name": "Advertiser ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_ADVERTISER_ID",
	},
	"advertiser_name": {
		"id": "advertiser_name",
		"maxLength": 64,
		"name": "Advertiser Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_ADVERTISER_NAME",
	},
	"agency_id": {
		"id": "agency_id",
		"access": true,
		"name": "Agency ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_AGENCY_ID",
	},
	"agency_name": {
		"id": "agency_name",
		"maxLength": 64,
		"name": "Agency Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_AGENCY_NAME",
	},
	"app_id": {
		"id": "app_id",
		"maxLength": 256,
		"name": "App ID",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_APP_ID",
	},
	"app_name": {
		"id": "app_name",
		"maxLength": 256,
		"name": "App Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_APP_NAME",
	},
	"attribution_group": {
		"id": "attribution_group",
		"maxLength": 64,
		"name": "Attribution Group",
		"type": "string",
		"values": ["MediaMath Last Touch", "Facebook Inc."],
		"kind": "dimension",
		"messageId": "LABEL_ATTRIBUTION_GROUP",
	},
	"campaign_budget": {
		"id": "campaign_budget",
		"name": "Campaign Budget",
		"type": "money",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_BUDGET",
	},
	"campaign_currency_code": {
		"id": "campaign_currency_code",
		"name": "Campaign Currency Code",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_CURRENCY_CODE",
	},
	"campaign_end_date": {
		"id": "campaign_end_date",
		"name": "(GMT) Campaign End Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_END_DATE",
	},
	"campaign_end_date_tz": {
		"id": "campaign_end_date_tz",
		"name": "(CTZ) Campaign End Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_END_DATE_TZ",
	},
	"campaign_goal_type": {
		"id": "campaign_goal_type",
		"name": "Campaign Goal Type",
		"type": "string",
		"values": [
			"cpa",
			"cpc",
			"cpcv",
			"cpe",
			"ctr",
			"reach",
			"roi",
			"spend",
			"vcpm",
			"vcr",
			"viewability_rate",
		],
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_GOAL_TYPE",
	},
	"campaign_goal_value": {
		"id": "campaign_goal_value",
		"name": "Campaign Goal Value",
		"type": "money",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_GOAL_VALUE",
	},
	"campaign_id": {
		"id": "campaign_id",
		"access": true,
		"name": "Campaign ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_ID",
	},
	"campaign_initial_start_date": {
		"id": "campaign_initial_start_date",
		"name": "(GMT) Campaign Initial Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_INITIAL_START_DATE",
	},
	"campaign_initial_start_date_tz": {
		"id": "campaign_initial_start_date_tz",
		"name": "(CTZ) Campaign Initial Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_INITIAL_START_DATE_TZ",
	},
	"campaign_name": {
		"id": "campaign_name",
		"maxLength": 256,
		"name": "Campaign Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_NAME",
	},
	"campaign_start_date": {
		"id": "campaign_start_date",
		"name": "(GMT) Campaign Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_START_DATE",
	},
	"campaign_start_date_tz": {
		"id": "campaign_start_date_tz",
		"name": "(CTZ) Campaign Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_START_DATE_TZ",
	},
	"campaign_timezone": {
		"id": "campaign_timezone",
		"name": "Campaign Time Zone",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_TIMEZONE",
	},
	"campaign_timezone_code": {
		"id": "campaign_timezone_code",
		"name": "Campaign Time Zone Code",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CAMPAIGN_TIMEZONE_CODE",
	},
	"city_code": {
		"id": "city_code",
		"maxLength": 50,
		"name": "City Code",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CITY_CODE",
	},
	"city_name": {
		"id": "city_name",
		"maxLength": 200,
		"name": "City Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CITY_NAME",
	},
	"creative_id": {
		"id": "creative_id",
		"maxLength": 50,
		"name": "Creative ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_CREATIVE_ID",
	},
	"creative_name": {
		"id": "creative_name",
		"maxLength": 256,
		"name": "Creative Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CREATIVE_NAME",
	},
	"creative_size": {
		"id": "creative_size",
		"maxLength": 16,
		"name": "Creative Size",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_CREATIVE_SIZE",
	},
	"country_code": {
		"id": "country_code",
		"maxLength": 50,
		"name": "Country Code",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_COUNTRY_CODE",
	},
	"country_name": {
		"id": "country_name",
		"maxLength": 200,
		"name": "Country Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_COUNTRY_NAME",
	},
	"exchange_id": {
		"id": "exchange_id",
		"name": "Exchange ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_EXCHANGE_ID",
	},
	"exchange_name": {
		"id": "exchange_name",
		"maxLength": 20,
		"name": "Exchange Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_EXCHANGE_NAME",
	},
	"metro_name": {
		"id": "metro_name",
		"maxLength": 50,
		"name": "Metro Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_METRO_NAME",
	},
	"organization_id": {
		"id": "organization_id",
		"access": true,
		"name": "Organization ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_ORGANIZATION_ID",
	},
	"organization_name": {
		"id": "organization_name",
		"maxLength": 64,
		"name": "Organization Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_ORGANIZATION_NAME",
	},
	"region_code": {
		"id": "region_code",
		"maxLength": 50,
		"name": "Region Code",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_REGION_CODE",
	},
	"region_id": {
		"id": "region_id",
		"name": "Region ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_REGION_ID",
	},
	"region_name": {
		"id": "region_name",
		"maxLength": 200,
		"name": "Region Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_REGION_NAME",
	},
	"site_domain": {
		"id": "site_domain",
		"maxLength": 1000,
		"name": "Site Domain",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_SITE_DOMAIN",
	},
	"start_date": {
		"id": "start_date",
		"name": "Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_R_START_DATE",
	},
	"end_date": {
		"id": "end_date",
		"name": "End Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_R_END_DATE",
	},
	"strategy_budget": {
		"id": "strategy_budget",
		"name": "Strategy Budget",
		"type": "money",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_BUDGET",
	},
	"strategy_channel": {
		"id": "strategy_channel",
		"name": "Strategy Channel",
		"type": "string",
		"values": ["DISPLAY", "VIDEO"],
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_CHANNEL",
	},
	"strategy_end_date": {
		"id": "strategy_end_date",
		"name": "Strategy End Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_END_DATE",
	},
	"strategy_goal_type": {
		"id": "strategy_goal_type",
		"name": "Strategy Goal Type",
		"type": "string",
		"values": [
			"cpa",
			"cpc",
			"cpcv",
			"cpe",
			"ctr",
			"reach",
			"roi",
			"spend",
			"vcpm",
			"vcr",
			"viewability_rate",
		],
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_GOAL_TYPE",
	},
	"strategy_goal_value": {
		"id": "strategy_goal_value",
		"name": "Strategy Goal Value",
		"type": "money",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_GOAL_VALUE",
	},
	"strategy_id": {
		"id": "strategy_id",
		"access": true,
		"name": "Strategy ID",
		"type": "id",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_ID",
	},
	"strategy_name": {
		"id": "strategy_name",
		"maxLength": 128,
		"name": "Strategy Name",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_NAME",
	},
	"strategy_start_date": {
		"id": "strategy_start_date",
		"name": "Strategy Start Date",
		"type": "datetime",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_START_DATE",
	},
	"strategy_supply_type": {
		"id": "strategy_supply_type",
		"maxLength": 64,
		"name": "Strategy Supply Type",
		"type": "string",
		"values": [
			"RTB & Private Market Place (PMP)",
			"External Media Tracking",
			"T1_API",
			"Batch Supply",
		],
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_SUPPLY_TYPE",
	},
	"strategy_type": {
		"id": "strategy_type",
		"maxLength": 64,
		"name": "Strategy Type",
		"type": "string",
		"kind": "dimension",
		"messageId": "LABEL_STRATEGY_TYPE",
	},
};
