import { Dimmer, Image, Loader, Segment } from "semantic-ui-react";

const IMG_SRC = "https://react.semantic-ui.com/images/wireframe/paragraph.png";

const RouteFallback = () => {
  return (
    <Segment disabled tertiary textAlign="center" className="loading">
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
      <Image src={IMG_SRC} />
    </Segment>
  );
};

export default RouteFallback;
