/**
 * Controls display options for various goal types.
 * Option labels are returned by the backend, so we don't include them here.
 */
export const revenue_attributions = [
  {"value": "V1", "text": "V1"},
  {"value": "V2", "text": "V2"},
  {"value": "S1", "text": "S1"},
  {"value": "S2", "text": "S2"}
];
