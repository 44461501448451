import React, {useState, useRef} from "react";

import {
	Button,
	Divider,
	Dropdown,
	Form, Grid,
	Input, Checkbox,
	Radio,
	Ref, Segment, Select
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import { useForm } from "../../../../libs/component_utils";
import "react-datepicker/dist/react-datepicker.css";
import CreativesService from "../../../../services/creatives";
import T1Service from "../../../../services/t1";
import AgenciesService from "../../../../services/agencies";
import {FileInputComponent} from "../../../common/file_input";

import {default_button_text} from "../../fixtures";
import PropTypes from "prop-types";
import CreativeModel, {CreativeVideo3PModel} from "../../../../models/creative_video";
import {useFlags} from "launchdarkly-react-client-sdk";
import VideoCreativeThirdPartyComponent from "./components/third_party";
import {humanFileSize, isValidHttpUrl} from "../../../../libs/common_utils";
import BannerCompanionComponent from "./components/banner_companion";


const VideoFormContext = React.createContext();
/**
 * Creative create page component
 * @param history
 * @return {*}
 * @constructor
 */
const CreativeVideoForm = ({
	initialData,
	onSubmit,
	onCancel,
	loading,
	formType = "create",
	submitText = "Create Creative",
}) => {
	const fileSize = {
		"MIN": 1000,
		"MAX": 150e+6
	};

	const intl = useIntl();
	const {whitelabelCreativeCompanionAd} = useFlags();

	const services = React.useRef(new Map([
			["creatives", new CreativesService()],
			["t1", new T1Service()],
			["agencies", new AgenciesService()],
		]));
	const [_, redraw] = useState();

	/** Fields from additional components. */
	const additionalFields = React.useRef({});

	/** Errors from additional components. */
	const [additionalErrors, setAdditionalErrors] = React.useState({});

	const fileRef = useRef();

	const agency = services.current.get("agencies").getSelectedAgency() || 0;
	const is_edit_mode = (formType.toString() === "edit");

	// loaded page data
	const [pageData, setPageData] = useState(() => {
		return {
			"advertisersLoading": true,
			"advertisers": [],
		};
	});

	/**
	 * initially loading advertisers list
	 */
	React.useEffect(() => {
		const service = services.current.get("t1");
		(async () => {
			try {
				const r = await service.advertisers(agency);
				/** @namespace {array} r.data **/

				setPageData({
					...pageData,
					"advertisersLoading": false,
					"advertisers": pageData.advertisers.concat(
						r.data.map(({ id, title }) => ({
							"key": id,
							"text": title,
							"value": id,
						}))
					),
				});
			} catch (e) {
				console.log(e);
			}
		})();
	}, []);

	/**
	 * updated fields from additional components
	 * @param {object} fields
	 */
	const updateAdditionalFields = fields => {
		additionalFields.current = {
			...additionalFields.current,
			...fields
		};
	};

	/**
	 * updated errors from additional components
	 * @param {object} errors
	 */
	const updateAdditionalErrors = errors => {
		additionalErrors.current = errors;
		redraw(Date.now());
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const addCreativeForm = async () => {
		const creative = (values.third_party)? CreativeVideo3PModel.fromJson(values).toJson() : CreativeModel.fromJson(Object.assign(values, additionalFields.current));
		await onSubmit(creative, (values.third_party)? null : fileRef.current.querySelector("[type='file']")?.files[0]);
	};

	const {
		values,
		errors,
		onChange,
		onSubmit: handleSubmit,
	} = useForm(addCreativeForm, () => {
		const found =
			initialData.button_text &&
			default_button_text(intl).find(
				(x) => x.value.toLowerCase() === initialData.button_text.toLowerCase()
			);
		return {
			...initialData,
			"button_text_type": (found)? "standard" : "custom"
		};
	}, () => {
		let errors = {...additionalErrors};

		if(values.title && !!values.title.match(/[^a-z0-9\-_ ]/i)) {
			errors.title = intl.formatMessage({
				id: "ERROR_CREATIVE_TITLE_PATTERN",
				defaultMessage: "Creative title may only contain letters, numbers, spaces, underscores, and dashes.",
			});
		}

		if(values.advertiser_id === null) {
			errors.advertiser_id = intl.formatMessage({
				id: "ERROR_EMPTY_ADVERTISER_ID",
				defaultMessage: "Please select an advertiser.",
			});
		}

		if(!values.third_party) {
			const fileInput = fileRef.current.querySelector("[type='file']");
			if (fileInput && fileInput.files.length === 0) {
				errors.creative_file = intl.formatMessage({
					id: "ERROR_EMPTY_CREATIVE_FILE",
					defaultMessage: "Please select a creative asset.",
				});
			} else if (fileInput && fileInput.files[0].type !== "video/mp4") {
				errors.creative_file = intl.formatMessage({
					id: "ERROR_CREATIVE_FILE_WRONG_FORMAT",
					defaultMessage: "Creative asset must be an MP4 file.",
				});
			}

			if(formType === "create" && fileInput.files[0]?.size) {
				const file_size = fileInput.files[0].size;
				if(file_size < fileSize.MIN) {
					errors.creative_file = intl.formatMessage({
						id: "ERROR_CREATIVE_FILE_TOO_SMALL",
						defaultMessage: "Creative asset size cannot be less than {file_min_size}.",
					}, {
						"file_size": humanFileSize(fileSize.MIN)
					});
				}

				if(file_size > fileSize.MAX) {
					errors.creative_file = intl.formatMessage({
						id: "ERROR_CREATIVE_FILE_TOO_LARGE",
						defaultMessage: "Creative asset size cannot be more than 150 mb.",
					});
				}
			}
		} else {
			if(values.custom_vast_url && !isValidHttpUrl(values.custom_vast_url)) {
				errors.custom_vast_url = intl.formatMessage({
					id: "VALIDATOR_VALID_URL_FIELD_NAME",
					defaultMessage: "{field_name} must be a valid URL",
				}, {
					"field_name": intl.formatMessage({
						id: "LABEL_VAST_URL",
						defaultMessage: "VAST URL",
					})
				});
			}
		}

		return errors;
	});

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				size="small"
				loading={loading}
				noValidate
				error={!!Object.keys(errors).length}
				autoComplete="off"
			>
				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Radio
						name="status"
						label={intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
						})}
						value={1}
						checked={Boolean(values.status)}
						onChange={onChange}
					/>
					<Radio
						style={{ "marginLeft": "15px" }}
						name="status"
						label={intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
						})}
						value={0}
						checked={!Boolean(values.status)}
						onChange={onChange}
					/>
				</Form.Field>

				<Form.Field inline error={errors.hasOwnProperty("title")} required>
					<label>
						{intl.formatMessage({
							id: "LABEL_CREATIVE_NAME",
							defaultMessage: "Creative Name",
						})}
					</label>
					<Input
						name="title"
						required
						minLength={1}
						maxLength={100}
						defaultValue={values.title}
						onBlur={onChange}
					/>
					<div className="custom-error">{errors["title"]}</div>
				</Form.Field>

				<Form.Field
					inline
					error={errors.hasOwnProperty("advertiser_id")}
					required
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_ADVERTISER",
							defaultMessage: "Advertiser",
						})}
					</label>
					<Dropdown
						required
						search
						selection
						disabled={is_edit_mode}
						options={pageData.advertisers}
						loading={pageData.advertisersLoading}
						placeholder={intl.formatMessage({
							id: "HINT_ADVERTISER",
							defaultMessage: "Select advertiser",
						})}
						name="advertiser_id"
						value={values.advertiser_id}
						onChange={onChange}
					/>
					<div className="custom-error">{errors["advertiser_id"]}</div>
				</Form.Field>

				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_ASSET",
							defaultMessage: "Asset",
						})}
					</label>
					<Radio
						name="third_party"
						label={intl.formatMessage({
							id: "LABEL_FIRST_PARTY_VIDEO_ASSET",
							defaultMessage: "Upload a video asset",
						})}
						value={0}
						checked={!values.third_party}
						onChange={onChange}
						disabled={is_edit_mode}
					/>
					<Radio
						style={{"marginLeft": "15px"}}
						name="third_party"
						label={intl.formatMessage({
							id: "LABEL_THIRD_PARTY_VIDEO_ASSET",
							defaultMessage: "Enter a third party VAST tag",
						})}
						value={1}
						checked={Boolean(values.third_party)}
						onChange={onChange}
						disabled={is_edit_mode}
					/>
				</Form.Field>
				{Boolean(values.third_party) && <VideoCreativeThirdPartyComponent errors={errors} values={values} onChange={onChange}/>}
				{!values.third_party && <><Form.Field
						inline
						error={errors.hasOwnProperty("creative_file")}
						required
					>
						<label>
							{intl.formatMessage({
								id: "LABEL_ASSET",
								defaultMessage: "Asset",
							})}
						</label>
						<Ref innerRef={fileRef}>
							{is_edit_mode ? (
								<span>{initialData.creative_file}</span>
							) : (
								<FileInputComponent
									accept=".mp4"
									required={true}
									placeholder={intl.formatMessage({
										id: "HINT_ASSET_MP4",
										defaultMessage: "Upload a MP4 file from your computer",
									})}
								/>
							)}
						</Ref>
						<div className="custom-error">{errors["creative_file"]}</div>
					</Form.Field>

					<Form.Field
						inline
						error={errors.hasOwnProperty("clickthrough_url")}
						required
					>
						<label>
							{intl.formatMessage({
								id: "LABEL_CLICKTHROUGH_URL",
								defaultMessage: "Clickthrough URL",
							})}
						</label>
						<Input
							type="url"
							pattern="https?://.*\..*"
							title={intl.formatMessage({
								id: "ERROR_URL_PATTERN",
								defaultMessage: "must be a valid non-local HTTP URL",
							})}
							required
							name="clickthrough_url"
							defaultValue={values.clickthrough_url}
							placeholder="http://www.example.com/"
							style={{"width": "300px"}}
							onBlur={onChange}
						/>
						<div className="custom-error">{errors["clickthrough_url"]}</div>
					</Form.Field>

					<Form.Field
						inline
						error={errors.hasOwnProperty("landing_page_url")}
						required
					>
						<label>
							{intl.formatMessage({
								id: "LABEL_LANDING_PAGE_URL",
								defaultMessage: "Landing Page URL",
							})}
						</label>
						<Input
							type="url"
							pattern="https?://.*\..*"
							title={intl.formatMessage({
								id: "ERROR_URL_PATTERN",
								defaultMessage: "must be a valid non-local HTTP URL",
							})}
							required
							name="landing_page_url"
							defaultValue={values.landing_page_url}
							placeholder="http://www.example.com/"
							style={{"width": "300px"}}
							onBlur={onChange}
						/>
						<div className="custom-error">{errors["landing_page_url"]}</div>
					</Form.Field>
					<VideoFormContext.Provider value={{
						"errors": Object.assign(errors, additionalErrors.current)
					}}>
						{whitelabelCreativeCompanionAd && <VideoCompanionAdBlock
							values={values}
							updateAdditionalErrors={setAdditionalErrors}
							update={updateAdditionalFields}
						/>}
					</VideoFormContext.Provider>
				</>}
				<Divider hidden />
				<Divider hidden />
				<Divider hidden />
				<Form.Field align="right" disabled={pageData.advertisersLoading}>
					<Button size="tiny" type="button" onClick={onCancel}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" color="green" type="submit">
						{submitText}
					</Button>
				</Form.Field>
			</Form>
		</>
	);
};

const VideoCompanionAdBlock = ({values, update, updateAdditionalErrors}) => {
	const intl = useIntl();
	const [showBlock, setShowBlock] = React.useState(Boolean(values.companion_ad));
	return <>
		<Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_COMPANION_AD",
					defaultMessage: "Companion Ad",
				})}
			</label>
			{/* TODO: unhardcode this label: */}
			<Checkbox
				label="Hotstar Companion Banner"
				checked={showBlock}
				name="companion_ad"
				onClick={() => {
					update({"companion_ad": +(!showBlock)});
					setShowBlock(!showBlock)
				}}
			/>
		</Form.Field>
		{showBlock && <Segment secondary style={{"fontSize": "unset"}}>
			<AdditionalVideoCreativeFields root_values={values}
																		 updateAdditionalErrors={updateAdditionalErrors}
																		 update={update} />
		</Segment>}
	</>
};
VideoCompanionAdBlock.propTypes = {
	"values": PropTypes.object.isRequired,
	"update": PropTypes.func.isRequired
};


const AdditionalVideoCreativeFields = ({root_values, update, updateAdditionalErrors}) => {
	const intl = useIntl();

	let {values, errors, setErrors, onChange} = useForm(() => {}, {
		"brand_name": root_values.brand_name || "",
		"ad_title": root_values.ad_title || "",
		"logo_url": root_values.logo_url || "",
		"button_color": root_values.button_color || "#f2711c",
		"button_text_type": root_values.button_text_type || "standard",
		"button_text": root_values.button_text || default_button_text(intl)[0].value,
		"deep_link": root_values.deep_link || ""
	}, () => {});

	const context = React.useContext(VideoFormContext);

	// update parent component
	React.useEffect(() => {
		update(values);
	}, [values]);

	// update errors for root component
	React.useEffect(() => {
		updateAdditionalErrors(errors);
	}, [errors]);

	/**
	 * get value selected for button text dropdown
	 * @param {string} selected
	 * @returns {string}
	 */
	const getButtonTextSelected = selected => {
		const found = default_button_text(intl).find(x => x.value.toLowerCase() === selected.toLowerCase());
		return (found)? found.value : default_button_text(intl)[0].value;
	}

	/**
	 * update logotype
	 * @param e
	 */
	const onUpdateLogo = e => {
		const url = e.target.value;
		delete errors["logo_url"];
		setErrors({
			...errors
		});

		if(!url) {
			onChange(null, {"name": "logo_url", "value": ""});
			return;
		}

		if(!url.match(/png$/i)) {
			errors["logo_url"] = intl.formatMessage({
				id: "ERROR_LOGO_URL_WRONG_FORMAT",
				defaultMessage: "Logo should be in PNG format",
			});
			setErrors({
				...errors
			});
			return;
		}

		const i = new Image();
		i.onload = () => {
			if(i.width !== 120 || i.height !== 120) {
				errors["logo_url"] = intl.formatMessage({
					id: "ERROR_LOGO_URL_WRONG_DIMENSIONS",
					defaultMessage: "Logo should be 120x120",
				});
				setErrors({
					...errors
				});
				return;
			}

			onChange(null, {"name": "logo_url", "value": url});
		}

		i.onerror = () => {
			errors["logo_url"] = intl.formatMessage({
				id: "ERROR_LOGO_URL_INACCESSIBLE",
				defaultMessage: "Logo should be publicly accessible",
			});
			setErrors({
				...errors
			});
		};
		i.src = url;
	};

	/**
	 * verify and assign deep link
	 * @param e
	 */
	const onUpdateDeepLink = e => {
		const value = e.target.value;
		delete errors["deep_link"];
		setErrors({
			...errors
		});

		if(!value) {
			onChange(null, {"name": "deep_link", "value": ""});
			return;
		}

		if(!value.match(/^.+:\/\/.+\/.+/i)) {
			errors["deep_link"] = intl.formatMessage({
				id: "ERROR_DEEP_LINK_PATTERN",
				defaultMessage: "Please provide correct link",
			});
			setErrors({
				...errors
			});

			return;
		}

		onChange(null, {"name": "deep_link", "value": value});
	};

	return <>
		<Grid columns="equal" className="companion_creative">
			<Grid.Row>
				<Grid.Column>
					<Form.Field
						inline
						required
						error={context.errors.hasOwnProperty("brand_name")}>
						<label>
							{intl.formatMessage({
								id: "LABEL_BRAND_NAME",
								defaultMessage: "Brand Name",
							})}
						</label>
						<Input
							name="brand_name"
							required
							size="small"
							minLength={1}
							maxLength={20}
							defaultValue={values.brand_name}
							onChange={onChange}
						/>
						<div className="custom-error">{context.errors["brand_name"]}</div>
					</Form.Field>
					<Form.Field
						inline
						error={context.errors.hasOwnProperty("ad_title")}
						required>
						<label>
							{intl.formatMessage({
								id: "LABEL_AD_TITLE",
								defaultMessage: "Ad Title",
							})}
						</label>
						<Input
							name="ad_title"
							required
							size="small"
							minLength={1}
							maxLength={24}
							defaultValue={values.ad_title}
							onChange={onChange}
						/>
						<div className="custom-error">{context.errors["ad_title"]}</div>
					</Form.Field>
					<Form.Field
						inline
						error={context.errors.hasOwnProperty("logo_url")}
						required>
						<label>
							{intl.formatMessage({
								id: "LABEL_BRAND_LOGO_URL",
								defaultMessage: "Brand Logo URL",
							})}
						</label>
						<Input
							size="small"
							name="logo_url"
							required
							onBlur={onUpdateLogo}
							defaultValue={values.logo_url}
							style={{"width": "300px"}}
						/>
						<div className="custom-error">{context.errors["logo_url"]}</div>
					</Form.Field>
					<Form.Field inline>
						<label>
							{intl.formatMessage({
								id: "LABEL_BUTTON_COLOUR",
								defaultMessage: "Button Colour",
							})}
						</label>
						<Input
							type="color"
							size="small"
							name="button_color"
							defaultValue={values.button_color}
							onBlur={onChange}
						/>
					</Form.Field>
					<Form.Field inline>
						<label style={{"top": "-5px"}}>
							{intl.formatMessage({
								id: "LABEL_CREATIVE_BTN_TEXT",
								defaultMessage: "Button Text",
							})}
						</label>
						<div style={{"display": "inline-block"}}>
							<div>
								<Radio
									label={intl.formatMessage({
										id: "CREATIVE_BTN_TEXT_STANDARD",
										defaultMessage: "Standard",
									})}
									name="button_text_type"
									value="standard"
									checked={values.button_text_type === "standard"}
									style={{"marginRight": "20px"}}
									onClick={onChange}
								/>
								<Radio
									label={intl.formatMessage({
										id: "CREATIVE_BTN_TEXT_CUSTOM",
										defaultMessage: "Custom",
									})}
									value="custom"
									checked={values.button_text_type === "custom"}
									name="button_text_type"
									onClick={onChange}
								/>
							</div>
							<div style={{"marginTop": "15px"}}>
								{values.button_text_type === "standard" && <Select
									options={default_button_text(intl)}
									name="button_text"
									size="small"
									value={getButtonTextSelected(values.button_text)}
									onChange={onChange}
								/>}
								{values.button_text_type === "custom" && <Input
										minLength={1}
										maxLength={10}
										name="button_text"
										size="small"
										value={values.button_text}
										onChange={onChange}
									/>}
							</div>
						</div>
					</Form.Field>
					<Form.Field
						inline
						error={context.errors.hasOwnProperty("deep_link")}>
						<label>
							{intl.formatMessage({
								id: "LABEL_MOBILE_DEEP_LINK",
								defaultMessage: "Mobile Deep Link",
							})}
						</label>
						<Input
							type="text"
							size="small"
							name="deep_link"
							defaultValue={values.deep_link}
							style={{"width": "300px"}}
							onBlur={onUpdateDeepLink}
						/>
						<div className="custom-error">{context.errors["deep_link"]}</div>
					</Form.Field>
				</Grid.Column>
				<Grid.Column>
					<BannerCompanionComponent {...values} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</>;
};
AdditionalVideoCreativeFields.propTypes = {
	"root_values": PropTypes.object.isRequired,
	"update": PropTypes.func.isRequired
};



export default CreativeVideoForm;
