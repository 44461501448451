export const statuses = (intl) => [
	{
		"text": intl.formatMessage({
			id: "STATUS_ALL",
			defaultMessage: "All",
		}),
		"value": "all",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_ACTIVE",
			defaultMessage: "Active",
		}),
		"value": "active",
	},
	{
		"text": intl.formatMessage({
			id: "STATUS_INACTIVE",
			defaultMessage: "Inactive",
		}),
		"value": "inactive",
	},
];

export default class Filter {
	constructor() {
		this.reset();
	}

	/**
	 * update campaign status
	 * @param {string} value
	 */
	setStatus(value) {
		this.status = value;
	}

	/**
	 * update pixel name
	 * @param {string} value
	 */
	setTitle(value) {
		this.title = value.trim().toLowerCase();
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.status = item.status;
		this.title = item.title;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.status = "all";
		this.title = "";
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"status": this.status,
			"title": this.title,
		};
	}

	/**
	 * transform data into request json
	 * @returns {object}
	 */
	toRequestJson() {
		const params = {};

		if (this.status !== "all") {
			params["pixel_status"] = this.status;
		}

		if (this.title !== "") {
			params["pixel_name"] = this.title;
		}

		return params;
	}

	/**
	 * check that user didn't touch filter
	 * @return {boolean}
	 */
	isEmpty() {
		return this.status === "all" && this.title === "";
	}
}
