import React, {useCallback, useState, useEffect} from "react";
import {Dropdown, Form, Popup, Icon, Label, Message } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useFlags } from "launchdarkly-react-client-sdk";

import {goal_options} from "../fixtures";
import PixelsService from "../../../services/pixels";
import AgenciesService from "../../../services/agencies";
import DecimalInput from "../../common/decimal_input";
import { Config } from "../../../config/api";

/**
 * generate goal type component following a few rules
 * @param goalTypes
 * @param loading
 * @param disabled
 * @param currency
 * @param onChange
 * @param errors
 * @param values
 * @return {*}
 * @constructor
 */
const GoalTypeComponent = ({
	goalTypes,
	loading,
	disabled,
	currency,
	onChange,
	errors,
	values,
}) => {
	const intl = useIntl();
	const isPG = (campaign) => campaign.toLowerCase() === "pg";
	//ROI/CPA Margin Notice - Disabled notice at WMX request 03/26/24
	//const { whitelabelRoiCpaCampaignMargin } = useFlags();
	//const isRoiCpaCampaignMargin = Boolean(~["roi", "cpa"].indexOf(values.goal_type.toLowerCase()))
	//const displayRoiCpaCampaignMargin = whitelabelRoiCpaCampaignMargin && isRoiCpaCampaignMargin;
	const displayRoiCpaCampaignMargin = false;

	/**
	 * check we need additional fields based on goal_type
	 * @param {string} goal_type
	 * @returns {boolean}
	 */
	const goalAdditionalFieldsToBeGenerated = goal_type => {
		return Boolean(~["roi", "cpa"].indexOf(goal_type.toLowerCase()));
	};

	/**
	 * get selected goal object
	 * @param {array} loadedGoals
	 * @param {string} selectedGoal
	 * @return {*}
	 */
	const getGoalType = (loadedGoals, selectedGoal) => {
		return loadedGoals.find((x) => x.value === selectedGoal);
	};

	/**
	 * generate goal value component based on selected goal type
	 * @param {string} goal_type
	 * @param {function} onChange
	 * @param {string} value
	 * @param {string} currency_code
	 * @return {JSX.Element}
	 */
	const getGoalValueField = (goal_type, onChange, value, currency_code) => {
		let component = <></>;

		const goal = getGoalType(goalTypes, values.goal_type);
		switch (goal_type) {
			case "roi":
				component = (
					<GoalTypeValueROI
						value={value}
						min={!goal? 0 : goal.minimum}
						max={!goal? 0 : goal.maximum}
						step={0.01}
						places={2}
						onChange={onChange}
						currency={currency_code}
					/>
				);
				break;

			case "percent":
				component = (
					<GoalTypeValuePercent
						value={value}
						min={!goal? 0 : goal.minimum}
						max={!goal? 0 : goal.maximum}
						step={0.01}
						places={2}
						onChange={onChange}
					/>
				);
				break;

			case "currency":
			default:
				component = (
					<GoalTypeValueCurrency
						value={value}
						min={!goal? 0 : goal.minimum}
						max={!goal? 0 : goal.maximum}
						step={0.01}
						places={2}
						onChange={onChange}
						currency={currency_code}
					/>
				);
				break;
		}

		return component;
	};

	return (
		<>
			<Form.Group inline>
				<Form.Field required>
					<label>
						{intl.formatMessage({
							id: "LABEL_GOAL",
							defaultMessage: "Goal",
						})}
						{isPG(values.campaign_type) && (
							<Popup
								inverted
								content={intl.formatMessage({
									id: "HINT_GOAL_PG",
									defaultMessage: "The Goal Type / Value will not affect campaign optimization. Use this field for reporting purposes only.",
								})}
								size="mini"
								trigger={
									<Icon
										name="help circle"
										style={{ "position": "relative" }}
										className="cursor-help"
									/>
								}
							/>
						)}
					</label>
				</Form.Field>
				<Form.Field disabled={disabled}>
					<Dropdown
						selection
						options={goalTypes}
						loading={loading}
						onChange={onChange}
						value={values.goal_type}
						name="goal_type"
					/>
				</Form.Field>
				<Form.Field error={errors.hasOwnProperty("goal_value")}>
					{getGoalValueField(
						goal_options.filter((x) => x.value === values.goal_type)[0].display,
						onChange,
						values.goal_value.toString(),
						currency || ""
					)}
					<div className="custom-error" style={{ "marginLeft": "11px" }}>
						{errors["goal_value"]}
					</div>
				</Form.Field>
			</Form.Group>
			{
				displayRoiCpaCampaignMargin && <Message color="blue">
					{
						intl.formatMessage(
							{
								id: "ROI_CPA_ATTRIBUTION_MSG_FEE_NOTIFICATION",
								defaultMessage: `For ROI/CPA campaigns, a ${Config.roi_cpa_campaign_margin}% attribution measurement fee will be charged on the total spend.`,
							},
							{ roi_cpa_campaign_margin: Config.roi_cpa_campaign_margin }
						)
					}
				</Message>
			}
			{goalAdditionalFieldsToBeGenerated(values.goal_type) && (
				<GoalRoiCPAFields onChange={onChange} errors={errors} {...values} />
			)}
		</>
	);
};

const GoalRoiCPAFields = React.memo(({
	onChange,
	advertiser_id,
	merit_pixel_id, pixel_name,
	pc_window_days,
	pv_window_days,
	errors,
}) => {
	const intl = useIntl();
	const [pixels, setPixels] = useState({
		"loading": false,
		"data": [{"value": "", "text": "Select an Advertiser first!"}]
	});
	const services = React.useRef(new Map([["pixels", new PixelsService()],
		["agencies", new AgenciesService()]]));

	/**
	 * generate list of default values
	 * @returns {array}
	 */
	const defaultWindowValues = useCallback(() => {
		return [...Array(30).keys()].map(n => {
			const i = n + 1;
			return {
				"text": i.toString(), "value": i
			}
		})
	}, []);

	const pixelPlaceholder = (advertiser_id > 0)? "Please choose pixel" : "Select an Advertiser first!";

	// handle pixels
	useEffect(() => {
		if(advertiser_id) {
			const agency = services.current.get("agencies").getSelectedAgency() || 0;
			(async () => {
				try {
					setPixels({
						...pixels,
						"loading": true
					});

					const r = await services.current.get("pixels").active_by_advertiser(agency, advertiser_id);
					let data = r.data.map(n => ({
						"text": n.title,
						"value": n.id,
						"key": n.id
					}));

					// check and add missed inactive pixel if not in original list
					if(!data.find(x => x.key === merit_pixel_id)) {
						data.push({
							"text": pixel_name,
							"value": merit_pixel_id,
							"key": merit_pixel_id
						});
					}

					setPixels({
						"loading": false,
						data
					});
				} catch (e) {
					console.error(e);
					setPixels({
						...pixels,
						"loading": false
					});
				}
			})();
		}
	}, [advertiser_id])

	const options = defaultWindowValues();
	return <>
		<Form.Field
			inline
			required
			error={Boolean(errors["merit_pixel_id"]) || pixels.data.length === 0}
		>
			<label>
				{intl.formatMessage({
					id: "LABEL_CONVERSION_PIXEL",
					defaultMessage: "Conversion Pixel",
				})}
			</label>
			<Dropdown
				selection
				options={pixels.data}
				loading={pixels.loading}
				clearable={merit_pixel_id > 0}
				disabled={pixels.loading || !advertiser_id}
				onChange={onChange}
				noResultsMessage={intl.formatMessage({
					id: "EMPTY_SEARCH_RESULTS",
					defaultMessage: "No results found",
				})}
				placeholder={pixelPlaceholder}
				value={merit_pixel_id}
				name="merit_pixel_id"
			/>
			<div className="custom-error">
				{pixels.data.length === 0
					? intl.formatMessage({
							id: "ERROR_NO_PIXELS_AVAILABLE",
							defaultMessage: "You don’t have any tracking pixels for this advertiser. Please create a pixel to use this goal type.",
						})
					: errors["merit_pixel_id"]}
			</div>
		</Form.Field>
		<Form.Group inline>
			<Form.Field required>
				<label>
					{intl.formatMessage({
						id: "LABEL_ATTRIBUTION_WINDOW",
						defaultMessage: "Attribution window",
					})}
				</label>
			</Form.Field>
			<Form.Field width={3}>
				<strong>
					{intl.formatMessage({
						id: "LABEL_CLICK_WINDOW",
						defaultMessage: "Click window",
					})}
				</strong>
				<Dropdown
					selection
					compact
					options={options}
					onChange={onChange}
					value={pc_window_days}
					name="pc_window_days"
				/>
				<strong style={{"marginLeft": ".85714286em"}}>
					{intl.formatMessage({
						id: "LABEL_DAYS",
						defaultMessage: "Days",
					})}
				</strong>
			</Form.Field>
			<Form.Field width={3}>
				<strong>
					{intl.formatMessage({
						id: "LABEL_VIEW_WINDOW",
						defaultMessage: "View window",
					})}
				</strong>
				<Dropdown
					selection
					compact
					options={options}
					onChange={onChange}
					value={pv_window_days}
					name="pv_window_days"
				/>
				<strong style={{"marginLeft": ".85714286em"}}>
					{intl.formatMessage({
						id: "LABEL_DAYS",
						defaultMessage: "Days",
					})}
				</strong>
			</Form.Field>
		</Form.Group>
	</>;
}, (prev, next) => {
	return JSON.stringify(prev) === JSON.stringify(next);
});
GoalRoiCPAFields.propTypes = {
	"pc_window_days": PropTypes.number.isRequired,
	"pv_window_days": PropTypes.number.isRequired,
	"onChange": PropTypes.func.isRequired
};

const GoalTypeValueROI = ({ value, min, max, step, places, onChange, currency }) => {
	const intl = useIntl();

	return (
		<>
			<DecimalInput
				name="goal_value"
				required
				type="number"
				min={min}
				max={max}
				step={step}
				places={places}
				defaultValue={value}
				onChange={onChange}
				labelPosition="right"
			>
				<Label>{currency}</Label>
				<input />
				<Label>
					{intl.formatMessage({
						id: "LABEL_AOV",
						defaultMessage: "AOV",
					})}
				</Label>
			</DecimalInput>
			<Popup
				inverted
				content={intl.formatMessage({
					id: "HINT_AOV",
					defaultMessage: "Please enter the average order value (AOV) for conversions attributed to this campaign. The ROI desired will be available for entry at the strategy level.",
				})}
				size="mini"
				trigger={
					<Icon
						name="help circle"
						style={{ "position": "relative" }}
						className="cursor-help"
					/>
				}
			/>
		</>
	);
};
GoalTypeValueROI.propTypes = {
	"value": PropTypes.string.isRequired,
	"min": PropTypes.number.isRequired,
	"max": PropTypes.number.isRequired,
	"step": PropTypes.number.isRequired,
	"onChange": PropTypes.func.isRequired,
	"currency": PropTypes.string.isRequired,
};

const GoalTypeValueCurrency = ({
	value,
	min,
	max,
	step,
	places,
	onChange,
	currency,
}) => {
	return (
		<>
			<DecimalInput
				name="goal_value"
				label={currency}
				required
				type="number"
				min={min}
				max={max}
				step={step}
				places={places}
				defaultValue={value}
				onChange={onChange}
			/>
		</>
	);
};
GoalTypeValueCurrency.propTypes = {
	"value": PropTypes.string.isRequired,
	"min": PropTypes.number.isRequired,
	"max": PropTypes.number.isRequired,
	"step": PropTypes.number.isRequired,
	"onChange": PropTypes.func.isRequired,
	"currency": PropTypes.string.isRequired,
};

const GoalTypeValuePercent = ({ value, min, max, step, places, onChange }) => {
	return (
		<>
			<DecimalInput
				name="goal_value"
				required
				type="number"
				min={min}
				max={max}
				step={step}
				places={places}
				label="%"
				labelPosition="right"
				defaultValue={value}
				onChange={onChange}
			/>
		</>
	);
};
GoalTypeValuePercent.propTypes = {
	"value": PropTypes.string.isRequired,
	"min": PropTypes.number.isRequired,
	"max": PropTypes.number.isRequired,
	"step": PropTypes.number.isRequired,
	"onChange": PropTypes.func.isRequired,
};

export default GoalTypeComponent;
