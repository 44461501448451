import React, {useCallback, useState, useEffect, useContext, useRef} from "react";
import {Button, Divider, Form, Grid, Header, Message, Radio, Segment, TextArea} from "semantic-ui-react";
import {useIntl} from "react-intl";
import CreativesService from "../../../../services/creatives";
import {useForm} from "../../../../libs/component_utils";
import {useFlags} from "launchdarkly-react-client-sdk";
import {OnlineContext} from "../../../../context/online-context";
import PropTypes from "prop-types";
import {capitalizeFirstLetter, isApiDateTimeExpired, isExists, isNil} from "../../../../libs/common_utils";
import {
  approval_statuses,
  expansion_directions,
  expansion_triggers,
  file_types,
  mraid_types,
  tag_types
} from "../../fixtures";
import {ApprovalStatuses} from "../../../../models/enum/creatve_approval_status";


const AdminCreativeApprovalEditPage = ({history, match}) => {
  const intl = useIntl();
  const creativesService = new CreativesService();
  const creative_id = parseInt(match.params.creative_id, 10) || 0;
  let _isMounted = React.useRef(false);
  const back_url = "/admin/onsite-creatives/";
  const {currentUserIsAdmin} = useContext(OnlineContext);
  const {whitelabelDoubleCreativeApproval} = useFlags();

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  let isThirdPartyCreative = false;
  let isExpired = useRef(false);
  let [firstPartyCreativeDimension, setFirstPartyCreativeDimension] = useState({});

  /**
   * form submit handler
   * @param {object} values
   */
  const handleSubmit = async values => {
    setLoading(true);

    try {
      let params = {
        "approval_status": values.status
      };

      if(params.approval_status === ApprovalStatuses.REJECTED) {
        params["rejected_reason"] = values.rejected_reason;
      }

      await creativesService.update_local(creative_id, params);
      history.push(back_url, {"action": "confirmed", "title": values.title, "status": params.approval_status});
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if(_isMounted.current) {
        setLoading(false);
      }
    }
  };

  let {values, errors, onChange, updateValues, onSubmit} = useForm(handleSubmit, {"status": ApprovalStatuses.APPROVED}, () => {
    return [];
  });

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(back_url);
  }, []);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(back_url, {"action": "error", "msg": intl.formatMessage({
        "id": "ERROR_CREATIVE_NOT_FOUND",
        "defaultMessage": "No creative found"
      })});
  };

  /**
   * get media type translation
   * @param {string} media_type
   * @returns {string}
   */
  const media_type_translation = media_type => {
    return media_type === "display"? intl.formatMessage({
      id: "MEDIA_TYPE_DISPLAY",
      defaultMessage: "Display"
    }) : intl.formatMessage({
      id: "MEDIA_TYPE_VIDEO",
      defaultMessage: "Video"
    });
  }

  /**
   * get expansion value
   * @param {string} expansion_direction
   * @returns {string}
   */
  const get_expansion_direction_value = expansion_direction => {
    const directions = expansion_direction.split(",").map(x => x.replace(/\s/g, ""));
    return directions.map(x => expansion_directions(intl).find(node => node.value === x).text).join(", ");
  }

  /**
   * get expansion trigger
   * @param {string} trigger_value
   * @returns {string}
   */
  const get_expansion_trigger_value = trigger_value => {
    return expansion_triggers(intl).find(node => node.value === trigger_value).text;
  }

  /**
   * get tag type value
   * @param {string} tag_type
   * @returns {string}
   */
  const get_tag_type_value = tag_type => {
    return tag_types(intl).find(node => node.value === tag_type).text;
  }

  /**
   * get file type value
   * @param {string} file_type
   * @returns {string}
   */
  const get_file_type_value = file_type => {
    return file_types(intl).find(node => node.value === file_type).text;
  };

  /**
   * get mraid type value
   * @param {string} mraid_type
   * @returns {string}
   */
  const get_mraid_type_value = mraid_type => {
    return mraid_types(intl).find(node => node.value === mraid_type).text;
  };

  /**
   * format creative end date
   * @param {string} end_date
   * @returns {string}
   */
  const get_end_date = end_date => {
    let creative_end_date = end_date;
    if(isApiDateTimeExpired(end_date)) {
      const expired_translation = intl.formatMessage({
        id: "TEXT_EXPIRED",
        defaultMessage: "expired"
      });
      creative_end_date = `${creative_end_date} (${expired_translation})`;
    }

    return creative_end_date;
  };

  /**
   * check that we need to show info or control blocks
   * @param {object} creative
   * @returns {boolean}
   */
  const isSavable = creative => {
    return creative.approval_status === ApprovalStatuses.PENDING;
  };

  if(creative_id < 1) {
    handle404();
  }

  if(!currentUserIsAdmin || !whitelabelDoubleCreativeApproval) {
    handleCancel();
  }

  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await creativesService.get_creative_approval(creative_id);
        isThirdPartyCreative = r.data.hasOwnProperty("display_asset");
        isExpired.current = isApiDateTimeExpired(r.data.end_date);

        let initialStatus = r.data.approval_status,
          rejectedReason = r.data.rejected_reason;
        if(r.data.approval_status === ApprovalStatuses.PENDING) {
          initialStatus = (isExpired.current)? ApprovalStatuses.REJECTED : ApprovalStatuses.APPROVED;
          rejectedReason = (isExpired.current)? intl.formatMessage({
            id: "VALUE_CREATIVE_REJECT_REASON",
            defaultMessage: "The creative has expired and will not be reviewed. Please create a new creative and submit for approval.",
          }) : rejectedReason;
        }

        updateValues({
          "status": initialStatus,
          ...r.data,
          "rejected_reason": rejectedReason
        });
      } catch (e) {
        history.push(back_url);
      }
    })();

    return () => {
      _isMounted.current = false;
    }
  }, [creative_id]);

  let mraid = "";
  if(isExists(values, "mraid")) {
    if(values.mraid === 0) {
      mraid = intl.formatMessage({
        id: "LABEL_NO",
        defaultMessage: "No"
      });
    } else if(isExists(values, "mraid_type")) {
      mraid = get_mraid_type_value(values.mraid_type);
    } else {
      mraid = intl.formatMessage({
        id: "LABEL_YES",
        defaultMessage: "Yes"
      });
    }
  }

  if (!values.id) {
    return (<Segment disabled tertiary textAlign="center" className="loading">
      &nbsp;
    </Segment>);
  }

  return <Segment basic>
    <Header as="h2">{intl.formatMessage({
          id: "HEADING_ONSITE_CREATIVE_APPROVAL",
          defaultMessage: "Onsite Creative Approval",
        })}
    </Header>
    <Divider />
    <Message
      style={{ "marginTop": "10px" }}
      error
      hidden={!serverError}
      size="tiny"
      content={serverError}
    />
    <Form
      onSubmit={onSubmit}
      size="small"
      loading={loading}
      noValidate
      autoComplete="off"
    >
      <Grid className="creative_approval_info">
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{intl.formatMessage({
              id: "LABEL_CREATIVE_NAME",
              defaultMessage: "Creative Name",
            })}</strong>
            <div title={values.title} className="creative_name">{values.title}</div>
          </Grid.Column>
          <Grid.Column width={4}>
            <strong>{intl.formatMessage({
              id: "LABEL_ADVERTISER",
              defaultMessage: "Advertiser",
            })}</strong>
            {values.advertiser_name}
          </Grid.Column>
          <Grid.Column width={4}>
            <strong>{intl.formatMessage({
              id: "LABEL_CREATIVE_TYPE",
              defaultMessage: "Creative Type",
            })}</strong>
            {intl.formatMessage({
              id: "LABEL_CREATIVE_TYPE_ON_SITE",
              defaultMessage: "On-Site",
            })}
          </Grid.Column>
          <Grid.Column width={4}>
            <strong>{intl.formatMessage({
              id: "LABEL_CHANNEL",
              defaultMessage: "Channel",
            })}</strong>
            {media_type_translation(values.media_type)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{"paddingBottom": "0"}}>
          <Grid.Column className="creative-preview-container tree-loading">
            <CreativeRenderer
              creative={values}
              maxHeight="398"
              setFirstPartyCreativeDimension={setFirstPartyCreativeDimension}
            />
          </Grid.Column>
        </Grid.Row>
        <div align="center" id="creative_resized_id">{intl.formatMessage({
          id: "NOTE_CREATIVE_RESIZED",
          defaultMessage: "Note: The creative has been resized to fit this review page.",
        })}</div>
      </Grid>
      <Divider hidden />
      <div style={{"width": "50%", "margin": "auto"}}>
        <div style={{"maxHeight": "300px", "overflowY": "auto"}}>
          {isExists(values, "end_date") && <Form.Field inline>
            <label style={{"marginRight": 0}}>
              {intl.formatMessage({
                id: "LABEL_END_DATE",
                defaultMessage: "End Date",
              })}
            </label>
            <span className={isApiDateTimeExpired(values.end_date)? "red" : ""}>{get_end_date(values.end_date)}</span>
          </Form.Field>}
          {isExists(values, "ad_server") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_AD_SERVER_NAME",
                defaultMessage: "Ad Server Name"
              })}
            </label>
            {values.ad_server}
          </Form.Field>}
          {isExists(values, "tag_type") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_AD_TAG_TYPE",
                defaultMessage: "Ad Tag Type",
              })}
            </label>
            {get_tag_type_value(values.tag_type)}
          </Form.Field>}
          {isExists(values, "tpas_tag_id") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_3PAS_TAG_ID",
                defaultMessage: "3PAS Tag ID",
              })}
            </label>
            {values.tpas_tag_id}
          </Form.Field>}
          {isExists(values, "file_type") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_FILE_TYPE",
                defaultMessage: "File Type"
              })}
            </label>
            {get_file_type_value(values.file_type)}
          </Form.Field>}
          {isExists(values, "width") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_DIMENSIONS",
                defaultMessage: "Dimensions"
              })}
            </label>
            {values.width} x {values.height}
          </Form.Field>}
          {isExists(firstPartyCreativeDimension, "width") &&
           isExists(firstPartyCreativeDimension, "height") &&
            <Form.Field inline>
              <label>
                {intl.formatMessage({
                  id: "LABEL_DIMENSIONS",
                  defaultMessage: "Dimensions"
                })}
              </label>
              {firstPartyCreativeDimension.width} x {firstPartyCreativeDimension.height}
            </Form.Field>
          }
          {isExists(values, "mraid") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_MRAID",
                defaultMessage: "MRAID",
              })}
            </label>
            {mraid}
          </Form.Field>}
          {isExists(values, "is_expandable") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_EXPANDABLE",
                defaultMessage: "Expandable",
              })}
            </label>
            {values.is_expandable === 1? intl.formatMessage({
              id: "LABEL_YES",
              defaultMessage: "Yes"
            }) : intl.formatMessage({
              id: "LABEL_NO",
              defaultMessage: "No"
            })}
          </Form.Field>}
          {isExists(values, "expansion_trigger") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_EXPANSION_TRIGGER",
                defaultMessage: "Expansion Trigger",
              })}
            </label>
            {get_expansion_trigger_value(values.expansion_trigger)}
          </Form.Field>}
          {isExists(values, "expansion_direction") && <Form.Field inline>
            <label>
              {intl.formatMessage({
                id: "LABEL_EXPANSION_DIRECTION",
                defaultMessage: "Expansion Direction",
              })}
            </label>
            {get_expansion_direction_value(values.expansion_direction)}
          </Form.Field>}
          {isExists(values, "clickthrough_url") && <Form.Field inline style={{"display": "flex"}}>
            <label>
              {intl.formatMessage({
                id: "LABEL_CLICKTHROUGH_URL",
                defaultMessage: "Clickthrough URL",
              })}
            </label>
            <a href={values.clickthrough_url} target="_blank" style={{"wordBreak": "break-all", "width": "540px"}}>{values.clickthrough_url}</a>
          </Form.Field>}
          {isExists(values, "landing_page_url") && <Form.Field inline style={{"display": "flex"}}>
            <label>
              {intl.formatMessage({
                id: "LABEL_LANDING_PAGE_URL",
                defaultMessage: "Landing Page"
              })}
            </label>
            <a href={values.landing_page_url} target="_blank" style={{"wordBreak": "break-all", "width": "540px"}}>{values.landing_page_url}</a>
          </Form.Field>}
          {isExists(values, "ad_tag") && <Form.Field inline>
            <label style={{"verticalAlign": "top", "marginRight": "0"}}>
              {intl.formatMessage({
                id: "LABEL_AD_TAG",
                defaultMessage: "Ad Tag"
              })}
            </label>
            <div>{values.ad_tag}</div>
          </Form.Field>}
        </div>
        {isSavable(values)? <OnsiteCreativesStatusControl intl={intl} onChange={onChange} errors={errors}
                                                          status={values.status} rejected_reason={values.rejected_reason}
                                                          isExpired={isExpired.current} /> :
          <OnsiteCreativesStatusInfo intl={intl} values={values} isExpired={isExpired.current} />}
      </div>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Form.Field align="right">
        <Button size="tiny" type="button" onClick={handleCancel}>
          {intl.formatMessage({
            id: "BTN_CANCEL",
            defaultMessage: "Cancel",
          })}
        </Button>
        {isSavable(values) && <Button size="tiny" color="green" type="submit">
          Save Changes
        </Button>}
      </Form.Field>
    </Form>
  </Segment>
};
AdminCreativeApprovalEditPage.propTypes = {
  "history": PropTypes.object.isRequired,
  "match": PropTypes.object.isRequired
};

const OnsiteCreativesStatusControl = ({intl, status, rejected_reason, errors, onChange, isExpired}) => {
  return <>
    <Form.Field inline style={{"marginTop": "1em"}}>
      <label style={{"marginRight": "0"}}>
        {intl.formatMessage({
          id: "LABEL_APPROVAL_STATUS",
          defaultMessage: "Approval Status"
        })}
      </label>
      <Radio
        name="status"
        label={intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_APPROVED",
          defaultMessage: "Approved",
        })}
        value={ApprovalStatuses.APPROVED}
        checked={status !== ApprovalStatuses.REJECTED}
        onChange={onChange}
        disabled={isExpired}
      />
      <Radio
        style={{"marginLeft": "15px"}}
        name="status"
        label={intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_REJECTED",
          defaultMessage: "Rejected",
        })}
        value={ApprovalStatuses.REJECTED}
        checked={status === ApprovalStatuses.REJECTED}
        onChange={onChange}
      />
    </Form.Field>
    {status === ApprovalStatuses.REJECTED && <Form.Field required error={errors.hasOwnProperty("rejected_reason")} inline>
      <label style={{"marginRight": "0"}}>
        {intl.formatMessage({
          id: "LABEL_REJECTION_REASON",
          defaultMessage: "Rejection Reason"
        })}
      </label>
      <TextArea
        style={{"width": "650px"}}
        name="rejected_reason"
        rows="5"
        required={true}
        onBlur={onChange}
        defaultValue={rejected_reason}
      />
      <div className="custom-error" style={{"marginLeft": 0}}>{errors["rejected_reason"]}</div>
    </Form.Field>}
  </>;
};
OnsiteCreativesStatusControl.propTypes = {
  "intl": PropTypes.object.isRequired,
  "errors": PropTypes.object.isRequired,
  "status": PropTypes.string.isRequired,
  "onChange": PropTypes.func.isRequired,
  "isExpired": PropTypes.bool.isRequired,
  "rejected_reason": PropTypes.string
};

const OnsiteCreativesStatusInfo = ({intl, values, isExpired}) => {
  const status_translate = (isExpired)? capitalizeFirstLetter(intl.formatMessage({
    id: "TEXT_EXPIRED",
    defaultMessage: "expired"
  })) : approval_statuses(intl).get(values.approval_status);

  return <>
    <Form.Field inline style={{"marginTop": "1em"}}>
      <label style={{"marginRight": "0"}}>
        {intl.formatMessage({
          id: "LABEL_APPROVAL_STATUS",
          defaultMessage: "Approval Status"
        })}
      </label>
      {status_translate}
    </Form.Field>
    {values.approval_status === ApprovalStatuses.REJECTED && <Form.Field inline>
      <label style={{"marginRight": "0"}}>
        {intl.formatMessage({
          id: "LABEL_REJECTION_REASON",
          defaultMessage: "Rejection Reason"
        })}
      </label>
      {values.rejected_reason}
    </Form.Field>}
  </>;
};
OnsiteCreativesStatusInfo.propTypes = {
  "intl": PropTypes.object.isRequired,
  "values": PropTypes.object.isRequired,
  "isExpired": PropTypes.bool.isRequired
};

const CreativeRenderer = React.memo(({creative, setFirstPartyCreativeDimension, maxWidth = null, maxHeight = null}) => {
    let scaleFactor = React.useRef(1);
    if (maxWidth !== null && creative.width) {
      scaleFactor.current = Math.min(maxWidth / creative.width, scaleFactor.current);
    }
    if (maxHeight !== null && creative.height) {
      scaleFactor.current = Math.min(maxHeight / creative.height, scaleFactor.current);
    }

    const [s3Preview, sets3Preview] = React.useState(null);

    React.useLayoutEffect(() => {
      window.addEventListener("message", function(e) {
        const message = e.data,
          iframe = document.getElementsByTagName("iframe")[0];

        if(!message.hasOwnProperty("banner")) {
          return;
        }
        const resized_div = document.getElementById("creative_resized_id");
        if(resized_div) {
          resized_div.style.display = message["banner"]["scaleFactor"] < 1? "block" : "none";
        }
        document.querySelector(".creative-preview-container").classList.remove("tree-loading");

        const size = {"width": message["banner"].width, "height": message["banner"].height};
        size.width *= (message["banner"]["scaleFactor"] < 1)? message["banner"]["scaleFactor"] : 1;
        size.height *= (message["banner"]["scaleFactor"] < 1)? message["banner"]["scaleFactor"] : 1;

        iframe.style.width = (size.width)? `${size.width}px` : "auto";
        iframe.style.height = (size.height)? `${size.height}px` : "auto"; // sometimes scrollHeight we receive as 0, set default to auto
        iframe.classList.add("fade-in");
      }, false);
    }, []);

    React.useEffect(() => {
      if(isExists(creative, "display_asset")) {
        const img = new Image();

        img.onload = () => {
          scaleFactor.current = Math.min(maxHeight / img.height, scaleFactor.current);
          setFirstPartyCreativeDimension({width: img.width, height: img.height})
          sets3Preview(<><img src={img.src} alt={img.title} style={{transform: scaleFactor.current < 1 ? `scale(${scaleFactor.current})` : "none"}} /></>);
        };

        img.onerror = () => {
          sets3Preview(<div>Cannot load preview</div>);
        }

        img.src = creative["display_asset"];
      }

      return () => {
        document.getElementById("creative_resized_id").style.display = "none";
      }
    }, [creative.display_asset]);

    const resized_div = document.getElementById("creative_resized_id");
    if(resized_div) {
      resized_div.style.display = scaleFactor.current < 1? "block" : "none";
    }

    if(!isNil(s3Preview)) {
      document.querySelector(".creative-preview-container").classList.remove("tree-loading");
      return s3Preview;
    }

    return creative.ad_tag? <>
      <iframe
      srcDoc={`<html lang="en-us"><body style="margin: 0; padding: 0; overflow: hidden;display:flex;align-items: center;justify-content: center;"><script>window.maxHeight=${maxHeight}; window.onload=function() { 
  const scaleFactor = Math.min(window.maxHeight / (this.document.body.scrollHeight || 1));
  if(scaleFactor < 1) {
    /*
    window.document.body.style["transform"] = "scale(${scaleFactor})";
    window.document.body.style["-o-transform"] = "scale(${scaleFactor})";
    window.document.body.style["-webkit-transform"] = "scale(${scaleFactor})";
    window.document.body.style["-moz-transform"] = "scale(${scaleFactor})";
    console.log(window.document.body.style.transform);
    */
    window.document.head.insertAdjacentHTML("beforeend", "<style>body{transform-origin: 0 0; transform: scale(" + scaleFactor + ")}</style>");
  }
  
  parent.postMessage({"banner": {"scaleFactor": scaleFactor, "width": this.document.body.scrollWidth, "height": this.document.body.scrollHeight}}, "*");
}; </script>${creative.ad_tag}</body></html>`}
      style={{
        "border": "none",
        "opacity": 0,
        "height": "100%"
      }}
      title={`Ad by ${creative.advertiser_name}`}
      ></iframe></> : <></>;
})
CreativeRenderer.propTypes = {
  "creative": PropTypes.shape({
    "display_asset": PropTypes.string,
    "ad_tag": PropTypes.string,
    "advertiser_name": PropTypes.string.isRequired,
    "width": PropTypes.number,
    "height": PropTypes.number,
    "title": PropTypes.string.isRequired
  }).isRequired,
  "maxWidth": PropTypes.string,
  "maxHeight": PropTypes.string,
  "setFirstPartyCreativeDimension": PropTypes.func
};

export default AdminCreativeApprovalEditPage;
