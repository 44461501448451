import React, { useCallback, useState, useEffect } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PixelForm from "../form";
import PixelModal from "../modal";
import PixelsService from "../../../services/pixels/index.js";

/**
 * Pixel edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {string} match.params.pixel_id
 * @return {*}
 * @constructor
 */
const PixelEditPage = ({ history, match }) => {
	const back_url = "/pixels";
	const service = new PixelsService();
	const pixel_id = parseInt(match.params.pixel_id, 10) || 0;
	let _isMounted = React.useRef(false);
	const intl = useIntl();

	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	/**
	 * navigate user back
	 */
	const handleCancel = useCallback(() => {
		history.push(back_url);
	}, []);

	/**
	 * handle 404
	 */
	const handle404 = () => {
		history.push(back_url, { "action": "error", "msg": "No pixel found" });
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const handleSubmit = async values => {
		setLoading(true);

		// get object that contain only updated values
		let diff = Object.keys(initialObject.current).reduce((diff, key) => {
			if (values[key] === initialObject.current[key]) return diff;
			return {
				...diff,
				[key]: values[key],
			};
		}, {});

		if (diff?.status) {
			diff["status"] = Boolean(values.status);
		}

		try {
			await service.update(pixel_id, diff);
			history.push(back_url, {"action": "updated", "title": values.title});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setLoading(false);
			}
		}
	};

	if (pixel_id < 1) {
		handle404();
	}

	const [initialData, setInitialData] = useState(null);
	const initialObject = React.useRef(null);

	useEffect(() => {
		_isMounted.current = true;
		(async () => {
			try {
				const r = await service.get(pixel_id);
				initialObject.current = {...r.data};
				setInitialData(r.data);
			} catch (e) {
				handle404();
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, [pixel_id]);

	if (initialData === null) {
		return (
			<Segment disabled tertiary textAlign="center" className="loading">
				&nbsp;
			</Segment>
		);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_EDIT_PIXEL",
					defaultMessage: "Edit Pixel",
				})}
			</Header>
			<Divider />
			<Message
				style={{ "marginTop": "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<PixelForm
				initialData={initialData}
				formType="edit"
				loading={loading}
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				onOpenInstructionsModal={() => setModalOpen(true)}
			/>
			<PixelModal
				open={modalOpen}
				type="edit"
				id={initialData?.id}
				advertiser_id={initialData?.advertiser_id}
				values={initialData}
				onClose={() => setModalOpen(false)}
			/>
		</Segment>
	);
};

export default PixelEditPage;
