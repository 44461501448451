export default class SitesFilter {
	constructor() {
		this.reset();
	}

	/**
	 * update campaign status
	 * @param {string} value
	 */
	setStatus(value) {
		this.status = value;
	}

	/**
	 * update pixel name
	 * @param {string} value
	 */
	setTitle(value) {
		this.title = value.trim().toLowerCase();
	}

	setId(value) {
		this.id = value;
	}

	/**
	 * unpack item from json format
	 * @param {object} item
	 */
	fromJson(item) {
		this.status = item.status;
		this.title = item.title;
		this.id = item.id;
	}

	/**
	 * reset filter data
	 */
	reset() {
		this.status = "all";
		this.title = "";
		this.id = "";
	}

	/**
	 * Transform data into API object
	 * @returns {object}
	 */
	toJson() {
		return {
			"status": this.status,
			"title": this.title,
			"id": this.id
		};
	}

	/**
	 * transform data into request json
	 * @returns {object}
	 */
	toRequestJson() {
		const params = {};

		if (this.status !== "all") {
			params["site_list_status"] = this.status === "active" ? 1 : 0;
		}

		if (this.title !== "") {
			params["site_list_name"] = this.title;
		}

		if (this.id !== "") {
			params["site_list_id"] = this.id;
		}

		return params;
	}

	/**
	 * check that user didn't touch filter
	 * @return {boolean}
	 */
	isEmpty() {
		return this.status === "all" && this.title === "" && this.id === "";
	}
}
