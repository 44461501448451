import {Common} from "./index";

export const Days = Object.freeze({"MTWRFSU": "MTWRFSU",
	"DEFAULT": "MTWRFSU",
	"MTWRF": "MTWRF",
	"SU": "SU",
	"M": "M",
	"T": "T",
	"W": "W",
	"R": "R",
	"F": "F",
	"S": "S",
	"U": "U",
	...Common
});