export default class CreativeModel {
	constructor(id, status, title, advertiser_id, companion_ad, brand_name,
							ad_title, logo_url, button_color, button_text, deep_link,
							clickthrough_url, landing_page_url)
	{
		this.id = parseInt(id, 10) || 0;
		this.status = parseInt(status, 10) || 0;
		this.title = title || "";
		this.advertiser_id = parseInt(advertiser_id, 10) || 0;
		this.companion_ad = parseInt(companion_ad, 10) || 0;
		this.brand_name = brand_name || "";
		this.ad_title = ad_title || "";
		this.logo_url = logo_url || "";
		this.button_color = button_color || "";
		this.button_text = button_text || "";
		this.deep_link = deep_link || "";
		this.clickthrough_url = clickthrough_url || "";
		this.landing_page_url = landing_page_url || "";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new CreativeModel(json.id, json.status, json.title, json.advertiser_id, json.companion_ad,
			json.brand_name, json.ad_title,
			json.logo_url, json.button_color, json.button_text,
			json.deep_link, json.clickthrough_url, json.landing_page_url);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this
		};

		return json;
	}
}

class Creative {
	constructor(id, status, title, advertiser_id)
	{
		this.id = parseInt(id, 10) || 0;
		this.status = parseInt(status, 10) || 0;
		this.title = title || "";
		this.advertiser_id = parseInt(advertiser_id, 10) || 0;
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new Creative(json.id, json.status, json.title, json.advertiser_id);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this
		};

		return json;
	}
}

export class CreativeVideo3PModel extends Creative {
	constructor(id, status, title, advertiser_id,
							custom_vast_url, external_identifier, landing_page_url) {
		super(id, status, title, advertiser_id);
		this.third_party = 1;
		this.custom_vast_url = custom_vast_url || "";
		this.external_identifier = external_identifier || "";
		this.landing_page_url = landing_page_url || "";
	}

	/**
	 * transform data that comes from API/json
	 * @param {object} json
	 */
	static fromJson(json) {
		return new CreativeVideo3PModel(json.id, json.status, json.title, json.advertiser_id,
			json.custom_vast_url, json.external_identifier, json.landing_page_url);
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		const json = {
			...this
		};

		if(!json.id) {
			delete json.id;
		}

		if(!json.external_identifier) {
			delete json.external_identifier;
		}

		return json;
	}
}
