import AbstractService from "../abstract.service";

export default class LocaleService extends AbstractService {
	static URI_STATES = new Map([
		["locale.locales", "/locales"],
		["locale.translation", "/translation"],
	]);

	/**
	 * List available locales
	 * @returns {Promise<any | never>}
	 */
	listLocales() {
		return this.api_server.get(
			LocaleService.URI_STATES.get("locale.locales")
		);
	}

	getTranslationsForLocale(locale) {
		return this.api_server.get(
			LocaleService.URI_STATES.get("locale.translation"),
			{ locale }
		);
	}
}
