import Registry from "../libs/register_storage";
import HttpConnect from "../libs/http_connect";

export default class AbstractService {
	static URI_STATES = {
		"schedule": "/schedule/"
	};

	constructor() {
		this.registry = new Registry(window.localStorage);
		this.api_server = HttpConnect;
	}

	/**
	 * remove schedule from server by id
	 * @param {number} id
	 * @return {Promise}
	 */
	remove(id) {
		return this.api_server.remove(AbstractService.URI_STATES["schedule"] + id, {});
	}
}