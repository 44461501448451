import AuthService from "../../services/auth";
import Registry from "../../libs/register_storage";

class AuthController {
	constructor() {
		this.auth_service = new AuthService();
		this.userModel = this.auth_service.getUser();
		this.isAuthenticated = this.userModel.online;
		this.storage = new Registry(window.localStorage);
	}

	/**
	 * Clean user data
	 */
	cleanSession() {
		this.storage.removeAll();
		const session = new Registry(window.sessionStorage);
		session.removeAll();
	}

	/**
	 * Sign user in
	 * @returns {Promise<any | never | never>}
	 */
	async authenticate(user_data) {
		return this.auth_service.login(user_data).then(() => {
			this.userModel = this.auth_service.getUser();
			this.isAuthenticated = this.userModel.online;
			return this.userModel;
		});
	}

	/**
	 * call authorized endpoint
	 * @return {Promise<any|never>}
	 */
	async authorized() {
		return this.auth_service.authozied();
	}

	/**
	 * Clean stored user data
	 * @return {*}
	 */
	cleanStorage() {
		this.storage.removeAll();
		this.userModel = this.auth_service.getUser();
		this.isAuthenticated = false;
		return this.userModel;
	}

	/**
	 * Sign user out
	 * @returns {Promise<any | never | never>}
	 */
	async signout() {
		await this.auth_service.logout();
		this.userModel = this.auth_service.getUser();
		this.isAuthenticated = this.userModel.online;

		// clean all session items
		this.storage.removeAll();
		return this.userModel;
	}
}

export default AuthController;
