import sortBy from "lodash/sortBy";

export const reportActions = {
	"INIT": "INIT",
	"GET_PAGE": "GET_PAGE",
	"CHANGE_SORT": "CHANGE_SORT",
};

/**
 * Init items
 * @param {array} items
 * @param {object} state
 * @return {object} new state
 */
const initItems = (items, state) => {
	state.pager.reset();
	state.pager.setTotal({ "total_count": items.length });
	return {
		...state,
		items: items.slice(
			state.pager.offset,
			state.pager.offset + state.pager.limit
		),
		allItems: items,
		sortedBy: null,
		sortDirection: null,
	};
};

/**
 * Get another page of the current report
 * @param {array} items
 * @param {object} state
 * @return {object} new state
 */
const getPage = (nextActivePage, state) => {
	state.pager.setPage(nextActivePage);
	return {
		...state,
		items: state.allItems.slice(
			state.pager.offset,
			state.pager.offset + state.pager.limit
		),
	};
};

/**
 * Get another page of the current report
 * @param {string} column - column ID to sort by
 * @param {string} order - asc, desc
 * @param {object} state
 * @return {object} new state
 */
const sort = (column, state) => {
	state.pager.reset();

	if (state.sortedBy === column) {
		const allItems = state.allItems.reverse();
		return {
			...state,
			allItems,
			items: allItems.slice(
				state.pager.offset,
				state.pager.offset + state.pager.limit
			),
			sortDirection:
				state.sortDirection === "ascending" ? "descending" : "ascending",
		};
	}

	const allItems = sortBy(state.allItems, [column]);
	return {
		...state,
		allItems,
		items: allItems.slice(
			state.pager.offset,
			state.pager.offset + state.pager.limit
		),
		sortedBy: column,
		sortDirection: "ascending",
	};
};

export const reportGridReducer = (state, action) => {
	switch (action.type) {
		case reportActions.INIT:
			return initItems(action.data, state);

		case reportActions.GET_PAGE:
			return getPage(action.data, state);

		case reportActions.CHANGE_SORT:
			return sort(action.data, state);

		default:
			throw new Error("Method is not implemented");
	}
};
